import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import type {
  useNewSourcesOfFundsFragment$data,
  useNewSourcesOfFundsFragment$key,
} from '@onramp/data/__generated__/useNewSourcesOfFundsFragment.graphql';
import type { useNewSourcesOfFundsQuery as UseNewSourcesOfFundsQuery } from '@onramp/data/__generated__/useNewSourcesOfFundsQuery.graphql';
import { useLogOnrampEventOnce } from '@onramp/utils/eventing/useLogOnrampEvent';
import { useSourceOfFundsToCommonFormatRequiredData } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/useSourceOfFundsToCommonFormatRequiredData';
import { isNonEmpty } from '@onramp/utils/isNonEmpty';
import { assertPresence } from '@onramp/utils/relayUtils';
import { isNotNullish } from '@onramp/utils/types';
import { useIsApplePayAvailable } from '@onramp/utils/useIsApplePayAvailable';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import { z } from 'zod';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from '@cbhq/data-layer';

import { useIsApplePaySupportedWithActiveCard } from '../useIsApplePaySupportedWithActiveCard';
import { useRunDigitalWalletVerification } from '../useRunDigitalWalletVerification';

import { logEnabledNonZeroPaymentMethodsOnce } from './logEnabledNonZeroPaymentMethodsOnce';
import { messages } from './messages';
import { useParseSourcesOfFunds } from './useParseSourcesOfFunds';

export const useNewSourcesOfFundsQuery = graphql`
  query useNewSourcesOfFundsQuery {
    viewer {
      ...useNewSourcesOfFundsFragment
    }
  }
`;

export const fragment = graphql`
  fragment useNewSourcesOfFundsFragment on Viewer
  @refetchable(queryName: "useNewSourcesOfFundsRefetchableFragment") {
    ...useSourceOfFundsToCommonFormatRequiredData
    cbPaySourcesOfFunds(
      supportedSourcesOfFunds: ["PAYMENT_METHOD", "FIAT_WALLET", "CRYPTO_ACCOUNT"]
    ) {
      __typename
      ... on CBPaySourceOfFundsCryptoAccount {
        account {
          ...useParseSourcesOfFundsAccountFragment
        }
      }
      ... on CBPaySourceOfFundsPaymentMethod {
        isEnabled
        paymentMethod {
          ...useParseSourcesOfFundsPaymentMethodFragment
          pickerData {
            __typename
          }
        }
      }
      ... on CBPaySourceOfFundsFiatWallet {
        isEnabled
        account {
          ...useParseSourcesOfFundsAccountFragment
          ...useParseSourcesOfFundsAccountDepositInformationFragment
        }
        paymentMethod {
          ...useParseSourcesOfFundsPaymentMethodFragment
        }
      }
    }
    accountsV2 {
      edges {
        node {
          ...useParseSourcesOfFundsAccountFragment
        }
      }
    }
  }
`;

type CBPaySourceOfFunds = NonNullable<
  NonNullable<useNewSourcesOfFundsFragment$data['cbPaySourcesOfFunds']>[number]
>;

/**
 * You should only call this in another hook which wraps it (e.g. useCheckoutSourceOfFunds, useOnrampSourceOfFunds)
 */
export const useNewSourcesOfFunds = (nativeAssetUuid: string) => {
  const applePayEnabled = useIsApplePayAvailable();
  const { isApplePaySupportedWithActiveCard } = useIsApplePaySupportedWithActiveCard();
  const runDigitalWalletVerification = useRunDigitalWalletVerification();

  const queryData = useLazyLoadQuery<UseNewSourcesOfFundsQuery>(useNewSourcesOfFundsQuery, {
    applePayEnabled,
  });

  const [data, refetchSourcesOfFunds] = useRefetchableFragment(
    fragment,
    queryData.viewer as useNewSourcesOfFundsFragment$key,
  );
  const readSourceOfFundsData = useSourceOfFundsToCommonFormatRequiredData(data);

  const intl = useIntl();
  const { formatMessage } = intl;

  const fields = useMemo(() => {
    const cbPaySourcesOfFunds = assertPresence(data.cbPaySourcesOfFunds, {
      debugMessage: 'Failed to load CBPay sources of funds.',
      message: formatMessage(messages.missingFieldErrorMessage),
    }).filter(isNonEmpty);

    const accountsV2 = assertPresence(data.accountsV2, {
      debugMessage: 'Failed to load account V2 field.',
      message: formatMessage(messages.missingFieldErrorMessage),
    });

    const accounts = accountsV2.edges.map((edge) => edge.node).filter(isNonEmpty);
    const paymentMethods = cbPaySourcesOfFunds
      .filter(
        (el): el is CBPaySourceOfFunds & { __typename: 'CBPaySourceOfFundsPaymentMethod' } =>
          el.__typename === 'CBPaySourceOfFundsPaymentMethod',
      )
      .filter(isNonEmpty);

    const fiatWallets = cbPaySourcesOfFunds
      .filter(
        (el): el is Required<CBPaySourceOfFunds & { __typename: 'CBPaySourceOfFundsFiatWallet' }> =>
          el.__typename === 'CBPaySourceOfFundsFiatWallet' &&
          el.account !== null &&
          el.paymentMethod !== null &&
          el.isEnabled !== null,
      )
      .map((el) => ({
        account: assertPresence(el.account, {
          debugMessage:
            'Impossible error: `account` field is null despite objects with null fields being filtered out beforehand',
          message: formatMessage(messages.missingFieldErrorMessage),
        }),
        paymentMethod: assertPresence(el.paymentMethod, {
          debugMessage:
            'Impossible error: `paymentMethod` field is null despite objects with null fields being filtered out beforehand',
          message: formatMessage(messages.missingFieldErrorMessage),
        }),
        isEnabled: assertPresence(el.isEnabled, {
          debugMessage:
            'Impossible error: `isEnabled` field is null despite objects with null fields being filtered out beforehand',
          message: formatMessage(messages.missingFieldErrorMessage),
        }),
        __typename: el.__typename,
      }));

    return {
      accounts,
      paymentMethods,
      fiatWallets,
    };
  }, [data, formatMessage]);

  const paymentMethodsRef = fields.paymentMethods
    .map((sof) => {
      let isEnabled = sof.isEnabled ?? undefined;
      if (
        sof.paymentMethod?.pickerData?.__typename === 'ApplePayPickerDataV2' &&
        !isApplePaySupportedWithActiveCard
      ) {
        isEnabled = false;
      }

      return (
        sof.paymentMethod && {
          paymentMethod: sof.paymentMethod,
          isEnabled,
        }
      );
    })
    .filter(isNonEmpty);

  const parsedSourceOfFunds = {
    ...useParseSourcesOfFunds({
      inputAccountsRef: fields.accounts,
      paymentMethodsRef,
      fiatWalletRefs: {
        paymentMethodsRef: fields.fiatWallets.map((el) => ({
          paymentMethod: el.paymentMethod,
          isEnabled: el.isEnabled,
        })),
        inputAccountsRef: fields.fiatWallets.map((el) => el.account),
        depositInformationRef: fields.fiatWallets.map((el) => el.account),
      },
      readSourceOfFundsData,
      nativeAssetUuid,
    }),
    refetchSourcesOfFunds,
  };

  const applePay = parsedSourceOfFunds.fiatAccounts.find(
    ({ paymentMethodType }) => paymentMethodType === 'APPLE_PAY',
  );

  useEffect(() => {
    if (applePay?.verified === false) {
      void runDigitalWalletVerification({
        // payment method's uuid is passed in for both paymentMethodId and pmsvcId: https://github.cbhq.net/consumer/react-native/blob/712c54901db91015e728b8623a25b9d6ce167fbd/src/packages/app/src/hooks/payments/useRunDigitalWalletPaymentMethodVerification.ts#L58-L59
        paymentMethodId: applePay.uuid,
        pmsvcId: applePay.uuid,
      }).then(() => refetchSourcesOfFunds({}, { fetchPolicy: 'store-and-network' }));
    }
  }, [applePay?.uuid, applePay?.verified, refetchSourcesOfFunds, runDigitalWalletVerification]);

  const logOnrampEventOnce = useLogOnrampEventOnce();

  useEffect(() => {
    const enabledPaymentMethodTypesWithBalance = uniq(
      parsedSourceOfFunds.fiatAccounts
        .filter((sof) => !sof.isDisabled && Number(sof.limitData?.fiat) > 0)
        .map((pm) => pm.paymentMethodType)
        .filter(isNotNullish),
    );

    // crypto asset symbols (e.g. ETH, BTC, MATIC, etc.)
    const cryptoAssetsWithBalance = uniq(
      parsedSourceOfFunds.cryptoAccounts
        .filter((sof) => !sof.isDisabled && Number(sof.limitData?.fiat) > 0)
        .map((pm) => pm.meta)
        .filter(isNotNullish),
    );

    // currency codes (e.g. USD, BRL, GBP, etc.)
    const fiatCurrenciesWithBalance = uniq(
      parsedSourceOfFunds.cashAccounts
        .filter((sof) => !sof.isDisabled && Number(sof.limitData?.fiat) > 0)
        .map((pm) => pm.meta)
        .filter(isNotNullish),
    );

    const limitDataSchema = z
      .object({ value: z.coerce.number(), currency: z.string() })
      .optional()
      .catch(undefined);

    const paymentMethodsLimitData = parsedSourceOfFunds.fiatAccounts
      .map((pm) => limitDataSchema.parse(pm.limitData?.fiat))
      .filter(isNotNullish);

    const limitsPerCurrency = mapValues(
      groupBy(paymentMethodsLimitData, (limitData) => limitData.currency),
      (arr) => Math.max(...arr.map((limitData) => limitData.value)),
    );

    logOnrampEventOnce('source_of_funds_report', {
      enabledPaymentMethodTypesWithBalance,
      cryptoAssetsWithBalance,
      fiatCurrenciesWithBalance,
      highestPaymentMethodLimitPerCurrency: limitsPerCurrency,
    });
  });

  logEnabledNonZeroPaymentMethodsOnce({
    applePay,
    cashAccounts: parsedSourceOfFunds.cashAccounts,
    fiatAccounts: parsedSourceOfFunds.fiatAccounts,
    cryptoAccounts: parsedSourceOfFunds.cryptoAccounts,
  });

  return parsedSourceOfFunds;
};
