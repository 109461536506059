import { z } from 'zod';

const DepositInformationField = z.object({
  title: z.string(),
  value: z.string(),
});

export const FasterPaymentsDepositInformation = z.object({
  reference: DepositInformationField,
  account_number: DepositInformationField,
  account_name: DepositInformationField,
  sort_code: DepositInformationField,
  bank_name: DepositInformationField,
});

export const SepaDepositInformation = z.object({
  iban: DepositInformationField,
  account_name: DepositInformationField,
  bank_country_name: DepositInformationField, // Not needed?
  account_address: DepositInformationField,
  bank_name: DepositInformationField,
  swift: DepositInformationField,
  reference: DepositInformationField,
});
