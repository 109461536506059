import { useEffect, useMemo } from 'react';
import type { useParseSourcesOfFundsAccountDepositInformationFragment$key } from '@onramp/data/__generated__/useParseSourcesOfFundsAccountDepositInformationFragment.graphql';
import type {
  useParseSourcesOfFundsAccountFragment$data,
  useParseSourcesOfFundsAccountFragment$key,
} from '@onramp/data/__generated__/useParseSourcesOfFundsAccountFragment.graphql';
import type {
  useParseSourcesOfFundsPaymentMethodFragment$data,
  useParseSourcesOfFundsPaymentMethodFragment$key,
} from '@onramp/data/__generated__/useParseSourcesOfFundsPaymentMethodFragment.graphql';
import type { SourceOfFundsData } from '@onramp/state/types/SourceOfFundsData';
import { logInstantAch, logSourceOfFunds } from '@onramp/utils/eventing';
import type { SourceOfFundsToCommonFormatRequiredData } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/useSourceOfFundsToCommonFormatRequiredData';
import { isPaymentMethodSupportedByCountry } from '@onramp/utils/getIsCountryAllowed';
import { useIsApplePayAvailable } from '@onramp/utils/useIsApplePayAvailable';
import { graphql, readInlineData } from '@cbhq/data-layer';

import { readCryptoAccounts } from './readCryptoAccounts';
import { readDepositInformation } from './readDepositInformation';
import { readFiatWallets } from './readFiatWallets';
import { readPaymentMethods } from './readPaymentMethods';
import { getCryptoValueFromAccount } from './utils';

const accountFragment = graphql`
  fragment useParseSourcesOfFundsAccountFragment on Account @inline {
    ...getIsCountryAllowedAccountFragment
    ...readCryptoAccountsFragment
    ...readFiatWalletsAccountFragment
  }
`;

const accountDepositInformationFragment = graphql`
  fragment useParseSourcesOfFundsAccountDepositInformationFragment on Account @inline {
    ...readDepositInformationFragment
  }
`;

const paymentMethodFragment = graphql`
  fragment useParseSourcesOfFundsPaymentMethodFragment on PaymentMethodV2 @inline {
    ...getIsCountryAllowedFiatFragment
    ...readFiatWalletsPaymentMethodFragment
    ...readPaymentMethodsFragment
  }
`;

export type UnparsedAccount = useParseSourcesOfFundsAccountFragment$data;
export type UnparsedPaymentMethod = useParseSourcesOfFundsPaymentMethodFragment$data;

type Params = {
  nativeAssetUuid: string;
  inputAccountsRef: readonly useParseSourcesOfFundsAccountFragment$key[];
  paymentMethodsRef: {
    paymentMethod: useParseSourcesOfFundsPaymentMethodFragment$key;
    isEnabled?: boolean;
  }[];
  fiatWalletRefs?: {
    inputAccountsRef: useParseSourcesOfFundsAccountFragment$key[];
    depositInformationRef?: useParseSourcesOfFundsAccountDepositInformationFragment$key[];
    paymentMethodsRef: {
      paymentMethod: useParseSourcesOfFundsPaymentMethodFragment$key;
      isEnabled?: boolean;
    }[];
  };
  readSourceOfFundsData: SourceOfFundsToCommonFormatRequiredData;
};

export function useParseSourcesOfFunds(params: Params) {
  const applePayAvailable = useIsApplePayAvailable();

  const cashAccounts = useMemo(() => {
    return readFiatWallets({
      inputAccountsRef: getInputAccounts(
        params.fiatWalletRefs?.inputAccountsRef ?? params.inputAccountsRef,
      ),
      paymentMethodsRef: getPaymentMethods(
        (params.fiatWalletRefs?.paymentMethodsRef ?? params.paymentMethodsRef).map(
          (ref) => ref.paymentMethod,
        ),
      ),
      readSourceOfFundsData: params.readSourceOfFundsData,
    });
  }, [
    params.fiatWalletRefs?.inputAccountsRef,
    params.fiatWalletRefs?.paymentMethodsRef,
    params.inputAccountsRef,
    params.paymentMethodsRef,
    params.readSourceOfFundsData,
  ]);

  const computedFiatAccountData = useMemo(() => {
    return readPaymentMethods({
      readSourceOfFundsData: params.readSourceOfFundsData,
      paymentMethodsRef: params.paymentMethodsRef.map((ref) => ({
        paymentMethod: readInlineData(paymentMethodFragment, ref.paymentMethod),
        isEnabled: ref.isEnabled,
      })),
      applePayAvailable,
    });
  }, [params.readSourceOfFundsData, params.paymentMethodsRef, applePayAvailable]);

  const { fasterPaymentsDepositInformation, sepaDepositInformation } = useMemo(() => {
    return readDepositInformation({
      inputAccountsRef:
        params.fiatWalletRefs?.depositInformationRef?.map((ref) =>
          readInlineData(accountDepositInformationFragment, ref),
        ) ?? [],
    });
  }, [params.fiatWalletRefs?.depositInformationRef]);

  useEffect(() => {
    const achPaymentMethods = computedFiatAccountData.fiatAccounts.filter(
      (p) => p.paymentMethodType === 'ACH_BANK_ACCOUNT',
    );
    const cardPaymentMethods = computedFiatAccountData.fiatAccounts.filter(
      (p) => p.paymentMethodType === 'WORLDPAY_CARD',
    );

    logSourceOfFunds({
      isInstantACHEnabled: params.readSourceOfFundsData.isInstantACHEnabled,
      numOfAchAccounts: achPaymentMethods.length,
      numOfDisabledAchAccounts: achPaymentMethods.filter((p) => p.isDisabled).length,
      numOfCards: cardPaymentMethods.length,
      numOfDisabledCards: cardPaymentMethods.filter((p) => p.isDisabled).length,
    });

    // Datadog metric for checking instant ach availablity
    if (
      isPaymentMethodSupportedByCountry(params.readSourceOfFundsData.countryCode, 'instant_ach')
    ) {
      logInstantAch(params.readSourceOfFundsData.isInstantACHEnabled);
    }
  }, [
    computedFiatAccountData.fiatAccounts,
    params.readSourceOfFundsData?.countryCode,
    params.readSourceOfFundsData?.isInstantACHEnabled,
  ]);

  return useMemo(() => {
    const inputAccounts = getInputAccounts(params.inputAccountsRef);

    const { cryptoAccounts, primaryCryptoAccount } = readCryptoAccounts({
      readSourceOfFundsData: params.readSourceOfFundsData,
      inputAccountsRef: inputAccounts,
      nativeAssetUuid: params.nativeAssetUuid,
    });

    // Setting the primary source of funds determines whether the default flow is buy or send.
    // The logic is as follows:
    //   1. Selected asset crypto account (send) when there's funds available.
    //   2. Payment method (buy).
    //   3. Selected asset crypto account with no funds (default state for when the user can't buy or send).
    // > Note: we always need a primary source of funds selected otherwise the input page will not have a fallback state.
    let primarySourceOfFunds: SourceOfFundsData | undefined;
    if (getCryptoValueFromAccount(primaryCryptoAccount) > 0) {
      primarySourceOfFunds = primaryCryptoAccount;
    } else if (computedFiatAccountData.primaryFiatAccount) {
      primarySourceOfFunds = computedFiatAccountData.primaryFiatAccount;
    } else {
      primarySourceOfFunds = primaryCryptoAccount;
    }

    return {
      fiatAccounts: computedFiatAccountData.fiatAccounts,
      primaryFiatAccount: computedFiatAccountData.primaryFiatAccount,
      cryptoAccounts,
      primaryCryptoAccount,
      primarySourceOfFunds,
      cashAccounts,
      fasterPaymentsDepositInformation,
      sepaDepositInformation,
    };
  }, [
    params.inputAccountsRef,
    params.readSourceOfFundsData,
    params.nativeAssetUuid,
    computedFiatAccountData.primaryFiatAccount,
    computedFiatAccountData.fiatAccounts,
    cashAccounts,
    fasterPaymentsDepositInformation,
    sepaDepositInformation,
  ]);
}

const getInputAccounts = (inputAccountsRef: readonly useParseSourcesOfFundsAccountFragment$key[]) =>
  inputAccountsRef.map((ref) => readInlineData(accountFragment, ref));
const getPaymentMethods = (
  paymentMethodsRef: readonly useParseSourcesOfFundsPaymentMethodFragment$key[],
) => paymentMethodsRef.map((ref) => readInlineData(paymentMethodFragment, ref));
