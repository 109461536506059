import type { WidgetParameters } from '@onramp/components/AppManagerProvider/types';
import type { appParamsStateNetworksQuery$data } from '@onramp/data/__generated__/appParamsStateNetworksQuery.graphql';
import type { NetworkMetadata } from '@onramp/data/graphql-types';
import type { DestinationAddressAppParamsSchema, WidgetKey } from '@onramp/shared/appParams.schema';
import { StoneIdToNetworkId } from '@onramp/utils/blockchains/networkMetadata';
import { parseStoneIdToNetworkId } from '@onramp/utils/blockchains/stoneIdFormat';
import type { TokenMetadata } from '@onramp/utils/blockchains/types';
import { dedupe } from '@onramp/utils/dedupe';
// eslint-disable-next-line import/no-cycle
import { getIsGuestCheckoutPath } from '@onramp/utils/guestCheckoutUtils';
import { isNotNullish } from '@onramp/utils/types';
import type { Atom } from 'jotai';
import { atom, useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import fromPairs from 'lodash/fromPairs';
import type { z } from 'zod';
import { graphql } from '@cbhq/data-layer';

import type { CBPayAssetNetworks } from '../BuyWidgetState';
import { readCbPayNetwork } from '../contexts/readCbPayNetwork';
import { isBaseKilledAtom } from '../killSwitches/killSwitchesState';

import { secureInitParametersAtom } from './atoms/secureInitAtoms';
// eslint-disable-next-line import/no-cycle
import { guestCheckoutAssetMetadataAtom } from './guestCheckoutState';
import { atomWithQueryAndMap } from './utils';

export const assetsAndNetworksQuery = graphql`
  query appParamsStateNetworksQuery {
    viewer {
      cbPayTradableAssets(filter: { networks: [], tickers: [] }) {
        edges {
          node {
            symbol
            networks {
              name
              displayName
              isDefault
              minSend
            }
          }
        }
      }
    }
  }
`;

export const activeWidgetAtom = atom<WidgetKey | undefined>(undefined);

export const buyWidgetParamsAtom = atom<WidgetParameters['buy'] | undefined>(undefined);

const legacyIdMap = { avacchain: 'AVAX', ethereum: 'ETH', solana: 'SOL' };

export const cbPayTradableAssetsSelector = atomWithQueryAndMap({
  query: assetsAndNetworksQuery,
  variables: () => ({}),
  mapResponse: (data: appParamsStateNetworksQuery$data) => data,
});

export const networkMetadataSelector = atom(async (get) => {
  const shouldShowBase = !get(isBaseKilledAtom);
  const data = await get(cbPayTradableAssetsSelector);

  const rawNetworkList = data.viewer.cbPayTradableAssets?.edges
    ?.map((e) => e?.node)
    .filter(isNotNullish)
    .flatMap((asset) => asset.networks);

  const networkIds = dedupe(
    rawNetworkList?.map((n) => readCbPayNetwork(n?.name).id).filter(Boolean) ?? [],
  );

  const networkMetadata = networkIds.reduce<Record<string, NetworkMetadata>>(
    (networkMap, currentNetworkId) => {
      const network: NetworkMetadata | null | undefined = rawNetworkList?.find(
        (n) => readCbPayNetwork(n?.name).id === currentNetworkId,
      );
      if (!network) return networkMap;
      return {
        ...networkMap,
        [currentNetworkId]: {
          name: network.displayName,
          legacyId:
            currentNetworkId in legacyIdMap
              ? legacyIdMap[currentNetworkId as keyof typeof legacyIdMap]
              : undefined,
        },
      };
    },
    {} as Record<string, NetworkMetadata>,
  );

  if (shouldShowBase) {
    if (!networkMetadata.base) {
      networkMetadata.base = { displayName: 'Base' };
    }
  } else {
    const { base, ...otherNetworkMetadata } = networkMetadata;
    return otherNetworkMetadata;
  }

  return networkMetadata;
});

export const stoneIdsSelector = atom(async (get) => {
  const networkMetadata = await get(networkMetadataSelector);

  return Object.keys(networkMetadata);
});

export const stoneIdsLoadable = loadable(stoneIdsSelector);

export const networkIdsSelector = atom(async (get) => {
  const stoneIds = await get(stoneIdsSelector);
  const networkIds = stoneIds.map((stoneId) =>
    stoneId in StoneIdToNetworkId ? StoneIdToNetworkId[stoneId] : stoneId,
  );

  return networkIds;
});

type TypeOfRecoilValue<T extends Atom<unknown>> = T extends Atom<infer U> ? Awaited<U> : never;

export type AssetMetadata = TypeOfRecoilValue<typeof assetMetadataSelector>;
export const assetMetadataSelector = atom(async (get) => {
  const data = await get(cbPayTradableAssetsSelector);
  const rawAssetList = data.viewer.cbPayTradableAssets?.edges
    ?.map((e) => e?.node)
    .filter(isNotNullish);

  const formattedAssets = rawAssetList
    ?.map((asset): [string, TokenMetadata | undefined] | undefined => {
      const defaultNetwork = asset.networks?.find((n) => n?.isDefault) || asset.networks?.[0];
      const l2SupportedNetworks = asset.networks
        ?.filter((n) => !n?.isDefault)
        .map((n) => readCbPayNetwork(n?.name).id);

      if (!asset.symbol) return undefined;

      return [
        asset.symbol,
        {
          ticker: asset.symbol,
          minimumSendAmount: Number(defaultNetwork?.minSend || 0),
          blockchain: readCbPayNetwork(defaultNetwork?.name).id,
          ...((l2SupportedNetworks?.length || 0) > 0 ? { l2SupportedNetworks } : undefined),
        },
      ];
    })
    .filter(isNotNullish);

  return fromPairs(formattedAssets);
});

type InternalDestinationWalletType = {
  networks: string[];
  /** Temp: Required for v0 implementation to submit to the API but not include as part of our config logic in the app */
  networksForAPISubmission: string[];
  assets: string[];
  address: string;
};

function parseDestinationWalletsToUpdatedConfig({
  destinationWallets,
  getStoneIdByAssetTicker,
}: {
  destinationWallets: z.infer<typeof DestinationAddressAppParamsSchema>;
  getStoneIdByAssetTicker: (assetTicker: string) => string | undefined;
}): InternalDestinationWalletType[] {
  return destinationWallets.map((configEntry) => {
    // Merge both network entries since they will be restrictive.
    const allNetworks = [
      ...(configEntry.blockchains || []),
      ...(configEntry.supportedNetworks || []),
    ].filter(isNotNullish);

    let singleAssetNetworks: string[] = [];
    // Add default networks when no associated network is found.
    // For restrictive implementations after v0, use the allNetworks check for this.
    if ((configEntry.supportedNetworks?.length || 0) === 0) {
      singleAssetNetworks =
        configEntry.assets?.map((asset) => getStoneIdByAssetTicker(asset)).filter(isNotNullish) ||
        [];
    }

    return {
      address: configEntry.address,
      assets: dedupe(configEntry.assets || []).filter(isNotNullish),
      networks: dedupe(allNetworks),
      networksForAPISubmission: dedupe([...allNetworks, ...singleAssetNetworks]),
    };
  });
}

/**
 * Use `useDestinationWalletsV2` instead.
 *
 * This selector isn't meant to be consumed in components directly.
 */
export const destinationWalletsV2Selector = atom(async (get) => {
  const destinationWalletsFromAppParams =
    get(secureInitParametersAtom)?.destinationWallets ??
    get(buyWidgetParamsAtom)?.destinationWallets;
  if (!destinationWalletsFromAppParams) throw new Error('BUY app params not initialized');

  const isGuestCheckout = getIsGuestCheckoutPath();
  const supportedAssets = isGuestCheckout
    ? get(guestCheckoutAssetMetadataAtom)
    : await get(assetMetadataSelector);

  const destinationWallets = parseDestinationWalletsToUpdatedConfig({
    destinationWallets: destinationWalletsFromAppParams,
    getStoneIdByAssetTicker: (assetId) => supportedAssets[assetId]?.blockchain,
  });

  /** The developer supplied a config entry where all assets are displayed for the network */
  const networksWithAllAssetsRequested = (() => {
    const parsedNetworks = destinationWallets
      .filter((config) => config.assets.length === 0)
      .flatMap((config) => config.networks);

    /**
     * v0 support requirement:
     * Including this persists the logic around making assets "inclusive". Remove or omit this to
     * make it restrictive when we're ready.
     */
    const appParamNetworks = destinationWalletsFromAppParams
      .flatMap(({ blockchains }) => blockchains || [])
      .filter(isNotNullish);

    return dedupe([...parsedNetworks, ...appParamNetworks]);
  })();

  /** All single asset tickers that are requested by developer */
  const singleAssetsRequested = dedupe(destinationWallets.flatMap((config) => config.assets));

  /** Whether the asset is the only one specified by the developer which indicates it should be auto selected */
  const shouldAutoSelectAsset =
    singleAssetsRequested.length === 1 && networksWithAllAssetsRequested.length === 0;

  /** Find the first compatible config for the asset ticker and return it */
  const getConfigByAssetTicker = (
    ticker: string,
    networks: CBPayAssetNetworks = [],
  ): InternalDestinationWalletType | undefined => {
    const configRequestedByAssetTicker = destinationWallets.find((config) =>
      config.assets.includes(ticker),
    );
    if (configRequestedByAssetTicker) {
      return configRequestedByAssetTicker;
    }

    const configRequestedByNetwork = destinationWallets.find((config) =>
      networks
        .map((network) => parseStoneIdToNetworkId(network.name))
        .some((stoneId) => stoneId && config.networksForAPISubmission.includes(stoneId)),
    );
    if (configRequestedByNetwork) {
      return configRequestedByNetwork;
    }

    return undefined;
  };

  return {
    destinationWallets,
    networksWithAllAssetsRequested,
    singleAssetsRequested,
    shouldAutoSelectAsset,
    getConfigByAssetTicker,
  };
});

export const useDestinationWalletsV2 = () => {
  return useAtomValue(destinationWalletsV2Selector);
};

export const useNetworkIdsSelector = () => {
  return useAtomValue(networkIdsSelector);
};

export const useNetworkMetadataSelector = () => {
  return useAtomValue(networkMetadataSelector);
};
