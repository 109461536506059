/* eslint-disable relay/must-colocate-fragment-spreads */
import React, { Suspense } from 'react';
import { useIntl } from 'react-intl';
import { useWidgetParams } from '@onramp/components/AppManagerProvider';
import { Loader } from '@onramp/components/Loader';
import { PageWrapper } from '@onramp/components/PageWrapper';
import { usePolicyRestrictionsGuardArgs } from '@onramp/components/PolicyRestrictionsGuard';
import type { BuyOneClickPageDataProviderQuery } from '@onramp/data/__generated__/BuyOneClickPageDataProviderQuery.graphql';
import { useNativeCurrency } from '@onramp/state/recoil/selectors/nativeCurrencySelector';
import { genericMessages } from '@onramp/utils/genericMessages';
import { isMobileExperience } from '@onramp/utils/postMessage';
import { useCbPayAssetsFilter } from '@onramp/utils/useCbPayAssetsFilter';
import { useAtom } from 'jotai';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';

import { countryCodeLoadable } from '../recoil/selectors/countryCodeSelector';
import { nativeCurrencyLoadable } from '../recoil/selectors/nativeCurrencySelector';
import { twoFactorMethodLoadable } from '../recoil/selectors/twoFactorMethodSelector';

const buyOneClickPageQuery = graphql`
  query BuyOneClickPageDataProviderQuery(
    $filter: CBPayAssetFilters!
    $transactionUuid: Uuid
    $uuid: Uuid!
    $skipAccountByUuid: Boolean!
    $fiatCurrency: TickerSymbol!
    $skipAssetByUuid: Boolean!
    $sends: Boolean!
    $buys: Boolean!
    $pay: Boolean!
    $assetUuid: Uuid!
    $inWebView: Boolean!
  ) {
    viewer {
      ...useSelectAssetFragment @arguments(filter: $filter)
      ...useRunChecksAgainstAssetListFragment @arguments(filter: $filter)
      ...useSourceOfFundsToCommonFormatRequiredData
      ...useDefaultSourceOfFundsViewerFragment
        @arguments(
          transactionUuid: $transactionUuid
          uuid: $uuid
          skipAccountByUuid: $skipAccountByUuid
          fiatCurrency: $fiatCurrency
          skipAssetByUuid: $skipAssetByUuid
        )
      ...PolicyRestrictionsGuardFragment
        @arguments(sends: $sends, buys: $buys, pay: $pay, inWebView: $inWebView)
    }
    ...useExchangeRateFragment @arguments(assetUuid: $assetUuid, fiatCurrency: $fiatCurrency)
  }
`;

// This component triggers async loading of all the data necessary to render the one-click buy page, preventing a series
// of waterfalling requests that significantly increase first page load time.
export const BuyOneClickPageDataProvider: React.FC = ({ children }) => {
  const { formatMessage } = useIntl();
  const headerText = formatMessage(genericMessages.coinbaseOnramp);
  return (
    <Suspense
      fallback={
        <PageWrapper headerText={headerText}>
          <Loader />
        </PageWrapper>
      }
    >
      <DataProvider>{children}</DataProvider>
    </Suspense>
  );
};

const DataProvider: React.FC = ({ children }) => {
  // These Atoms make graphql requests that are needed for the initial page load, so we trigger loading them async
  useAtom(countryCodeLoadable);
  useAtom(nativeCurrencyLoadable);
  useAtom(twoFactorMethodLoadable);

  const filter = useCbPayAssetsFilter();
  const {
    sends: isSendsRequired,
    buys: isBuysRequired,
    pay: isPayRequired,
  } = usePolicyRestrictionsGuardArgs();

  const { defaultAsset } = useWidgetParams('buy');
  const nativeCurrency = useNativeCurrency();

  useLazyLoadQuery<BuyOneClickPageDataProviderQuery>(
    buyOneClickPageQuery,
    {
      filter,
      uuid: defaultAsset ?? '',
      assetUuid: defaultAsset ?? '',
      skipAccountByUuid: !defaultAsset,
      fiatCurrency: nativeCurrency ?? '',
      skipAssetByUuid: !defaultAsset || !nativeCurrency,
      sends: isSendsRequired || false,
      buys: isBuysRequired || false,
      pay: isPayRequired || false,
      inWebView: isMobileExperience(),
    },
    {
      // This forces the component to suspend, preventing duplicate queries in child components
      fetchPolicy: 'network-only',
    },
  );

  return <>{children}</>;
};
