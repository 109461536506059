import invert from 'lodash/invert';

/**
 * @deprecated - Do not add to this list.
 * Old names we were using for the blockchain that are passed into the query string from old CB Wallet apps.
 */
type LegacyBlockchainId = 'AVAX' | 'ETH' | 'SOL';

export type NetworkMetadata = {
  /** Old name we were using for the blockchain that's passed into the query string */
  legacyId?: LegacyBlockchainId;
  /** Unique chain id used for web3 libraries (not compatible across all blockchains). @see https://chainlist.org/ */
  chainId?: number;
  /** The name of the network */
  name: string;
};

const LegacyIdToBlockchainId: Record<LegacyBlockchainId, string> = {
  AVAX: 'avacchain',
  ETH: 'ethereum',
  SOL: 'solana',
};

export const NetworkIdToStoneId = {
  'avalanche-c-chain': 'avacchain',
  'bitcoin-cash': 'bitcoincash',
  eos: 'eosio',
  'ethereum-classic': 'ethereumclassic',
} as const;

export const StoneIdToNetworkId = invert(NetworkIdToStoneId);

/**
 * This parses our public sdk network ids to internal backend "stone format" ids.
 * Technically the full stone format is `networks/<network_id>-<mainnet/testnet>` i.e. `networks/ethereum-mainnet`
 * @see https://github.cbhq.net/engineering/stone
 * */
export function parseNetworkIdToStoneId(networkId?: string): string | undefined {
  if (!networkId) return undefined;

  // Parse the id through the legacy values i.e. ETH => ethereum
  let parsedNetworkId =
    (LegacyIdToBlockchainId as Partial<Record<string, string>>)[networkId] ?? networkId;

  // Check if the network id matches any of our other legacy network IDs that we manually map to stone IDs
  // e.g. 'avalanche-c-chain' => 'avacchain'
  parsedNetworkId =
    (NetworkIdToStoneId as Partial<Record<string, string>>)[parsedNetworkId] ?? parsedNetworkId;

  return parsedNetworkId;
}
