import { coerceError } from '@onramp/utils/errors';
import { fetchWithCommonHeaders } from '@onramp/utils/requests';

import { reportBugsnag } from '../bugsnag';
import { clientSessionIdStore } from '../clientSessionIdStore';
import { useReffedFunction } from '../useReffedFunction';

// the namespace 'onramp_service' is added to the event name in onramp-service
type CDPMetricName = 'CDP_METRIC_TYPE_INIT_SUCCESS';

// Log metrics to CDP metrics via an onramp-service API. These metrics are used to populate the CDP portal dashboards.
export const useLogCDPMetric = () => {
  const appId = clientSessionIdStore.getAppId();
  return useReffedFunction((metric: CDPMetricName) => {
    fetchWithCommonHeaders('/api/v1/internal/metrics/cdp', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ metric, app_id: appId }),
    }).catch((err) => {
      reportBugsnag(coerceError(err), { context: 'cdp_metrics', severity: 'warning' });
    });
  });
};
