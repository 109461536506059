import { useEffect } from 'react';
import { useCountryCodeLoadable } from '@onramp/state/recoil/selectors/countryCodeSelector';

import { useLogWidgetEventOnce } from './eventing/useLogWidgetEvent';
import { useKillSwitches } from './killswitches/useKillSwitches';
import { checkIfApplePayIsSupported } from './applePay';
import { clientSessionIdStore } from './clientSessionIdStore';
import { isPaymentMethodSupportedByCountry } from './getIsCountryAllowed';

export function useIsApplePayAvailable() {
  const isApplePayKilled = useKillSwitches().kill_cbpay_applepay;

  const featureFlags = clientSessionIdStore.getFeatureFlags();
  const logWidgetEvent = useLogWidgetEventOnce();
  const countryLoadable = useCountryCodeLoadable();
  const country = countryLoadable.state === 'hasData' ? countryLoadable.data : undefined;

  const isApplePayEnabledForClient = featureFlags?.applePayEnabled ?? false;
  const isApplePayAllowedInCountry = isPaymentMethodSupportedByCountry(country, 'apple_pay');

  // if ApplePay isn't supported, we shouldn't track this as exposure to the experiments
  const isApplePaySupported = checkIfApplePayIsSupported();

  const isIos = clientSessionIdStore.getPlatformAttribution() === 'ios';

  useEffect(() => {
    logWidgetEvent('apple_pay_availability_metadata', {
      isApplePayEnabledForClient,
      isIos,
      isApplePaySupported,
      isApplePaySessionDefined: Boolean(window.ApplePaySession),
      isApplePayKilled,
      supportsVersion3: (() => {
        try {
          const ApplePaySession =
            typeof window !== 'undefined' ? window.ApplePaySession : undefined;
          if (!ApplePaySession) return false;

          return Boolean(ApplePaySession.supportsVersion(3));
        } catch {
          return false;
        }
      })(),
      canMakePayments: (() => {
        try {
          const ApplePaySession =
            typeof window !== 'undefined' ? window.ApplePaySession : undefined;
          if (!ApplePaySession) return false;

          return Boolean(ApplePaySession.canMakePayments());
        } catch {
          return false;
        }
      })(),
    });
  }, [isApplePayEnabledForClient, isApplePayKilled, isApplePaySupported, isIos, logWidgetEvent]);

  return (
    !isApplePayKilled &&
    isApplePaySupported &&
    isApplePayEnabledForClient &&
    isApplePayAllowedInCountry
  );
}
