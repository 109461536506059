/**
 * @generated SignedSource<<d93d024d7bc1f4e227cdd7ecc66dbfa3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type OnrampNetworksProviderQuery$variables = {
  assetUuid: string;
  include: boolean;
};
export type OnrampNetworksProviderQuery$data = {
  readonly viewer: {
    readonly assetByUuid?: {
      readonly supportedNetworks: {
        readonly supportedNetworks: ReadonlyArray<{
          readonly assetImageUrl: string | null;
          readonly isDefault: boolean | null;
          readonly networkName: string | null;
          readonly networkSlug: string | null;
        } | null> | null;
      } | null;
    } | null;
  };
};
export type OnrampNetworksProviderQuery = {
  response: OnrampNetworksProviderQuery$data;
  variables: OnrampNetworksProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assetUuid"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "include"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "uuid",
    "variableName": "assetUuid"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "action",
      "value": "RECEIVE"
    },
    {
      "kind": "Literal",
      "name": "applicationContext",
      "value": "CB_PAY"
    }
  ],
  "concreteType": "SendReceiveSupportedNetworks",
  "kind": "LinkedField",
  "name": "supportedNetworks",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SendReceiveSupportedNetwork",
      "kind": "LinkedField",
      "name": "supportedNetworks",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "networkName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "networkSlug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDefault",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assetImageUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "supportedNetworks(action:\"RECEIVE\",applicationContext:\"CB_PAY\")"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "OnrampNetworksProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "include",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ViewerAsset",
                "kind": "LinkedField",
                "name": "assetByUuid",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "OnrampNetworksProviderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "include",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ViewerAsset",
                "kind": "LinkedField",
                "name": "assetByUuid",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ae6d0cfcbcfe00b8560c09cde781bbb6",
    "id": null,
    "metadata": {},
    "name": "OnrampNetworksProviderQuery",
    "operationKind": "query",
    "text": "query OnrampNetworksProviderQuery(\n  $assetUuid: Uuid!\n  $include: Boolean!\n) {\n  viewer {\n    assetByUuid(uuid: $assetUuid) @include(if: $include) {\n      supportedNetworks(action: RECEIVE, applicationContext: CB_PAY) {\n        supportedNetworks {\n          networkName\n          networkSlug\n          isDefault\n          assetImageUrl\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "67d10cb4d48f1789923537c99beb5c28";

export default node;
