import { usePrefixedKillSwitches } from '@onramp/hooks/usePrefixedKillSwitches';
import { useCountryCode } from '@onramp/state/recoil/selectors/countryCodeSelector';

import { useKillSwitches } from './killswitches/useKillSwitches';

export const useIsBuyFlowKillSwitched = () => {
  const countryCode = useCountryCode();
  const killSwitchedCountryCodes = usePrefixedKillSwitches('kill_cbpay_buy_flow_country_');

  const isGloballyKillSwitched = useKillSwitches().kill_cbpay_buy_flow;
  const isCountryKillSwitched = countryCode
    ? killSwitchedCountryCodes.includes(countryCode.toLowerCase())
    : true;

  return isGloballyKillSwitched || isCountryKillSwitched;
};
