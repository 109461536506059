import { useCallback } from 'react';
import { usePrefixedKillSwitches } from '@onramp/hooks/usePrefixedKillSwitches';
import { networkMetadataSelector } from '@onramp/state/recoil/appParamsState';
import { guestCheckoutNetworkMetadataAtom } from '@onramp/state/recoil/guestCheckoutState';
import { getIsGuestCheckoutPath } from '@onramp/utils/guestCheckoutUtils';
import { useAtomValue } from 'jotai';

export const useIsNetworkKillSwitched = () => {
  const isGuestCheckout = getIsGuestCheckoutPath();
  const networkMetadata = useAtomValue(
    isGuestCheckout ? guestCheckoutNetworkMetadataAtom : networkMetadataSelector,
  );
  const networkKillSwitches = usePrefixedKillSwitches('kill_cbpay_network_');

  const isNetworkKilledById = useCallback(
    (networkId: string) => {
      return networkKillSwitches.includes(networkId);
    },
    [networkKillSwitches],
  );

  const isNetworkKilledByDisplayName = useCallback(
    (displayName: string) => {
      const networkId = Object.entries(networkMetadata).find(
        ([, metadata]) => metadata.name === displayName,
      )?.[0];

      return Boolean(networkId && isNetworkKilledById(networkId));
    },
    [isNetworkKilledById, networkMetadata],
  );

  return {
    isNetworkKilledById,
    isNetworkKilledByDisplayName,
  };
};
