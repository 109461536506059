import { useEffect } from 'react';
import type { GraphQLTaggedNode, OperationType, VariablesOf } from 'relay-runtime';
import { getRequest } from 'relay-runtime';
import { createOperationDescriptor, fetchQuery, useRelayEnvironment } from '@cbhq/data-layer';

/**
 * `TQuery` defaults to `never` so that we always get a type error if the type parameter isn't provided,
 * making the second parameter cause a type error until the type parameter is provided
 * */
export function usePrefetchQuery<TQuery extends OperationType = never>(
  query: GraphQLTaggedNode,
  inputVariables: VariablesOf<TQuery>,
) {
  const relayEnv = useRelayEnvironment();

  useEffect(() => {
    // Retain results in store
    const request = getRequest(query);
    const operation = createOperationDescriptor(request, {});
    relayEnv.retain(operation);

    // Check current status of operation
    const { status } = relayEnv.check(operation);

    // Only fetch query if status is not available
    if (status !== 'available') {
      fetchQuery(relayEnv, query, inputVariables ?? {})
        .toPromise()
        .catch(() => {});
    }
  }, [inputVariables, query, relayEnv]);
}
