import { useCallback } from 'react';
import { useWidgetParams } from '@onramp/components/AppManagerProvider';
import type { useDefaultSourceOfFundsQuery } from '@onramp/data/__generated__/useDefaultSourceOfFundsQuery.graphql';
import type { useDefaultSourceOfFundsViewerFragment$key } from '@onramp/data/__generated__/useDefaultSourceOfFundsViewerFragment.graphql';
import type { useDefaultSourceOfFundsViewerQuery } from '@onramp/data/__generated__/useDefaultSourceOfFundsViewerQuery.graphql';
import type { SourceOfFundsV2Type } from '@onramp/state/BuyWidgetState';
import { useBuyWidgetState } from '@onramp/state/BuyWidgetState';
import { useDestinationWalletsV2 } from '@onramp/state/recoil/appParamsState';
import { useIsBuyFlowType } from '@onramp/state/recoil/buyFlowType';
import { useNativeCurrency } from '@onramp/state/recoil/selectors/nativeCurrencySelector';
import type { SourceOfFundsData } from '@onramp/state/types/SourceOfFundsData';
import { readAccountBalance } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readAccountBalance';
import { readCryptoAccount } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readCryptoAccount';
import { readFiatWallet } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readFiatWallet';
import { readPaymentMethod } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readPaymentMethod';
import { useSourceOfFundsToCommonFormatRequiredData } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/useSourceOfFundsToCommonFormatRequiredData';
import { isNonEmpty } from '@onramp/utils/isNonEmpty';
import { assertPresence } from '@onramp/utils/relayUtils';
import { useIsApplePayAvailable } from '@onramp/utils/useIsApplePayAvailable';
import { useIsBuyFlowKillSwitched } from '@onramp/utils/useIsBuyFlowKillSwitched';
import type { BigSource } from 'big.js';
import bigJs from 'big.js';
import { graphql, readInlineData, useLazyLoadQuery } from '@cbhq/data-layer';

import { useCanMakePaymentsWithActiveCard } from '../useIsApplePaySupportedWithActiveCard';
import { messages } from '../useSourcesOfFunds/messages';
import { useQueryFetcher } from '../utils/useQueryFetcher';

import { readPrimaryCryptoAccountFromAccountsV2 } from './readPrimaryCryptoAccountFromAccountsV2';
import { readPrimaryFiatWallet } from './readPrimaryFiatWallet';
import { readPrimaryPaymentMethod } from './readPrimaryPaymentMethod';

const FIAT_WALLET_MINIMUM_FOR_DEFAULT = bigJs(2);

export const defaultSourceOfFundsQuery = graphql`
  query useDefaultSourceOfFundsQuery(
    $transactionUuid: Uuid
    $uuid: Uuid!
    $skipAccountByUuid: Boolean!
    $fiatCurrency: TickerSymbol!
    $skipAssetByUuid: Boolean!
  ) {
    viewer {
      ...useDefaultSourceOfFundsViewerFragment
        @arguments(
          transactionUuid: $transactionUuid
          uuid: $uuid
          skipAccountByUuid: $skipAccountByUuid
          fiatCurrency: $fiatCurrency
          skipAssetByUuid: $skipAssetByUuid
        )
    }
  }
`;

export const defaultSourceOfFundsViewerFragment = graphql`
  fragment useDefaultSourceOfFundsViewerFragment on Viewer
  @inline
  @argumentDefinitions(
    transactionUuid: { type: "Uuid" }
    uuid: { type: "Uuid!" }
    skipAccountByUuid: { type: "Boolean!" }
    fiatCurrency: { type: "TickerSymbol!" }
    skipAssetByUuid: { type: "Boolean!" }
  ) {
    # eslint-disable-next-line relay/unused-fields
    cbPaySourcesOfFunds(
      transactionUuid: $transactionUuid
      supportedSourcesOfFunds: ["PAYMENT_METHOD", "FIAT_WALLET", "CRYPTO_ACCOUNT"]
    ) {
      ...readPrimaryPaymentMethodSoFFragment
      ...readPrimaryFiatWalletFragment
      # eslint-disable-next-line relay/must-colocate-fragment-spreads
      ...readPrimaryCryptoAccountFragment

      # read common format stuff
      ... on CBPaySourceOfFundsCryptoAccount {
        account {
          ...readCryptoAccountFragment
        }
      }
      ... on CBPaySourceOfFundsPaymentMethod {
        isEnabled
        paymentMethod {
          uuid
          ...readPaymentMethodFragment
        }
      }
      ... on CBPaySourceOfFundsFiatWallet {
        account {
          ...readFiatWalletAccountFragment
        }
        paymentMethod {
          uuid
          ...readFiatWalletPaymentMethodFragment
        }
      }
    }

    # For v0 we still need to read from accountV2
    ...readPrimaryCryptoAccountFromAccountsV2
    accountsV2 {
      edges {
        node {
          uuid
          assetOrFiatCurrency {
            ... on ViewerAsset {
              asset {
                uuid
              }
            }
          }
          ...readCryptoAccountFragment
          ...readFiatWalletAccountFragment
        }
      }
    }

    accountByUuid(uuid: $uuid) @skip(if: $skipAccountByUuid) {
      ...readCryptoAccountFragment
    }

    assetByUuid(uuid: $uuid) @skip(if: $skipAssetByUuid) {
      asset {
        latestPrice(quoteCurrency: $fiatCurrency) {
          price
        }
      }
    }
  }
`;

const defaultSourceOfFundsViewerQuery = graphql`
  query useDefaultSourceOfFundsViewerQuery {
    viewer {
      ...useSourceOfFundsToCommonFormatRequiredData
      userProperties {
        country {
          code
        }
      }
    }
  }
`;

type GetDefaultSourceOfFundsParams = {
  selectedAssetUuid: string;
  assetTicker: string;
  amount?: {
    value: string;
    type: 'fiat' | 'crypto';
  };
};

type GetDefaultSourceOfFundsResult =
  | undefined
  | {
      v1: SourceOfFundsData;
      v2: SourceOfFundsV2Type;
    };

export function useDefaultSourceOfFunds() {
  const fetchQuery = useQueryFetcher();
  const applePayEnabled = useIsApplePayAvailable();
  const getCanMakePaymentsWithActiveCard = useCanMakePaymentsWithActiveCard();

  const viewerData = useLazyLoadQuery<useDefaultSourceOfFundsViewerQuery>(
    defaultSourceOfFundsViewerQuery,
    {},
  );
  const readSourceOfFundsData = useSourceOfFundsToCommonFormatRequiredData(viewerData.viewer);

  const isBuyFlowKillSwitched = useIsBuyFlowKillSwitched();
  const nativeCurrency = useNativeCurrency();

  const isOneClickBuy = useIsBuyFlowType('one-click-buy');
  const { destinationWallets } = useDestinationWalletsV2();
  const hasSingleWallet = destinationWallets.length === 1;
  const wallet = destinationWallets[0];
  // Theoretically it's not possible for wallet.assets or wallet.networks to be empty, but we're seeing Bugsnag errors
  // complaining about trying to read undefined values so giving this a try...
  const isEthOnlyAsset =
    wallet?.assets && wallet.assets.length === 1 && wallet.assets[0].toLowerCase() === 'eth';
  const isBaseOnlySupportedNetwork =
    wallet?.networks &&
    wallet.networks.length === 1 &&
    wallet.networks[0].toLocaleLowerCase() === 'base';
  const isEthOnBase = hasSingleWallet && isEthOnlyAsset && isBaseOnlySupportedNetwork;

  const { defaultPaymentMethod, defaultExperience } = useWidgetParams('buy');
  const { selectAssetPageActiveTab } = useBuyWidgetState();
  const activeTab = selectAssetPageActiveTab.value;

  return useCallback(
    async function getDefaultSourceOfFunds({
      assetTicker,
      selectedAssetUuid,
      amount,
    }: GetDefaultSourceOfFundsParams): Promise<GetDefaultSourceOfFundsResult> {
      const [canMakePaymentsWithActiveCard, query] = await Promise.all([
        getCanMakePaymentsWithActiveCard(),
        fetchQuery<useDefaultSourceOfFundsQuery>(
          defaultSourceOfFundsQuery,
          {
            uuid: assetTicker,
            skipAccountByUuid: !assetTicker,
            fiatCurrency: nativeCurrency ?? '',
            skipAssetByUuid: !assetTicker || !nativeCurrency,
          },
          { fetchPolicy: 'store-or-network' },
        ),
      ]);

      const viewer = readInlineData(
        defaultSourceOfFundsViewerFragment,
        query.viewer as useDefaultSourceOfFundsViewerFragment$key,
      );

      const cbPaySourcesOfFunds = assertPresence(viewer.cbPaySourcesOfFunds, {
        debugMessage: 'Failed to load CBPay sources of funds.',
        message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
      }).filter(isNonEmpty);

      const accountsV2 = assertPresence(viewer.accountsV2, {
        debugMessage: 'Failed to load account V2 field.',
        message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
      });

      const fiatPerCoin = viewer.assetByUuid?.asset.latestPrice?.price
        ? bigJs(viewer.assetByUuid.asset.latestPrice.price)
        : null;
      const fiatAmount = (() => {
        if (!amount || !fiatPerCoin) {
          return undefined;
        }

        const value = bigJs(amount.value || '0');

        if (amount.type === 'fiat') {
          return value;
        }

        if (amount.type === 'crypto') {
          return fiatPerCoin.mul(value);
        }

        // impossible state
        return undefined;
      })();

      const isAboveZero = (value: BigSource) => {
        const bigJsValue = bigJs(value);
        return bigJsValue.gt(0);
      };

      // add crypto account when ready - crypto account ready in v1
      const primaryPmSof = (() => {
        const primaryPm = readPrimaryPaymentMethod({
          viewer,
          canMakePaymentsWithActiveCard,
          fiatAmount,
          defaultPaymentMethod,
          applePayEnabled,
          ...readSourceOfFundsData,
        });
        const primaryPmSofRef =
          primaryPm &&
          cbPaySourcesOfFunds.find(
            (node): node is typeof node & { __typename: 'CBPaySourceOfFundsPaymentMethod' } =>
              node.paymentMethod?.uuid === primaryPm.uuid,
          );

        const paymentMethodRef = primaryPmSofRef?.paymentMethod;

        return (
          paymentMethodRef &&
          readPaymentMethod({
            paymentMethodRef,
            isEnabled: primaryPmSofRef?.isEnabled ?? undefined,
            ...readSourceOfFundsData,
          })
        );
      })();

      // no need to sort according to fiatAmount since it's already sorted by limit
      const primaryFiatWallet = readPrimaryFiatWallet(
        viewer,
        viewerData.viewer.userProperties.country?.code,
      );
      // no need to sort according to cryptoAmount since it's already sorted by balance
      const primaryCryptoAccount = readPrimaryCryptoAccountFromAccountsV2(
        viewer,
        selectedAssetUuid,
      );

      let defaultSourceOfFunds: GetDefaultSourceOfFundsResult;

      function handleCryptoAccount() {
        const inputAccountRef = assertPresence(
          accountsV2.edges
            .map(({ node }) => node)
            .filter(isNonEmpty)
            .find((node) => node?.uuid === primaryCryptoAccount?.uuid),
          {
            debugMessage:
              '[useDefaultSourcesOfFunds] Failed to find matching crypto account for primaryCryptoAccount',
            message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
          },
        );

        const v1 = assertPresence(
          readCryptoAccount({
            inputAccountRef,
            nativeAssetUuid: selectedAssetUuid,
            ...readSourceOfFundsData,
          }),
          {
            debugMessage: '[useDefaultSourcesOfFunds] readCryptoAccount returned null',
            message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
          },
        );

        defaultSourceOfFunds = {
          v1,
          v2: {
            type: 'CRYPTO_ACCOUNT',
            uuid: primaryCryptoAccount?.uuid || '',
          },
        };
      }

      function handleFiatWallet() {
        const paymentMethod = assertPresence(primaryFiatWallet?.paymentMethod, {
          debugMessage:
            "[useDefaultSourcesOfFunds] primaryFiatWallet is defined, but primaryFiatWallet.paymentMethod isn't",
          message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
        });

        const fiatWallet = assertPresence(
          viewer.cbPaySourcesOfFunds?.find(
            (sof) => sof?.paymentMethod?.uuid === paymentMethod.uuid,
          ),
          {
            debugMessage:
              "[useDefaultSourcesOfFunds] primaryFiatWallet is defined, but fiatWallet isn't",
            message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
          },
        );

        const inputAccountRef = assertPresence(fiatWallet.account, {
          debugMessage:
            "[useDefaultSourcesOfFunds] fiatWallet is defined, but fiatWallet.account isn't",
          message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
        });

        const paymentMethodRef = assertPresence(fiatWallet.paymentMethod, {
          debugMessage:
            "[useDefaultSourcesOfFunds] fiatWallet is defined, but fiatWallet.paymentMethod isn't",
          message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
        });

        const v1 = assertPresence(
          readFiatWallet({
            inputAccountRef,
            paymentMethodRef,
            ...readSourceOfFundsData,
          }),
          {
            debugMessage: '[useDefaultSourcesOfFunds] readFiatWallet returned null',
            message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
          },
        );

        defaultSourceOfFunds = {
          v1,
          v2: {
            type: 'FIAT_WALLET',
            uuid: primaryFiatWallet?.paymentMethod?.uuid || '',
          },
        };
      }

      function handlePaymentMethod() {
        defaultSourceOfFunds = {
          v1: primaryPmSof as SourceOfFundsData,
          v2: {
            type: 'PAYMENT_METHOD',
            uuid: primaryPmSof?.uuid || '',
          },
        };
      }

      function handleDefaultToSendCrypto() {
        /** As `accountByUuidV2` _may_ not return zero balance accounts, we're adding an `accountsV2` fallback */
        const zeroBalanceAccount =
          viewer.accountByUuid ??
          viewer.accountsV2?.edges.find(
            (acc) => acc.node?.assetOrFiatCurrency?.asset?.uuid === selectedAssetUuid,
          )?.node;

        // case where user has no payment methods, default to crypto account
        const inputAccountRef = assertPresence(zeroBalanceAccount, {
          debugMessage: '[useDefaultSourcesOfFunds] Failed to find accountByUuidV2',
          message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
        });

        const asset = assertPresence(
          readCryptoAccount({
            inputAccountRef,
            nativeAssetUuid: selectedAssetUuid,
            ...readSourceOfFundsData,
          }),
          {
            debugMessage: '[useDefaultSourcesOfFunds] readCryptoAccount returned null',
            message: readSourceOfFundsData.intl.formatMessage(messages.missingFieldErrorMessage),
          },
        );

        defaultSourceOfFunds = {
          v1: asset,
          v2: {
            type: 'CRYPTO_ACCOUNT',
            uuid: asset.uuid || '',
          },
        };
      }

      const isPrimaryFiatWalletAvailable = primaryFiatWallet?.isEnabled === true;
      const isPrimaryPaymentMethodAvailable = primaryPmSof?.isDisabled === false;

      const shouldDefaultToBuy =
        !isBuyFlowKillSwitched &&
        (activeTab === 'buy' || defaultExperience === 'buy' || isOneClickBuy || isEthOnBase) &&
        (isPrimaryFiatWalletAvailable || isPrimaryPaymentMethodAvailable);

      const shouldDefaultToCryptoAccount = (() => {
        const allowedByActiveTab = activeTab ? activeTab === 'send' : true;
        const allowedByPaymentMethodParam = defaultPaymentMethod
          ? defaultPaymentMethod === 'CRYPTO_ACCOUNT'
          : true;

        return allowedByPaymentMethodParam && allowedByActiveTab;
      })();

      const shouldDefaultToFiatWallet = (() => {
        const allowedByActiveTab = activeTab ? activeTab === 'buy' : true;
        const allowedByPaymentMethodParam = defaultPaymentMethod
          ? defaultPaymentMethod === 'FIAT_WALLET'
          : true;

        return allowedByPaymentMethodParam && allowedByActiveTab;
      })();

      const shouldDefaultToPaymentMethod = (() => {
        const allowedByActiveTab = activeTab ? activeTab === 'buy' : true;
        const allowedByPaymentMethodParam = defaultPaymentMethod
          ? defaultPaymentMethod === 'CARD' || defaultPaymentMethod === 'ACH_BANK_ACCOUNT'
          : true;

        return allowedByPaymentMethodParam && allowedByActiveTab;
      })();

      const defaultToCryptoAccountAllowed =
        primaryCryptoAccount &&
        !shouldDefaultToBuy && // crypto account as source of funds defaults to send instead of buy
        shouldDefaultToCryptoAccount &&
        isAboveZero(primaryCryptoAccount.availableBalanceInNativeCurrency?.value ?? 0);

      const defaultToFiatWalletAllowed =
        !isBuyFlowKillSwitched &&
        primaryFiatWallet &&
        isPrimaryFiatWalletAvailable &&
        shouldDefaultToFiatWallet &&
        isAboveZero(readAccountBalance(primaryFiatWallet?.account).value) &&
        // When the input is initially $0, any amount will above the threshold and we should prevent defaulting to an empty fiat wallet
        bigJs(readAccountBalance(primaryFiatWallet?.account).value).gte(
          FIAT_WALLET_MINIMUM_FOR_DEFAULT,
        );

      const defaultToPaymentMethodAllowed =
        !isBuyFlowKillSwitched &&
        primaryPmSof &&
        !primaryPmSof.isDisabled &&
        shouldDefaultToPaymentMethod &&
        isAboveZero(primaryPmSof.limitData?.fiat?.value ?? 0);

      if (defaultToCryptoAccountAllowed) {
        handleCryptoAccount();
      } else if (defaultToFiatWalletAllowed) {
        handleFiatWallet();
      } else if (defaultToPaymentMethodAllowed) {
        handlePaymentMethod();
      } else {
        handleDefaultToSendCrypto();
      }

      return defaultSourceOfFunds;
    },
    [
      getCanMakePaymentsWithActiveCard,
      fetchQuery,
      applePayEnabled,
      nativeCurrency,
      readSourceOfFundsData,
      viewerData.viewer.userProperties.country?.code,
      isBuyFlowKillSwitched,
      activeTab,
      defaultExperience,
      isOneClickBuy,
      isEthOnBase,
      defaultPaymentMethod,
    ],
  );
}
