import fromPairs from 'lodash/fromPairs';
import { z } from 'zod';

export const jsonStringSchema = z.string().transform((str, ctx): unknown => {
  try {
    return JSON.parse(str);
  } catch {
    ctx.addIssue({ code: 'custom', message: 'invalid JSON string' });
    return z.NEVER;
  }
});

type CaughtOptionalObjectSchema<Shape extends z.ZodRawShape> = z.ZodObject<{
  [Key in keyof Shape]: z.ZodCatch<z.ZodOptional<Shape[Key]>>;
}>;

/**
 * This function takes an object schema and returns a schema that accepts `undefined` for all fields
 * (like `objectSchema.partial()`) and ignores any invalid fields (unline `objectSchema.partial()`),
 * returning `undefined` for those instead.
 */
export const partialSchemaCatchingTypeErrors = <Shape extends z.ZodRawShape>(
  schema: z.ZodObject<Shape>,
): CaughtOptionalObjectSchema<Shape> => {
  return z.object(
    fromPairs(
      Object.entries(schema.shape).map(([key, value]) => [key, value.optional().catch(undefined)]),
    ),
  ) as CaughtOptionalObjectSchema<Shape>;
};
