import getAppVersion from '@onramp/appVersion';
import { releaseStage } from '@onramp/utils/environment/sharedEnv';
import { getUserContext } from '@cbhq/client-analytics';
import type { Client } from '@cbhq/error-vitals-web';
import { init } from '@cbhq/error-vitals-web';

let ErrorVitalsClient: Client | undefined;

const clientStub: Omit<Client, 'internalClient'> = {
  addBreadcrumb(message, metadata) {
    // eslint-disable-next-line no-console
    console.log(
      'ErrorVitalsClient: addBreadcrumb called without initialization -',
      JSON.stringify({ message, metadata }),
    );
  },
  logError(err, metadata) {
    // eslint-disable-next-line no-console
    console.log(
      'ErrorVitalsClient: logError called without initialization -',
      err,
      JSON.stringify({ metadata }),
    );
  },
  setFeatureFlags(flags) {
    // eslint-disable-next-line no-console
    console.log(
      'ErrorVitalsClient: setFeatureFlags called without initialization -',
      JSON.stringify({ flags }),
    );
  },
  setGlobalMetadata(metadata) {
    // eslint-disable-next-line no-console
    console.log(
      'ErrorVitalsClient: setGlobalMetadata called without initialization -',
      JSON.stringify({ metadata }),
    );
  },
  options: {
    apiKey: '',
    appVersion: '',
    appName: undefined,
    environment: '',
    maxBreadcrumbs: undefined,
    maxEvents: undefined,
    useGlobalHandlers: undefined,
    getUserContext: undefined,
    beforeBreadcrumb: undefined,
    beforeSend: undefined,
    beforeSession: undefined,
    featureFlagEnabledApiKeys: undefined,
  },
  globalMetadata: {},
  featureFlags: {},
};

/** This needs to be called _after_ we've initialized client analytics. */
export function initErrorVitals() {
  if (process.env.NEXT_PUBLIC_BUGSNAG_ENABLE !== 'true') return;

  ErrorVitalsClient = init({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
    environment: releaseStage,
    appVersion: getAppVersion(),
    useGlobalHandlers: true,
    getUserContext,
  });
}

export function getErrorVitals() {
  return ErrorVitalsClient ?? clientStub;
}
