import { useCallback } from 'react';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@onramp/config/queryKeys';
import { fetchWithCommonHeaders } from '@onramp/utils/requests';
import { z } from 'zod';

import { getRelativeUri } from './getRelativeUri';

const CB_VERSION = '2021-01-11';

export const useAddressBook = () => {
  const { data: addressBookConfig } = useQuery(
    QUERY_KEYS.ADDRESS_BOOK_WHITELIST,
    fetchAddressBookWhitelistConfig,
    {
      select: (response) => {
        return {
          isEnabled: Boolean(response?.data.enabled || response?.data.forced),
          gracePeriod: response?.data.grace_period_until,
        };
      },
    },
  );
  const isAddressBookEnabled = Boolean(addressBookConfig?.isEnabled);

  const { data: addressBook } = useQuery(
    QUERY_KEYS.ADDRESS_BOOK,
    async () => fetchAddressBookPaginatedAddresses(),
    { enabled: isAddressBookEnabled },
  );

  const checkIsAddressAllowed = useCallback(
    (address: string, currency: string) => {
      if (!isAddressBookEnabled) return true;

      const addressesForCurrency = addressBook?.filter((a) => a.currency === currency);
      return !!addressesForCurrency?.some((a) => a.address === address);
    },
    [addressBook, isAddressBookEnabled],
  );

  return { isAddressBookEnabled, checkIsAddressAllowed };
};

export const fetchAddressBookWhitelistConfig = async () =>
  fetchWithCommonHeaders('/cb-api-proxy/v2/user/address-whitelisting', {
    headers: {
      'CB-VERSION': CB_VERSION,
    },
  })
    .then(async (res) => res.json())
    .then((json) => {
      return AddressBookWhitelistResponseSchema.parse(json);
    });

export const fetchAddressBookPaginatedAddresses = async (uri = '/cb-api-proxy/v2/address-book') => {
  const resp = await fetchWithCommonHeaders(uri, {
    headers: {
      'CB-VERSION': CB_VERSION,
    },
  });

  const {
    data,
    pagination: { next_uri: nextUri },
  } = AddressBookResponseSchema.parse(await resp.json());

  if (nextUri) {
    const relativeUri = getRelativeUri(nextUri);
    const moreData: typeof data = await fetchAddressBookPaginatedAddresses(relativeUri);
    return data.concat(moreData);
  }

  return data;
};

const AddressBookWhitelistResponseSchema = z.object({
  data: z.object({
    enabled: z.boolean(),
    forced: z.boolean(),
    // For recently turned on address books
    grace_period_until: z.string().optional(),
  }),
});

const AddressBookResponseSchema = z.object({
  data: z.array(
    z.object({
      address: z.string(),
      currency: z.string(),
    }),
  ),
  pagination: z.object({
    next_uri: z.string().nullable(),
  }),
});
