import { defineMessages, useIntl } from 'react-intl';
import { Loader } from '@onramp/components/Loader';
import { ModalLayout } from '@onramp/components/Modal';

const messages = defineMessages({
  loading: {
    id: 'GenericLoadingScreen.loading',
    description: 'Header indicating loading status.',
    defaultMessage: 'Loading',
  },
});

export const GenericLoadingScreen = () => {
  const { formatMessage } = useIntl();

  return (
    <ModalLayout title={formatMessage(messages.loading)}>
      <Loader />
    </ModalLayout>
  );
};
