import type { readPrimaryCryptoAccountFromAccountsV2$key } from '@onramp/data/__generated__/readPrimaryCryptoAccountFromAccountsV2.graphql';
import type { useDefaultSourceOfFundsViewerFragment$data } from '@onramp/data/__generated__/useDefaultSourceOfFundsViewerFragment.graphql';
import { isNotNullish } from '@onramp/utils/types';
import { graphql, readInlineData } from '@cbhq/data-layer';

const readPrimaryCryptoAccountFromAccountsV2Fragment = graphql`
  fragment readPrimaryCryptoAccountFromAccountsV2 on Viewer @inline {
    accountsV2 {
      edges {
        node {
          type
          uuid
          availableBalanceInNativeCurrency {
            value
          }
          assetOrFiatCurrency {
            __typename
            ... on ViewerAsset {
              asset {
                uuid
              }
            }
          }
        }
      }
    }
  }
`;

export const readPrimaryCryptoAccountFromAccountsV2 = (
  viewer: useDefaultSourceOfFundsViewerFragment$data,
  nativeAssetUuid: string,
) => {
  const { accountsV2 } = readInlineData<readPrimaryCryptoAccountFromAccountsV2$key>(
    readPrimaryCryptoAccountFromAccountsV2Fragment,
    viewer,
  );

  const filteredCryptoAccounts = accountsV2?.edges
    ?.map((e) => e?.node)
    .filter(isNotNullish)
    .filter((account) => {
      return (
        account.type === 'WALLET' &&
        account.assetOrFiatCurrency?.__typename === 'ViewerAsset' &&
        account.assetOrFiatCurrency.asset.uuid === nativeAssetUuid
      );
    });

  const sortedCryptoAccounts = filteredCryptoAccounts?.sort((a, b) => {
    return (
      Number(b.availableBalanceInNativeCurrency?.value) ||
      0 - Number(a.availableBalanceInNativeCurrency?.value) ||
      0
    );
  });
  return sortedCryptoAccounts?.[0];
};
