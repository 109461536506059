import '@cbhq/cds-web/globalStyles';
import '@cbhq/two-factor-register/commonStyles';
import '@cbhq/two-factor-register/styles';
import '@onramp/utils/bugsnag';
import React from 'react';
import type { FC } from 'react';
import { MissingParameters } from '@onramp/components/MissingParameters';
import { NoSSR } from '@onramp/components/NoSSR';
import { PAGE_TITLE_BASE } from '@onramp/components/PageTitle';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { useI18n } from '@onramp/hooks/useI18n';
import useInitApis from '@onramp/hooks/utils/useInitApis';
import { useLocalStorageAtoms } from '@onramp/hooks/utils/useLocalStorageAtoms';
import { appState3dsAtom } from '@onramp/state/recoil/atoms/appState3dsAtom';
import type { NonNullSecureInitParameters } from '@onramp/state/recoil/atoms/secureInitAtoms';
import { secureInitParametersAtom } from '@onramp/state/recoil/atoms/secureInitAtoms';
import { jotaiStore } from '@onramp/state/recoil/utils';
import type { InitParamsType, InternalInitParamsType } from '@onramp/types/initParams';
// eslint-disable-next-line @typescript-eslint/no-duplicate-imports -- keeping the original import for the side effects
import { setBugsnagUserDetails } from '@onramp/utils/bugsnag';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { consoleUserSafety } from '@onramp/utils/consoleUserSafety';
import { isProduction, isServer } from '@onramp/utils/environment/sharedEnv';
import { identifyUser, initClientAnalytics } from '@onramp/utils/init/analytics';
import { handleLoadRiskJs, riskJsScriptSrc } from '@onramp/utils/riskJs';
import { getPathType } from '@onramp/utils/routes';
import usePageViewMetric from '@onramp/utils/usePageViewMetric';
import { useReportWebVitals } from '@onramp/utils/useWebVitals';
import { GuestCheckoutProviders, LandingProvidersV2 } from '@onramp/v2/client/contexts/app-providers/AppProviders';
import { initErrorVitals } from '@onramp/v2/client/utils/initErrorVitals';
import { DevTools } from 'jotai-devtools';
import { css } from 'linaria';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import Script from 'next/script';
import { v4 as uuidv4 } from 'uuid';
import { noop } from '@cbhq/cds-utils';
import { persistentData } from '@cbhq/client-analytics';
import type { InitialProps } from './_app/initialization/getInitialProps';
import { getInitialProps } from './_app/initialization/getInitialProps';
import { BuyWidgetProvider, FlowAgnosticProvider, MinimalContentProvider } from './_app/providers';
export const globalStyles = "gdfkt4l";

// Disable logs for embedded pixel to avoid spamming client consoles and leaking internal terminology.
// @see https://github.com/coinbase/cbpay-js/issues/47
if (isProduction && !isServer() && window.location.pathname === '/embed') {
  // eslint-disable-next-line no-console
  console.log = noop;
  // eslint-disable-next-line no-console
  console.error = noop;
  // eslint-disable-next-line no-console
  console.warn = noop;
}

// This has been tested to confirm it isn't triggered on nav
initClientAnalytics();
// At least one of deviceId or userId are required for amplitude to not ignore an event
// and we need to idenitfUser to set that ID. A subsequent identifyUser with a userId will
// update the value while still keeping new events in the same session.
// The the condition is needed to prevent an error on server.
if (globalThis?.navigator !== undefined) {
  identifyUser({});
}
initErrorVitals();
let initParamsFromProps: InitParamsType | undefined;
let internalInitParams: InternalInitParamsType | undefined;
function App({
  Component,
  pageProps,
  router,
  csrfToken,
  clientAppDetails,
  gsscData,
  authenticationMethod,
  guestCheckoutCountryAllowlist,
  featureFlags,
  userUuid,
  isGuestCheckoutRoute,
  isV2Route,
  secureInitParameters,
  initParams,
  appState3ds
}: AppProps & InitialProps) {
  if (!isServer()) {
    // reuse CCA's session id, if already set
    // Setting session UUID in CCA's persistentData
    persistentData.sessionUUID ??= uuidv4();

    // Set our CSRF token, used for all follow-up requests
    if (typeof csrfToken === 'string') {
      clientSessionIdStore.setCsrfToken(csrfToken);
    }

    // the App component is rerendered without server props on navigation, so we need to check so that we don't set this to undefined
    if (clientAppDetails) {
      clientSessionIdStore.setClientAppDetails(clientAppDetails);
    }
    if (gsscData) {
      clientSessionIdStore.setGsscData(gsscData);
    }
    if (authenticationMethod) {
      clientSessionIdStore.setAuthenticationMethod(authenticationMethod);
    }
    if (guestCheckoutCountryAllowlist) {
      clientSessionIdStore.setGuestCheckoutCountryAllowlist(guestCheckoutCountryAllowlist);
    }
    if (featureFlags) {
      clientSessionIdStore.setFeatureFlags(featureFlags);
    }
    if (userUuid) {
      clientSessionIdStore.setUserUuid(userUuid);
      identifyUser({
        userId: userUuid
      });
      setBugsnagUserDetails(userUuid);
    }
    if (secureInitParameters) {
      // CB GQL conventions make the fields nullable in case they're returned from multiple endpoints
      // to allow for a partial result, but we know the params are all or none.
      jotaiStore.set(secureInitParametersAtom, (secureInitParameters as NonNullSecureInitParameters));
    }
    if (appState3ds) {
      jotaiStore.set(appState3dsAtom, appState3ds);
    }
  }
  initParamsFromProps ??= initParams;
  internalInitParams ??= ({
    clientAppDetails
  } as InternalInitParamsType);
  return <>
      <Head>
        <title>{PAGE_TITLE_BASE}</title>
        <meta key="viewport-meta" // this key is required
      name="viewport" content="width=device-width, initial-scale=1.0, viewport-fit=cover" />
      </Head>

      <NoSSR>
        {initParamsFromProps?.requiredParamsError ? <MissingParameters /> : <AppRoot pathname={router.pathname} initParams={initParams} internalInitParamsFromServer={internalInitParams}>
            {!isProduction && <DevTools />}
            <Component {...pageProps} />
          </AppRoot>}

        {(isGuestCheckoutRoute || isV2Route) && <Script id="risk-js" async src={riskJsScriptSrc} onLoad={handleLoadRiskJs} />}
      </NoSSR>
    </>;
}
type AppRootProps = {
  pathname: string;
  initParams?: InitParamsType;
  internalInitParamsFromServer?: InternalInitParamsType;
};
const AppRoot: FC<AppRootProps> = ({
  children,
  pathname,
  initParams,
  internalInitParamsFromServer
}) => {
  useEffectOnMount(() => {
    if (isProduction) {
      consoleUserSafety();
    }

    // We've seen initialization issues with nonce + redirects &
    // are aligned that query param initialization is fine from a
    // security perspective.
    // initParams contain the values from the nonce. This runs
    // in the browser to pull out the nonce param & persist
    // its contents in the query string.
    const haveNonce = new URLSearchParams(window.location.search).has('nonce');
    if (haveNonce && initParams) {
      replaceNonceWithQueryParams(initParams);
    }
  });
  useInitApis();
  usePageViewMetric();
  useReportWebVitals();
  useLocalStorageAtoms();
  const {
    locale,
    messages
  } = useI18n();
  if (!messages) {
    return null;
  }
  const type = getPathType(pathname);
  if (type === 'embed') {
    return <MinimalContentProvider locale={locale} messages={messages}>
        {children}
      </MinimalContentProvider>;
  }
  if (type === 'buy') {
    return <BuyWidgetProvider locale={locale} messages={messages}>
        {children}
      </BuyWidgetProvider>;
  }
  if (type === 'landing') {
    return <LandingProvidersV2 initParamsFromServer={initParams} internalInitParamsFromServer={internalInitParamsFromServer} locale={locale} messages={messages}>
        {children}
      </LandingProvidersV2>;
  }
  if (type === 'v2') {
    if (pathname.includes('guest')) {
      return <GuestCheckoutProviders initParamsFromServer={initParams} locale={locale} messages={messages}>
          {children}
        </GuestCheckoutProviders>;
    }
    return <LandingProvidersV2 initParamsFromServer={initParams} internalInitParamsFromServer={internalInitParams} locale={locale} messages={messages}>
        {children}
      </LandingProvidersV2>;
  }
  return <FlowAgnosticProvider locale={locale} messages={messages}>
      {children}
    </FlowAgnosticProvider>;
};
App.getInitialProps = getInitialProps;

// Temporary fix to avoid flash of unstyled content
// during route transitions. Keep an eye on this
// issue and remove this code when resolved:
// https://github.com/vercel/next.js/issues/17464
const routeChange = () => {
  const tempFix = () => {
    const allStyleElems = document.querySelectorAll('style[media="x"]');
    allStyleElems.forEach(elem => {
      elem.removeAttribute('media');
    });
  };
  tempFix();
};
Router.events.on('routeChangeComplete', routeChange);
Router.events.on('routeChangeStart', routeChange);
function replaceNonceWithQueryParams(initParams: InitParamsType) {
  const newUrl = new URL(window.location.href);
  for (const [key, value] of Object.entries(initParams)) {
    if (typeof value === 'string') {
      newUrl.searchParams.set(key, `${value}`);
    } else {
      newUrl.searchParams.set(key, JSON.stringify(value));
    }
  }

  // secure_standlone indicates that we should init from post message
  if (newUrl.searchParams.get('type') === 'secure_standalone') {
    newUrl.searchParams.delete('type');
  }
  newUrl.searchParams.delete('nonce');
  window.history.replaceState(window.history.state, '', newUrl);
}
export default App;

require("./_app.page.linaria.module.css!=!../../node_modules/linaria/lib/outputCssLoader.js?cacheProvider=!./_app.page.tsx");