/**
 * @generated SignedSource<<a8e7baeb6aacf402a9887c1ba7ee86ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetListCellFragment$data = {
  readonly imageUrl: string | null;
  readonly name: string | null;
  readonly symbol: string | null;
  readonly uuid: string | null;
  readonly " $fragmentType": "AssetListCellFragment";
};
export type AssetListCellFragment$key = {
  readonly " $data"?: AssetListCellFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetListCellFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetListCellFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "uuid",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "symbol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageUrl",
      "storageKey": null
    }
  ],
  "type": "CBPayAsset",
  "abstractKey": null
};

(node as any).hash = "c21dc98812c372f89d39a7be425721f8";

export default node;
