/**
 * @generated SignedSource<<9bb23b01c6284dc83e645875bbbd4b5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetListFragment$data = {
  readonly accountsV2: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly availableBalanceInNativeCurrency: {
          readonly value: string;
        } | null;
        readonly primary: boolean;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"CryptoAccountListCellFragment" | "filterCryptoAccountsByBalanceFragment" | "readAccountViewerAssetUuidFragment">;
      } | null;
    }>;
  } | null;
  readonly cbPayTradableAssets: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly marketCap: string | null;
        readonly symbol: string | null;
        readonly uuid: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"AssetListCellFragment" | "readCBPayAssetAsOnrampStateFragment" | "readCBPayAssetUuidFragment" | "reduceCBPayAssetsBySearchTermFragment" | "useFilterCBPayAssetsByDestinationWalletsFragment">;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentType": "AssetListFragment";
};
export type AssetListFragment$key = {
  readonly " $data"?: AssetListFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetListFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v0/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "Amount",
  "kind": "LinkedField",
  "name": "availableBalanceInNativeCurrency",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filter"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetListFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "filter"
        }
      ],
      "concreteType": "CbPayAssetConnection",
      "kind": "LinkedField",
      "name": "cbPayTradableAssets",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CBPayAssetEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CBPayAsset",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "AssetListCellFragment"
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "readCBPayAssetAsOnrampStateFragment",
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "imageUrl",
                      "storageKey": null
                    },
                    (v2/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "exponent",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NetworkMetadata",
                      "kind": "LinkedField",
                      "name": "networks",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "displayName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "minSend",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "isDefault",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "reduceCBPayAssetsBySearchTermFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useFilterCBPayAssetsByDestinationWalletsFragment",
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "NetworkMetadata",
                      "kind": "LinkedField",
                      "name": "networks",
                      "plural": true,
                      "selections": [
                        (v2/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "readCBPayAssetUuidFragment",
                  "selections": (v3/*: any*/),
                  "args": null,
                  "argumentDefinitions": []
                },
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "quoteCurrency",
                      "value": "USD"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "marketCap",
                  "storageKey": "marketCap(quoteCurrency:\"USD\")"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountConnection",
      "kind": "LinkedField",
      "name": "accountsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "filterCryptoAccountsByBalanceFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "type",
                      "storageKey": null
                    },
                    (v4/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "assetOrFiatCurrency",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "readAccountViewerAssetUuidFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "assetOrFiatCurrency",
                      "plural": false,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Asset",
                              "kind": "LinkedField",
                              "name": "asset",
                              "plural": false,
                              "selections": (v3/*: any*/),
                              "storageKey": null
                            }
                          ],
                          "type": "ViewerAsset",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CryptoAccountListCellFragment"
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "primary",
                  "storageKey": null
                },
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "72fbcba57eba8ffbe7c4df98c6a810fe";

export default node;
