/**
 * @generated SignedSource<<5225915a54da3cdc964ae2204d162564>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccountType = "COLLATERAL" | "DEFI_YIELD" | "FIAT" | "MULTISIG" | "MULTISIG_VAULT" | "STAKED_FUNDS" | "UNKNOWN" | "VAULT" | "WALLET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type filterCryptoAccountsByBalanceFragment$data = {
  readonly assetOrFiatCurrency: {
    readonly __typename: string;
  } | null;
  readonly availableBalanceInNativeCurrency: {
    readonly value: string;
  } | null;
  readonly type: AccountType;
  readonly " $fragmentType": "filterCryptoAccountsByBalanceFragment";
};
export type filterCryptoAccountsByBalanceFragment$key = {
  readonly " $data"?: filterCryptoAccountsByBalanceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"filterCryptoAccountsByBalanceFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "filterCryptoAccountsByBalanceFragment"
};

(node as any).hash = "e740db2db47c2bde11e99cb79a07faf0";

export default node;
