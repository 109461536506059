/**
 * @generated SignedSource<<b1663a751f11c6b0b33fba4f72d87d9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CryptoAccountListCellFragment$data = {
  readonly assetOrFiatCurrency: {
    readonly __typename: "ViewerAsset";
    readonly asset: {
      readonly imageUrl: string;
      readonly name: string;
      readonly symbol: string;
      readonly uuid: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"readAccountBalanceFiatFragment" | "readAccountBalanceFragment">;
  readonly " $fragmentType": "CryptoAccountListCellFragment";
};
export type CryptoAccountListCellFragment$key = {
  readonly " $data"?: CryptoAccountListCellFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CryptoAccountListCellFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "currency",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetBalanceBreakdown",
  "kind": "LinkedField",
  "name": "assetBalanceBreakdown",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "availableToSendBalance",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AssetBalanceBreakdown",
    "kind": "LinkedField",
    "name": "assetBalanceBreakdown",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Amount",
        "kind": "LinkedField",
        "name": "availableToSendBalanceFiat",
        "plural": false,
        "selections": (v0/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CryptoAccountListCellFragment",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readAccountBalanceFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "assetOrFiatCurrency",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "type",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "type": "TiersCurrency",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v1/*: any*/)
              ],
              "type": "ViewerAsset",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readAccountBalanceFiatFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "assetOrFiatCurrency",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": (v2/*: any*/),
              "type": "TiersCurrency",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": (v2/*: any*/),
              "type": "ViewerAsset",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "assetOrFiatCurrency",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Asset",
              "kind": "LinkedField",
              "name": "asset",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "uuid",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "symbol",
                  "storageKey": null
                },
                (v3/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "imageUrl",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ViewerAsset",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "c6888c805096740b50999a8044f43f53";

export default node;
