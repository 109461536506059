/**
 * @generated SignedSource<<6de463f8cf5fe707bb409be6febf3121>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readPrimaryFiatWalletFragment$data = {
  readonly __typename: "CBPaySourceOfFundsFiatWallet";
  readonly account: {
    readonly " $fragmentSpreads": FragmentRefs<"readAccountBalanceFragment">;
  } | null;
  readonly isEnabled: boolean | null;
  readonly paymentMethod: {
    readonly uuid: string;
  } | null;
  readonly " $fragmentType": "readPrimaryFiatWalletFragment";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "readPrimaryFiatWalletFragment";
};
export type readPrimaryFiatWalletFragment$key = {
  readonly " $data"?: readPrimaryFiatWalletFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readPrimaryFiatWalletFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readPrimaryFiatWalletFragment"
};

(node as any).hash = "f8a4e058ae35a85b746d3365ffbc4f78";

export default node;
