import appVersion from '@onramp/appVersion';
import { reportBugsnag } from '@onramp/utils/bugsnag';
import {
  analyticsDevOverride,
  enableAnalyticsDebug,
  isProduction,
} from '@onramp/utils/environment/sharedEnv';
import { identify, init, PlatformName, UserTypeRetail } from '@cbhq/client-analytics';

import { clientSessionIdStore } from '../clientSessionIdStore';

type IdentifyData = {
  userId?: string;
  countryCode?: string;
};

/**
 * Gets the browser locale, defaulting to the specified default
 * if the browser locale could not be determined.
 *
 * @param defaultLocale The default locale to use.
 */
const getNavigatorLocale = (defaultLocale = 'en'): string => {
  if (navigator.languages && navigator.languages.length > 0) {
    return navigator.languages[0] || defaultLocale;
  }
  return navigator.language || defaultLocale;
};

export const identifyUser = ({ userId, countryCode }: IdentifyData) => {
  // At least one of userId or deviceId are required for amplitude, or it drops the event
  identify({
    userId,
    countryCode,
    locale: getNavigatorLocale('en'),
    deviceId: clientSessionIdStore.getDeviceId(),
    userTypeEnum: UserTypeRetail,
    session_lcc_id: clientSessionIdStore.getGsscData()?.sid,
  });
};

export const initClientAnalytics = (showDebugLogging = enableAnalyticsDebug) => {
  init({
    amplitudeApiKey: process.env.NEXT_PUBLIC_AMPLITUDE_KEY,
    isProd: isProduction && !analyticsDevOverride,
    platform: process.browser ? PlatformName.web : PlatformName.server,
    onError: (error: Error) => {
      reportBugsnag(error, { context: 'analytics_sdk', severity: 'warning' });
    },
    projectName: 'coinbase_pay',
    showDebugLogging,
    version: isProduction ? appVersion() : 'local',
  });
};
