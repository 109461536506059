/**
 * Simple check for non-null values, and hints TS into the type if not null
 */
export const isNonEmpty = <T>(x: T | null | undefined): x is T => x !== null && x !== undefined;

/**
 * Utility (mostly for typing) to use a value if non-null/undef, and fallback otherwise
 */
export const nonEmpty = <T>(x: T | null | undefined, fallback: T): T =>
  isNonEmpty(x) ? x : fallback;
