import type { FormatNumberOptions, IntlShape } from 'react-intl';

type Params = {
  amount?: string | number;
  currency?: string;
  intl: IntlShape;
  opts?: FormatNumberOptions;
};

/**
 * Formats fiat currencies
 */
const formatFiatCurrency = ({ amount: maybeAmount, currency = 'USD', intl, opts = {} }: Params) => {
  const amount = typeof maybeAmount === 'string' ? parseFloat(maybeAmount) : maybeAmount;
  if (typeof amount !== 'number' || Number.isNaN(amount)) return undefined;
  if (currency.length === 0) return undefined;

  return intl.formatNumber(amount, {
    style: 'currency',
    currency,
    ...opts,
  });
};

export default formatFiatCurrency;
