import {
  isDebug3dsEnabledAtom,
  isMockBuyAndSendEnabledAtom,
} from '@onramp/state/recoil/atoms/debugAtoms';
import { useAtom } from 'jotai';

/**
 * When you first call useAtom on a LocalStorageAtom, you get back the default value. On the next render cycle you'll
 * get the real value loaded from local storage. Because we often access these atoms in hooks that return callbacks
 * and don't get a chance to re-render, we need to call useAtom on these atoms in a top-level component that will load
 * before any of the hooks get called. So we call useAtom on all our local storage atoms here, and load this hook in
 * _app.page.tsx.
 */
export function useLocalStorageAtoms(): void {
  useAtom(isMockBuyAndSendEnabledAtom);
  useAtom(isDebug3dsEnabledAtom);
}
