import type { ExperimentTestName } from '@onramp/utils/experiments/experiments';
import fromPairs from 'lodash/fromPairs';

import { clientSessionIdStore } from '../clientSessionIdStore';
import type { ExperimentCookieName } from '../cookies';
import { getCookie } from '../cookies/clientUtils';

import type { ExperimentGroupData, ExperimentsResponse } from './consts';
import { experimentCookieSchema } from './consts';
import { Experiments } from './experiments';

export function getExperimentsFromCookieName(cookieName: ExperimentCookieName, subjectId?: string) {
  const parsedCookie = experimentCookieSchema.parse(getCookie({ cookieName }));

  // if we get a subjectId and it doesn't match the cookie's, we return ignore the cookie
  if (subjectId && parsedCookie?.subjectId && parsedCookie.subjectId !== subjectId)
    return undefined;

  return parsedCookie?.groups;
}

/**
 * Merges experiment arrays so that there are no duplicate tests in the returned value. The last element wins, so
 * `deduplicateExperimentArrays([{ test: 'some-test', group: 'control' }, { test: 'some-test', group: 'treatment' }])`
 * returns `[{ test: 'some-test', group: 'treatment' }]`
 */
export function deduplicateExperimentArrays(experimentData: ExperimentGroupData[]) {
  const experimentMap = fromPairs(
    experimentData.map((g) => [g.test, g] as [string, ExperimentGroupData]),
  );

  return Object.values(experimentMap);
}

export function getExperimentsFromCookies(): ExperimentsResponse | undefined {
  try {
    const deviceExperiments = getExperimentsFromCookieName(
      'deviceExperiments',
      clientSessionIdStore.getDeviceId(),
    );
    const walletExperiments = getExperimentsFromCookieName(
      'walletExperiments',
      clientSessionIdStore.getWalletUserId(),
    );

    // we need to return undefined in case the actual cookies aren't cached so that we fetch them
    if (!deviceExperiments && !walletExperiments) return undefined;

    const overriddenExperiments = getExperimentsFromCookieName('overridenExperiments');

    return {
      groups: deduplicateExperimentArrays([
        ...(deviceExperiments ?? []),
        ...(walletExperiments ?? []),
        ...(overriddenExperiments ?? []),
      ]),
    };
    // eslint-disable-next-line no-empty
  } catch {}

  return undefined;
}

export const isExperimentInTreatment = (
  groups: ExperimentGroupData[] | undefined,
  experimentName: ExperimentTestName,
) => {
  const name = Experiments[experimentName]?.name ?? '';
  return groups?.find((gr) => gr.test === name)?.group === 'treatment';
};
