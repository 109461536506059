import { useCallback } from 'react';
import { activeWidgetAtom, buyWidgetParamsAtom } from '@onramp/state/recoil/appParamsState';
import { broadcastEvent } from '@onramp/utils/postMessage';
import { useAtomValue } from 'jotai';

export const useOpenUrl = () => {
  const activeWidget = useAtomValue(activeWidgetAtom);
  const buyParams = useAtomValue(buyWidgetParamsAtom);

  const handlingRequestedUrls = activeWidget === 'buy' && Boolean(buyParams?.handlingRequestedUrls);

  const openUrl = useCallback(
    (url: string | URL) => {
      if (!handlingRequestedUrls) {
        window.open(url, '_blank');
      }

      broadcastEvent({ eventName: 'request_open_url', url: `${url}` });
    },
    [handlingRequestedUrls],
  );

  return openUrl;
};
