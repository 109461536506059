import React from 'react';
import type { ErrorLevel } from '@onramp/utils/errors';

import { ErrorBoundary } from './ErrorBoundary';
import { ErrorHandlerProvider } from './ErrorHandlerProvider';

type Props = {
  children: React.ReactNode;
  level: ErrorLevel;
};

function ErrorFrameworkProvider({ children, level }: Props) {
  return (
    <ErrorBoundary level={level}>
      <ErrorHandlerProvider level={level}>{children}</ErrorHandlerProvider>
    </ErrorBoundary>
  );
}

export default ErrorFrameworkProvider;
