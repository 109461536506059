/**
 * @generated SignedSource<<f3078bafd6d31b25128b851d05a17db3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useNewSourcesOfFundsFragment$data = {
  readonly accountsV2: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"useParseSourcesOfFundsAccountFragment">;
      } | null;
    }>;
  } | null;
  readonly cbPaySourcesOfFunds: ReadonlyArray<{
    readonly __typename: "CBPaySourceOfFundsCryptoAccount";
    readonly account: {
      readonly " $fragmentSpreads": FragmentRefs<"useParseSourcesOfFundsAccountFragment">;
    } | null;
  } | {
    readonly __typename: "CBPaySourceOfFundsFiatWallet";
    readonly account: {
      readonly " $fragmentSpreads": FragmentRefs<"useParseSourcesOfFundsAccountDepositInformationFragment" | "useParseSourcesOfFundsAccountFragment">;
    } | null;
    readonly isEnabled: boolean | null;
    readonly paymentMethod: {
      readonly " $fragmentSpreads": FragmentRefs<"useParseSourcesOfFundsPaymentMethodFragment">;
    } | null;
  } | {
    readonly __typename: "CBPaySourceOfFundsPaymentMethod";
    readonly isEnabled: boolean | null;
    readonly paymentMethod: {
      readonly pickerData: {
        readonly __typename: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"useParseSourcesOfFundsPaymentMethodFragment">;
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"useSourceOfFundsToCommonFormatRequiredData">;
  readonly " $fragmentType": "useNewSourcesOfFundsFragment";
};
export type useNewSourcesOfFundsFragment$key = {
  readonly " $data"?: useNewSourcesOfFundsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useNewSourcesOfFundsFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "kind": "InlineDataFragmentSpread",
  "name": "getIsCountryAllowedAccountFragment",
  "selections": [
    (v1/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  (v4/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetBalanceBreakdown",
  "kind": "LinkedField",
  "name": "assetBalanceBreakdown",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Amount",
      "kind": "LinkedField",
      "name": "availableToSendBalance",
      "plural": false,
      "selections": (v5/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "kind": "InlineDataFragmentSpread",
  "name": "readAccountBalanceFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "assetOrFiatCurrency",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            (v6/*: any*/)
          ],
          "type": "TiersCurrency",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v6/*: any*/)
          ],
          "type": "ViewerAsset",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v8 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AssetBalanceBreakdown",
    "kind": "LinkedField",
    "name": "assetBalanceBreakdown",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Amount",
        "kind": "LinkedField",
        "name": "availableToSendBalanceFiat",
        "plural": false,
        "selections": (v5/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = {
  "kind": "InlineDataFragmentSpread",
  "name": "useParseSourcesOfFundsAccountFragment",
  "selections": [
    (v2/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readCryptoAccountsFragment",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "readCryptoAccountFragment",
          "selections": [
            (v2/*: any*/),
            (v7/*: any*/),
            {
              "kind": "InlineDataFragmentSpread",
              "name": "readAccountBalanceFiatFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "assetOrFiatCurrency",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineFragment",
                      "selections": (v8/*: any*/),
                      "type": "TiersCurrency",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v8/*: any*/),
                      "type": "ViewerAsset",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": ([]/*: any*/)
            },
            (v9/*: any*/),
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "assetOrFiatCurrency",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Asset",
                      "kind": "LinkedField",
                      "name": "asset",
                      "plural": false,
                      "selections": [
                        (v9/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "symbol",
                          "storageKey": null
                        },
                        (v10/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "imageUrl",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "type": "ViewerAsset",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v10/*: any*/)
                  ],
                  "type": "TiersCurrency",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": ([]/*: any*/)
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readFiatWalletsAccountFragment",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "readFiatWalletAccountFragment",
          "selections": [
            (v2/*: any*/),
            (v7/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "assetOrFiatCurrency",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "code",
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "type": "TiersCurrency",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": ([]/*: any*/)
        },
        (v9/*: any*/),
        (v1/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v12 = [
  (v11/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isEnabled",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "use3ds",
  "storageKey": null
},
v15 = {
  "kind": "InlineDataFragmentSpread",
  "name": "getIsCountryAllowedFiatFragment",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "instantBuy",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "pickerData",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v14/*: any*/)
          ],
          "type": "WorldpayCardPickerData",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "pickerData",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "PaymentMethodFiatAccount",
          "kind": "LinkedField",
          "name": "fiatAccount",
          "plural": false,
          "selections": [
            (v9/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "CoinbaseFiatAccountPickerData",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "kind": "InlineDataFragmentSpread",
  "name": "useParseSourcesOfFundsPaymentMethodFragment",
  "selections": [
    (v15/*: any*/),
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readFiatWalletsPaymentMethodFragment",
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "readFiatWalletPaymentMethodFragment",
          "selections": [
            (v9/*: any*/),
            (v10/*: any*/),
            (v1/*: any*/),
            (v3/*: any*/),
            (v16/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": ([]/*: any*/)
        },
        (v9/*: any*/),
        (v1/*: any*/),
        (v16/*: any*/)
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "readPaymentMethodsFragment",
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "primaryBuy",
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "readPaymentMethodFragment",
          "selections": [
            (v9/*: any*/),
            (v1/*: any*/),
            {
              "alias": "paymentMethodName",
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "verified",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "TradeAllowance",
              "kind": "LinkedField",
              "name": "tradeCapabilities",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "allowBuy",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "pickerData",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "accountName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "accountNumber",
                      "storageKey": null
                    }
                  ],
                  "type": "BankPickerDataV2",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "institutionName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "bin",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cardLast4",
                      "storageKey": null
                    },
                    (v14/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "requires3dsDdc",
                      "storageKey": null
                    }
                  ],
                  "type": "WorldpayCardPickerData",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentLimits",
              "kind": "LinkedField",
              "name": "limits",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Amount",
                  "kind": "LinkedField",
                  "name": "remainingAvailableLimit",
                  "plural": false,
                  "selections": (v5/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "description",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v15/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": ([]/*: any*/)
        }
      ],
      "args": null,
      "argumentDefinitions": ([]/*: any*/)
    }
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./useNewSourcesOfFundsRefetchableFragment.graphql')
    }
  },
  "name": "useNewSourcesOfFundsFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "supportedSourcesOfFunds",
          "value": [
            "PAYMENT_METHOD",
            "FIAT_WALLET",
            "CRYPTO_ACCOUNT"
          ]
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "cbPaySourcesOfFunds",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "type": "CBPaySourceOfFundsCryptoAccount",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentMethodV2",
              "kind": "LinkedField",
              "name": "paymentMethod",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "pickerData",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                (v17/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "CBPaySourceOfFundsPaymentMethod",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v13/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "account",
              "plural": false,
              "selections": [
                (v11/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "useParseSourcesOfFundsAccountDepositInformationFragment",
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "readDepositInformationFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AccountDepositInformation",
                          "kind": "LinkedField",
                          "name": "depositInformation",
                          "plural": false,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isDepositAllowed",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "depositCurrency",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AccountDepositMethod",
                              "kind": "LinkedField",
                              "name": "availableDepositMethods",
                              "plural": true,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isActive",
                                  "storageKey": null
                                },
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "AccountDepositMethodInformation",
                                  "kind": "LinkedField",
                                  "name": "depositInformation",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "key",
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "title",
                                      "storageKey": null
                                    },
                                    (v4/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentMethodV2",
              "kind": "LinkedField",
              "name": "paymentMethod",
              "plural": false,
              "selections": [
                (v17/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "type": "CBPaySourceOfFundsFiatWallet",
          "abstractKey": null
        }
      ],
      "storageKey": "cbPaySourcesOfFunds(supportedSourcesOfFunds:[\"PAYMENT_METHOD\",\"FIAT_WALLET\",\"CRYPTO_ACCOUNT\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountConnection",
      "kind": "LinkedField",
      "name": "accountsV2",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Account",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": (v12/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSourceOfFundsToCommonFormatRequiredData"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "de61c6e937fa88ca0c9c5699a2938e72";

export default node;
