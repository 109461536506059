import type { IntlShape } from 'react-intl';

type Params = {
  amount?: string | number;
  currency?: string;
  precision?: number;
  intl: IntlShape;
};

/**
 * Formats crypto currencies
 */
const formatCryptoCurrency = ({ amount: maybeAmount, currency, intl, precision = 8 }: Params) => {
  const amount = typeof maybeAmount === 'string' ? parseFloat(maybeAmount) : maybeAmount;
  if (typeof amount !== 'number' || Number.isNaN(amount)) return undefined;

  const numValue = intl.formatNumber(Number(amount), { maximumFractionDigits: precision });

  return `${numValue} ${currency}`;
};

export default formatCryptoCurrency;
