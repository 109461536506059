import { VStack } from '@cbhq/cds-web/layout/VStack';
import { Spinner } from '@cbhq/cds-web/loaders';
import { TextBody } from '@cbhq/cds-web/typography';

type LoaderProps = {
  subText?: string;
};

export const Loader = ({ subText }: LoaderProps) => (
  <VStack
    spacingHorizontal={3}
    spacingVertical={8}
    gap={3}
    width="100%"
    height="100%"
    justifyContent="center"
    alignItems="center"
  >
    <Spinner size={4} color="primary" testID="loader" />
    {typeof subText === 'string' && (
      <TextBody as="p" color="foregroundMuted" align="center">
        {subText}
      </TextBody>
    )}
  </VStack>
);
