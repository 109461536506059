import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { onCLS, onFCP, onFID, onLCP, onTTFB } from 'web-vitals';

import type { MetricDefinitionMap } from './metrics';
import { logWidgetMetricOnce } from './metrics';

// The Coinbase client analytics library also reports web vitals metrics, but those metrics don't make it to Datadog
// due to filters put in place by infra to reduce datadog costs, since many teams don't use these metrics. We need web
// vitals metrics do monitor our FE latency, so we report them ourselves and monitor them in Datadog.
// See https://docs.cbhq.net/frontend/analytics/log-metrics#blocking-metrics-from-datadog

const logWebVitalMetric = (
  metricValue: number,
  vitalType: MetricDefinitionMap['perf_web_vital']['tags']['vital_type'],
) => {
  logWidgetMetricOnce({
    metricName: 'perf_web_vital',
    value: metricValue,
    tags: { vital_type: vitalType },
  });
};

// See https://web.dev/vitals/ for more details on these metrics.
export const useReportWebVitals = () => {
  // This must only trigger once per page load as each 'on' method creates a new event listener.
  useEffectOnMount(() => {
    onTTFB((metric) => {
      logWebVitalMetric(metric.value, 'ttfb');
    });
    onLCP((metric) => {
      logWebVitalMetric(metric.value, 'lcp');
    });
    onFID((metric) => {
      logWebVitalMetric(metric.value, 'fid');
    });
    onCLS((metric) => {
      logWebVitalMetric(metric.value, 'cls');
    });
    onFCP((metric) => {
      logWebVitalMetric(metric.value, 'fcp');
    });
  });
};
