import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { atomFamily, atomWithReset } from 'jotai/utils';

// eslint-disable-next-line @typescript-eslint/no-unused-vars -- we need to accept an argument for atomFamily to work
export const textInputAtomFamily = atomFamily((fieldName: string) => atomWithReset(''));

export function useTextInputState(initialStateAtom: ReturnType<typeof textInputAtomFamily>) {
  const [state, setState] = useAtom(initialStateAtom);
  const stateSetter = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setState(e.currentTarget.value);
    },
    [setState],
  );

  return [state, stateSetter] as const;
}
