import type { SourceOfFundsData } from '@onramp/state/types/SourceOfFundsData';
import { have_sources_of_funds } from '@onramp/utils/eventing/eventDeclarations';
import { logEventOnce } from '@onramp/utils/eventing/logEventOnce';
import { logWidgetMetricOnce } from '@onramp/utils/metrics';
import bigJs from 'big.js';

type LogEnabledNonZeroPaymentMethodsOnceArgs = {
  applePay: { verified?: boolean } | undefined | null;
  cashAccounts: SourceOfFundsData[];
  cryptoAccounts: SourceOfFundsData[];
  fiatAccounts: SourceOfFundsData[];
};

export const logEnabledNonZeroPaymentMethodsOnce = ({
  applePay,
  cashAccounts,
  cryptoAccounts,
  fiatAccounts,
}: LogEnabledNonZeroPaymentMethodsOnceArgs) => {
  const cryptoCount = cryptoAccounts.filter(filterDisabledOrZeroBalanceAccounts).length;

  const fiatSources = fiatAccounts.concat(cashAccounts).filter(filterDisabledOrZeroBalanceAccounts);
  const fiatCount = applePay?.verified ? fiatSources.length + 1 : fiatSources.length;

  // adding this to track users who have no sof
  logEventOnce('have_sources_of_funds', {
    ...have_sources_of_funds,
    haveCryptoSourceOfFunds: cryptoCount > 0,
    haveFiatSourceOfFunds: fiatCount > 0,
  });

  logWidgetMetricOnce({
    metricName: 'have_sources_of_funds',
    value: 1,
    tags: {
      haveCryptoSourceOfFunds: cryptoCount > 0,
      haveFiatSourceOfFunds: fiatCount > 0,
    },
  });
};

const filterDisabledOrZeroBalanceAccounts = (account: SourceOfFundsData) => {
  if (account.isDisabled) return false;

  const haveNonZeroCryptoLimit = haveNonZeroLimit(account.limitData?.crypto?.value);
  const haveNonZeroFiatLimit = haveNonZeroLimit(account.limitData?.fiat?.value);

  if (!haveNonZeroCryptoLimit && !haveNonZeroFiatLimit) {
    return false;
  }

  return true;
};

const haveNonZeroLimit = (value: string | undefined) => {
  if (value === undefined) return false;
  try {
    return bigJs(value).gt(0);
  } catch {
    return false;
  }
};
