import type { AddMetadataArgument } from '@onramp/utils/errors';

import { mockAssertPresenceValuesOnError } from './environment/clientEnv';
import { isLocalDevelopment } from './environment/sharedEnv';
import { HandledError } from './errors';

type OptionalHandledErrorConstructorParams = Partial<ConstructorParameters<typeof HandledError>[0]>;

type RequiredHandledErrorConstructorParams = Required<
  Pick<OptionalHandledErrorConstructorParams, 'debugMessage' | 'message'>
>;

type AdditionalErrorParams = Omit<OptionalHandledErrorConstructorParams, 'code'> &
  RequiredHandledErrorConstructorParams & {
    metadata?: AddMetadataArgument;
    /** Set to enable fallback value instead of error, when globally enabled. */
    mockEnvVar?: keyof typeof mockValueStrings;
  };

/**
 * Throw specific error (that can be handled by error boundaries) if value not present
 */
export const assertPresence = <T>(value: T, additionalErrorParams: AdditionalErrorParams) => {
  if (value === null || value === undefined) {
    const { metadata, mockEnvVar, ...otherParams } = additionalErrorParams;

    if (mockAssertPresenceValuesOnError && mockEnvVar) {
      const mockValueString = mockValueStrings[mockEnvVar];

      if (mockValueString !== undefined) {
        try {
          const mockValue = JSON.parse(mockValueString) as NonNullable<T>;
          console.error(`assertPresence using mock value from ${mockEnvVar}`);
          return mockValue;
        } catch {
          console.error(`assertPresence failed to use mock value for ${mockEnvVar}`);
        }
      }
    }

    const handledError = new HandledError({
      ...otherParams,
      code: 'assert_data_presence',
    });

    if (isLocalDevelopment) {
      // eslint-disable-next-line no-console
      console.error(`assertPresence: ${handledError.debugMessage}`);
    }

    if (metadata) {
      handledError.addMetadata(metadata);
    }

    throw handledError;
  }

  return value as NonNullable<T>;
};

const mockValueStrings = {
  BUY_RESTRICTIONS: process.env.NEXT_PUBLIC_ASSERT_PRESENCE_MOCK_BUY_RESTRICTIONS,
  SEND_RESTRICTIONS: process.env.NEXT_PUBLIC_ASSERT_PRESENCE_MOCK_SEND_RESTRICTIONS,
  TWO_FACTOR_METHOD: process.env.NEXT_PUBLIC_ASSERT_PRESENCE_MOCK_TWO_FACTOR_METHOD,
} as const;
