import { getQueryParams } from '@onramp/utils/queryParams';

type CbpayQueryValues = {
  appId: string;
  nonce: string;
  nonce3ds: string;
};

export function getCbpayQueryValues(): CbpayQueryValues | undefined {
  const queryParams = getQueryParams();
  const cbpayQuery = queryParams.get('cbpayQuery');
  const parsedQuery = window.atob(cbpayQuery ?? '');
  const searchParams = new URLSearchParams(parsedQuery);
  const appId = searchParams.get('appId');
  const nonce = searchParams.get('nonce');
  const nonce3ds = searchParams.get('nonce3ds');

  if (!appId || !nonce || !nonce3ds) return undefined;
  return { appId, nonce, nonce3ds };
}
