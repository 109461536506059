/**
 * @generated SignedSource<<cd5cc4ef4ece2a8a1fdada0bbfcb6f94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type reduceCBPayAssetsBySearchTermFragment$data = {
  readonly name: string | null;
  readonly symbol: string | null;
  readonly " $fragmentType": "reduceCBPayAssetsBySearchTermFragment";
};
export type reduceCBPayAssetsBySearchTermFragment$key = {
  readonly " $data"?: reduceCBPayAssetsBySearchTermFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reduceCBPayAssetsBySearchTermFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "reduceCBPayAssetsBySearchTermFragment"
};

(node as any).hash = "6185ee7abd5280bc3caf3d7269dca13a";

export default node;
