import { createContext, useContext, useMemo, useState } from 'react';
import type { InitParamsType, InternalInitParamsType } from '@onramp/types/initParams';

type InitParamsContextType = {
  initParams: InitParamsType;
  internalInitParams?: InternalInitParamsType;
};

const defaultInternalInitParams = {
  clientAppDetails: {
    appId: '',
    oauthId: '',
    domains: [],
    displayName: '',
    isActive: false,
    isThirdParty: false,
    isSecureInitRequired: false,
  },
};

export const InitParamsContext = createContext<InitParamsContextType>({
  initParams: {
    appId: '',
    destinationWallets: [],
  },
  internalInitParams: defaultInternalInitParams,
});

export const InitParamsProvider: React.FC<{
  initParamsFromServer: InitParamsType;
  internalInitParamsFromServer?: InternalInitParamsType;
}> = ({ children, initParamsFromServer, internalInitParamsFromServer }) => {
  const [initParams] = useState<InitParamsType>(initParamsFromServer ?? {});
  const [internalInitParams] = useState<InternalInitParamsType>(
    internalInitParamsFromServer ?? defaultInternalInitParams,
  );

  const value = useMemo(
    () => ({
      initParams,
      internalInitParams,
    }),
    [initParams, internalInitParams],
  );

  return <InitParamsContext.Provider value={value}>{children}</InitParamsContext.Provider>;
};

export const useInitParams = () => {
  const context = useContext(InitParamsContext);
  if (context === undefined) {
    throw new Error('useInitParams must be used within a InitParamsProvider');
  }
  return context;
};
