import { useMemo } from 'react';
import type { CbPayAssetFilters } from '@onramp/data/graphql-types';
import { useDestinationWalletsV2 } from '@onramp/state/recoil/appParamsState';

export const useCbPayAssetsFilter = () => {
  const { destinationWallets } = useDestinationWalletsV2();

  return useMemo<CbPayAssetFilters>(() => {
    return {
      networks: destinationWallets.flatMap(
        ({ networksForAPISubmission }) => networksForAPISubmission || [],
      ),
      tickers: destinationWallets.flatMap(({ assets }) => assets || []),
    };
  }, [destinationWallets]);
};
