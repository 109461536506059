/**
 * https://github.cbhq.net/commerce/frontend/blob/88662b4ee954338e8515b2086d5bb8e9e5aec02c/libs/commerce-shared/utils/session/doOncePerSession.ts
 * Executes a function once per page session based on the idem key
 * See https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 * @param fn will be executed once per session based on the idem key
 * @param idemKey used as the cache key to determine if fn has been executed this session
 * @returns a wrapper function that will execute fn once per session
 */
export function doOncePerSession<F extends (...args: never[]) => void>(fn: F, idemKey: string): F {
  return function doOncePerSessionInnerFn(...args: never[]) {
    if (sessionStorage.getItem(idemKey)) {
      return;
    }
    sessionStorage.setItem(idemKey, 'true');
    fn(...args);
  } as F;
}
