/**
 * @generated SignedSource<<06ed398fe127ec988b458316930caeb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccountType = "COLLATERAL" | "DEFI_YIELD" | "FIAT" | "MULTISIG" | "MULTISIG_VAULT" | "STAKED_FUNDS" | "UNKNOWN" | "VAULT" | "WALLET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type getIsCountryAllowedAccountFragment$data = {
  readonly type: AccountType;
  readonly " $fragmentType": "getIsCountryAllowedAccountFragment";
};
export type getIsCountryAllowedAccountFragment$key = {
  readonly " $data"?: getIsCountryAllowedAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"getIsCountryAllowedAccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "getIsCountryAllowedAccountFragment"
};

(node as any).hash = "67d0979ca1229f96a945043005d95e73";

export default node;
