import { z } from 'zod';

export const isGuestCheckoutEnabledHandlerPayloadSchema = z.object({
  /**
   * This parameter is meant to be used by CBWallet. On the FE, we won't know the user's country on
   * the start of the flow
   */
  countryCode: z.string().min(1).optional(),
});

export type IsGuestCheckoutEnabledHandlerPayload = z.TypeOf<
  typeof isGuestCheckoutEnabledHandlerPayloadSchema
>;

export const isGuestCheckoutEnabledHandlerResponseSchema = z.object({
  isGuestCheckoutEnabled: z.boolean(),
});

export type IsGuestCheckoutEnabledHandlerResponse = z.TypeOf<
  typeof isGuestCheckoutEnabledHandlerResponseSchema
>;
