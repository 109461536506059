/* eslint-disable relay/must-colocate-fragment-spreads */
import React, { Suspense } from 'react';
import { PageWrapper } from '@onramp/components/PageWrapper';
import { usePolicyRestrictionsGuardArgs } from '@onramp/components/PolicyRestrictionsGuard';
import type { BuySelectAssetPageDataProviderQuery } from '@onramp/data/__generated__/BuySelectAssetPageDataProviderQuery.graphql';
import { LoadingAssets } from '@onramp/pages/buy/select-asset/components/LoadingAssets';
import { useSelectAssetHeaderText } from '@onramp/pages/buy/select-asset/index.page';
import { isMobileExperience } from '@onramp/utils/postMessage';
import { useCbPayAssetsFilter } from '@onramp/utils/useCbPayAssetsFilter';
import { useAtom } from 'jotai';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';

import { countryCodeLoadable } from '../recoil/selectors/countryCodeSelector';
import { nativeCurrencyLoadable } from '../recoil/selectors/nativeCurrencySelector';
import { twoFactorMethodLoadable } from '../recoil/selectors/twoFactorMethodSelector';

const buySelectAssetPageQuery = graphql`
  query BuySelectAssetPageDataProviderQuery(
    $filter: CBPayAssetFilters!
    $sends: Boolean!
    $buys: Boolean!
    $pay: Boolean!
    $inWebView: Boolean!
  ) {
    viewer {
      ...useNewSourcesOfFundsFragment
      ...AssetListFragment @arguments(filter: $filter)
      ...useSelectAssetTabsFragment @arguments(filter: $filter)
      ...useRunChecksAgainstAssetListFragment @arguments(filter: $filter)
      ...PolicyRestrictionsGuardFragment
        @arguments(sends: $sends, buys: $buys, pay: $pay, inWebView: $inWebView)
    }
  }
`;

// This component triggers async loading of all the data necessary to render the select-asset page, preventing a series
// of waterfalling requests that significantly increase first page load time.
export const BuySelectAssetPageDataProvider: React.FC = ({ children }) => {
  const headerText = useSelectAssetHeaderText();
  return (
    <Suspense
      fallback={
        <PageWrapper headerText={headerText}>
          <LoadingAssets />
        </PageWrapper>
      }
    >
      <DataProvider>{children}</DataProvider>
    </Suspense>
  );
};

const DataProvider: React.FC = ({ children }) => {
  // These Atoms make graphql requests that are needed for the initial page load, so we trigger loading them async
  useAtom(countryCodeLoadable);
  useAtom(nativeCurrencyLoadable);
  useAtom(twoFactorMethodLoadable);

  const filter = useCbPayAssetsFilter();
  const {
    sends: isSendsRequired,
    buys: isBuysRequired,
    pay: isPayRequired,
  } = usePolicyRestrictionsGuardArgs();

  useLazyLoadQuery<BuySelectAssetPageDataProviderQuery>(
    buySelectAssetPageQuery,
    {
      filter,
      sends: isSendsRequired || false,
      buys: isBuysRequired || false,
      pay: isPayRequired || false,
      inWebView: isMobileExperience(),
    },
    {
      // This forces the component to suspend, preventing duplicate queries in child components
      fetchPolicy: 'network-only',
    },
  );

  return <>{children}</>;
};
