import { useCallback, useMemo } from 'react';
import { logClickBack } from '@onramp/utils/eventing';
// eslint-disable-next-line import/no-cycle
import { useLogWidgetEvent } from '@onramp/utils/eventing/useLogWidgetEvent';
import { persistQueryParams } from '@onramp/utils/persistQueryParams';
import type { RouteKey } from '@onramp/utils/routes';
import { Routes } from '@onramp/utils/routes';
import get from 'lodash/get';
import { useRouter as useNextRouter } from 'next/router';

export const getRoute = (path: RouteKey) => get(Routes, path) as string;
export const getRouteWithParams = (path: RouteKey) => persistQueryParams(getRoute(path));

// Wrapper around the next.js router.
export const useOnRampRouter = () => {
  const nextRouter = useNextRouter();
  const logWidgetEvent = useLogWidgetEvent();

  const push = useCallback(
    (path: RouteKey) => {
      logWidgetEvent('onramp_router_navigation', { type: 'push', path });
      void nextRouter.push(getRouteWithParams(path));
    },
    [logWidgetEvent, nextRouter],
  );

  const replace = useCallback(
    async (path: RouteKey) => {
      logWidgetEvent('onramp_router_navigation', { type: 'replace', path });
      return nextRouter.replace(getRouteWithParams(path)).catch(() => {});
    },
    [logWidgetEvent, nextRouter],
  );

  const goBack = useCallback(() => {
    logWidgetEvent('onramp_router_navigation', { type: 'goBack' });
    logClickBack();
    nextRouter.back();
  }, [logWidgetEvent, nextRouter]);

  const reload = useCallback(() => {
    logWidgetEvent('onramp_router_navigation', { type: 'reload' });
    nextRouter.reload();
  }, [logWidgetEvent, nextRouter]);

  return useMemo(
    () => ({
      push,
      replace,
      goBack,
      getRoute: getRouteWithParams,
      reload,
    }),
    [push, replace, goBack, reload],
  );
};
