import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cashDescription: {
    id: 'useSourcesOfFunds.cashDescription',
    defaultMessage: '{cashAmount} remaining. No buying limit, and instant access to your assets.',
    description: 'How much money the user has left in their cash account.',
  },
  missingFieldErrorMessage: {
    id: 'useSourcesOfFunds.missingFieldErrorMessage',
    defaultMessage: 'Failed to process your request at this time.',
    description: 'Error message shown when a GraphQL field is null because it failed to load.',
  },
  usDollar: {
    id: 'useSourcesOfFunds.usDollar',
    defaultMessage: 'US Dollar',
    description: 'Name for US Dollar currency',
  },
});
