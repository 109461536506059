import { useEffect } from 'react';
import { logWidgetMetric } from '@onramp/utils/metrics';
import { getRouteKeyFromPath } from '@onramp/utils/routes';
import { useRouter } from 'next/router';

// THIS SHOULD ONLY BE CALLED FROM THE ROOT APP COMPONENT!

// The CB client analytics lib publishes page_view events to Amplitude, but we publish our own page_view metrics to
// Datadog so we can monitor error counts as a % of pageviews to determine impact.
export default function usePageViewMetric() {
  const router = useRouter();

  const handleRouteChange = (url: string) => {
    logWidgetMetric({
      metricName: 'page_view',
      value: 1,
      tags: { page_key: getRouteKeyFromPath(url), is_initial_page: false },
    });
  };

  useEffect(() => {
    // Publish the initial page view
    logWidgetMetric({
      metricName: 'page_view',
      value: 1,
      tags: { page_key: getRouteKeyFromPath(window.location.href), is_initial_page: true },
    });

    // Listen to the router and publish a page_view on route change completed events
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
}
