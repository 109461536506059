// eslint-disable-next-line no-restricted-imports
import type { Response } from '@onramp/server/types';
import type { AppContext } from 'next/app';

export function checkGuestCheckoutEligibility(query: AppContext['router']['query'], res: Response) {
  const { locals } = res;

  const defaultPaymentMethod =
    query.defaultPaymentMethod ?? res.locals.nonceCookieData?.defaultPaymentMethod;

  const killed = locals.killSwitches?.kill_cbpay_guest_checkout;

  // Note: when doing local development feature flags are not available so this will always be false
  const isGuestCheckoutEnabledForPartner = locals.featureFlags?.guestCheckoutEnabled ?? false;
  const allowedDefaultPaymentMethod =
    defaultPaymentMethod === 'CARD' || defaultPaymentMethod === undefined;

  const isAllowedToUseGuestCheckout =
    !killed && isGuestCheckoutEnabledForPartner && allowedDefaultPaymentMethod;

  return {
    defaultPaymentMethod,
    isAllowedToUseGuestCheckout,
    isGuestCheckoutEnabledForPartner,
  };
}
