import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAppParams } from '@onramp/components/AppManagerProvider';
import { useIsWalletApp } from '@onramp/hooks/useIsWalletApp';
import { isGuestCheckoutEnabledHandlerResponseSchema } from '@onramp/shared/isGuestCheckoutEnabledHandler.schema';
import type { guestCheckoutCardDetailsSelector } from '@onramp/state/recoil/guestCheckoutState';
import type { ExtractAtomValue } from 'jotai';
import { useRouter } from 'next/router';
import { Countries as countriesWithCallingCode } from '@cbhq/two-factor-register/types';

import { bytesToString, digestString } from './crypto';
import { coerceError, reportError } from './errors';
import { isMobileExperience } from './postMessage';
// eslint-disable-next-line import/no-cycle
import { fetchWithCommonHeaders } from './requests';
import { parseUrl } from './url';

const DAPP_WALLET_APP_ID = '15374852-b34d-44eb-afe6-4a6bb1da8e31';

export const getIsGuestCheckoutPath = (href = window.location.href): boolean => {
  const url = parseUrl(href, window.location.href);
  if (!url) return false;

  const { pathname } = url;
  return pathname.startsWith('/buy/guest/');
};

export const useIsGuestCheckoutPath = () => {
  const { asPath } = useRouter();
  return useMemo(() => getIsGuestCheckoutPath(asPath), [asPath]);
};

export const useFetchIsGuestCheckoutEnabled = (source: string) => {
  // that endpoint will check for the killswitch & the per-partner feature flag
  return useQuery(
    '/guest-checkout/is-enabled',
    async () =>
      fetchWithCommonHeaders('/guest-checkout/is-enabled', { method: 'POST' })
        .then(async (res) => res.json())
        .then(
          (data) => isGuestCheckoutEnabledHandlerResponseSchema.parse(data).isGuestCheckoutEnabled,
        )
        // this fetch should never fail so that we don't raise the error to our ErrorBoundary
        .catch((error) => {
          reportError(
            coerceError(
              new Error('fetching /guest-checkout/is-enabled failed', { cause: error }),
            ).addMetadata({ source }),
          );

          return false;
        }),
    // using suspense for our Suspense wrapper to handle loading state & so that data is always available
    { suspense: true },
  );
};

export const useShouldGuestCheckoutRedirectToBuyFlow = () => {
  const { data: isGuestCheckoutEnabled } = useFetchIsGuestCheckoutEnabled(
    'useShouldGuestCheckoutRedirectToBuyFlow',
  );

  return !isGuestCheckoutEnabled;
};

/**
 * This function determines if we skip the login page for internal partner mobile apps
 * and redirect them to the next page in the flow, where we show them the Terms of Service modal.
 */
export const useShouldSkipLoginPage = () => {
  const isWalletApp = useIsWalletApp();
  const { appId } = useAppParams();
  const isDappWalletApp = appId === DAPP_WALLET_APP_ID;

  const isMobile = useMemo(isMobileExperience, []);

  const { data: isGuestCheckoutEnabled } = useFetchIsGuestCheckoutEnabled(
    'useShouldSkipLoginPageForCBWallet',
  );

  return (isWalletApp || isDappWalletApp) && isMobile && isGuestCheckoutEnabled;
};

export function useIsMobileAndDappOrWallet() {
  const isWalletApp = useIsWalletApp();
  const { appId } = useAppParams();
  const isMobile = useMemo(isMobileExperience, []);
  const isDappWalletApp = appId === DAPP_WALLET_APP_ID;
  return (isWalletApp || isDappWalletApp) && isMobile;
}

/**
 * Generates an entity hash, used to identify users within guest sessions, using the same process
 * used on the BE, so that we use the same identifier through both stacks and make our DS's job a
 * bit easier, hopefully.
 *
 * @see https://sourcegraph.cbhq.net/github.cbhq.net/cbpay/onramp-service@fcca8d96f22fb41c594d8c99546eb1ce5a873e7d/-/blob/internal/models/guest_transaction.go?L131-134
 */
export async function generateEntityHash(phoneNumber: string, countryCode: string) {
  /** source: https://sourcegraph.cbhq.net/github.cbhq.net/cbpay/onramp-service@fcca8d96f22fb41c594d8c99546eb1ce5a873e7d/-/blob/internal/models/guest_transaction.go?L104 */
  const entityType = 'PHONE_NUMBER';

  const countryCallingCode = countriesWithCallingCode.find(
    (c) => c.code === countryCode,
  )?.callingCode;
  const onlyDigits = phoneNumber.replace(/\D+/g, '');

  /**
   * The format should be as specified in the guest transaction model: https://sourcegraph.cbhq.net/github.cbhq.net/cbpay/onramp-service@fcca8d96f22fb41c594d8c99546eb1ce5a873e7d/-/blob/internal/models/guest_transaction.go?L104
   *
   * `/\((?<countryCallingCode>\d+)\)(?<actualPhoneNumber>\d+)/` — e.g. (+55)71912345678
   */
  const formattedPhoneNumber = countryCallingCode
    ? `(+${countryCallingCode})${onlyDigits}`
    : onlyDigits;

  /**
   * Using `entityType + phoneNumber`, as we do on the BE: https://sourcegraph.cbhq.net/github.cbhq.net/cbpay/onramp-service@fcca8d96f22fb41c594d8c99546eb1ce5a873e7d/-/blob/internal/models/guest_transaction.go?L131-134
   * Using `SHA-256`, as we do on the BE: https://sourcegraph.cbhq.net/github.cbhq.net/cbpay/onramp-service@fcca8d96f22fb41c594d8c99546eb1ce5a873e7d/-/blob/internal/utils/hash.go?L8-11
   */
  const digestBuffer = await digestString(entityType + formattedPhoneNumber, 'SHA-256');
  // We only use the first 16 bytes to generate the hash, as done on the BE
  return digestBuffer && bytesToString(new Uint8Array(digestBuffer).slice(0, 16));
}

/**
 * The expiration input has the form of MM/YY.
 *
 * We create a date with today's month and year to compare against (leaving out the day will
 * automatically set it to the 1st).
 *
 * Splitting the value by `/`, we use those two values to create another date with a month and year
 * (starting on the 1st).
 *
 * We simply compare the two dates to ensure the date is at least equal or after today. JavaScript
 * will give us a date object back even if our input is bad. In that case, this will always return
 * `false` which is what we would expect.
 */
export function validateCardDetailsExpiration(value: string) {
  const dateRegex = /^(0[1-9]|1[0-2])\/(\d{2})$/;
  if (!dateRegex.test(value)) {
    return false;
  }
  const [month, year] = value.split('/');

  // Convert the year to a full year (e.g., '21' becomes '2021')
  const fullYear = parseInt(`20${year}`, 10);
  // a card's date is inclusive of so we need to check against the time after the date
  const expirationDate = new Date(fullYear, parseInt(month, 10), 1);
  const currentDate = new Date();
  // Check if the expiration date is in the future
  return expirationDate >= currentDate;
}

// https://stackoverflow.com/questions/11522529/regexp-for-checking-the-full-name
const namePattern = /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/i;

export const cardDetailsFormFieldValidators = {
  email: (val: string) =>
    // https://github.cbhq.net/frontend/coinbase-www/blob/2f0680a7c601fbbdaad553e5407a0706983d33e5/shared/src/validators/email.ts
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      val,
    ),
  nameOnCard: (val: string) => namePattern.test(val),
  expiration: validateCardDetailsExpiration,
  zipcode: (val: string) => val.trim().length === 5,
  addressLine1: (val: string) => !!val.trim(),
  city: (val: string) => !!val.trim(),
  state: (val: string) => !!val,
} as const;

export function getIsCardDetailsFormValid({
  formValues,
  isInFramesInternalTestingTreatment,
}: {
  formValues: ExtractAtomValue<typeof guestCheckoutCardDetailsSelector>;
  isInFramesInternalTestingTreatment: boolean;
}) {
  const { nameOnCard, expiration, zipcode, addressLine1, city, state } = formValues;

  return [
    cardDetailsFormFieldValidators.email(formValues.email),
    cardDetailsFormFieldValidators.nameOnCard(nameOnCard),
    cardDetailsFormFieldValidators.expiration(expiration),
    isInFramesInternalTestingTreatment ? true : cardDetailsFormFieldValidators.zipcode(zipcode),
    cardDetailsFormFieldValidators.addressLine1(addressLine1),
    cardDetailsFormFieldValidators.city(city),
    cardDetailsFormFieldValidators.state(state),
  ].every(Boolean);
}

export type GuestCheckoutTimerName = 'signupSession' | 'quote' | '3ds';
type GuestCheckoutTimer = {
  endTime: number | undefined;
  startedAt: number | undefined;
  length: number;
  timeoutId: number | undefined;
};

function minToMs(min: number) {
  return min * 60 * 1000;
}

const timerDefaults: Omit<GuestCheckoutTimer, 'length'> = {
  endTime: undefined,
  startedAt: undefined,
  timeoutId: undefined,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const _timers: Record<GuestCheckoutTimerName, GuestCheckoutTimer> = {
  /** This is for the one-time-use guest session required by 2FA register,
   * not the guest checkout session required to create & commit GC transactions. */
  signupSession: { ...timerDefaults, length: minToMs(60) },
  quote: { ...timerDefaults, length: minToMs(10) },
  '3ds': { ...timerDefaults, length: minToMs(15) },
};

export const guestCheckoutTimers = {
  startTimer(
    timerName: GuestCheckoutTimerName,
    options: {
      /** Timestamp in milliseconds. */
      endTime?: number;
      onTimeout?: () => void;
    } = {},
  ) {
    const { endTime, onTimeout } = options;
    const timer = _timers[timerName];
    clearTimeout(timer.timeoutId);
    timer.startedAt = Date.now();
    timer.endTime = endTime !== undefined ? endTime : timer.startedAt + timer.length;

    if (onTimeout) {
      const length = endTime !== undefined ? endTime - timer.startedAt : timer.length;
      timer.timeoutId = window.setTimeout(onTimeout, length);
    }
  },
  stopTimer(timerName: GuestCheckoutTimerName) {
    const timer = _timers[timerName];
    clearTimeout(timer.timeoutId);
    timer.endTime = undefined;
    timer.timeoutId = undefined;
    timer.startedAt = undefined;
  },
  getIsTimerExpired(timerName: GuestCheckoutTimerName) {
    const { startedAt, endTime } = _timers[timerName];
    if (startedAt === undefined) return false;
    if (endTime === undefined) return false;

    return Date.now() >= endTime;
  },
};

/**
 * Original enum found at https://github.cbhq.net/cbpay/onramp-service/blob/master/protos/coinbase/onramp_service/errors.proto
 */
export type GuestCheckoutErrorCode =
  // When guest's current transaction exceeds the remaining weekly limit
  | 'ERROR_CODE_GUEST_TRANSACTION_LIMIT'
  // When guest try to commit more than 15 transaction
  | 'ERROR_CODE_GUEST_TRANSACTION_COUNT'
  // When guest session expired after 1 hour and another phone verfication needed
  | 'ERROR_CODE_GUEST_SESSION_EXPIRED'
  // When guest phone region, ip region, billing address region are not consistent
  | 'ERROR_CODE_GUEST_REGION_MISMATCH'
  // When guest is not allowed to conduct the transaction because their region is under sanctioned list
  | 'ERROR_CODE_GUEST_REGION_FORBIDDEN'
  // When guest is not allowed to conduct the transaction due to sanction match
  | 'ERROR_CODE_GUEST_PERMISSION_DENIED'
  // When guest card is hard declined by bank or risk rules, they need to try another card
  | 'ERROR_CODE_GUEST_CARD_HARD_DECLINED'
  // When guest card is soft declined, and they need to contact their bank or try another card
  | 'ERROR_CODE_GUEST_CARD_SOFT_DECLINED'
  // When guest card is declined due to insufficient balance
  | 'ERROR_CODE_GUEST_CARD_INSUFFICIENT_BALANCE'
  // When guest card is declined due to risk rule configuration: e.g. exceed weekly limit, pre-paid card
  | 'ERROR_CODE_GUEST_CARD_RISK_DECLINED'
  // When guest card information is invalid
  | 'ERROR_CODE_GUEST_INVALID_CARD'
  // When guest card info does not have CVV present
  | 'ERROR_CODE_GUEST_CARD_CVV_NOT_PRESENT'
  // When guest card type is not supported
  | 'ERROR_CODE_GUEST_CARD_TYPE_NOT_SUPPORTED'
  // When guest recaptcha failed with high risk
  | 'ERROR_CODE_GUEST_RECAPTCHA_FAILED';
