import type { ExperimentTestName } from '@onramp/utils/experiments/experiments';
import { z } from 'zod';

import { jsonStringSchema, partialSchemaCatchingTypeErrors } from './utils.schema';

export const serverMetadataSchema = partialSchemaCatchingTypeErrors(
  z
    .object({
      /** A record from experiment name to group e.g. `{ "unified_login": "treatment" }` */
      exposedExperiments: z.record(z.string()) as z.ZodRecord<
        z.ZodType<ExperimentTestName>,
        z.ZodString
      >,
      /**
       * The original domain the user was referred from i.e. the partner's website, mainly user to attribute
       * transactions for channel partners that have many clients e.g. Meld, WalletConnect
       */
      originalReferringDomain: z.string(),
    })
    .partial(),
);

export type ServerMetadata = z.TypeOf<typeof serverMetadataSchema>;

export const serverMetadataCookieSchema = jsonStringSchema
  .pipe(serverMetadataSchema)
  .optional()
  .catch(undefined);
