import { useContext } from 'react';
import { ErrorHandlerProviderContext } from '@onramp/components/error/ErrorHandlerProvider';
import { isTestEnvironment } from '@onramp/utils/environment/sharedEnv';
import type { ReportAndViewErrorFunc } from '@onramp/utils/errors';
import { noop } from '@cbhq/cds-utils/noop';

export const useReportAndViewError = (): ReportAndViewErrorFunc => {
  const context = useContext(ErrorHandlerProviderContext);
  if (!context?.reportAndViewError) {
    if (!isTestEnvironment) {
      // eslint-disable-next-line no-console
      console.warn('No ErrorHandlerProvider provided');
    }

    return noop;
  }

  return context.reportAndViewError;
};
