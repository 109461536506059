import type { DestinationAddressAppParam } from '@onramp/shared/appParams.schema';
import { validate as validateUuid } from 'uuid';

import { isNotNullish } from './types';

export function checkDestinationWalletsUniformShape(
  destinationWallets: DestinationAddressAppParam[] | undefined,
): boolean {
  if (!Array.isArray(destinationWallets)) {
    return false;
  }

  const wallets = (destinationWallets ?? []).filter(isNotNullish);
  if (wallets.length === 0) {
    return true;
  }

  const haveAssets = walletHasAssets(wallets[0]);
  const assetsAreUuids = walletAssetsAreUuids(wallets[0]);
  const haveBlockchains = walletHasBlockchains(wallets[0]);
  const haveSupportedNetworks = walletHasSupportedNetworks(wallets[0]);

  for (const wallet of wallets) {
    if (haveAssets !== walletHasAssets(wallet)) {
      return false;
    }

    if (assetsAreUuids !== walletAssetsAreUuids(wallet)) {
      return false;
    }

    if (haveBlockchains !== walletHasBlockchains(wallet)) {
      return false;
    }

    if (haveSupportedNetworks !== walletHasSupportedNetworks(wallet)) {
      return false;
    }
  }

  return true;
}

const walletHasAssets = (wallet: DestinationAddressAppParam) => 'assets' in wallet;
const walletAssetsAreUuids = (wallet: DestinationAddressAppParam) =>
  validateUuid(wallet.assets?.[0] ?? '');
const walletHasBlockchains = (wallet: DestinationAddressAppParam) => 'blockchains' in wallet;
const walletHasSupportedNetworks = (wallet: DestinationAddressAppParam) =>
  'supportedNetworks' in wallet;
