import { doOncePerSession } from '@onramp/v2/client/utils/session/doOncePerSession';
import type { AnalyticsEventData } from '@cbhq/client-analytics';
import { ActionType, ComponentType, logEvent } from '@cbhq/client-analytics';

export const GUEST_FLOW = 'guest_flow';

export type NamedAnalyticsEventData = AnalyticsEventData & {
  name: string;
};

export const logEventOnce = (data: NamedAnalyticsEventData) => {
  const idemKey = `once_per_session_event.${data.name}`;
  const log = doOncePerSession(() => {
    logEvent(GUEST_FLOW, data);
  }, idemKey);

  log();
};

const GUEST_FLOW_EVENT = {
  action: ActionType.view,
  componentType: ComponentType.page,
};

const GUEST_FLOW_USER_CLICK_ACTION = {
  action: ActionType.click,
};

const GUEST_FLOW_BUTTON = {
  componentType: ComponentType.button,
};

export const GUEST_FLOW_CARD_INPUT_CONTINUE_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '45bdc097-2cc5-4e1e-b71e-befe1efee280',
  name: 'CARD_INPUT_CONTINUE_CLICKED',
};

export const GUEST_FLOW_BUY_NOW_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'efbe2240-1f68-4572-83e5-4420a1a8989a',
  name: 'BUY_NOW_CLICKED',
};

export const GUEST_FLOW_ORDER_SUBMITTED: NamedAnalyticsEventData = {
  action: ActionType.process,
  componentType: ComponentType.unknown,
  loggingId: '00e63af8-909f-4812-844e-20c5dcafa892',
  name: 'ORDER_SUBMITTED',
};

export const GUEST_FLOW_EDIT_ORDER_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '0636e062-cbf3-45bc-aa2f-6d74a6bea59a',
  name: 'EDIT_ORDER_CLICKED',
};

export const GUEST_FLOW_EDIT_BACK_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '2be15489-47c4-4ef3-acbc-1bf3fd8af106',
  name: 'EDIT_BACK_PRESSED',
};

export const GUEST_FLOW_EDIT_ASSET_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '7cbd417f-fabb-4e42-82fe-48d8f3c96382',
  name: 'EDIT_ASSET_CLICKED',
};

export const GUEST_FLOW_EDIT_NETWORK_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'b0df5b66-6da8-4a99-b4bd-1dbac39732e4',
  name: 'EDIT_NETWORK_CLICKED',
};

export const GUEST_FLOW_REVIEW_ORDER_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '9069abf7-7c25-4197-9bd8-d9a075693643',
  name: 'REVIEW_ORDER_CLICKED',
};

export const GUEST_FLOW_SWAP_AND_CONVERT_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '7f4c9c30-4b0d-4065-92de-424c640351fb',
  name: 'SWAP_AND_CONVERT_CLICKED',
};

export const GUEST_FLOW_EXIT_PHONE_VERIFICATION_VIEW_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '98cd77c1-7cfb-4faf-8337-5303b0ef3244',
  name: 'EXIT_PHONE_VERIFICATION_VIEW_CLICKED',
};

export const GUEST_FLOW_ASSET_SELECTED = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '3481c8dd-f627-43ab-8147-72064a98759e',
  name: 'ASSET_SELECTED',
};

export const GUEST_FLOW_NETWORK_SELECTED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'f9d1b5c8-7f4a-4d7e-8c1d-6c4f3b6b9b4d',
  name: 'NETWORK_SELECTED',
};

export const GUEST_FLOW_EXIT_CARD_VIEW_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: '6254d4ae-1cf9-4c40-96b0-282dd64505dc',
  name: 'EXIT_CARD_VIEW_CLICKED',
};

export const GUEST_FLOW_BACK_TO_INPUT_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'a9d8ce12-83ee-461a-9150-4b754c67bc92',
  name: 'GUEST_FLOW_BACK_TO_INPUT_CLICKED',
};

export const GUEST_FLOW_AUTH_VIEW_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'a493e867-6832-4c0c-8523-9dab75ed8ffb',
  name: 'AUTH_VIEW_CLICKED',
};

export const GUEST_FLOW_AUTH_VIEW_REDIRECTED: NamedAnalyticsEventData = {
  action: ActionType.unknown,
  componentType: ComponentType.unknown,
  loggingId: '47fabf3f-acd8-41ef-b20b-c34c9ad95039',
  name: 'AUTH_VIEW_REDIRECTED',
};

export const GUEST_FLOW_VIEW_DETAILS_CLICKED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_USER_CLICK_ACTION,
  ...GUEST_FLOW_BUTTON,
  loggingId: 'daa1b81b-96f0-4f76-8e5f-b5d39064bce7',
  name: 'VIEW_DETAILS_CLICKED',
};

export const GUEST_FLOW_REDIRECT_TO_EDIT_BELOW_MIN_SEND: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '2be15489-47c4-4ef3-acbc-1bf3fd8af106',
  name: 'REDIRECT_TO_EDIT_BELOW_MIN_SEND',
};

export const CHANGE_NETWORK_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'ae9bcca3-8424-4f2e-89f2-2d0ea59e902a',
  name: 'CHANGE_NETWORK_VIEWED',
};

export const EDIT_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '3842b5e0-b1fb-455d-83fb-d4d871e8d03a',
  name: 'EDIT_VIEWED',
};

export const ORDER_DETAILS_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'a4a154e8-32a0-49a8-802a-2c8bbc5a960e',
  name: 'ORDER_DETAILS_VIEWED',
};

export const ORDER_PREVIEW_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '62f9ab67-7152-459c-9bf8-e469fc47549e',
  name: 'ORDER_PREVIEW_VIEWED',
};

export const ORDER_SUBMITTED_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'f06e9b62-ead8-44f5-b9b7-681393e5f06e',
  name: 'ORDER_SUBMITTED_VIEWED',
};

export const SUCCESS_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '3a8df573-e332-4f3f-8cda-1e9d50303826',
  name: 'SUCCESS_VIEWED',
};

export const PHONE_VERIFICATION_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'a4a4da70-25a6-4017-9ebb-28054da457a8',
  name: 'PHONE_VERIFICATION_VIEWED',
};

export const SELECT_ASSET_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '9f2fd471-469a-4dc0-9598-5db5099183f1',
  name: 'SELECT_ASSET_VIEWED',
};

export const TRANSACTION_LIMIT_VIEWED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '0beafd62-45d0-45b2-ad14-efffc51433e0',
  name: 'TRANSACTION_LIMIT_VIEWED',
};

export const TRANSACTION_LIMIT_BACK_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'bb0fb2ae-aff4-4fb9-8ecc-659adf15f0c5',
  name: 'TRANSACTION_LIMIT_BACK_PRESSED',
};

export const TRANSACTION_LIMIT_CREATE_ACCOUNT_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'fd0fb833-a2f2-4abb-9748-4f321493a586',
  name: 'TRANSACTION_LIMIT_CREATE_ACCOUNT_PRESSED',
};

export const TRANSACTION_LIMIT_SKIP_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '7819d25c-1e17-489f-9498-61bc80e64f27',
  name: 'TRANSACTION_LIMIT_SKIP_PRESSED',
};

export const PHONE_INPUT_CHANGED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '9ae9450b-5afa-4696-b9d3-0d250e0c32ea',
  name: 'PHONE_INPUT_CHANGED',
};

export const CARD_EXPIRATION_CHANGED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '0a8f7016-0f8a-4c17-b89d-71ee5f3b7d59',
  name: 'CARD_EXPIRATION_CHANGED',
};

export const ADDRESS_AUTOCOMPLETE_ACCEPTED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '0a8f7016-0f8a-4c17-b89d-71ee5f3b7d59',
  name: 'ADDRESS_AUTOCOMPLETE_ACCEPTED',
};

export const SWITCH_TO_AUTHED_FLOW: (automatic: boolean) => NamedAnalyticsEventData = (
  automatic,
) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: '75aee2eb-fdbd-4f7a-bc1b-a7b251f61d25',
  name: 'SWITCH_TO_AUTHED_FLOW',
  automatic,
});

type RedirectFromCardDetailsGuestUnavailableArgs = {
  killed: boolean;
  eligible: boolean;
};

export const REDIRECT_FROM_CARD_DETAILS_GUEST_UNAVAILABLE: (
  args: RedirectFromCardDetailsGuestUnavailableArgs,
) => NamedAnalyticsEventData = ({ killed, eligible }) => ({
  ...GUEST_FLOW_EVENT,
  loggingId: '7832c112-8ef1-4688-819c-d0749918eac2',
  name: 'REDIRECT_FROM_CARD_DETAILS_GUEST_UNAVAILABLE',
  eligible,
  killed,
});

export const PHONE_VERIFICATION_BACK_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: 'f95fdaf1-7f1b-4a27-ad5f-c6c9da25b68c',
  name: 'PHONE_VERIFICATION_BACK_PRESSED',
};

export const PHONE_VERIFICATION_NO_ASSETS_EXIT_PRESSED: NamedAnalyticsEventData = {
  ...GUEST_FLOW_EVENT,
  loggingId: '518732b9-1963-406e-a938-4324030493be',
  name: 'PHONE_VERIFICATION_NO_ASSETS_EXIT_PRESSED',
};
