import { useCallback } from 'react';
import type { useBraintreeClientTokenMutation } from '@onramp/data/__generated__/useBraintreeClientTokenMutation.graphql';
import { coerceError, InternalError, reportError } from '@onramp/utils/errors';
import { graphql, useMutation } from '@cbhq/data-layer';

export type BrainTreeNetwork = 'paypal' | 'apple_pay' | 'google_pay';

type BraintreeClientTokenSuccess = Extract<
  useBraintreeClientTokenMutation['response']['generatePaymentMethodClientToken'],
  {
    __typename: 'GeneratePaymentMethodClientTokenSuccess';
  }
>;

export function useBraintreeClientToken(brainTreeNetwork: BrainTreeNetwork) {
  const [generateClientToken] = useMutation<useBraintreeClientTokenMutation>(graphql`
    mutation useBraintreeClientTokenMutation($input: GeneratePaymentMethodClientTokenInput!) {
      generatePaymentMethodClientToken(input: $input) {
        __typename
        ... on GeneratePaymentMethodClientTokenSuccess {
          token
          url
        }
      }
    }
  `);

  return useCallback(
    async () =>
      new Promise<BraintreeClientTokenSuccess>((res, rej) => {
        generateClientToken({
          variables: { input: { vendor: 'braintree', brainTreeNetwork } },
          onCompleted: ({ response }) => {
            if (
              response.generatePaymentMethodClientToken?.__typename ===
              'GeneratePaymentMethodClientTokenSuccess'
            ) {
              res(response.generatePaymentMethodClientToken);
            } else {
              throw new InternalError('Failed to generate client token for Braintree');
            }
          },
          onError: (err) => {
            reportError(
              coerceError(err).addMetadata({
                brainTreeNetwork,
                apiError: String(err.alreadyReportedError),
                context: 'useBraintreeClientToken',
              }),
            );
            rej(err);
          },
        });
      }),

    [generateClientToken, brainTreeNetwork],
  );
}
