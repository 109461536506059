import { z } from 'zod';

import { isProduction } from './environment/sharedEnv';

/** As per JWT spec */
const baseJwtPayloadSchema = z.object({
  iss: z.string().optional(),
  /** The user's uuid hased with MD5 */
  sub: z.string().optional(),
  aud: z.union([z.string(), z.array(z.string())]).optional(),
  exp: z.number().optional(),
  nbf: z.number().optional(),
  iat: z.number().optional(),
  jti: z.string().optional(),
});

/** As per {@link https://docs.google.com/document/d/1yFE3zpUCmfvOYYRx992qqF7TaaqAMi4D4JeAHqAYgTQ/edit#heading=h.jo717sex7yec GSSC TDD} */
export const gsscJwtPayloadSchema = baseJwtPayloadSchema.extend({
  auth_time: z.number().optional(),
  /** LCC session id */
  sid: z.string().optional(),
  cb_2fa_proof: z.string().optional(),
});

export const clientGsscDataSchema = gsscJwtPayloadSchema.pick({
  sub: true,
  sid: true,
  auth_time: true,
});

export type GsscData = z.infer<typeof gsscJwtPayloadSchema>;
export type ClientGsscData = z.infer<typeof clientGsscDataSchema>;

export const GSSC_COOKIE_KEY = isProduction ? 'cb-gssc' : 'cb-gssc_development';
export const GSSC_EXPERIMENT_NAME = 'mar_2023_cbpay_require_gssc_auth';

export function getClientGsscData(gsscData: GsscData): ClientGsscData {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { sub, sid, auth_time } = gsscData;
  return { sub, sid, auth_time };
}
