import { QueryClient } from 'react-query';
import { QUERY_KEYS } from '@onramp/config/queryKeys';
import { fetchExperiments } from '@onramp/utils/experiments/fetchExperiments';
import { noop } from '@cbhq/cds-utils';

import { fetchKillSwitches } from './killswitches/fetchKillSwitches';

export const queryClient = createQueryClient();

// Allows test helpers to avoid using a global `queryClient` which will make life easier when testing.
export function createQueryClient() {
  return new QueryClient({
    defaultOptions: {
      queries: {
        // By default, assume data does not go stale during a user session.
        staleTime: Infinity,
        cacheTime: Infinity,
      },
    },
  });
}

export const prefetchRestQueries = () => {
  Promise.all([
    queryClient.prefetchQuery(QUERY_KEYS.KILL_SWITCH, fetchKillSwitches).catch(noop),
    queryClient.prefetchQuery(QUERY_KEYS.EXPERIMENTS, fetchExperiments).catch(noop),
  ]).catch(noop);
};
