import { useMemo, useState } from 'react';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import {
  smartlingProjectId,
  smartlingServiceIdentifier,
} from '@onramp/utils/environment/sharedEnv';
import type { MessagesMap } from '@cbhq/intl';
import { convertToCBSupported, fetchMessagesFromCDN, getClientLocale } from '@cbhq/intl';
import { polyfillIntl } from '@cbhq/intl/polyfills';

export const useI18n = () => {
  const locale = convertToCBSupported(getClientLocale());
  const [messages, setMessages] = useState<MessagesMap>();

  useEffectOnMount(() => {
    void Promise.all([polyfillIntl(locale), getMessages(locale)])
      .then((values) => {
        setMessages(values[1]);
      })
      .catch(() => setMessages({}));
  });

  return useMemo(() => ({ locale, messages }), [locale, messages]);
};

const getMessages = async (locale: string) => {
  return smartlingProjectId
    ? // Fetch domain depends on NODE_ENV - set to "production" to fetch prod messages
      // https://github.cbhq.net/intl/i18n-frontend/blob/eb44d3a8eeb748a8916e228e9fcce4e8aa067439/packages/intl/src/translations.ts#L90
      fetchMessagesFromCDN(locale, smartlingProjectId, smartlingServiceIdentifier)
    : Promise.resolve({});
};
