import { defineMessages, IntlProvider, useIntl } from 'react-intl';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { useI18n } from '@onramp/hooks/useI18n';
import { getErrorVitals } from '@onramp/v2/client/utils/initErrorVitals';
import { useRouter } from 'next/router';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { Box } from '@cbhq/cds-web/layout';
import { VStack } from '@cbhq/cds-web/layout/VStack';
import { ThemeProvider } from '@cbhq/cds-web/system/ThemeProvider';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

const messages = defineMessages({
  title: {
    id: `MissingParameters.title`,
    defaultMessage: 'Sorry, we are unable to process your request',
    description: 'Header title for MissingParameters screen',
  },
  description: {
    id: `MissingParameters.description`,
    defaultMessage:
      'Seems like we are missing some parameters. Please try again. If the issue persists, please contact support.',
    description: 'Description for MissingParameters screen',
  },
});

// We do have a generic error screen for missing parameters which is used when we are missing some parameters in the URL.
// In order to quick fail, we only use nessary providers and components to render the screen.
// So this component has only the necessary providers to render the message.
export const MissingParameters = () => {
  const DEFAULT_LOCALE = useRouter().locale;

  const { locale, messages: translatedMessages } = useI18n();
  useEffectOnMount(() => {
    getErrorVitals().logError(new Error('Failed to initialize client due to missing parameters'), {
      context: 'missing_params',
      isHandled: true,
      severity: 'critical',
    });
  });

  if (!translatedMessages) {
    return null;
  }

  return (
    <ThemeProvider>
      <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE} messages={translatedMessages}>
        <MissingParametersContent />
      </IntlProvider>
    </ThemeProvider>
  );
};

export const MissingParametersContent = () => {
  const { formatMessage } = useIntl();
  return (
    <VStack testID="generic-parameter-error-screen" as="main" spacing={3} height="100%">
      <VStack flexGrow={1} alignItems="center">
        <Box spacingVertical={3} justifyContent="center">
          <HeroSquare name="bigWarning" dimension="200x200" />
        </Box>
        <TextTitle3 as="h3" color="foreground" spacingTop={2} spacingBottom={1} align="center">
          {formatMessage(messages.title)}
        </TextTitle3>
        <TextBody as="p" color="foregroundMuted" spacingBottom={3} align="center">
          {formatMessage(messages.description)}
        </TextBody>
      </VStack>
    </VStack>
  );
};
