/**
 * @generated SignedSource<<a59826ae1b9ba0c9fca17cac6bf74054>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readPrimaryPaymentMethodSoFFragment$data = {
  readonly __typename: "CBPaySourceOfFundsPaymentMethod";
  readonly isEnabled: boolean | null;
  readonly paymentMethod: {
    readonly pickerData: {
      readonly __typename: string;
    } | null;
    readonly tradeCapabilities: {
      readonly allowBuy: boolean;
    } | null;
    readonly verified: boolean;
    readonly " $fragmentSpreads": FragmentRefs<"getIsCountryAllowedFiatFragment" | "readPrimaryPaymentMethodFragment">;
  } | null;
  readonly " $fragmentType": "readPrimaryPaymentMethodSoFFragment";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "readPrimaryPaymentMethodSoFFragment";
};
export type readPrimaryPaymentMethodSoFFragment$key = {
  readonly " $data"?: readPrimaryPaymentMethodSoFFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readPrimaryPaymentMethodSoFFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readPrimaryPaymentMethodSoFFragment"
};

(node as any).hash = "5330f68a06c78e44c7897159e7bac3c3";

export default node;
