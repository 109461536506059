import type { reduceCBPayAssetsBySearchTermFragment$key } from '@onramp/data/__generated__/reduceCBPayAssetsBySearchTermFragment.graphql';
import { calculateSearchScore } from '@onramp/hooks/useAccountSearch/calculateSearchScore';
import { graphql, readInlineData } from '@cbhq/data-layer';

const reduceCBPayAssetsBySearchTermFragment = graphql`
  fragment reduceCBPayAssetsBySearchTermFragment on CBPayAsset @inline {
    symbol
    name
  }
`;

/** Filter the assets by a search term. Using reduce as it's more performant on large lists. */
export const reduceCBPayAssetsBySearchTerm = <T extends reduceCBPayAssetsBySearchTermFragment$key>(
  assets?: T[],
  searchQuery = '',
): T[] => {
  return (assets || []).reduce((previousValue, currentValue) => {
    const asset = readInlineData(reduceCBPayAssetsBySearchTermFragment, currentValue);
    const searchScore = calculateSearchScore(searchQuery, asset?.symbol || '', asset?.name || '');

    if (searchScore.relevance !== null) previousValue.push(currentValue);
    return previousValue;
  }, [] as T[]);
};
