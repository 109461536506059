import { TopLevelGqlError } from './errors';
import { HandledError } from './HandledError';

export function canRetryError(error: Error | undefined) {
  if (error === undefined) {
    return false;
  }

  return (
    (error instanceof HandledError && error.code === 'assert_data_presence') ||
    error instanceof TopLevelGqlError
  );
}
