import React, { useEffect, useRef } from 'react';
import { useWidgetParams } from '@onramp/components/AppManagerProvider';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { useLogOnrampEvent } from '@onramp/utils/eventing/useLogOnrampEvent';
import { useLogWidgetMetric, useLogWidgetMetricOnce } from '@onramp/utils/metrics';
import { useSetAtom } from 'jotai';
import { noop } from '@cbhq/cds-utils';

import { buyFlowTypeAtom } from '../recoil/buyFlowType';
import { useNativeCurrency } from '../recoil/selectors/nativeCurrencySelector';
import { jotaiStore } from '../recoil/utils';

import { BuyWidgetState, defaultValues } from './common';
import { useBuyWidgetState, useBuyWidgetStateValues } from './hooks';

export const BuyWidgetStateProvider: React.FC = ({ children }) => {
  return (
    <BuyWidgetState.Provider onSubmit={noop}>
      <BuyWidgetStatePresetValues>{children}</BuyWidgetStatePresetValues>
    </BuyWidgetState.Provider>
  );
};

export const BuyWidgetStatePresetValues: React.FC = ({ children }) => {
  const hasPresetFiatAmountRun = useRef(false);
  const nativeCurrency = useNativeCurrency();
  const { presetCryptoAmount, presetFiatAmount, fiatCurrency } = useWidgetParams('buy');
  const { inputAmount, inputAmountType, inputSource, sourceOfFunds, selectedAsset } =
    useBuyWidgetState();
  const buyWidgetStateValues = useBuyWidgetStateValues();
  const setBuyFlowType = useSetAtom(buyFlowTypeAtom);
  const logWidgetMetric = useLogWidgetMetric();
  const logOnrampEvent = useLogOnrampEvent();

  const logWidgetMetricOnce = useLogWidgetMetricOnce();
  useEffect(
    function logMetricWhenAssetIsFirstSelected() {
      if (selectedAsset.value !== defaultValues.selectedAsset) {
        const currentBuyFlowType = jotaiStore.get(buyFlowTypeAtom);
        setBuyFlowType(currentBuyFlowType ?? 'regular');

        // Logging this here because the asset can be selected in multiple places (auto selection, regular selection, and one click buy)
        logWidgetMetricOnce({
          metricName: 'critical_step',
          value: 1,
          tags: { step: 'select-asset' },
        });
      }
    },
    [logWidgetMetricOnce, selectedAsset.value, setBuyFlowType],
  );

  // Preset crypto input amount (default if both presets are passed)
  useEffectOnMount(() => {
    if (presetCryptoAmount) {
      inputAmount.onChange(presetCryptoAmount.toString());
      inputAmountType.onChange('crypto');
      inputSource.onChange('presetCrypto');
    }
  });

  // Preset fiat input amount
  useEffect(() => {
    // Preset crypto amount takes precedence
    if (!hasPresetFiatAmountRun.current && presetFiatAmount && !presetCryptoAmount) {
      // When the `fiatCurrency` param is present, it must match the account's native currency
      const paramCurrencyMatchesAccount = fiatCurrency === nativeCurrency;
      // When the `fiatCurrency` param is missing, preset fiat amounts are restricted to an allowlist so there's not random fiat values for some currencies i.e. Yen
      const paramCurrencyIsAllowlisted = ['USD', 'CAD', 'GBP', 'EUR'].includes(
        nativeCurrency ?? '',
      );

      const shouldPresetFiatAmountApply = fiatCurrency
        ? paramCurrencyMatchesAccount
        : paramCurrencyIsAllowlisted;

      if (!shouldPresetFiatAmountApply) {
        const metadata = { accountCurrency: nativeCurrency, paramCurrency: fiatCurrency };

        logWidgetMetric({
          metricName: 'preset_fiat_amount_param_ignored',
          tags: metadata,
          value: 1,
        });

        logOnrampEvent('preset_fiat_amount_param_ignored', metadata);

        return;
      }

      hasPresetFiatAmountRun.current = true;
      inputAmount.onChange(presetFiatAmount.toString());
      inputAmountType.onChange('fiat');
      inputSource.onChange('presetFiat');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceOfFunds]);

  // Set clientSessionIdStore state
  useEffect(() => {
    clientSessionIdStore.setBuyWidgetState(buyWidgetStateValues);
  }, [buyWidgetStateValues]);

  return <>{children}</>;
};
