import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyArray = any[];

export const getUseCallFnOnMount = <Fn extends (...args: AnyArray) => void>(fn: Fn): Fn => {
  const hook = function useCallFnOnMount(...args: Parameters<Fn>) {
    useEffectOnMount(() => {
      fn(...args);
    });
  };

  return hook as Fn;
};

export const getUseCallHookFnOnMount = <Fn extends (...args: AnyArray) => void>(
  useHook: () => Fn,
): Fn => {
  return function useCallHookFnOnMount(...args: Parameters<Fn>) {
    const fn = useHook();
    useEffectOnMount(() => {
      fn(...args);
    });
  } as Fn;
};
