import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@onramp/config/queryKeys';
// eslint-disable-next-line import/no-cycle
import { fetchKillSwitches } from '@onramp/utils/killswitches/fetchKillSwitches';
import type { KillSwitchesConfigType } from '@onramp/utils/killswitches/killSwitchConfiguration';
import { DEFAULT_KILL_SWITCHES } from '@onramp/utils/killswitches/killSwitchConfiguration';

import { isNotNullish } from '../types';

export const useKillSwitches = (): KillSwitchesConfigType => {
  const { data } = useQuery(QUERY_KEYS.KILL_SWITCH, fetchKillSwitches);
  return isNotNullish(data) ? data : DEFAULT_KILL_SWITCHES;
};
