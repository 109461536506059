/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-param-reassign */
import type { Event } from '@bugsnag/js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import {
  bugsnagEnabled,
  isServer,
  isTestEnvironment,
  releaseStage,
  targetEnvironmentMap,
} from './environment/sharedEnv';
import { BaseError } from './errors/errors';
import { Experiments } from './experiments/experiments';
import { getExperimentsFromCookies } from './experiments/utils';
import { clientSessionIdStore } from './clientSessionIdStore';
import { getServerMetadata } from './getServerMetadata';
import { isMobileExperience } from './postMessage';
import { getRouteKeyFromPath } from './routes';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
const appVersion = require('../appVersion')();

if (bugsnagEnabled && !isTestEnvironment && !isServer()) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
    plugins: [new BugsnagPluginReact()],
    endpoints: {
      notify: 'https://exceptions.coinbase.com',
      sessions: 'https://sessions.coinbase.com',
    },
    appVersion,
    releaseStage,
    enabledReleaseStages: [
      targetEnvironmentMap.production,
      targetEnvironmentMap.staging,
      targetEnvironmentMap.development,
    ],
  });
}

/**
 * Attach user info to Bugsnag for better error investigations
 */
export const setBugsnagUserDetails = (uuid: string) => {
  if (bugsnagEnabled) {
    Bugsnag.setUser(uuid);
  }
};

/**
 * Severity Types
 */
type SeverityTypes = 'info' | 'warning' | 'error';

type LogErrorOptsParam = {
  context?: string;
  severity?: SeverityTypes;
  metadata?: Record<string, string | unknown>;
};

export type ErrorContextType = 'internal' | 'network' | 'analytics_sdk';

type BugsnagEvent = Event & {
  context?: ErrorContextType | string;
};

/**
 * Generic error logging
 */
export const reportBugsnag = (error: BaseError | Error, opts?: LogErrorOptsParam) => {
  if (bugsnagEnabled) {
    Bugsnag.notify(error, (evt: BugsnagEvent) => {
      if (opts?.context) {
        evt.context = opts.context;
      } else if (error instanceof BaseError && error.bugsnagData.context) {
        evt.context = error.bugsnagData.context;
      }

      if (error instanceof BaseError) {
        evt.groupingHash = error.bugsnagData.groupingHash ?? error.debugMessage;
      }

      evt.severity = opts?.severity ?? 'error';

      evt.addMetadata('session', getSessionMetadata());

      const pageKey = getRouteKeyFromPath(window.location.href);

      const metadata = {
        ...(pageKey ? { pageKey } : {}),
        ...(opts?.metadata ?? {}),
      };

      if (metadata) {
        evt.addMetadata('error_info', metadata);
      }
    });
  }
};

function getSessionMetadata() {
  return {
    session_id: clientSessionIdStore.getClientSessionId(),
    app_id: clientSessionIdStore.getAppId(),
    device_id: clientSessionIdStore.getDeviceId(),
    wallet_user_id: clientSessionIdStore.getWalletUserId(),
    app_client_name: clientSessionIdStore.getClientAppDetails()?.displayName,
    oauth_client_id: clientSessionIdStore.getClientAppDetails()?.oauthId,
    platform_attribution: clientSessionIdStore.getPlatformAttribution(),
    sdk_version: clientSessionIdStore.getSdkVersion(),
    platform_version: clientSessionIdStore.getPlatformVersion(),
    react_native_version: clientSessionIdStore.getReactNativeVersion(),
    app_params: clientSessionIdStore.getRedactedAppParamsMetadata(),
    enabled_experiments_from_cookie: getExperimentsFromCookies()
      ?.groups.filter((exp) => exp.test in Experiments && exp.group !== 'control')
      .map((exp) => exp.test),
    exposed_experiments: clientSessionIdStore.getExposedExperiments(),
    buy_flow_type: clientSessionIdStore.getBuyFlowType(),
    auth_provider: clientSessionIdStore.getAuthProvider(),
    is_in_react_native_web_view: isMobileExperience(),
    authentication_method: clientSessionIdStore.getAuthenticationMethod(),
    server_metadata: getServerMetadata(),
    guest_checkout_transaction_uuid: clientSessionIdStore.getGuestCheckoutTransactionUuid(),
    guest_checkout_entity_hash: clientSessionIdStore.getGuestCheckoutEntityHash(),
  };
}

/**
 * Breadcrumbs
 */
export const leaveBreadcrumb: typeof Bugsnag.leaveBreadcrumb = (...args) => {
  if (bugsnagEnabled) {
    Bugsnag.leaveBreadcrumb(...args);
  }
};

export { Bugsnag };
