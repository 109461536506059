/**
 * @generated SignedSource<<f8fe65de5a32166d353a018adabe403f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readDepositInformationFragment$data = {
  readonly depositInformation: {
    readonly availableDepositMethods: ReadonlyArray<{
      readonly depositInformation: ReadonlyArray<{
        readonly key: string | null;
        readonly title: string | null;
        readonly value: string | null;
      } | null> | null;
      readonly isActive: boolean | null;
      readonly type: string | null;
    } | null> | null;
    readonly depositCurrency: string | null;
    readonly isDepositAllowed: boolean | null;
  } | null;
  readonly " $fragmentType": "readDepositInformationFragment";
};
export type readDepositInformationFragment$key = {
  readonly " $data"?: readDepositInformationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readDepositInformationFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readDepositInformationFragment"
};

(node as any).hash = "533ea5cd6bdadc592398c77f7adcaf3d";

export default node;
