import { useCallback, useEffect, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { Loader } from '@onramp/components/Loader';
import { PageWrapper } from '@onramp/components/PageWrapper';
import { useSplashScreenContext } from '@onramp/components/SplashScreen/context';
import { CB_WALLET_APP_ID } from '@onramp/constants/cbWallets';
import type {
  PolicyRestrictionsGuardFragment$data,
  PolicyRestrictionsGuardFragment$key,
} from '@onramp/data/__generated__/PolicyRestrictionsGuardFragment.graphql';
import type { PolicyRestrictionsGuardQuery } from '@onramp/data/__generated__/PolicyRestrictionsGuardQuery.graphql';
import { useIsWalletApp } from '@onramp/hooks/useIsWalletApp';
import { useReportAndViewError } from '@onramp/hooks/useReportAndViewError';
import useInterval from '@onramp/hooks/utils/useInterval';
import { useOpenUrl } from '@onramp/hooks/utils/useOpenUrl';
import type { WidgetKey } from '@onramp/shared/appParams.schema';
import { getMobileAppBundleId, getMobileAppVersion, isCBWalletApp } from '@onramp/utils/appVersion';
import { setBugsnagUserDetails } from '@onramp/utils/bugsnag';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import copyToClipboard from '@onramp/utils/copyToClipboard';
import { isTestEnvironment } from '@onramp/utils/environment/sharedEnv';
import { HandledError, reportError } from '@onramp/utils/errors';
import { logEvent } from '@onramp/utils/eventing/logEvent';
import { getIsCBWalletAppWithPolicyRestrictionSupport } from '@onramp/utils/getIsCBWalletAppWithPolicyRestrictionSupport';
import { useIsCountryAllowed } from '@onramp/utils/getIsCountryAllowed';
import { identifyUser } from '@onramp/utils/init/analytics';
import { broadcastEvent, isMobileExperience } from '@onramp/utils/postMessage';
import { assertPresence } from '@onramp/utils/relayUtils';
import { isNotNullish } from '@onramp/utils/types';
import semver from 'semver';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { IconButton } from '@cbhq/cds-web/buttons/IconButton';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { useToast } from '@cbhq/cds-web/overlays/useToast';
import { Link, TextBody, TextLabel1, TextTitle3 } from '@cbhq/cds-web/typography';
import { ActionType, ComponentType } from '@cbhq/client-analytics';
import { graphql, useLazyLoadQuery, useRefetchableFragment } from '@cbhq/data-layer';

import { useAppParams } from '../AppManagerProvider';

import { policyRestrictionsGuardMessages as messages } from './messages';
import { WebView2FaRestrictionOverlay } from './WebView2faRestrictionOverlay';

type PolicyRestrictionsConfig = {
  // Buy & Sends restrictions are from Retail that check the user's ability to buy/send.
  sends?: boolean;
  buys?: boolean;
  // Pay is a new policy restriction specifically for the checkout widget.
  pay?: boolean;
};

export const policyRestrictionsConfigForWidget: Record<WidgetKey, PolicyRestrictionsConfig> = {
  buy: { sends: true, buys: true },
};

type PolicyRestrictionsGuardProps = {
  children: ReactNode;
};

const query = graphql`
  query PolicyRestrictionsGuardQuery(
    $sends: Boolean!
    $buys: Boolean!
    $pay: Boolean!
    $inWebView: Boolean!
  ) {
    viewer {
      ...PolicyRestrictionsGuardFragment
        @arguments(sends: $sends, buys: $buys, pay: $pay, inWebView: $inWebView)
    }
  }
`;

const policyRestrictionsGuardFragment = graphql`
  fragment PolicyRestrictionsGuardFragment on Viewer
  @refetchable(queryName: "PolicyRestrictionsGuardRefetchableFragment")
  @argumentDefinitions(
    sends: { type: "Boolean!" }
    buys: { type: "Boolean!" }
    pay: { type: "Boolean!" }
    inWebView: { type: "Boolean!" }
  ) {
    userProperties {
      uuid
      country {
        code
        name
      }
    }

    isWebview2faEligible @include(if: $inWebView)

    sendRestrictions: policyRestrictions(action: sends) @include(if: $sends) {
      restrictionName
      required
      link {
        url
        text
      }
      description
      message
    }

    buyRestrictions: policyRestrictions(action: buys) @include(if: $buys) {
      restrictionName
      required
      link {
        url
        text
      }
      description
      message
    }

    payRestrictions: policyRestrictions(action: pay) @include(if: $pay) {
      restrictionName
      required
      link {
        url
        text
      }
      description
      message
    }
  }
`;

const CountryNotAllowedMessage: React.FC<{ country: string }> = ({ country }) => {
  const { formatMessage } = useIntl();
  return (
    <TextBody as="p" align="center" color="foregroundMuted">
      {formatMessage(messages.countryNotAllowedMessage, {
        country,
        // eslint-disable-next-line formatjs/no-literal-string-in-jsx
        link: <Link href="https://www.coinbase.com">Coinbase</Link>,
      })}
    </TextBody>
  );
};

function useShowUpdateWalletAppErrorMessageIfNeeded() {
  const isFaultyVersion = useMemo(() => {
    if (isTestEnvironment || !isMobileExperience() || !isCBWalletApp()) return false;

    const versionString = getMobileAppVersion();

    if (!versionString) return true;

    return semver.lt(versionString, '28.16.0');
  }, []);

  const isWalletAppId = useIsWalletApp();

  const shouldShowUpdateWalletAppErrorMessage = useMemo(
    () => isFaultyVersion && isWalletAppId,
    [isFaultyVersion, isWalletAppId],
  );
  const reportAndViewError = useReportAndViewError();

  useEffect(() => {
    const isReturn3DSRoute =
      window.location.pathname.includes('/return-3ds') ||
      window.location.search.includes('nonce3ds=');

    if (shouldShowUpdateWalletAppErrorMessage && !isReturn3DSRoute) {
      reportAndViewError(
        new HandledError({
          message: 'Please update your Coinbase Wallet app to buy crypto',
          debugMessage: 'Blocking CBWallet app user from version <= 28.16.0',
        }).addMetadata({ bundleId: getMobileAppBundleId(), appVersion: getMobileAppVersion() }),
      );
    }
  }, [shouldShowUpdateWalletAppErrorMessage, reportAndViewError]);
}

export const PolicyRestrictionsGuard = ({ children }: PolicyRestrictionsGuardProps) => {
  const { formatMessage } = useIntl();
  const [hasClearedRestrictionsCheck, setHasClearedRestrictionsCheck] = useState(false);
  const [showClose, setShowClose] = useState(false);

  const handleClose = useCallback(() => broadcastEvent({ eventName: 'exit' }), []);

  const markAsCleared = useCallback(() => {
    setHasClearedRestrictionsCheck(true);
  }, []);

  useShowUpdateWalletAppErrorMessageIfNeeded();

  return hasClearedRestrictionsCheck ? (
    <>{children}</>
  ) : (
    <PageWrapper
      headerText={formatMessage(messages.title)}
      loadingElement={<Loader subText={formatMessage(messages.loading)} />}
      onBack={showClose ? handleClose : undefined}
    >
      <PolicyRestrictionsGuardCheck markAsCleared={markAsCleared} setShowClose={setShowClose} />
    </PageWrapper>
  );
};

type PolicyRestrictionsGuardCheckProps = {
  markAsCleared: () => void;
  setShowClose: (show: boolean) => void;
};

export const usePolicyRestrictionsGuardArgs = () => {
  const { formatMessage } = useIntl();
  const { activeWidget } = useAppParams();
  const widgetKey = assertPresence(activeWidget, {
    debugMessage: 'Widget key is not defined',
    message: formatMessage(messages.failedToLoadAccountInfo),
  });
  return policyRestrictionsConfigForWidget[widgetKey];
};

const PolicyRestrictionsGuardCheck = ({
  markAsCleared,
  setShowClose,
}: PolicyRestrictionsGuardCheckProps) => {
  const { formatMessage } = useIntl();
  const { appId } = useAppParams();
  const {
    sends: isSendsRequired,
    buys: isBuysRequired,
    pay: isPayRequired,
  } = usePolicyRestrictionsGuardArgs();

  const splashScreenContext = useSplashScreenContext();

  const { viewer } = useLazyLoadQuery<PolicyRestrictionsGuardQuery>(query, {
    sends: isSendsRequired || false,
    buys: isBuysRequired || false,
    pay: isPayRequired || false,
    inWebView: isMobileExperience(),
  });
  const [data, refetch] = useRefetchableFragment(
    policyRestrictionsGuardFragment,
    viewer as PolicyRestrictionsGuardFragment$key,
  );
  useSetUserProperties(data);

  useInterval(() => {
    refetch({}, { fetchPolicy: 'network-only' });
  }, 30000);

  const sendRestrictions = assertPresence(isSendsRequired ? data.sendRestrictions : [], {
    debugMessage: 'Failed to load send restrictions',
    message: formatMessage(messages.failedToLoadAccountInfo),
    mockEnvVar: 'SEND_RESTRICTIONS',
  });
  const buyRestrictions = assertPresence(isBuysRequired ? data.buyRestrictions : [], {
    debugMessage: 'Failed to load buy restrictions.',
    message: formatMessage(messages.failedToLoadAccountInfo),
    mockEnvVar: 'BUY_RESTRICTIONS',
  });

  const payRestrictions = assertPresence(isPayRequired ? data.payRestrictions : [], {
    debugMessage: 'Failed to load pay restrictions.',
    message: formatMessage(messages.failedToLoadAccountInfo),
  });

  const countryCode = data.userProperties.country?.code;
  const countryName = data.userProperties.country?.name || '';
  const isCountryAllowed = useIsCountryAllowed(countryCode);
  const reportAndViewError = useReportAndViewError();

  const webview2faBlocked = useMemo(
    () => isMobileExperience() && !data.isWebview2faEligible,
    [data.isWebview2faEligible],
  );

  const userRestrictions = useMemo(() => {
    const restrictions = [...sendRestrictions, ...buyRestrictions, ...payRestrictions];

    const restriction = restrictions
      .filter(isNotNullish)
      .filter(
        (res) =>
          res?.required && res?.link.url && res.restrictionName !== 'payment_method_required',
      );

    return restriction;
  }, [sendRestrictions, buyRestrictions, payRestrictions]);
  const userRestriction = useMemo(() => userRestrictions[0], [userRestrictions]);

  // TODO [ONRAMP-2034]: Remove this hotfix once longterm fix is in place
  const indirectLinkHotfix = useMemo(
    () => appId === CB_WALLET_APP_ID && isMobileExperience(),
    [appId],
  );

  useEffect(() => {
    if (!userRestriction && !webview2faBlocked) {
      markAsCleared();
    }

    if (userRestrictions.length > 0) {
      const restrictionNames = userRestrictions.map((restriction) => restriction?.restrictionName);
      reportError(
        new HandledError({ message: 'User is blocked by policy restrictions' }).addMetadata({
          restrictions: restrictionNames,
        }),
        { broadcast: false },
      );
      logEvent('blocked_by_policy_restrictions', {
        action: ActionType.process,
        componentType: ComponentType.unknown,
        loggingId: '465f4638-ae2d-4e4a-b111-69242959855b',
        restrictions: restrictionNames,
      });

      if (indirectLinkHotfix) {
        setShowClose(true);
      }
    } else if (webview2faBlocked) {
      logEvent('onramp.blocked_by_webview_2fa_guard', {
        action: ActionType.process,
        componentType: ComponentType.unknown,
      });
      reportError(
        new HandledError({ message: 'Web View users is blocked due to unsupported 2FA methods' }),
        {
          broadcast: false,
        },
      );
    } else {
      setShowClose(false);
    }

    if (!isCountryAllowed) {
      logEvent('onramp.unsupported_country', {
        action: ActionType.process,
        componentType: ComponentType.unknown,
        loggingId: 'a4cc3391-422f-4ffa-b9e7-5e9518e60eef',
        countryCode: countryCode ?? '<missing country>',
      });
      reportAndViewError(
        new HandledError({
          // Rich message overrides this so setting this to constant message
          message: `Coinbase Onramp is currently not available`,
        })
          .addHandlingParams({
            title: formatMessage(messages.notYetAvailable),
            richMessage: <CountryNotAllowedMessage country={countryName} />,
            pageTitle: 'Coinbase Onramp',
            backType: 'none',
            pictogramName: 'hero:restrictedCountry',
          })
          .addMetadata({
            countryCode,
            countryName,
          }),
      );
    }
  }, [
    countryCode,
    countryName,
    isCountryAllowed,
    markAsCleared,
    reportAndViewError,
    userRestriction,
    userRestrictions,
    formatMessage,
    appId,
    setShowClose,
    indirectLinkHotfix,
    webview2faBlocked,
  ]);

  if ((userRestriction || webview2faBlocked) && isCountryAllowed) {
    if (splashScreenContext.isVisible) {
      splashScreenContext.dismissSplashScreen();
    }

    if (webview2faBlocked) {
      return <WebView2FaRestrictionOverlay />;
    }

    return (
      <PolicyRestrictionsOverlay
        url={new URL(
          userRestriction.link.url || '/',
          process.env.NEXT_PUBLIC_COINBASE_BASE_URL,
        ).toString()}
        description={userRestriction.description}
        message={userRestriction.message}
        buttonText={userRestriction.link.text}
        indirectLinkHotfix={indirectLinkHotfix}
      />
    );
  }

  return null;
};

type PolicyRestrictionsOverlayProps = {
  url: string;
  description: string;
  message: string;
  buttonText: string;
  indirectLinkHotfix: boolean;
};

export const PolicyRestrictionsOverlay = ({
  url,
  description,
  message,
  buttonText,
  indirectLinkHotfix,
}: PolicyRestrictionsOverlayProps) => {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const isCBWalletAppWithPolicyRestrictionSupport = getIsCBWalletAppWithPolicyRestrictionSupport();

  const openUrl = useOpenUrl();

  const showPolicyRestrictionButton = useMemo(
    () => !indirectLinkHotfix || isCBWalletAppWithPolicyRestrictionSupport,
    [indirectLinkHotfix, isCBWalletAppWithPolicyRestrictionSupport],
  );

  const copyUrlToClipboard = useCallback(() => {
    copyToClipboard(url);
    toast.show(formatMessage(messages.copiedToClipboard), {
      hideCloseButton: true,
    });
  }, [formatMessage, toast, url]);

  const fullDescription = useMemo(() => {
    if (indirectLinkHotfix && !isCBWalletAppWithPolicyRestrictionSupport) {
      return `${description} ${formatMessage(messages.copyToContinue)}`;
    }

    return description;
  }, [description, formatMessage, indirectLinkHotfix, isCBWalletAppWithPolicyRestrictionSupport]);

  const handleButtonPress = useCallback(() => url && openUrl(url), [openUrl, url]);

  return (
    <VStack flexGrow={1} testID="policy-restrictions-overlay">
      <Box spacing={3} justifyContent="center">
        <HeroSquare name="securityShield" />
      </Box>
      <VStack spacing={1} spacingTop={0} flexGrow={1}>
        <TextTitle3 as="h3" align="center" spacingBottom={1}>
          {message}
        </TextTitle3>
        <TextBody as="p" align="center" color="foregroundMuted">
          {fullDescription}
        </TextBody>
        {indirectLinkHotfix && !isCBWalletAppWithPolicyRestrictionSupport && (
          <HStack alignItems="center" justifyContent="center" gap={2} spacing={2} flexGrow={1}>
            <TextLabel1 as="code">{url}</TextLabel1>
            <IconButton
              name="copy"
              variant="secondary"
              transparent
              onPress={copyUrlToClipboard}
              testID="policy-restrictions-copy-button"
            />
          </HStack>
        )}
      </VStack>
      {showPolicyRestrictionButton && (
        <Box spacing={3}>
          <Button block onPress={handleButtonPress} testID="policy-restrictions-link-button">
            {buttonText}
          </Button>
        </Box>
      )}
    </VStack>
  );
};

/**
 * Setting user properties for bugsnag & analytics
 */
const useSetUserProperties = (data: PolicyRestrictionsGuardFragment$data) => {
  const { formatMessage } = useIntl();
  const { userProperties } = data;
  const uuid = userProperties.uuid || '';
  const countryCode = assertPresence(userProperties.country?.code, {
    debugMessage: 'Country code is not defined',
    message: formatMessage(messages.failedToLoadAccountInfo),
  });

  useEffect(() => {
    if (uuid) {
      setBugsnagUserDetails(uuid);
      identifyUser({ userId: uuid, countryCode });
      clientSessionIdStore.setUserUuid(uuid);
    }
  }, [countryCode, uuid]);
};
