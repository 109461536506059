/**
 * @generated SignedSource<<613419f8dd6836908cb62e04e2f14f37>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type guestCheckoutStateAssetsQuery$variables = {
  symbol: string;
};
export type guestCheckoutStateAssetsQuery$data = {
  readonly assetBySymbol: {
    readonly displaySymbol: string | null;
    readonly exponent: number;
    readonly imageUrl: string;
    readonly name: string;
    readonly networks: ReadonlyArray<{
      readonly displayName: string | null;
      readonly isDefaultNetwork: boolean | null;
      readonly networkName: string | null;
    } | null> | null;
    readonly platformName: string | null;
    readonly slug: string;
    readonly supportedAddressRegexes: ReadonlyArray<string>;
    readonly uuid: string;
  };
};
export type guestCheckoutStateAssetsQuery = {
  response: guestCheckoutStateAssetsQuery$data;
  variables: guestCheckoutStateAssetsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "symbol"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "symbol",
    "variableName": "symbol"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uuid",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "platformName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displaySymbol",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "exponent",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "supportedAddressRegexes",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetNetwork",
  "kind": "LinkedField",
  "name": "networks",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "networkName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefaultNetwork",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "guestCheckoutStateAssetsQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": (v1/*: any*/),
          "concreteType": "Asset",
          "kind": "LinkedField",
          "name": "assetBySymbol",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/),
            (v4/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v7/*: any*/),
            (v8/*: any*/),
            (v9/*: any*/),
            (v10/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "assetBySymbol"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "guestCheckoutStateAssetsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Asset",
        "kind": "LinkedField",
        "name": "assetBySymbol",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b2561a3cbab9e07ff09afde338d68070",
    "id": null,
    "metadata": {},
    "name": "guestCheckoutStateAssetsQuery",
    "operationKind": "query",
    "text": "query guestCheckoutStateAssetsQuery(\n  $symbol: String!\n) {\n  assetBySymbol(symbol: $symbol) {\n    uuid\n    slug\n    platformName\n    displaySymbol\n    name\n    imageUrl\n    exponent\n    supportedAddressRegexes\n    networks {\n      networkName\n      isDefaultNetwork\n      displayName\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8db8af031815ede8fb72a1a266d94be9";

export default node;
