import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { coerceError, InternalError } from '@onramp/utils/errors';
import { logApplePayCorrelationIdFailure } from '@onramp/utils/eventing';
import { genericMessages } from '@onramp/utils/genericMessages';
import { assertPresence } from '@onramp/utils/relayUtils';
import Braintree from 'braintree-web';
import { noop } from '@cbhq/cds-utils';

import type { BrainTreeNetwork } from './useBraintreeClientToken';
import { useBraintreeClientToken } from './useBraintreeClientToken';

export function useBraintree(network: BrainTreeNetwork) {
  const { formatMessage } = useIntl();
  const getClientToken = useBraintreeClientToken(network);

  const getBraintreeClient = useCallback(async () => {
    try {
      const token = assertPresence((await getClientToken()).token, {
        debugMessage: 'useBraintreeClientToken.getClientToken response has a null token',
        message: formatMessage(genericMessages.somethingWentWrong),
      });

      return await Braintree.client.create({
        authorization: token,
      });
    } catch (error) {
      throw coerceError(error)
        .addMetadata({
          context: 'useBraintree.getBraintreeClient',
          braintreeNetwork: network,
        })
        .addHandlingParams({ message: formatMessage(genericMessages.somethingWentWrong) });
    }
  }, [formatMessage, getClientToken, network]);

  const getCorrelationId = useCallback(async () => {
    let dataCollector: Braintree.DataCollector | undefined;
    let client: Braintree.Client | undefined;
    try {
      client = await getBraintreeClient();
      dataCollector = await Braintree.dataCollector.create({ client });
      const data = (await dataCollector.getDeviceData({ raw: true })) as Record<string, unknown>;
      if (!data?.correlation_id) {
        throw new InternalError(
          'BraintreeDataCollector.getDeviceData({ raw: true }).correlation_id is missing',
        );
      }
      return String(data.correlation_id);
    } catch (error) {
      if (network === 'apple_pay') {
        logApplePayCorrelationIdFailure();
      }

      throw coerceError(error)
        .addMetadata({
          context: 'useBraintree.getCorrelationId',
          braintreeNetwork: network,
        })
        .addHandlingParams({ message: formatMessage(genericMessages.somethingWentWrong) });
    } finally {
      void client?.teardown(noop);
      void dataCollector?.teardown();
    }
  }, [getBraintreeClient, network, formatMessage]);

  return { getBraintreeClient, getCorrelationId };
}
