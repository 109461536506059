import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { UserMenuQuery } from '@onramp/data/__generated__/UserMenuQuery.graphql';
import { useSafeRelayEnvironment } from '@onramp/hooks/useSafeRelayEnvironment';
import { useHandleAddPaymentMethodInRetail } from '@onramp/hooks/utils/useHandleAddPaymentMethodInRetail';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { logNavMenuAction, logNavSignout } from '@onramp/utils/eventing';
import { logout } from '@onramp/utils/logout';
import { useReffedFunction } from '@onramp/utils/useReffedFunction';
import { noop } from '@cbhq/cds-utils/noop';
import { Button } from '@cbhq/cds-web/buttons';
import { Icon } from '@cbhq/cds-web/icons';
import { LogoMark } from '@cbhq/cds-web/icons/LogoMark';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { Avatar } from '@cbhq/cds-web/media';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';

import type { MenuTab } from '.';
import { AboutMenu } from './AboutMenu';
import { ListCell } from './ListCellUserMenu';

const userMenuQuery = graphql`
  query UserMenuQuery {
    viewer {
      userProperties {
        name
        email
        avatarUrl
      }
    }
  }
`;

const messages = defineMessages({
  signOut: {
    id: 'UserMenu.signOut',
    defaultMessage: 'Sign out',
    description: 'Label for a button that triggers sign out',
  },
  addPaymentMethod: {
    id: 'UserMenu.addPaymentMethod',
    defaultMessage: 'Add a payment method',
    description: 'Label for the Add a Payment Method menu item',
  },
  showAboutMenu: {
    id: 'UserMenu.showAboutMenu',
    defaultMessage: 'About Coinbase Onramp',
    description: 'Label for the menu item that opens the About Menu',
  },
  failedToLoad: {
    id: 'UserMenu.failedToLoad',
    defaultMessage: "We're unable to load this information. Please try again later.",
    description: 'Message that replaces name and email when those fail to load',
  },
});

const UserInfo = () => {
  const isGraphQLAvailable = Boolean(useSafeRelayEnvironment());

  // this is safe because `isGraphQLAvailable` will only change if a provider is added, which would cause the tree below it to remount and so wouldn't trigger an error
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const data = isGraphQLAvailable ? useLazyLoadQuery<UserMenuQuery>(userMenuQuery, {}) : undefined;
  const { name, email, avatarUrl } = data?.viewer.userProperties ?? {};
  const { formatMessage } = useIntl();

  return (
    <VStack gap={1} alignItems="center" spacingTop={4} spacingBottom={3}>
      <Avatar
        size="xxxl"
        selected
        colorScheme="gray"
        alt={name ?? 'Avatar'}
        src={avatarUrl || undefined}
      />
      <VStack alignItems="center" maxWidth={300}>
        {name && (
          <TextTitle3 align="center" as="h3" overflow="wrap">
            {name}
          </TextTitle3>
        )}
        {email && (
          <TextBody align="center" as="p" overflow="wrap" color="foregroundMuted">
            {email}
          </TextBody>
        )}
        {!name && !email && (
          <TextBody align="center" as="p" overflow="wrap" color="foregroundMuted">
            {formatMessage(messages.failedToLoad)}
          </TextBody>
        )}
      </VStack>
    </VStack>
  );
};

const MenuStack = ({ onShowAboutMenu }: { onShowAboutMenu?: () => void }) => {
  const handleAddPaymentMethodInRetail = useHandleAddPaymentMethodInRetail();

  const onAddPaymentMethod = useCallback(() => {
    logNavMenuAction('paymentMethod');
    handleAddPaymentMethodInRetail();
  }, [handleAddPaymentMethodInRetail]);
  const { formatMessage } = useIntl();

  return (
    <VStack>
      <ListCell
        title={formatMessage(messages.addPaymentMethod)}
        media={<Icon name="add" color="foreground" size="s" />}
        onPress={onAddPaymentMethod}
      />
      {onShowAboutMenu != null ? (
        <ListCell
          title={formatMessage(messages.showAboutMenu)}
          media={<LogoMark size={16} />}
          accessory="arrow"
          onPress={onShowAboutMenu}
          testID="about-cbpay-button"
        />
      ) : null}
    </VStack>
  );
};

const SignOutButton = () => {
  const { formatMessage } = useIntl();
  const onSignout = useReffedFunction(() => {
    const signoutURL = new URL(`${process.env.NEXT_PUBLIC_COINBASE_BASE_URL}/signout`);
    signoutURL.searchParams.append('return', window.location.href);
    const userUuid = clientSessionIdStore.getUserUuid();
    localStorage.removeItem(`transactions.${userUuid}`);
    logNavSignout('nav_menu');
    logout()
      .then(() => window.location.assign(signoutURL.toString()))
      .catch(noop);
  });

  return (
    <Box
      alignSelf="center"
      width="90%"
      spacingTop={3}
      position="absolute"
      bottom={0}
      spacingBottom={3}
    >
      <Button onPress={onSignout} variant="secondary" block>
        {formatMessage(messages.signOut)}
      </Button>
    </Box>
  );
};

type UserMenuProps = {
  menuState?: MenuTab;
  onShowMenu?: () => void;
};

export const UserMenu = ({ menuState, onShowMenu }: UserMenuProps) => {
  return (
    <div data-testid="user-menu">
      {menuState === 'about' ? (
        <AboutMenu />
      ) : (
        <VStack>
          <UserInfo />
          <MenuStack onShowAboutMenu={onShowMenu} />
          <SignOutButton />
        </VStack>
      )}
    </div>
  );
};
