import type { CookieOptions as ECookieOptions } from 'express';
// eslint-disable-next-line no-restricted-imports -- @onramp/utils/cookies/* are the only files where we should use js-cookie
import type Cookies from 'js-cookie';
import fromPairs from 'lodash/fromPairs';
import type { StringValue as MsStringValue } from 'ms';
import ms from 'ms';
import type { Merge } from 'type-fest';
import type { IsEqual } from 'type-fest/source/internal';

import type { AnyFunction } from '../types';
import { isNotNullish, satisfies } from '../types';

export const DEVICE_ID_COOKIE_NAME = 'coinbase_device_id';
export const DEVICE_ID_COOKIE_DOMAIN = (() => {
  try {
    const { hostname } = new URL(process.env.NEXT_PUBLIC_ONRAMP_BASE_URL ?? '');
    if (hostname.endsWith('.coinbase.com')) return '.coinbase.com';
    if (hostname.endsWith('.cbhq.net')) return '.cbhq.net';
  } catch {
    /* noop */
  }

  return undefined;
})();

type CookieOptions = Omit<ECookieOptions, 'sameSite'> & {
  // this makes sure only values that are accepted by both express & js-cookie are allowed
  sameSite?: ECookieOptions['sameSite'] & Cookies.CookieAttributes['sameSite'];
  authRelated?: boolean;
};

type CookieSettingHelper<T extends unknown[]> = CookieOptions & {
  key: IsEqual<T, []> extends true ? string : (...args: T) => string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CookieSetting<T extends unknown[] = any[] | []> = T extends unknown
  ? CookieSettingHelper<T>
  : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnparsedCookieSetting<T extends unknown[] = any[] | []> = Omit<CookieSetting<T>, 'maxAge'> & {
  age: MsStringValue | undefined;
};

const defaultCookieSettings = satisfies<CookieOptions>()({
  httpOnly: true,
  secure: true,
  signed: true,
  // with strict or lax, requests inside iframes won't set cookies
  sameSite: 'none',
  path: '/',
});

export const ACCESS_TOKEN_EXPIRY = '20 minutes';
export const REFRESH_TOKEN_EXPIRY = '2 weeks';

const DEVICE_EXPERIMENT_COOKIE_KEY = 'cbpay.experiments.device';
const WALLET_EXPERIMENT_COOKIE_KEY = 'cbpay.experiments.wallet-user';
const EXPERIMENT_OVERRIDES_COOKIE_KEY = 'cbpay.experiments.overrides';

const EXPERIMENT_COOKIE_ATTRIBUTES = Object.freeze(
  satisfies<Partial<UnparsedCookieSetting>>()({
    httpOnly: false,
    signed: false,
    secure: true,
    age: '10 minutes',
  } as const),
);

const KILL_SWITCH_COOKIE_KEY = 'cbpay.killswitches';
const KILL_SWITCH_OVERRIDES_COOKIE_KEY = 'cbpay.killswitches.overrides';

const KILL_SWITCH_COOKIE_ATTRIBUTES = Object.freeze(
  satisfies<Partial<UnparsedCookieSetting>>()({
    httpOnly: false,
    signed: false,
    secure: true,
    age: '1 minute',
  } as const),
);

export const DDC_3DS_DATA_COOKIE_KEY = '3ds-ddc';

const rawCookieSettings = satisfies<Record<string, UnparsedCookieSetting>>()({
  appParams: {
    key: (appId) => `${appId}:app_params`,
    age: '20 minutes',
  },
  state3ds: {
    key: (appId) => `${appId}:3ds_state`,
    age: '20 minutes',
  },
  jwt: {
    key: (appId) => `${appId}:jwt`,
    age: REFRESH_TOKEN_EXPIRY,
  },
  accessToken: {
    key: (appId) => `${appId}:access_token`,
    age: ACCESS_TOKEN_EXPIRY,
  },
  refreshToken: {
    key: (appId) => `${appId}:refresh_token`,
    age: REFRESH_TOKEN_EXPIRY,
  },
  oauthNonce: {
    key: 'state',
    age: '5 minutes',
  },
  deviceExperiments: {
    key: DEVICE_EXPERIMENT_COOKIE_KEY,
    ...EXPERIMENT_COOKIE_ATTRIBUTES,
  },
  walletExperiments: {
    key: WALLET_EXPERIMENT_COOKIE_KEY,
    ...EXPERIMENT_COOKIE_ATTRIBUTES,
  },
  overridenExperiments: {
    key: EXPERIMENT_OVERRIDES_COOKIE_KEY,
    ...EXPERIMENT_COOKIE_ATTRIBUTES,
    age: '1 day',
  },
  fetchedKillSwitches: {
    key: KILL_SWITCH_COOKIE_KEY,
    ...KILL_SWITCH_COOKIE_ATTRIBUTES,
  },
  overridenKillSwitches: {
    key: KILL_SWITCH_OVERRIDES_COOKIE_KEY,
    ...KILL_SWITCH_COOKIE_ATTRIBUTES,
    age: '1 day',
  },
  deviceId: {
    key: DEVICE_ID_COOKIE_NAME,
    age: '10 years', // inline with retail
    domain: DEVICE_ID_COOKIE_DOMAIN,
    httpOnly: false,
    signed: false,
  },
  ddc3ds: {
    key: DDC_3DS_DATA_COOKIE_KEY,
    age: '1 hour',
    httpOnly: false,
    signed: false,
  },
  serverMetadata: {
    key: 'cbpay.server_metadata',
    httpOnly: false,
    signed: false,
    age: undefined,
  },
  secureInit: {
    key: 'cbpay.secure_init',
    httpOnly: true,
    secure: true,
    age: undefined,
    authRelated: false,
  },
  coinbase_locale: {
    key: 'coinbase_locale',
    age: '1 hour', // will be set again by the query param when it expires
    domain: DEVICE_ID_COOKIE_DOMAIN,
    httpOnly: false,
    signed: false,
  },
} as const);

export type CookieName = keyof typeof rawCookieSettings;

type ParsedCookieSettings = {
  [Cookie in CookieName]: Merge<
    typeof defaultCookieSettings,
    Omit<typeof rawCookieSettings[Cookie], 'age'> & {
      maxAge: number;
      name: Cookie;
    }
  >;
};

export const cookieSettings = fromPairs(
  Object.entries(rawCookieSettings).map(([name, { age, ...setting }]) => [
    name,
    {
      ...defaultCookieSettings,
      ...setting,
      name,
      ...(isNotNullish(age) ? { maxAge: ms(age) } : {}),
    } as ParsedCookieSettings[CookieName],
  ]),
) as ParsedCookieSettings;

export type CookieSettings = typeof cookieSettings;

type CookieNameAndArgsHelper<Cookie extends keyof CookieSettings> = {
  cookieName: Cookie;
} & (CookieSettings[Cookie] extends { key: AnyFunction }
  ? { keyArgs: Parameters<CookieSettings[Cookie]['key']> }
  : { keyArgs?: never });

export type CookieNameAndArgs<T extends CookieName = CookieName> = {
  [Cookie in T]: CookieNameAndArgsHelper<Cookie>;
}[T];

export const getCookieKey = ({ cookieName, keyArgs }: CookieNameAndArgs) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const { key } = cookieSettings[cookieName as CookieName];
  if (typeof key === 'string') return key;

  // @ts-expect-error: `keyArgs` always has the right type, but TS doesn't understand it
  return key(...keyArgs);
};

export function getCookieOptions(cookieName: CookieName): CookieOptions {
  const { key, ...options } = cookieSettings[cookieName];
  return { ...defaultCookieSettings, ...options };
}

export type ExperimentCookieName = CookieName & `${string}Experiments`;
export type KillSwitchCookieName = CookieName & `${string}KillSwitches`;
