import type { GuestCheckoutErrorBannerData } from '@onramp/components/guest/GuestCheckoutErrorBanner';
import type { guestCheckoutStateAssetsQuery$data } from '@onramp/data/__generated__/guestCheckoutStateAssetsQuery.graphql';
import type { useCommitGuestCheckoutTransactionMutation$data } from '@onramp/data/__generated__/useCommitGuestCheckoutTransactionMutation.graphql';
import type { useCreateGuestCheckoutSessionMutation$data } from '@onramp/data/__generated__/useCreateGuestCheckoutSessionMutation.graphql';
import type { useCreateGuestCheckoutTransactionMutation$data } from '@onramp/data/__generated__/useCreateGuestCheckoutTransactionMutation.graphql';
import type { BillingAddressInput } from '@onramp/data/graphql-types';
import type { GuestCheckoutTransactionPolled } from '@onramp/pages/buy/guest/interstitial/usePollForGuestCheckoutTransaction';
import type { BuyWidgetSchema, DestinationAddressAppParam } from '@onramp/shared/appParams.schema';
import type { TokenizeData } from '@onramp/types/token-ex';
import { parseStoneIdToNetworkId } from '@onramp/utils/blockchains/stoneIdFormat';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { ETH_ASSET_SYMBOL, ETHEREUM_NETWORK_NAME } from '@onramp/utils/consts';
import { isTestEnvironment } from '@onramp/utils/environment/sharedEnv';
import { isNotNullish } from '@onramp/utils/types';
import bigJs from 'big.js';
import deepEqual from 'fast-deep-equal';
import type { ExtractAtomValue } from 'jotai';
import { atom, useAtomValue } from 'jotai';
import { atomFamily, atomWithDefault, loadable } from 'jotai/utils';
import { z } from 'zod';
import { graphql } from '@cbhq/data-layer';

import { textInputAtomFamily } from './form/textInputState';
import type { assetMetadataSelector } from './appParamsState';
// eslint-disable-next-line import/no-cycle
import { buyWidgetParamsAtom, destinationWalletsV2Selector } from './appParamsState';
import { atomWithQueryAndMap, jotaiStore } from './utils';

type MaybePromise<T> = T | Promise<T>;

export const guestCheckoutAssetMetadataAtom = atom<
  Partial<Awaited<ExtractAtomValue<typeof assetMetadataSelector>>>
>({
  BTC: {
    ticker: 'BTC',
    minimumSendAmount: 0.0001,
    blockchain: 'bitcoin',
  },
  ETH: {
    ticker: 'ETH',
    minimumSendAmount: 0.001,
    blockchain: 'ethereum',
    l2SupportedNetworks: ['arbitrum', 'optimism', 'polygon', 'avacchain', 'base'],
  },
  AVAX: {
    ticker: 'AVAX',
    minimumSendAmount: 0.1,
    blockchain: 'avacchain',
  },
  USDC: {
    ticker: 'USDC',
    minimumSendAmount: 1,
    blockchain: 'ethereum',
    l2SupportedNetworks: ['arbitrum', 'avacchain', 'polygon', 'solana'],
  },
  SOL: {
    ticker: 'SOL',
    minimumSendAmount: 0.05,
    blockchain: 'solana',
  },
});
export const supportedGuestCheckoutAssetsLoadable = loadable(guestCheckoutAssetMetadataAtom);
export const supportedGuestCheckoutStoneIdsLoadable = loadable(
  atom(['ethereum', 'base', 'bitcoin', 'solana', 'avacchain']),
);
export const guestCheckoutNetworkMetadataAtom = atom({
  ethereum: {
    name: 'Ethereum',
    legacyId: 'ETH',
  },
  base: {
    name: 'Base',
  },
  bitcoin: {
    name: 'Bitcoin',
  },
  solana: {
    name: 'Solana',
    legacyId: 'SOL',
  },
  avacchain: {
    name: 'Avalanche C-Chain',
    legacyId: 'AVAX',
  },
});

/**
 * Checking if the user has checked the asset list
 */
export const guestCheckoutHasCheckedAssetList = atom<boolean>(false);

/**
 * As per compliance, we will need to store the time the user accepted our TOS for Guest Checkout.
 * This value will be passed to subsequent CB Pay Guest Checkout GQL endpoints.
 */
export const guestCheckoutTOSAcceptDateAtom = atom<Date | undefined>(undefined);

/**
 * This atom will hold the code returned from login service. Login service is an un-authed REST
 * endpoint that signals the beginning of the Guest Checkout flow. This code will be passed to
 * subsequent CB Pay Guest Checkout GQL endpoints.
 */
export const loginGuestSessionCodeAtom = atom<string>('');

/**
 * This data comes from the TwoFactorRegister component. This value will be passed to
 * subsequent CB Pay Guest Checkout GQL endpoints.
 */
export const guestCheckoutCountryCode = atom<string>('');

/**
 * This data comes from the TwoFactorRegister component. This value will be passed to
 * subsequent CB Pay Guest Checkout GQL endpoints.
 */
export const guestCheckoutPhoneNumber = atom<string | undefined>(undefined);
export const guestCheckoutObfuscatedPhoneNumber = atom<string | undefined>(undefined);

/**
 * This selector is meant to populate the `destinationWalletDetails` input variable to the
 * `createGuestCheckoutSession` mutation that happens after the Guest Checkout phone verification
 * process is complete.
 */
export const guestCheckoutDestinationWalletDetailsSelector = atom(async (get) => {
  const { destinationWallets } = await get(destinationWalletsV2Selector);
  return destinationWallets.map(({ address, assets, networksForAPISubmission }) => {
    return {
      address,
      networks: networksForAPISubmission,
      assetIds: assets,
    };
  });
});

type GuestCheckoutSession = Extract<
  useCreateGuestCheckoutSessionMutation$data['createGuestCheckoutSession'],
  { __typename: 'CreateGuestCheckoutSessionSuccess' }
>;

/**
 * This atom will hold the response from the `createGuestCheckoutSession` mutation that happens
 * after the Guest Checkout phone verification process is complete.
 */
export const guestCheckoutSessionAtom = atom<GuestCheckoutSession | null>(null);
export const useGuestCheckoutSessionAtom = () => useAtomValue(guestCheckoutSessionAtom);

/**
 * This value gets added to all Guest Checkout logs throughout the flow. The logs do not accept
 * `null` values. We ensure `null` gets turned into `undefined` with the ?? operator.
 */
export const guestCheckoutIsReturningUserSelector = atom(
  (get) => get(guestCheckoutSessionAtom)?.isReturningUser ?? undefined,
);
export const useGuestCheckoutIsReturningUserSelector = () =>
  useAtomValue(guestCheckoutIsReturningUserSelector);

export const fiatCurrencySelector = atom(
  (get) => get(guestCheckoutSessionAtom)?.limits?.max?.currency,
);

// aliasing the selector to useAtomValue adds a extra loop but it might easier to mock and test
export const useGetFiatCurrency = () => useAtomValue(fiatCurrencySelector);

// PATRICK: find a better way to handle this by fixing the mocks
export const guestCheckoutSelectedNetworkNameAtom = atom<string>(
  isTestEnvironment ? ETHEREUM_NETWORK_NAME : '',
);
export const guestCheckoutAvailableNetworkShortNamesSelectorFamily = atomFamily(
  // `assetNetworkNames` strings have the long form network name - 'networks/ethereum-mainnet'
  ({ assetNetworkNames }: { assetNetworkNames: (string | undefined)[] }) =>
    atom(async (get) => {
      const destinationWallets = get(buyWidgetParamsAtom)?.destinationWallets;

      return _getGuestCheckoutAvailableNetworkShortNames({
        destinationWallets,
        assetNetworkNames,
      });
    }),
  deepEqual,
);
// eslint-disable-next-line @typescript-eslint/naming-convention
export function _getGuestCheckoutAvailableNetworkShortNames({
  destinationWallets,
  assetNetworkNames,
}: {
  destinationWallets: DestinationAddressAppParam[] | undefined;
  assetNetworkNames: (string | undefined)[];
}): string[] {
  // All networks available via app params.
  const networksToFilterSet = (destinationWallets ?? []).reduce((acc, wallet) => {
    wallet.blockchains?.forEach((blockchain) => acc.add(blockchain));
    wallet.supportedNetworks?.forEach((network) => acc.add(network));
    return acc;
  }, new Set<string>());

  return assetNetworkNames.map(parseStoneIdToNetworkId).filter((networkShortName) => {
    return networksToFilterSet.has(networkShortName ?? '');
  }) as string[];
}

/**
 * This write-only setter atom is used inside `AppManagerProvider` to set specific Guest Checkout
 * values based on the initial app params. This will allow us to skip the input page based upon
 * wether or not we already have that data from when the app was initialized.
 */
export const setGuestCheckoutInitialValuesAtom = atom(
  null,
  (get, set, initialValues: z.infer<typeof BuyWidgetSchema>) => {
    const {
      defaultAsset,
      defaultNetwork,
      destinationWallets,
      presetCryptoAmount,
      presetFiatAmount,
    } = initialValues;
    const fiatAmount = bigJs(presetFiatAmount ?? 0).gt(0) ? `${presetFiatAmount}` : undefined;
    const cryptoAmount = bigJs(presetCryptoAmount ?? 0).gt(0) ? `${presetCryptoAmount}` : undefined;

    const hasDefaultAssetFromDestinationWallets =
      destinationWallets &&
      destinationWallets.length === 1 &&
      destinationWallets[0].assets?.length === 1;
    const hasDefaultAsset = Boolean(defaultAsset || hasDefaultAssetFromDestinationWallets);
    const defaultSelectedAsset = defaultAsset ?? destinationWallets?.[0]?.assets?.[0];

    if (hasDefaultAsset && defaultSelectedAsset)
      set(guestCheckoutSelectedAssetSymbolAtom, defaultSelectedAsset.toUpperCase());
    if (defaultNetwork) set(guestCheckoutSelectedNetworkNameAtom, defaultNetwork.toLowerCase());
    // prioritizing fiatAmount because OCB does the same if both params are passed in
    if (fiatAmount) {
      set(inputAmountTypeAtom, 'fiat');
      set(userAmountValueAtom, `${presetFiatAmount}`);
    } else if (cryptoAmount) {
      set(inputAmountTypeAtom, 'crypto');
      set(userAmountValueAtom, `${presetCryptoAmount}`);
    }

    if (shouldSkipInputPage(initialValues, hasDefaultAsset)) {
      set(guestCheckoutShouldSkipInputPageAtom, true);
    }
  },
);

function shouldSkipInputPage(params: z.infer<typeof BuyWidgetSchema>, hasDefaultAsset: boolean) {
  const { presetFiatAmount, presetCryptoAmount } = params;
  const fiatAmount = bigJs(presetFiatAmount ?? 0).gt(0) ? `${presetFiatAmount}` : undefined;
  const cryptoAmount = bigJs(presetCryptoAmount ?? 0).gt(0) ? `${presetCryptoAmount}` : undefined;

  if (!(cryptoAmount || fiatAmount) || !hasDefaultAsset) return false;
  return true;
}

export const guestCheckoutShouldSkipInputPageAtom = atom<boolean>(false);

/**
 * This atom drives a lot of data and is an important upstream dependency for a number of selectors.
 * This atom should only be set directly when selecting an asset on the select-asset page.
 */
export const guestCheckoutSelectedAssetSymbolAtom = atom<string>(
  isTestEnvironment ? ETH_ASSET_SYMBOL : '',
);
jotaiStore.sub(guestCheckoutSelectedAssetSymbolAtom, async () => {
  // When selecting a new asset, "reset" the selected network to the assets default network.
  void jotaiStore.set(
    guestCheckoutSelectedAssetNetworkAtom,
    jotaiStore.get(guestCheckoutSelectedDefaultNetworkSelector),
  );
});
/**
 * This selector family houses all the data about the available assets in Guest Checkout. It powers
 * the input page, the select-asset page, and anywhere else we need parts of this data throughout
 * the flow.
 *
 * There should be no reason to call this selector prior to the input page.
 */
export const guestCheckoutAssetsSelectorFamily = atomFamily((symbol: string) =>
  atomWithQueryAndMap({
    /* eslint-disable relay/unused-fields */
    query: graphql`
      query guestCheckoutStateAssetsQuery($symbol: String!) {
        assetBySymbol(symbol: $symbol) @required(action: THROW) {
          uuid
          slug # 'bitcoin'
          platformName # 'BTC'
          displaySymbol # Public ticker displayed to users - 'BTC'
          name # 'Bitcoin'
          imageUrl
          exponent
          supportedAddressRegexes
          networks {
            # Using "networkName" instead of "networkBlockchainName". "networkBlockchainName" will
            # only be available on the default L1 network. For L2s, the value will be null.
            # "networkName" will be available on all networks. Example - "networks/polygon-mainnet"
            # We already have parsing logic for the buy flow that we can reuse in Guest Checkout.
            networkName
            isDefaultNetwork
            displayName
          }
        }
      }
    `,
    /* eslint-enable relay/unused-fields */
    variables: (get) => ({
      symbol,
      currency: get(guestCheckoutSessionAtom)?.limits?.max?.currency,
    }),
    mapResponse: (data: guestCheckoutStateAssetsQuery$data) => data.assetBySymbol,
  }),
);

/**
 * The selected asset.
 */
export const guestCheckoutSelectedAssetSelector = atom(async (get) => {
  const symbol = get(guestCheckoutSelectedAssetSymbolAtom);
  return get(guestCheckoutAssetsSelectorFamily(symbol));
});

export const guestCheckoutSelectedAssetNetworkAtom = atomWithDefault<
  MaybePromise<{ networkName: string; displayName: string }>
>(async (get) => {
  const selectedAsset = await get(guestCheckoutSelectedAssetSelector);
  const selectedAssetDefaultNetwork = await get(guestCheckoutSelectedDefaultNetworkSelector);
  const defaultNetworkParam = get(buyWidgetParamsAtom)?.defaultNetwork;

  if (!defaultNetworkParam || defaultNetworkParam === selectedAssetDefaultNetwork.networkName) {
    return selectedAssetDefaultNetwork;
  }

  const desiredNetwork = selectedAsset.networks?.find(
    (network) => parseStoneIdToNetworkId(network?.networkName) === defaultNetworkParam,
  );
  if (!desiredNetwork) return selectedAssetDefaultNetwork;

  const networkName = parseStoneIdToNetworkId(desiredNetwork.networkName);
  if (!networkName) {
    throw new Error('Guest Checkout - no value found for desiredNetwork.networkName');
  }
  const { displayName } = desiredNetwork;
  if (!displayName) {
    throw new Error('Guest Checkout - no value found for desiredNetwork.displayName');
  }

  return { networkName, displayName };
});

const guestCheckoutSelectedDefaultNetworkSelector = atom(async (get) => {
  const asset = await get(guestCheckoutSelectedAssetSelector);
  const symbol = get(guestCheckoutSelectedAssetSymbolAtom);
  const defaultNetwork = asset.networks?.find((network) => network?.isDefaultNetwork);
  if (!defaultNetwork) throw new Error(`Default network not found for ${symbol}`);

  const networkName = parseStoneIdToNetworkId(defaultNetwork.networkName);
  if (!networkName) {
    throw new Error('Guest Checkout - no value found for defaultNetwork.networkName');
  }
  const { displayName } = defaultNetwork;
  if (!displayName) {
    throw new Error('Guest Checkout - no value found for defaultNetwork.displayName');
  }

  return { networkName, displayName };
});

/** this atom tracks if the user decides to change assets from the unsupported asset page */
export const isChangingUnsupportedAssetAtom = atom<boolean>(false);

/**
 * This atom tracks if the user decides to change assets from the order-preview page
 */
export const isChangingNetworkFromOrderPreviewAtom = atom<boolean>(false);

export const inputAmountTypeAtom = atom<'fiat' | 'crypto'>('fiat');

/** this atom stores the user amount value (e.g. $12), regardless of the type (fiat or crypto) */
export const userAmountValueAtom = atom('');

export const userAmountTooBigSelector = atom((get) => {
  const inputType = get(inputAmountTypeAtom);
  const currentAmount = bigJs(get(userAmountValueAtom) || 0);
  const maxFiatAmount = bigJs(get(guestCheckoutSessionAtom)?.limits?.left?.value || '500');

  /**
   * This selector will drive showing warning messages in the UI. To avoid showing a flash of a
   * message before the API has returned transaction data, we return false here. The UI will update
   * accordingly once the data is in.
   */
  const rawExchangeRate = get(guestCheckoutTransactionAtom)?.transaction.exchangeRate.value;
  if (!rawExchangeRate) return false;

  const exchangeRate = bigJs(
    get(guestCheckoutTransactionAtom)?.transaction.exchangeRate.value || 0,
  );
  const maxCryptoAmount = bigJs(exchangeRate).mul(maxFiatAmount);

  return currentAmount.gt(inputType === 'fiat' ? maxFiatAmount : maxCryptoAmount);
});

/** this atom stores the user amount currency (e.g. USD), regardless of the type (fiat or crypto) */
export const userAmountCurrencySelector = atom((get) =>
  get(inputAmountTypeAtom) === 'fiat'
    ? get(fiatCurrencySelector) ?? ''
    : get(guestCheckoutSelectedAssetSymbolAtom),
);

/** this atom gets user amount type, value and currency into an object */
export const userAmountSelector = atom((get) => ({
  value: get(userAmountValueAtom),
  currency: get(userAmountCurrencySelector),
  type: get(inputAmountTypeAtom),
}));

type Amount = { value: string; currency: string };
type FiatAndCryptoAmount = { fiatAmount: Amount; cryptoAmount: Amount };

export const fiatAndCryptoAmountSelectorFamily = atomFamily((exponent: number) =>
  atom<Promise<FiatAndCryptoAmount | null>>(async (get) => {
    const type = get(inputAmountTypeAtom);
    const userAmount = get(userAmountSelector);
    const transaction = get(guestCheckoutTransactionAtom)?.transaction;
    if (!transaction) return null;

    /**
     * We calculate amounts with `exchangeRate` instead of the getting e.g. `transaction.subtotal`
     * for `fiatAmount` when `type` = `crypto` because preview transactions might use a placeholder
     * transaction amount
     */
    const exchangeRate = transaction.exchangeRate.value;
    const assetSymbol = transaction.exchangeRate.currency;
    const fiatCurrency = transaction.subtotal.currency;

    if (type === 'crypto') {
      return {
        fiatAmount: {
          currency: fiatCurrency,
          value: bigJs(userAmount.value || '0')
            .div(exchangeRate)
            // TODO [ONRAMP-2738]: somehow get the currency's "minor unit" count and update dynamically - https://en.wikipedia.org/wiki/ISO_4217
            .toFixed(2)
            .toString(),
        },
        cryptoAmount: { currency: userAmount.currency, value: userAmount.value },
      };
    }

    return {
      fiatAmount: {
        currency: userAmount.currency,
        value: bigJs(userAmount.value || '0')
          // TODO [ONRAMP-2738]: somehow get the currency's "minor unit" count and update dynamically - https://en.wikipedia.org/wiki/ISO_4217
          .toFixed(2)
          .toString(),
      },
      cryptoAmount: {
        value: bigJs(userAmount.value || '0')
          .mul(exchangeRate)
          .toPrecision(exponent),
        currency: assetSymbol,
      },
    };
  }),
);

/**
 * Keys:
 * Keys in this map match the key names to the the `billingAddress` object in the input variable
 * to the `createGuestCheckoutTransaction` mutation.
 *
 * Values:
 * Values in this map correspond to the `stateKey` prop used in the Recoil form components OR the
 * underlying textInputAtomFamily, which drive the billing-address page in the Guest Checkout flow.
 *
 * NOTE - the `billingAddress` also requires the user's country data (`code` and `name`). This is
 * located in a separate atom and, therefore, no associated keys are found in this object.
 */
export const guestCheckoutBillingAddressFormKeyMap = {
  firstName: 'guestcheckoutBillingAddressFirstName',
  lastName: 'guestcheckoutBillingAddressLastName',
  line1: 'guestcheckoutBillingAddressAddressLine1',
  line2: 'guestcheckoutBillingAddressAddressLine2',
  city: 'guestcheckoutBillingAddressCity',
  state: 'guestcheckoutBillingAddressState',
  postalCode: 'guestCheckoutBillingAddressZipCode',
} as const;

export const guestCheckoutBillingAddressSelector = atom<BillingAddressInput | undefined>((get) => {
  const data: BillingAddressInput = {
    line1: get(textInputAtomFamily(guestCheckoutBillingAddressFormKeyMap.line1)),
    line2: get(textInputAtomFamily(guestCheckoutBillingAddressFormKeyMap.line2)),
    city: get(textInputAtomFamily(guestCheckoutBillingAddressFormKeyMap.city)),
    state: get(textInputAtomFamily(guestCheckoutBillingAddressFormKeyMap.state)),
    postalCode: get(textInputAtomFamily(guestCheckoutBillingAddressFormKeyMap.postalCode)),
    country: {
      code: get(guestCheckoutCountryCode),
      /** this field is required by GraphQL, but it's totally ignored by the BE */
      name: '',
    },
  };

  /**
   * we use zod to make sure the form data is valid and return `undefined` otherwise — as if we
   * send anything (e.g. empty strings) to the GC transaction mutations, that will overwrite the
   * user's data
   */
  return z
    .object({
      line1: z.string().min(1),
      line2: z.string().optional(),
      city: z.string().min(1),
      state: z.string().min(1),
      postalCode: z.string().min(1),
      country: z.object({
        code: z.string().min(1),
        name: z.string(),
      }),
    })
    .optional()
    .catch(undefined)
    .parse(data);
});

export const guestCheckoutCardHolderNameSelector = atom<string>((get) => {
  const firstName = get(textInputAtomFamily(guestCheckoutBillingAddressFormKeyMap.firstName));
  const lastName = get(textInputAtomFamily(guestCheckoutBillingAddressFormKeyMap.lastName));

  return `${firstName} ${lastName}`.trim();
});

export type GuestCheckoutTransaction = Extract<
  useCreateGuestCheckoutTransactionMutation$data['createGuestCheckoutTransaction'],
  { __typename: 'CreateGuestCheckoutTransactionSuccess' }
>;

/**
 * This atom will hold the response from the `createGuestCheckoutTransaction` mutation that happens
 * after clicking "continue" on the the Guest Checkout input page.
 */
export const guestCheckoutTransactionAtom = atom<GuestCheckoutTransaction | null>(null);

type GuestCheckoutCommitTransaction = Extract<
  useCommitGuestCheckoutTransactionMutation$data['commitGuestCheckoutTransaction'],
  { __typename: 'CommitGuestCheckoutTransactionSuccess' }
>;

/**
 * This atom will hold the response from the `commitGuestCheckoutTransaction` mutation that happens
 * after clicking "continue" on the interstitial page, which is right after the preview transaction.
 */
export const guestCheckoutCommitTransactionAtom = atom<GuestCheckoutCommitTransaction | null>(null);

const updateCheckoutTransactionUuid = () => {
  const createGuestCheckoutTransactionResponse = jotaiStore.get(guestCheckoutTransactionAtom);
  const commitGuestCheckoutTransactionResponse = jotaiStore.get(guestCheckoutCommitTransactionAtom);
  clientSessionIdStore.setGuestCheckoutTransactionUuid(
    commitGuestCheckoutTransactionResponse?.uuid ??
      createGuestCheckoutTransactionResponse?.transaction?.uuid ??
      undefined,
  );
};

jotaiStore.sub(guestCheckoutTransactionAtom, updateCheckoutTransactionUuid);
jotaiStore.sub(guestCheckoutCommitTransactionAtom, updateCheckoutTransactionUuid);

/**
 * The URL for the checkout.com iframe where the user goes to complete their transaction.
 */
export const checkoutComIframeUrlSelector = atom(
  (get) => get(guestCheckoutCommitTransactionAtom)?.paymentRedirectLink,
);

export const guestCheckoutPollTransactionResponseAtom = atom<GuestCheckoutTransactionPolled | null>(
  null,
);

export const isTokenExScriptLoadedAtom = atom<boolean>(false);

export const tokenExTokenizeDataAtom = atom<TokenizeData | undefined>(undefined);

export const cardDetailsFormStateKeys = {
  email: 'guestCheckout.cardDetails.email',
  nameOnCard: 'guestCheckout.cardDetails.nameOnCard',
  expiration: 'guestCheckout.cardDetails.expiration',
  zipcode: 'guestCheckout.cardDetails.zipcode',
  addressLine1: 'guestCheckout.cardDetails.addressLine1',
  addressLine2: 'guestCheckout.cardDetails.addressLine2',
  city: 'guestCheckout.cardDetails.city',
  state: 'guestCheckout.cardDetails.state',
} as const;

export const guestCheckoutCardDetailsSelector = atom((get) => {
  return {
    email: get(textInputAtomFamily(cardDetailsFormStateKeys.email)),
    nameOnCard: get(textInputAtomFamily(cardDetailsFormStateKeys.nameOnCard)),
    addressLine1: get(textInputAtomFamily(cardDetailsFormStateKeys.addressLine1)),
    addressLine2: get(textInputAtomFamily(cardDetailsFormStateKeys.addressLine2)),
    city: get(textInputAtomFamily(cardDetailsFormStateKeys.city)),
    expiration: get(textInputAtomFamily(cardDetailsFormStateKeys.expiration)),
    state: get(textInputAtomFamily(cardDetailsFormStateKeys.state)),
    zipcode: get(textInputAtomFamily(cardDetailsFormStateKeys.zipcode)),
  };
});

export const guestCheckoutCommitTransactionPartialInputSelector = atom((get) => {
  const uuid = get(guestCheckoutTransactionAtom)?.transaction.uuid;
  const networkName = get(guestCheckoutSelectedNetworkNameAtom);
  const assetSymbol = get(guestCheckoutSelectedAssetSymbolAtom);
  const cardHolderName = get(textInputAtomFamily(cardDetailsFormStateKeys.nameOnCard));
  const billingAddress = get(guestCheckoutFramesBillingAddressSelector);
  const email = get(textInputAtomFamily(cardDetailsFormStateKeys.email));

  return { uuid, networkName, assetSymbol, billingAddress, cardHolderName, email };
});

export const guestCheckoutCardDetailsErrorBannerDataAtom = atom<
  GuestCheckoutErrorBannerData | undefined
>(undefined);

export const guestCheckoutFramesBillingAddressSelector = atom((get) => {
  return {
    line1: get(textInputAtomFamily(cardDetailsFormStateKeys.addressLine1)),
    line2: get(textInputAtomFamily(cardDetailsFormStateKeys.addressLine2)),
    city: get(textInputAtomFamily(cardDetailsFormStateKeys.city)),
    state: get(textInputAtomFamily(cardDetailsFormStateKeys.state)),
    postalCode: get(textInputAtomFamily(cardDetailsFormStateKeys.zipcode)),
    country: {
      code: get(guestCheckoutCountryCode),
      name: '', // This field is required by GraphQL, but it's totally ignored by the BE.
    },
  };
});

export const guestCheckoutTargetAddressSelector = atom((get) => {
  const networkName = get(guestCheckoutSelectedNetworkNameAtom);
  const walletNetworkMap = get(guestCheckoutSessionAtom)?.walletNetworkMap ?? [];

  return (
    walletNetworkMap.filter(isNotNullish).find(({ network }) => network === networkName)?.address ??
    ''
  );
});

/**
 * A handy selector family to aggregate most of the input data needed to call the
 * CreateGuestCheckoutTransaction endpoint. The frames and non-frames flows get data from different
 * forms (card-details and billing-address pages respectively). This selector family abstracts that
 * logic away and consumers can simply spread this selector into the call to the endpoint.
 */
export const guestCheckoutCreateTransactionPartialInputSelectorFamily = atomFamily(
  ({ isPreview }: { isPreview: boolean }) =>
    atom(async (get) => {
      const networkName = get(guestCheckoutSelectedNetworkNameAtom);
      const targetAddress = get(guestCheckoutTargetAddressSelector);
      const fiatCurrency = get(fiatCurrencySelector) ?? 'USD';
      const { value, currency } = get(userAmountSelector);
      const userAmount = { value, currency };
      const cardHolderName = get(textInputAtomFamily(cardDetailsFormStateKeys.nameOnCard));
      const billingAddress = get(guestCheckoutFramesBillingAddressSelector);
      const selectedAssetSymbol = get(guestCheckoutSelectedAssetSymbolAtom);
      const email = get(textInputAtomFamily(cardDetailsFormStateKeys.email));

      /**
       * If the user deletes the input amount, it will be an empty string.
       * If this is a preview, we don't care about accurate Coinbase fees, we just need a proper
       * amount to get an error-free response.
       */
      if (userAmount.value === '' || isPreview) {
        if (!fiatCurrency) throw new Error('No fiat currency found for createTransaction input');
        userAmount.value = '1';
        userAmount.currency = fiatCurrency;
      }

      return {
        networkName,
        targetAddress,
        userAmount,
        cardHolderName,
        billingAddress,
        email,
        selectedAssetSymbol,
      };
    }),
  deepEqual,
);

export const hasGuestCheckoutCreateTransactionGenericErrorAtom = atom(false);

// Expose on the global object for access in tests.
if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') {
  Object.assign(globalThis, { isTokenExScriptLoadedAtom });
}
