import type { useCreateBuyQuoteMutation$data } from '@onramp/data/__generated__/useCreateBuyQuoteMutation.graphql';
import type { useCreateSendMutation$data } from '@onramp/data/__generated__/useCreateSendMutation.graphql';
import type { Amount, NetworkMetadata } from '@onramp/data/graphql-types';
import type { CommitBuySuccessResponse } from '@onramp/hooks/buy/useCommitBuy';
import type {
  CreateBuyPartialErrorResponse,
  CreateBuySuccessResponse,
} from '@onramp/hooks/buy/useCreateBuy';
import type { CommitSendSuccessResponse } from '@onramp/hooks/send/useCommitSend';
import type { CreateSendSuccessResponse } from '@onramp/hooks/send/useCreateSend';
import type { TabId } from '@onramp/pages/buy/select-asset/hooks/useSelectAssetTabs';
import type { Complete } from '@onramp/utils/types';
import { createForm } from '@cb/forms';

import type { SourceOfFundsData } from '../types/SourceOfFundsData';

export type CreateSendSuccessData = useCreateSendMutation$data['createSend'];

export type BuySuccessData = useCreateBuyQuoteMutation$data['createBuy'];

export type SendStatus = 'created' | 'canceled' | 'started' | 'delayed' | 'completed';

/** We don't have a graphql resolver that can lookup CBPayAsset entities. For now, we are storing
 * the network data in global state until we can remove it when the resolve is available. */
export type CBPayAssetNetworks = NetworkMetadata[];

/** Used to parse CBPayAsset networks and Retail network format to the same type */
export type ParsedNetworkFormat = {
  id: string;
  isDefault: boolean;
  name: string;
  assetImageUrl?: string;
  minimumSendCryptoAmount: number;
};

export type SourceOfFundsV2Type = {
  uuid: string;
  type: 'PAYMENT_METHOD' | 'FIAT_WALLET' | 'CRYPTO_ACCOUNT' | null;
};

export type OnrampInputSource =
  | 'manualAmount'
  | 'presetCrypto'
  | 'presetFiat'
  | 'preset1'
  | 'preset2'
  | 'preset3';

export type DepositInformationType = 'sepa' | 'fasterPayments';

export type BuyWidgetStateProps = {
  // Mutation responses
  commitBuyResponse?: CommitBuySuccessResponse;
  commitSendResponse?: CommitSendSuccessResponse;
  createBuyResponse?: CreateBuySuccessResponse | CreateBuyPartialErrorResponse;
  createSendResponse?: CreateSendSuccessResponse;

  exchangeRateCacheKey?: string;
  // Set from Input page
  fiatPerCoin: number;
  inputAmount: string;
  inputAmountType: 'fiat' | 'crypto';
  inputValues: {
    crypto: string;
    fiat: string;
  };
  isInputMaxed: boolean;
  sendsFeeEstimate?: {
    cryptoAmount: Amount;
    fiatAmount: Amount;
  };
  selectedNetwork?: ParsedNetworkFormat;

  polledSendStatus?: SendStatus;
  selectedAsset: SelectedAsset;

  /** Preserve the select asset tab in state so it will be consistent when navigating back to the page */
  selectAssetPageActiveTab?: TabId;

  // Initialized by SourceOfFunds component, set by select payment method and select coinbase balance screens.
  sourceOfFunds?: SourceOfFundsData;

  // Used for new SourcesOfFunds API
  sourceOfFundsV2?: SourceOfFundsV2Type;

  // SEPA or Faster Payments
  depositInformationType?: DepositInformationType;

  isRecoveringFromBuySuccessSendFailure: boolean;

  // New API Migration transaction uuid
  walletTransactionUuid?: string;
  walletTransactionNetworkAddressMap?: Record<string, string>;

  inputSource: OnrampInputSource;
};

// NOTE! Add to these initial values as needed. - Should be enforced by Complete<T> typing, but check to be sure.
// > Without adding the values the fields won't be initialized and onChange() will have no effect.
export const defaultValues: Complete<BuyWidgetStateProps> = {
  createBuyResponse: undefined,
  sendsFeeEstimate: undefined,
  exchangeRateCacheKey: '',
  createSendResponse: undefined,
  sourceOfFunds: undefined,
  sourceOfFundsV2: undefined,
  depositInformationType: undefined,
  isInputMaxed: false,
  inputValues: {
    fiat: '',
    crypto: '',
  },
  inputAmount: '',
  inputAmountType: 'fiat',
  fiatPerCoin: 0,
  selectedAsset: {
    assetUuid: '',
    accountUuid: '',
    ticker: '',
    name: '',
    imageUrl: '',
    exponent: 0,
  },
  selectedNetwork: undefined,
  polledSendStatus: undefined,
  commitBuyResponse: undefined,
  commitSendResponse: undefined,
  isRecoveringFromBuySuccessSendFailure: false,
  walletTransactionUuid: undefined,
  selectAssetPageActiveTab: undefined,
  walletTransactionNetworkAddressMap: undefined,
  inputSource: 'manualAmount',
};

export const BuyWidgetState = createForm({
  initialValues: defaultValues,
});

export type SelectedAsset = {
  assetUuid: string;
  accountUuid: string;
  ticker: string;
  name: string;
  imageUrl: string;
  exponent: number;
  networks?: CBPayAssetNetworks;
};
