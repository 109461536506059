import React, { useCallback, useLayoutEffect, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { Index } from 'react-virtualized';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import List from 'react-virtualized/dist/commonjs/List';
import { useWidgetParams } from '@onramp/components/AppManagerProvider';
import type { AssetListFragment$key } from '@onramp/data/__generated__/AssetListFragment.graphql';
import { useBuyWidgetState } from '@onramp/state/BuyWidgetState';
import { POPULAR_ASSET_SYMBOLS } from '@onramp/utils/consts';
import { isNotNullish } from '@onramp/utils/types';
import partition from 'lodash/partition';
import { Button } from '@cbhq/cds-web/buttons';
import { SpotRectangle } from '@cbhq/cds-web/illustrations';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextCaption, TextHeadline, TextLabel2 } from '@cbhq/cds-web/typography';
import { graphql, useFragment } from '@cbhq/data-layer';

import { filterCryptoAccountsByBalance } from '../fragments/filterCryptoAccountsByBalance';
import { readAccountViewerAssetUuid } from '../fragments/readAccountViewerAssetUuid';
import { readCBPayAssetAsOnrampStateWithAccountUuid } from '../fragments/readCBPayAssetAsOnrampState';
import { readCBPayAssetUuid } from '../fragments/readCBPayAssetUuid';
import { reduceCBPayAssetsBySearchTerm } from '../fragments/reduceCBPayAssetsBySearchTerm';
import { useFilterCBPayAssetsByDestinationWallets } from '../hooks/useFilterCBPayAssetsByDestinationWallets';
import type { TabId } from '../hooks/useSelectAssetTabs';
import type { SelectAssetCallbackProps } from '../index.page';

import { AssetListCell } from './AssetListCell';
import { CryptoAccountListCell } from './CryptoAccountListCell';

export const messages = defineMessages({
  mostPopular: {
    id: 'AssetList.mostPopular.regularCase',
    defaultMessage: 'Most popular',
    description: 'Tag for most popular assets',
  },
  allAssets: {
    id: 'AssetList.allAssets.regularCase',
    defaultMessage: 'All assets',
    description: 'Tag for all assets',
  },
  buyItAgain: {
    id: 'AssetList.buyItAgain.regularCase',
    defaultMessage: 'Buy it again',
    description: 'Tag for buy it again header',
  },
  noAssetFound: {
    id: 'AssetList.noAssetFound',
    defaultMessage: 'No asset found. Try searching for another asset.',
    description: 'Label shown in place of the asset list when no asset is found',
  },
  buyAnotherAsset: {
    id: 'AssetList.buyAnotherAsset',
    defaultMessage: 'Buy another asset',
    description: 'Label for button that switches users to the buy tab',
  },
  createTransferNetworkError: {
    id: 'AssetList.createTransferNetworkError',
    defaultMessage: 'Network request failed. Please try again.',
    description: 'Error message for when the network request failed',
  },
  genericError: {
    id: 'AssetList.genericError',
    defaultMessage: 'Something went wrong. Please try again.',
    description: 'Error message for something goes wrong.',
  },
  buyCrypto: {
    id: 'AssetList.buyCrypto',
    defaultMessage: 'Buy crypto',
    description: 'Label for button that switches users to the buy tab for the empty state',
  },
  noCryptoTitle: {
    id: 'AssetList.noCryptoTitle',
    defaultMessage: "You don't have crypto in your Coinbase account",
    description: 'Title for when users have no funds in their Coinbase account',
  },
  noCryptoSubtitle: {
    id: 'AssetList.noCryptoSubtitle',
    defaultMessage: "To transfer crypto to your wallet, you'll need to buy some first.",
    description: 'Subtitle for when users have no funds in their Coinbase account',
  },
});

export const assetListFragment = graphql`
  fragment AssetListFragment on Viewer @argumentDefinitions(filter: { type: CBPayAssetFilters }) {
    cbPayTradableAssets(filter: $filter) {
      edges {
        node {
          ...AssetListCellFragment
          ...readCBPayAssetAsOnrampStateFragment
          ...reduceCBPayAssetsBySearchTermFragment
          ...useFilterCBPayAssetsByDestinationWalletsFragment
          ...readCBPayAssetUuidFragment
          uuid
          symbol
          # We can just pass in a currency like "USD" since we're not displaying marketCap anywhere so it would have the same weighting across currencies.
          marketCap(quoteCurrency: "USD")
        }
      }
    }
    accountsV2 {
      edges {
        node {
          ...filterCryptoAccountsByBalanceFragment
          ...readAccountViewerAssetUuidFragment
          ...CryptoAccountListCellFragment
          uuid
          primary
          availableBalanceInNativeCurrency {
            value
          }
        }
      }
    }
  }
`;

export type AssetListProps = {
  viewerRef: AssetListFragment$key;
  onSelect: (data: SelectAssetCallbackProps) => Promise<void>;
  searchQuery?: string;
  activeTab?: TabId;
  activeTabOnChange: (activeTab: TabId) => void;
  onBuyAnotherAssetPressed?: () => void;
};

export const AssetList = ({
  onSelect,
  viewerRef,
  activeTab,
  activeTabOnChange,
  onBuyAnotherAssetPressed,
  searchQuery,
}: AssetListProps) => {
  const { selectedAsset } = useBuyWidgetState();
  const { defaultExperience } = useWidgetParams('buy');

  const filterAssetsByDestinationWallets = useFilterCBPayAssetsByDestinationWallets();
  const intl = useIntl();
  const { formatMessage } = intl;

  const data = useFragment(assetListFragment, viewerRef);

  const cryptoAccountUuids = useMemo(() => {
    const accounts = data.accountsV2?.edges.map((n) => n.node).filter(isNotNullish) || [];
    const accountMap = new Map<string, string>();
    accounts.forEach((account) => {
      const assetUuid = readAccountViewerAssetUuid(account);
      if (assetUuid !== undefined && account != null && account.primary) {
        accountMap.set(assetUuid, account.uuid);
      }
    });
    return accountMap;
  }, [data.accountsV2?.edges]);

  const rawAssetList = useMemo(() => {
    return data.cbPayTradableAssets?.edges?.map((e) => e?.node).filter(isNotNullish) || [];
  }, [data]);

  const accountMap = useMemo(() => {
    const accounts = data.accountsV2?.edges.map((n) => n.node).filter(isNotNullish) || [];

    const filteredAccounts = accounts.filter((account) => filterCryptoAccountsByBalance(account));
    return new Map(
      filteredAccounts
        .map((acc) => {
          const assetUuid = readAccountViewerAssetUuid(acc);

          if (!assetUuid) return undefined;

          return [assetUuid, acc] as const;
        })
        .filter(isNotNullish),
    );
  }, [data.accountsV2?.edges]);

  // Filter calculation based on values that should only happen on first render for optimizing the computation on search input updates.
  const filteredAssetsByConfig = useMemo(() => {
    return rawAssetList
      .filter(filterAssetsByDestinationWallets)
      .sort((a, b) => Number(b.marketCap) - Number(a.marketCap));
  }, [filterAssetsByDestinationWallets, rawAssetList]);

  const shouldShowOnlySendItems = useMemo(
    () => !searchQuery && activeTab === 'send',
    [activeTab, searchQuery],
  );

  const filteredAssetsByActiveTab = useMemo(() => {
    const orderedAssets = filteredAssetsByConfig.sort((a, b) => {
      // should not order by account balance if on buy tab & not searching
      if (activeTab === 'send' || Boolean(searchQuery)) {
        const uuidA = readCBPayAssetUuid(a);
        const accountA = uuidA && accountMap.get(uuidA);
        const uuidB = readCBPayAssetUuid(b);
        const accountB = uuidB && accountMap.get(uuidB);

        if (accountA && accountB) {
          return (
            (Number(accountB.availableBalanceInNativeCurrency?.value) || 0) -
            (Number(accountA.availableBalanceInNativeCurrency?.value) || 0)
          );
        }

        // if A has an account and B doesn't, A should come first
        if (accountA) {
          return -1;
        }

        // if A doesn't have an account and B does, A should come later
        if (accountB) {
          return 1;
        }
      }

      return Number(b.marketCap) - Number(a.marketCap);
    });

    if (!shouldShowOnlySendItems) {
      return orderedAssets;
    }

    return orderedAssets.filter((asset) => {
      const assetUuid = readCBPayAssetUuid(asset);
      return assetUuid && accountMap.has(assetUuid);
    });
  }, [accountMap, activeTab, filteredAssetsByConfig, searchQuery, shouldShowOnlySendItems]);

  // default to buy tab if user has no crypto and defaultExperience is not set
  useLayoutEffect(() => {
    if (shouldShowOnlySendItems && filteredAssetsByActiveTab.length === 0 && !defaultExperience) {
      activeTabOnChange('buy');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assetsAfterSearch = useMemo(
    () => reduceCBPayAssetsBySearchTerm(filteredAssetsByActiveTab, searchQuery),
    [filteredAssetsByActiveTab, searchQuery],
  );

  const [popularAssets, nonPopularAssets] = partition(assetsAfterSearch, (asset) =>
    asset.symbol ? POPULAR_ASSET_SYMBOLS.includes(asset.symbol) : false,
  );

  const handleSelectAsset = useCallback(
    async (uuid: string) => {
      const newAsset = filteredAssetsByConfig.find((asset) => asset.uuid === uuid);
      const accountUuid = cryptoAccountUuids.get(uuid);

      return onSelect({
        asset: readCBPayAssetAsOnrampStateWithAccountUuid(newAsset, accountUuid),
      });
    },
    [filteredAssetsByConfig, onSelect, cryptoAccountUuids],
  );

  const virtualizedListRows = useMemo((): VirtualizedListRow[] => {
    const extraSpacingAmount = 3;
    const getListHeader = (content: string, extraSpacing = false) => ({
      element: (
        <TextCaption
          spacingStart={3}
          spacingTop={extraSpacing ? extraSpacingAmount : undefined}
          as="p"
          color="foregroundMuted"
          transform="uppercase"
        >
          {content}
        </TextCaption>
      ),
      height: extraSpacing ? 20 + extraSpacingAmount * 8 : 20,
    });

    const shouldShowBuyLink = activeTab === 'send' && Boolean(searchQuery);
    const getAssetListCell = (asset: typeof popularAssets[number]) => {
      const shouldShowCryptoAccountListCell = Boolean(searchQuery) || activeTab === 'send';

      const assetUuid = readCBPayAssetUuid(asset);
      const account = shouldShowCryptoAccountListCell && assetUuid && accountMap.get(assetUuid);

      return {
        element: account ? (
          <CryptoAccountListCell
            key={account.uuid}
            fragmentRef={account}
            onPress={handleSelectAsset}
            isSelected={account.uuid === selectedAsset.value.assetUuid}
            isEnabled
          />
        ) : (
          <AssetListCell
            assetRef={asset}
            onPress={handleSelectAsset}
            isSelected={asset.uuid === selectedAsset.value.assetUuid}
            showBuyLink={shouldShowBuyLink}
          />
        ),
        height: 80,
      };
    };

    const shouldShowPopularHeader = popularAssets.length > 0 && activeTab === 'buy';
    const shouldShowRegularHeader = nonPopularAssets.length > 0 && activeTab === 'buy';
    const shouldShowBuyAnotherAssetButton = shouldShowOnlySendItems;
    const shouldShowEmptyState = shouldShowOnlySendItems && filteredAssetsByActiveTab.length === 0;

    return [
      ...(shouldShowPopularHeader ? [getListHeader(formatMessage(messages.mostPopular))] : []),
      ...popularAssets.map(getAssetListCell),
      ...(shouldShowRegularHeader
        ? [getListHeader(formatMessage(messages.allAssets), shouldShowPopularHeader)]
        : []),
      ...nonPopularAssets.map(getAssetListCell),
      ...(shouldShowEmptyState ? [{ element: <SendEmptyState />, height: 280 }] : []),
      ...(shouldShowBuyAnotherAssetButton
        ? [
            {
              element: (
                <Box spacingHorizontal={2} spacingTop={2}>
                  {}
                  <Button variant="secondary" onPress={onBuyAnotherAssetPressed} block>
                    {filteredAssetsByActiveTab.length > 0
                      ? formatMessage(messages.buyAnotherAsset)
                      : formatMessage(messages.buyCrypto)}
                  </Button>
                </Box>
              ),
              height: 72,
            },
          ]
        : []),
    ];
  }, [
    activeTab,
    searchQuery,
    popularAssets,
    nonPopularAssets,
    shouldShowOnlySendItems,
    filteredAssetsByActiveTab,
    formatMessage,
    onBuyAnotherAssetPressed,
    accountMap,
    handleSelectAsset,
    selectedAsset.value.assetUuid,
  ]);

  const getDynamicRowHeight = useCallback(
    ({ index }: Index) => virtualizedListRows[index].height,
    [virtualizedListRows],
  );

  const rowRenderer = useCallback<List['props']['rowRenderer']>(
    ({ index, key, style }) => (
      <div key={key} style={style}>
        {virtualizedListRows[index].element}
      </div>
    ),
    [virtualizedListRows],
  );

  return (
    <VStack overflow="hidden" height="100%">
      <VStack flexGrow={1} overflow="auto" width="100%" spacingTop={2}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              rowCount={virtualizedListRows.length}
              rowHeight={getDynamicRowHeight}
              rowRenderer={rowRenderer}
            />
          )}
        </AutoSizer>
        {assetsAfterSearch.length === 0 && filteredAssetsByActiveTab.length > 0 ? (
          <TextLabel2
            as="p"
            color="foregroundMuted"
            spacing={3}
            align="center"
            testID="no-asset-found-text"
          >
            {formatMessage(messages.noAssetFound)}
          </TextLabel2>
        ) : null}
      </VStack>
    </VStack>
  );
};

function SendEmptyState() {
  const { formatMessage } = useIntl();
  return (
    <VStack alignItems="center" spacing={3} spacingBottom={2} testID="send-tab-empty-state">
      <SpotRectangle name="transferCoins" />
      <TextHeadline as="p" spacingTop={3}>
        {formatMessage(messages.noCryptoTitle)}
      </TextHeadline>
      <TextBody as="p" align="center" spacingTop={1}>
        {formatMessage(messages.noCryptoSubtitle)}
      </TextBody>
    </VStack>
  );
}

type VirtualizedListRow = {
  element: JSX.Element;
  height: number;
};
