import { useCallback, useEffect } from 'react';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useOnRampRouter } from '@onramp/hooks/useOnRampRouter';
import { isBaseKilledAtom } from '@onramp/state/killSwitches/killSwitchesState';
import exitWidget from '@onramp/utils/exitWidget';
import { useIsGuestCheckoutPath } from '@onramp/utils/guestCheckoutUtils';
import { useKillSwitches } from '@onramp/utils/killswitches/useKillSwitches';
import { isEmbeddedExperience, isMobileExperience } from '@onramp/utils/postMessage';
import { useSetAtom } from 'jotai';
import { Button } from '@cbhq/cds-web/buttons';
import { HeroSquare } from '@cbhq/cds-web/illustrations';
import { VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { PageWrapper } from './PageWrapper';

export default function KillSwitchGuard({ children }: { children: ReactNode }) {
  const switches = useKillSwitches();
  const isGuestCheckout = useIsGuestCheckoutPath();
  const isBaseKilled = isGuestCheckout
    ? switches.kill_cbpay_guest_base_support
    : switches.kill_cbpay_base_support;
  const setIsBaseKilled = useSetAtom(isBaseKilledAtom);

  useEffect(() => {
    setIsBaseKilled(isBaseKilled);
  }, [isBaseKilled, setIsBaseKilled]);

  return switches.kill_cb_pay ? <CBPayIsKilled /> : <>{children}</>;
}

const messages = defineMessages({
  headerText: {
    id: 'KillSwitchGuard.headerText',
    defaultMessage: 'Coinbase Onramp',
    description: 'Header text',
  },
  disabledMessageTitle: {
    id: 'KillSwitchGuard.disabledMessageTitle',
    defaultMessage: 'Coinbase Onramp is temporarily unavailable',
    description: 'Title to message indicating Coinbase Onramp is down.',
  },
  // TODO [ONRAMP-2298]: update this message after exchange downtime has passed (March 4th)
  disabledMessage: {
    id: 'KillSwitchGuard.disabledMessage',
    defaultMessage:
      "We're upgrading our system right now, so buying and transferring crypto is temporarily unavailable. We plan to be finished by 4pm PT but check our status page for details.",
    description: 'Message indicating Coinbase Onramp is temporarily unavailable.',
  },
  checkStatus: {
    id: 'KillSwitchGuard.checkStatus',
    defaultMessage: 'Check status',
    description: 'button text',
  },
});

function CBPayIsKilled() {
  const { formatMessage } = useIntl();
  const { goBack } = useOnRampRouter();
  const handleOnBack = useCallback(() => {
    const isMobileOrEmbedded = isEmbeddedExperience() || isMobileExperience();

    if (isMobileOrEmbedded) {
      exitWidget();
    } else {
      goBack();
    }
  }, [goBack]);

  return (
    <PageWrapper headerText={formatMessage(messages.headerText)} onBack={handleOnBack}>
      <VStack alignItems="center" spacing={3}>
        <HeroSquare name="errorWeb400" />
        <TextTitle3 as="div" align="center" spacingBottom={2}>
          {formatMessage(messages.disabledMessageTitle)}
        </TextTitle3>
        <TextBody as="div" align="center" spacingBottom={2}>
          {formatMessage(messages.disabledMessage)}
        </TextBody>
        <Button
          href="https://status.coinbase.com/?utm_source=Iterable&utm_medium=email&utm_campaign=campaign_6097404"
          variant="secondary"
        >
          {formatMessage(messages.checkStatus)}
        </Button>
      </VStack>
    </PageWrapper>
  );
}
