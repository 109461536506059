import type { MessageCode as PublicMessageCode, MessageData } from '@cbhq/onramp-sdk';
import {
  // eslint-disable-next-line no-restricted-imports
  broadcastEvent as sdkBroadcastEvent,
  // eslint-disable-next-line no-restricted-imports
  broadcastPostMessage as sdkBroadcastPostMessage,
  getSdkTarget,
  onBroadcastedPostMessage,
} from '@cbhq/onramp-sdk';

import { host } from './environment/clientEnv';
import { clientSessionIdStore } from './clientSessionIdStore';

type MessageCode = PublicMessageCode;

type OnBroadcastPostMessageParameters = Parameters<typeof onBroadcastedPostMessage>;

export const guestCheckoutRedirectSuccess = 'guest-checkout.redirect-success';

type BroadCastEventName =
  | typeof guestCheckoutRedirectSuccess
  | Parameters<typeof sdkBroadcastPostMessage>[1];

type BroadcastPostMessageFunc = (
  win: Window,
  eventName: BroadCastEventName,
  options?: Parameters<typeof sdkBroadcastPostMessage>[2],
) => ReturnType<typeof sdkBroadcastPostMessage>;

export const broadcastPostMessage: BroadcastPostMessageFunc = (win, eventName, options) => {
  const target = getSdkTarget(win) ?? window;

  // TODO [ONRAMP-2413]: Update broadcastPostMessage type from public repo to accept GC message.
  // @ts-expect-error We know that type BroadCastEventName isn't the same signature as this.
  sdkBroadcastPostMessage(target, eventName, options);
};

// Used for receiving post messages between button <> standalone experience
export const onSecurePostMessage = (
  eventName: MessageCode | typeof guestCheckoutRedirectSuccess,
  onMessage: OnBroadcastPostMessageParameters[1]['onMessage'],
  shouldUnsubscribe?: boolean,
) => {
  // TODO [ONRAMP-2413]: Update broadcastPostMessage type from public repo to accept GC message.
  // @ts-expect-error We know that type BroadCastEventName isn't the same signature as this.
  return onBroadcastedPostMessage(eventName, { onMessage, allowedOrigin: host, shouldUnsubscribe });
};

// Used for sending post messages between button <> standalone experience
export const sendSecurePostMessage = (
  win: Window,
  eventName: MessageCode | BroadCastEventName,
  data?: MessageData,
) => {
  broadcastPostMessage(win, eventName, { data, allowedOrigin: host });
};

export const postMessageToSdk = (eventName: MessageCode, data?: MessageData) => {
  broadcastPostMessage(window, eventName, { data });
};

type BroadcastEvent = Parameters<typeof sdkBroadcastEvent>[1];

export const broadcastEvent = (event: BroadcastEvent) => {
  const target = getSdkTarget(window) ?? window;
  sdkBroadcastEvent(target, event);
};

// TODO [ONRAMP-1778]: move type to SDK
type CBPayErrorSchema = {
  // TODO [ONRAMP-1779]: categorize these better like Plaid https://plaid.com/docs/errors/#Error-error-type
  errorType: 'internal_error' | 'handled_error' | 'network_error';
  code?: string;
  debugMessage?: string;
};

export const broadcastErrorEvent = (error: CBPayErrorSchema) => {
  broadcastEvent({
    eventName: 'error',
    error: {
      ...error,
      sessionId: clientSessionIdStore.getClientSessionId(),
    },
  });
};

export const isEmbeddedExperience = () => {
  return getSdkTarget(window) === window.parent;
};

export const isMobileExperience = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Boolean((window as any).ReactNativeWebView);
};

export const isSecureStandaloneExperience = () => {
  return getSdkTarget(window) === (window.opener as Window);
};

// Re-export types
export type { MessageCode, MessageData, PublicMessageCode };
export { onBroadcastedPostMessage };
