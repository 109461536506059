import { z } from 'zod';

import { AnyWidgetParametersSchema } from './appParams.schema';

export const initiateSessionHandlerPayloadSchema = z.object({
  isMobile: z.boolean().optional(),
  isEmbedded: z.boolean().optional(),
  appParams: AnyWidgetParametersSchema.optional(),
});

export type InitiateSessionHandlerPayload = z.infer<typeof initiateSessionHandlerPayloadSchema>;

export const initiateSessionHandlerResponseSchema = z.object({
  /**
   * Whether or not the user is considered as authenticated. This will always require a valid access token, and may
   * additionally require a valid Coinbase session, as validated via the GSSC cookie:
   * - If the user is not on mobile and is in `mar_2023_cbpay_require_gssc_auth`'s treatment, GSSC validation is required
   * - If the user is on mobile and is coming back from a WSST redirect, GSSC validation is required
   * - Otherwise, it's not required
   */
  isAuthenticated: z.boolean(),
  /**
   * Identifies if the access token was generated from legacy or UL
   */
  authenticationProvider: z.enum(['legacy', 'unified-login']).optional().catch(undefined),
  /**
   * Whether or not the user has a valid GSSC cookie. If true, that means the user has a GSSC cookie that proves they're
   * authenticated to Coinbase with the same account they're authenticated to CBPay with. It's included in the payload to
   * gate the U2FA experiment
   */
  isGsscValid: z.boolean(),
  /**
   * If the user has a valid access token, no valid GSSC cookie, and GSSC validation is required, we'll try to
   * generate a WSST URL to redirect them to. That should bootstrap a Coinbase session through an access token
   */
  wsstUrl: z.string().url().optional().catch(undefined),
});

export type InitiateSessionHandlerResponse = z.infer<typeof initiateSessionHandlerResponseSchema>;
export type AuthenticationProvider = NonNullable<
  InitiateSessionHandlerResponse['authenticationProvider']
>;
