import type { ErrorState, HandledErrorCode, HandledErrorState } from './errors';
import { BaseError, mergeErrorMetadata } from './errors';

/**
 * Used to report an error which has been handled. Should always be passed to `reportError` or `reportAndViewError` immediately after instantiation.
 *
 * The `handledError` property should never be another instance of `HandledError`, because _that_ instance should have been reported & not sent downstream.
 */
export class HandledError extends BaseError implements HandledErrorState {
  code?: HandledErrorCode;

  handledError?: Error | undefined;

  constructor({ message, code, debugMessage, handledError }: Omit<HandledErrorState, 'type'>) {
    super(message, { cause: handledError, debugMessage });
    this.code = code;
    this.handledError = handledError;
  }

  // eslint-disable-next-line class-methods-use-this
  public get type() {
    return 'handled' as const;
  }

  public asErrorState(): ErrorState {
    const errorState: ErrorState = {
      type: 'handled' as const,
      source: this.getSource(),
      message: this.message,
      metadata: mergeErrorMetadata(this.getMetadata(), {
        type: 'handled' as const,
      }),
      ...this.handlingParams,
    };

    if (this.code !== undefined) {
      errorState.code = this.code;
    }

    if (this.debugMessage !== undefined) {
      errorState.debugMessage = this.debugMessage;
    }

    if (this.handledError !== undefined) {
      errorState.handledError = this.handledError;
    }

    return errorState;
  }
}
