export const targetEnvironmentMap = {
  production: 'production',
  staging: 'staging',
  development: 'development',
  local: 'local',
  test: 'test',
} as const;

export type TargetEnvironment = keyof typeof targetEnvironmentMap;

/**
 * nodeEnv gets populated from either the client-side value or the server-side value.
 * Therefore it's safe to be in the shared file.
 */
const nodeEnv = (process.env.NEXT_PUBLIC_NODE_ENV || process.env.NODE_ENV) as TargetEnvironment;

export const isTestEnvironment = nodeEnv === 'test';

/**
 * This variable will be 'true' so long as you're using localhost or 127.0.0.1 - even if you spin
 * up the app against production.
 */
export const isLocalDevelopment = process.env.NEXT_PUBLIC_USE_LOCAL_CERTIFICATES === 'true';

export const isServer = () => typeof window === 'undefined';

/**
 * This is based on nodeEnv which is a shared value.
 */
const defaultEnvironment =
  nodeEnv !== 'production' ? targetEnvironmentMap.development : targetEnvironmentMap.production;

export const targetEnvironment =
  (process.env.NEXT_PUBLIC_TARGET_ENV as TargetEnvironment) || defaultEnvironment;

export const releaseStage = targetEnvironmentMap[targetEnvironment];

export const isProduction = targetEnvironment === targetEnvironmentMap.production;

/**
 * Is local dev OR Coinbase dev.
 */
export const isDevelopment =
  targetEnvironment === targetEnvironmentMap.local ||
  targetEnvironment === targetEnvironmentMap.development;

// This overrides client-analytics to use the dev environment, useful for when running localhost against prod
export const analyticsDevOverride = process.env.NEXT_PUBLIC_ANALYTICS_DEV_OVERRIDE === 'true';

// This enables client-analytics debug printing so you can see what metrics are being logged
export const enableAnalyticsDebug =
  process.env.NEXT_PUBLIC_ANALYTICS_ENABLE_DEBUG === 'true' || !isProduction;

// This controls wether busgnag is enabled or not, allowing us to avoid bugsnag errors when running local-production
export const bugsnagEnabled = process.env.NEXT_PUBLIC_BUGSNAG_ENABLE === 'true';

export const smartlingProjectId = process.env.NEXT_PUBLIC_SMARTLING_PROJECT_ID;

export const smartlingServiceIdentifier = process.env.NEXT_PUBLIC_SMARTLING_SERVICE_IDENTIFIER;
