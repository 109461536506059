import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useSplashScreenContext } from '@onramp/components/SplashScreen/context';
import { useOnRampRouter } from '@onramp/hooks/useOnRampRouter';
import type {
  BaseError,
  ErrorLevel,
  ReportAndViewError,
  ReportAndViewErrorFunc,
} from '@onramp/utils/errors';
import { canRetryError, reportError } from '@onramp/utils/errors';
import type { ErrorHandlingParameters } from '@onramp/utils/errors/types';
import { useLogOnrampEvent } from '@onramp/utils/eventing/useLogOnrampEvent';
import { genericMessages } from '@onramp/utils/genericMessages';

import { useResetRelayEnvironment } from '../AuthedGraphqlProvider/AuthedGraphqlProviderContext';

import { GenericErrorScreen } from './GenericErrorScreen';

type ErrorHandlerProviderProps = {
  level: ErrorLevel;
  children: ReactNode;
};

export const ErrorHandlerProvider = ({ children, level }: ErrorHandlerProviderProps) => {
  const [error, setError] = useState<BaseError | undefined>(undefined);
  const splashScreenContext = useSplashScreenContext();

  // Side effects per error type
  useEffect(() => {
    if (error) {
      error.addDefaultHandlingParams().addMetadata({
        boundary_level: level,
      });

      reportError(error, {
        userFacing: true,
      });
    }
    // Only trigger when error state changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const reportAndViewError = useCallback<ReportAndViewErrorFunc>(
    (errorArg) => {
      if (splashScreenContext.isVisible) {
        splashScreenContext.dismissSplashScreen();
      }
      setError(errorArg);
    },
    [splashScreenContext],
  );

  const handleResetError = useCallback(() => {
    setError(undefined);
  }, []);

  const value = useMemo(() => {
    return { reportAndViewError, resetError: handleResetError };
  }, [handleResetError, reportAndViewError]);

  return (
    <ErrorHandlerProviderContext.Provider value={value}>
      <ErrorHandlerView error={error} onResetError={handleResetError} level={level}>
        {children}
      </ErrorHandlerView>
    </ErrorHandlerProviderContext.Provider>
  );
};

type ErrorHandlerViewProps = {
  error?: BaseError;
  level: ErrorLevel;
  onResetError: () => void;
};

const ErrorHandlerView: React.FC<ErrorHandlerViewProps> = ({
  children,
  error,
  level,
  onResetError,
}) => {
  // This is only provided to 'screen' level errors due to the composition of WidgetProvider
  const resetRelayEnvironment = useResetRelayEnvironment();
  const router = useOnRampRouter();

  const { formatMessage } = useIntl();

  const canTryAgain = useMemo(() => canRetryError(error), [error]);

  const errorState = useMemo(() => error?.asErrorState(), [error]);

  const {
    pageTitle,
    showError,
    title,
    message,
    richMessage,
    pictogramName,
    showExitButton,
    retryButtonTitle,
    secondaryButtonTitle,
    onSecondaryAction,
  } = useMemo<
    {
      showError: boolean;
    } & Partial<ErrorHandlingParameters>
  >(() => {
    return {
      showError: Boolean(errorState),
      ...error?.handlingParams,
      ...(errorState?.type === 'handled' && { message: errorState.message }),
    };
  }, [errorState, error]);

  const logOnrampEvent = useLogOnrampEvent();

  const handleNavigateBack = useCallback(() => {
    if (errorState?.backType === 'back' && errorState?.goBackTo) {
      return router.replace(errorState.goBackTo).then(onResetError);
    }
    if (errorState?.backType === 'back') {
      router.goBack();
    }

    // Dismiss error dialog
    return onResetError();
  }, [errorState, onResetError, router]);

  const onTryAgain = useMemo(() => {
    if (typeof error?.handlingParams.onTryAgain === 'function') {
      return () => {
        logOnrampEvent('error_handler_try_again_handling_params_function');
        /**
         * onTryAgain is a promise. Wait for it to resolve before clearing error,
         *   otherwise you might remount a component even though you're trying to
         *   navigate away from it.
         */
        error?.handlingParams.onTryAgain?.()?.finally(() => {
          onResetError();
        });
      };
    }
    if (error?.handlingParams.showTryAgainToDismiss) {
      return () => {
        logOnrampEvent('error_handler_try_again_show_try_again_to_dismiss');
        onResetError();
      };
    }
    if (canTryAgain) {
      return () => {
        logOnrampEvent('error_handler_try_again_can_try_again');
        resetRelayEnvironment();
      };
    }

    // Does not render try again button
    return undefined;
  }, [error?.handlingParams, canTryAgain, logOnrampEvent, onResetError, resetRelayEnvironment]);

  if (showError) {
    return (
      <GenericErrorScreen
        modalTitle={pageTitle}
        title={title || formatMessage(genericMessages.somethingWentWrong)}
        subtitle={richMessage || message || formatMessage(genericMessages.pleaseTryAgainLater)}
        onBack={
          level !== 'root' && errorState?.backType !== 'none' ? handleNavigateBack : undefined
        }
        pictogramName={pictogramName}
        retryButtonTitle={retryButtonTitle}
        onTryAgain={onTryAgain}
        showExitButton={showExitButton}
        secondaryButtonTitle={secondaryButtonTitle}
        onSecondaryAction={onSecondaryAction}
      />
    );
  }

  return <>{children}</>;
};

export const ErrorHandlerProviderContext = createContext<ReportAndViewError | undefined>(undefined);
