import type { userEmailSelectorQuery$data } from '@onramp/data/__generated__/userEmailSelectorQuery.graphql';
import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import { graphql } from '@cbhq/data-layer';

import { atomWithQueryAndMap } from '../utils';

const query = graphql`
  query userEmailSelectorQuery {
    viewer {
      userProperties {
        email
      }
    }
  }
`;

export const userEmailSelector = atomWithQueryAndMap({
  query,
  variables: () => ({}),
  mapResponse: (data: userEmailSelectorQuery$data) => data.viewer.userProperties.email,
});

export const userEmailLoadable = loadable(userEmailSelector);

export const useUserEmail = () => useAtomValue(userEmailSelector);
export const useUserEmailLoadable = () => useAtomValue(userEmailLoadable);
