import semver from 'semver';

import { getMobileAppVersion, isCBWalletApp } from './appVersion';
import { isMobileExperience } from './postMessage';

export function getIsCBWalletAppWithPolicyRestrictionSupport(): boolean {
  if (!isCBWalletApp() || !isMobileExperience()) return false;

  const versionString = getMobileAppVersion();

  if (!versionString) {
    return false;
  }

  // NOTE: CB Wallet App versions 28.33.0+ support handling policy restrictions passed by widget
  return semver.gte(versionString, '28.33.0');
}
