import { defineMessages, useIntl } from 'react-intl';
import type { AssetListCellFragment$key } from '@onramp/data/__generated__/AssetListCellFragment.graphql';
import { useReffedFunction } from '@onramp/utils/useReffedFunction';
import { CellMedia, ListCell } from '@cbhq/cds-web/cells';
import { TextHeadline } from '@cbhq/cds-web/typography';
import { graphql, useFragment } from '@cbhq/data-layer';

const assetListCellFragment = graphql`
  fragment AssetListCellFragment on CBPayAsset {
    uuid
    name
    symbol
    imageUrl
  }
`;

type AssetListCellProps = {
  assetRef: AssetListCellFragment$key;
  isSelected: boolean;
  onPress: (uuid: string) => Promise<void>;
  showBuyLink?: boolean;
};

const messages = defineMessages({
  buyLink: {
    id: 'AssetListCell.buyLink',
    defaultMessage: 'Buy',
    description:
      'Label added to the end of the AssetListCell making it clear that the current asset will be bought, not sent',
  },
});

export const AssetListCell = ({
  assetRef,
  onPress,
  isSelected,
  showBuyLink,
}: AssetListCellProps) => {
  const { formatMessage } = useIntl();
  const asset = useFragment(assetListCellFragment, assetRef);
  const { name, symbol, imageUrl, uuid } = asset;

  const handleOnPress = useReffedFunction(async () => onPress(uuid || ''));

  return (
    <ListCell
      testID={`select-asset-${symbol}`}
      title={name}
      description={symbol}
      onPress={handleOnPress}
      selected={isSelected}
      media={<CellMedia type="asset" title={symbol || ''} source={imageUrl || ''} />}
      action={
        showBuyLink ? (
          <TextHeadline as="span" color="primary">
            {formatMessage(messages.buyLink)}
          </TextHeadline>
        ) : undefined
      }
    />
  );
};
