/**
 * @generated SignedSource<<4ff9604c02e6603ce2ffda258301449e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type appParamsStateNetworksQuery$variables = {};
export type appParamsStateNetworksQuery$data = {
  readonly viewer: {
    readonly cbPayTradableAssets: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly networks: ReadonlyArray<{
            readonly displayName: string | null;
            readonly isDefault: boolean | null;
            readonly minSend: string | null;
            readonly name: string | null;
          } | null> | null;
          readonly symbol: string | null;
        } | null;
      } | null> | null;
    } | null;
  };
};
export type appParamsStateNetworksQuery = {
  response: appParamsStateNetworksQuery$data;
  variables: appParamsStateNetworksQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "networks": ([]/*: any*/),
      "tickers": ([]/*: any*/)
    }
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "symbol",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "NetworkMetadata",
  "kind": "LinkedField",
  "name": "networks",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isDefault",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minSend",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "appParamsStateNetworksQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "CbPayAssetConnection",
            "kind": "LinkedField",
            "name": "cbPayTradableAssets",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CBPayAssetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CBPayAsset",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "cbPayTradableAssets(filter:{\"networks\":[],\"tickers\":[]})"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "appParamsStateNetworksQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v0/*: any*/),
            "concreteType": "CbPayAssetConnection",
            "kind": "LinkedField",
            "name": "cbPayTradableAssets",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CBPayAssetEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CBPayAsset",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "cbPayTradableAssets(filter:{\"networks\":[],\"tickers\":[]})"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07b6753bad884052b12e9a36a591332c",
    "id": null,
    "metadata": {},
    "name": "appParamsStateNetworksQuery",
    "operationKind": "query",
    "text": "query appParamsStateNetworksQuery {\n  viewer {\n    cbPayTradableAssets(filter: {networks: [], tickers: []}) {\n      edges {\n        node {\n          symbol\n          networks {\n            name\n            displayName\n            isDefault\n            minSend\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "dbb2b829ffdbad0a81394340b4a5a196";

export default node;
