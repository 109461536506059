import { useOpenUrl } from '@onramp/hooks/utils/useOpenUrl';

import { isMobileExperience } from '../../utils/postMessage';

export function useHandleAddPaymentMethodInRetail() {
  const openUrl = useOpenUrl();
  /**
   * If in a WebView, open URL in the current browsing context; otherwise, in a new tab or window.
   * The special behavior when in a WebView aims to allow Android WebViews to intercept the navigation and provide an alternative experience.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target}
   * @see {@link https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#setSupportMultipleWindows}
   * */
  const url = `${process.env.NEXT_PUBLIC_COINBASE_BASE_URL}/settings/linked-accounts?action=add-payment-method`;

  return () => {
    if (isMobileExperience()) {
      window.open(url, '_self');
      return;
    }

    openUrl(url);
  };
}
