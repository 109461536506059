import type { WidgetKey } from '@onramp/shared/appParams.schema';
import { BuyWidgetSchema, WidgetKeySchema } from '@onramp/shared/appParams.schema';
import { QueryParamsSchema } from '@onramp/shared/queryParams.schema';
import { getQueryParams, getQueryParamsObjectFromZodSchema } from '@onramp/utils/queryParams';
import { searchParamsToObject } from '@onramp/utils/searchParamsToObject';
import type { ZodError } from 'zod';
import { z } from 'zod';
import type { MessageData } from '@cbhq/onramp-sdk';

import type { InitMethodType } from './types';

type ParsedResponse<T> = {
  success: boolean;
  error?: z.ZodError;
  data: T;
};

export type ParsedQueryParams = z.infer<typeof QueryParamsSchema>;

export const parseQueryParams = (): ParsedResponse<ParsedQueryParams> => {
  const query = searchParamsToObject(getQueryParams());

  const errorFallbackData = {
    type: QueryParamsSchema.shape.type._def.defaultValue(),
    target: QueryParamsSchema.shape.target._def.defaultValue(),
  };

  const queryParamsParseResult = QueryParamsSchema.safeParse(query);
  if (!queryParamsParseResult.success) {
    return {
      success: queryParamsParseResult.success,
      error: queryParamsParseResult.error,
      data: errorFallbackData,
    };
  }

  // verify sessionToken or appId
  const secureInitParamsParseResult = z
    .object({
      appId: z.string(),
    })
    .or(z.object({ sessionToken: z.string() }))
    .safeParse(query);
  if (!secureInitParamsParseResult.success) {
    return {
      success: secureInitParamsParseResult.success,
      data: errorFallbackData,
    };
  }

  return {
    success: queryParamsParseResult.success,
    data: {
      ...queryParamsParseResult.data,
      ...secureInitParamsParseResult.data,
    },
  };
};

export const getErrorMessageFromSchema = (error?: ZodError): string => {
  const zodIssues = error && JSON.stringify(error.issues, undefined, 2);
  return `Invalid app parameter(s)${zodIssues ? `\n"${zodIssues}"` : ''}`;
};

type InitQueryParamsType = Extract<InitMethodType, 'query_params'>;

export const initializeUsingQueryParams = (
  /** query params parsed as a `Record`, like the result of `getQueryParamsAsRecord` — needed for server support */
  queryParams: Record<string, unknown>,
): {
  data: MessageData;
  initMethod?: InitQueryParamsType;
} => {
  // Default to buy widget for old usages
  const widget: WidgetKey = WidgetKeySchema.catch('buy').parse(queryParams.widget);
  const schema = BuyWidgetSchema;
  const queryParamsObject = getQueryParamsObjectFromZodSchema(schema, queryParams);
  return {
    data: { ...queryParamsObject, widget },
    initMethod: 'query_params',
  };
};
