import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { logWidgetMetricOnce } from '@onramp/utils/metrics';
import { atom, useAtomValue } from 'jotai';
import { z } from 'zod';

import { jotaiStore } from './utils';

export const buyFlowTypeSchema = z
  .enum(['regular', 'one-click-buy', 'recent-transactions', 'guest-checkout'])
  .optional();

export type BuyFlowType = z.infer<typeof buyFlowTypeSchema>;

const privateBuyFlowTypeAtom = atom<BuyFlowType | undefined>(undefined);

export const buyFlowTypeAtom = atom(
  (get) => get(privateBuyFlowTypeAtom),
  (get, set, unparsedBuyFlowType: BuyFlowType) => {
    const buyFlowType = buyFlowTypeSchema.catch(undefined).parse(unparsedBuyFlowType);
    set(privateBuyFlowTypeAtom, buyFlowType);
  },
);

jotaiStore.sub(buyFlowTypeAtom, () => {
  const buyFlowType = jotaiStore.get(buyFlowTypeAtom);
  clientSessionIdStore.setBuyFlowType(buyFlowType);
  logWidgetMetricOnce({
    metricName: 'critical_step',
    value: 1,
    tags: { step: 'buy-flow-type-determined' },
  });
});

export const useIsBuyFlowType = (expectedBuyFlowType: BuyFlowType) =>
  useAtomValue(buyFlowTypeAtom) === expectedBuyFlowType;
