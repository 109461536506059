import { useSafeAppParams } from '@onramp/components/AppManagerProvider';
import type { WidgetKey } from '@onramp/shared/appParams.schema';

import { getUseCallHookFnOnMount } from '../getUseCallFnOnMount';
import { wrapHookWithCallOnce } from '../wrapWithCallOnce';

import type {
  CommonEventName,
  WidgetCommonEventOptionalParametersMapping,
} from './eventDeclarations';
import type { LogEventOptions } from './logEvent';
import { logEventOnceCacheMap } from './logEventOnce';
import { useLogEvent } from './useLogEvent';
// eslint-disable-next-line import/no-cycle
import { useLogOnrampEvent } from './useLogOnrampEvent';

export type BaseLogWidgetEventFunctionType<ParametersMapping> = <
  T extends keyof ParametersMapping & string,
>(
  eventName: T,
  ...rest: undefined extends ParametersMapping[T]
    ? [optionalEventParameters?: ParametersMapping[T], options?: LogEventOptions]
    : [optionalEventParameters: ParametersMapping[T], options?: LogEventOptions]
) => void;

type LogWidgetCommonEventFunctionType =
  BaseLogWidgetEventFunctionType<WidgetCommonEventOptionalParametersMapping>;

export const useLogWidgetEvent = (): LogWidgetCommonEventFunctionType => {
  /**
   * Jest does some magic stuff and `useLogOnrampEvent` is `undefined` when accessed in the root module, but it's defined inside of functions.
   * Just working around it for the time being, ain't nobody got no time for that
   */
  const hookMap: Record<WidgetKey | 'fallback', () => LogWidgetCommonEventFunctionType> = {
    buy: useLogOnrampEvent,
    fallback: useLogEvent,
  };

  const useHook = hookMap[useSafeAppParams()?.activeWidget ?? 'fallback'] ?? hookMap.fallback;
  return useHook();
};

export const useLogWidgetEventOnce = wrapHookWithCallOnce(useLogWidgetEvent, {
  cacheMap: logEventOnceCacheMap,
  argsToKeyFn: (eventName: CommonEventName) => eventName,
});

export const useLogWidgetEventOnMount = getUseCallHookFnOnMount(useLogWidgetEvent);
export const useLogWidgetEventOnceOnMount = getUseCallHookFnOnMount(useLogWidgetEventOnce);
