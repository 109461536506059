declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    ApplePaySession?: typeof ApplePaySession;
  }
}

// copied from https://github.cbhq.net/frontend/coinbase-www/blob/f1eaee4/app/src/utils/applePay.ts#L36-L48
export const checkIfApplePayIsSupported = () => {
  try {
    const ApplePaySession = typeof window !== 'undefined' ? window.ApplePaySession : undefined;
    if (!ApplePaySession) return false;

    return Boolean(ApplePaySession.supportsVersion(3) && ApplePaySession.canMakePayments());
  } catch {
    return false;
  }
};
