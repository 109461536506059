import React, { Component, useCallback } from 'react';
import type { WrappedComponentProps } from 'react-intl';
import { injectIntl } from 'react-intl';
import type { ErrorLevel, InternalError } from '@onramp/utils/errors';
import { canRetryError, coerceError, reportError } from '@onramp/utils/errors';
import { genericMessages } from '@onramp/utils/genericMessages';

import { useResetRelayEnvironment } from '../AuthedGraphqlProvider/AuthedGraphqlProviderContext';

import { GenericErrorScreen } from './GenericErrorScreen';

type ErrorBoundaryLevelProps = {
  level: ErrorLevel;
  onTryAgain?: (e?: Error) => void;
};

type ErrorBoundaryLevelState = {
  canRetry?: boolean;
  error?: InternalError;
};

// eslint-disable-next-line @typescript-eslint/naming-convention
class _ErrorBoundaryLevel extends Component<
  ErrorBoundaryLevelProps & WrappedComponentProps<'intl'>,
  ErrorBoundaryLevelState
> {
  state: ErrorBoundaryLevelState = {};

  // state
  static getDerivedStateFromError(error: Error) {
    const parsedError = coerceError(error);
    const canRetry = canRetryError(parsedError);
    return { canRetry, error: parsedError };
  }

  // Side effects - report error to bugsnag and analytics
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    const { level } = this.props;

    reportError(
      coerceError(error).addMetadata({
        boundary_level: level,
        componentStack: errorInfo.componentStack,
      }),
    );
  }

  clearError = () => {
    this.setState({
      canRetry: undefined,
      error: undefined,
    });
  };

  handleTryAgain = () => {
    const { error } = this.state;
    this.props.onTryAgain?.(error);
    this.clearError();
  };

  render() {
    const { children, intl, level } = this.props;
    const { canRetry, error } = this.state;
    const { formatMessage } = intl;

    const isRoot = level === 'root';

    if (!error) {
      return children;
    }

    const isHandled = error.type === 'handled';

    return (
      <GenericErrorScreen
        title={formatMessage(genericMessages.somethingWentWrong)}
        subtitle={isHandled ? error.message : formatMessage(genericMessages.pleaseTryAgainLater)}
        onTryAgain={isRoot || !canRetry ? undefined : this.handleTryAgain}
      />
    );
  }
}

const ErrorBoundaryLevel = injectIntl(_ErrorBoundaryLevel);

function ScreenErrorBoundary({ children, onTryAgain }: Omit<ErrorBoundaryProps, 'level'>) {
  // This is only provided to 'screen' level errors due to the composition of WidgetProvider
  const resetRelayEnvironment = useResetRelayEnvironment();

  const handleTryAgain = useCallback(() => {
    if (onTryAgain) {
      onTryAgain();
      return;
    }

    resetRelayEnvironment();
  }, [resetRelayEnvironment, onTryAgain]);

  return (
    <ErrorBoundaryLevel level="screen" onTryAgain={handleTryAgain}>
      {children}
    </ErrorBoundaryLevel>
  );
}

type ErrorBoundaryProps = ErrorBoundaryLevelProps & {
  children: React.ReactNode;
};

export function ErrorBoundary({ children, level, ...otherProps }: ErrorBoundaryProps) {
  if (level === 'screen') {
    return <ScreenErrorBoundary {...otherProps}>{children}</ScreenErrorBoundary>;
  }

  return (
    <ErrorBoundaryLevel level={level} {...otherProps}>
      {children}
    </ErrorBoundaryLevel>
  );
}
