/**
 * @generated SignedSource<<ed4a6455978e4b110039bd7da0f47684>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccountType = "COLLATERAL" | "DEFI_YIELD" | "FIAT" | "MULTISIG" | "MULTISIG_VAULT" | "STAKED_FUNDS" | "UNKNOWN" | "VAULT" | "WALLET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type readPrimaryCryptoAccountFromAccountsV2$data = {
  readonly accountsV2: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assetOrFiatCurrency: {
          readonly __typename: "ViewerAsset";
          readonly asset: {
            readonly uuid: string;
          };
        } | {
          // This will never be '%other', but we need some
          // value in case none of the concrete values match.
          readonly __typename: "%other";
        } | null;
        readonly availableBalanceInNativeCurrency: {
          readonly value: string;
        } | null;
        readonly type: AccountType;
        readonly uuid: string;
      } | null;
    }>;
  } | null;
  readonly " $fragmentType": "readPrimaryCryptoAccountFromAccountsV2";
};
export type readPrimaryCryptoAccountFromAccountsV2$key = {
  readonly " $data"?: readPrimaryCryptoAccountFromAccountsV2$data;
  readonly " $fragmentSpreads": FragmentRefs<"readPrimaryCryptoAccountFromAccountsV2">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readPrimaryCryptoAccountFromAccountsV2"
};

(node as any).hash = "32f7d80fc02c28b82cb698c1aab160aa";

export default node;
