import type { readCryptoAccountsFragment$key } from '@onramp/data/__generated__/readCryptoAccountsFragment.graphql';
import type { SourceOfFundsData } from '@onramp/state/types/SourceOfFundsData';
import { readCryptoAccount } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readCryptoAccount';
import type { SourceOfFundsToCommonFormatRequiredData } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/useSourceOfFundsToCommonFormatRequiredData';
import { isNonEmpty } from '@onramp/utils/isNonEmpty';
import { graphql, readInlineData } from '@cbhq/data-layer';

import { getCryptoValueFromAccount } from './utils';

const accountFragment = graphql`
  fragment readCryptoAccountsFragment on Account @inline {
    ...readCryptoAccountFragment
  }
`;

export function readCryptoAccounts({
  inputAccountsRef,
  readSourceOfFundsData,
  nativeAssetUuid,
}: {
  inputAccountsRef: readonly readCryptoAccountsFragment$key[];
  readSourceOfFundsData: SourceOfFundsToCommonFormatRequiredData;
  nativeAssetUuid: string;
}) {
  const cryptoAccounts = inputAccountsRef
    .map((ref) => readInlineData(accountFragment, ref))
    .map((account) =>
      readCryptoAccount({ inputAccountRef: account, nativeAssetUuid, ...readSourceOfFundsData }),
    )
    .filter(isNonEmpty);

  const primaryCryptoAccounts: SourceOfFundsData[] = cryptoAccounts
    .filter((acct) => acct.assetUuid === nativeAssetUuid)
    .sort((a, b) => getCryptoValueFromAccount(b) - getCryptoValueFromAccount(a));

  const primaryCryptoAccount: SourceOfFundsData | undefined = primaryCryptoAccounts[0];

  return { cryptoAccounts, primaryCryptoAccount };
}
