/** Returns true if session was deleted. */
export const deleteGuestSignupSession = async () => {
  try {
    // eslint-disable-next-line no-restricted-globals
    const deleteRes = await fetch(`${process.env.NEXT_PUBLIC_LOGIN_HOST}/api/v1/delete`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    });
    return deleteRes.ok;
  } catch {
    return false;
  }
};
