// eslint-disable-next-line no-restricted-imports
import type { Response } from '@onramp/server/types';
import { decode3dsCbpayQuery } from '@onramp/utils/3ds/decode3dsCbpayQuery';
import { coerceError } from '@onramp/utils/errors';
import { Routes } from '@onramp/utils/routes';
import type { AppContext } from 'next/app';

// Handle returning from the 3DS redirect. The cbpay_query is a base64 encoded query string.
// We expect to parse the query parameter like this:
// i.e. cbpay_query=bm9uY2U9MSZub25jZTNkcz0yMjImYXBwSWQ9d2FsbGV0 === nonce=1&nonce3ds=222&appId=wallet
// We then redirect the user to the same url with the decoded params for the app to intialize correctly.
/**
 * Returns true when a redirect response was sent.
 */
export function handle3dsReturnRedirect(
  reqUrl: URL,
  query: AppContext['router']['query'],
  res: Response,
): boolean {
  if (reqUrl.pathname !== Routes.Return3DS) {
    return false;
  }

  const { locals } = res;

  if (reqUrl.searchParams.has('cbpay_query')) {
    const queryValue = `${reqUrl.searchParams.get('cbpay_query')}`;
    try {
      const parsedQuery = decode3dsCbpayQuery(queryValue);
      res.logger.info('parsed 3ds cbpay_query', {
        cbpay_query: queryValue,
        parsed_query: parsedQuery?.toString(),
      });

      if (!parsedQuery) {
        res.locals.statsd?.increment(RETURN_3DS_FAILURE_METRIC, {
          reason: 'decoded_query_falsy',
        });
      }

      const parsedQueryAsString = parsedQuery ? `?${parsedQuery.toString()}` : '';
      // TODO [ONRAMP-1728]: redirecting to buy for now. Will need to tweak for checkout widget (either make the request here or a generic route)
      const redirectUri = `/buy${Routes.Return3DS}${parsedQueryAsString}`;
      res.writeHead(307, { Location: redirectUri });
      res.end();
      return true;
    } catch (e) {
      res.logger.error('failed to parse 3ds cbpay_query', {
        error_message: coerceError(e).message,
        cbpay_query: queryValue,
      });
      res.locals.statsd?.increment(RETURN_3DS_FAILURE_METRIC, {
        reason: 'decoding_query_threw',
      });
      res.writeHead(307, { Location: '/404' });
      res.end();
      return true;
    }
    // After parsing and redirecting above, alert and 404 if we don't have expected parameters
  } else if (!query.nonce3ds || !locals.clientAppDetails?.appId) {
    res.locals.statsd?.increment(RETURN_3DS_FAILURE_METRIC, { reason: 'cbpay_query_missing' });
    res.logger.warn('navigated to 3ds /return-3ds without cbpay_query');
    res.writeHead(307, { Location: '/404' });
    res.end();
    return true;
  }

  return false;
}

const RETURN_3DS_FAILURE_METRIC = 'return_3ds_failure';
