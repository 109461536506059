import semver from 'semver';

import { clientSessionIdStore } from './clientSessionIdStore';

export const getAppIdQueryParam = () => {
  const queryParams = clientSessionIdStore.getAppParamsMetadata()?.queryExclusiveParams;
  return queryParams?.appVersion ?? queryParams?.sdkVersion;
};

export const getMobileAppBundleId = () => getAppIdQueryParam()?.split('@')[0];
export const getMobileAppVersion = () => semver.coerce(getAppIdQueryParam()?.split('@')[1])?.raw;

export const isCBWalletApp = () => {
  const bundleId = getMobileAppBundleId();
  // 'cbwallet-cbpay-react-native' is only used on versions of the app not using the CBPay RN SDK
  return (
    /^org\.toshi(\.[a-z]+)?$/.test(bundleId ?? '') || bundleId === 'cbwallet-cbpay-react-native'
  );
};
