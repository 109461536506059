import { fetchWithCommonHeaders } from '@onramp/utils/requests';
import semver from 'semver';

import { clientSessionIdStore } from '../clientSessionIdStore';
import type { ExperimentCookieName } from '../cookies';
import { setCookie } from '../cookies/clientUtils';

import type { ExperimentSettings, ExperimentsResponse, FetchExperimentsPayload } from './consts';
import {
  DEVICE_EXPERIMENT_SETTINGS,
  EXPERIMENT_PATH_CLIENT,
  experimentsConfigSchema,
  PLATFORM_TYPE_TO_NUMERIC_VALUE_MAP,
  platformAttributionToWalletUserExperimentSettingsMap,
  SUBJECT_TYPE_TO_NUMERIC_VALUE_MAP,
} from './consts';
import { deduplicateExperimentArrays, getExperimentsFromCookieName } from './utils';

const DEFAULT_EXPERIMENTS: ExperimentsResponse = {
  groups: [],
};

const baseFetchExperiments = async ({
  experimentSettings,
  subjectId,
  version,
  cookieName,
}: {
  experimentSettings: ExperimentSettings;
  subjectId: string;
  version?: string;
  cookieName: ExperimentCookieName;
}): Promise<ExperimentsResponse> => {
  const cachedExperiments = getExperimentsFromCookieName(cookieName, subjectId);
  if (cachedExperiments) return { groups: cachedExperiments };

  const params: FetchExperimentsPayload = {
    subject_id: subjectId,
    client: { type: PLATFORM_TYPE_TO_NUMERIC_VALUE_MAP[experimentSettings.platformType], version },
    subject_type: SUBJECT_TYPE_TO_NUMERIC_VALUE_MAP[experimentSettings.subjectType],
  };

  const encodedParams = window.btoa(JSON.stringify(params));

  const url = `${EXPERIMENT_PATH_CLIENT}?q=${encodedParams}`;
  try {
    const res = await fetchWithCommonHeaders(url);

    const maybeExperiments = (await res.json()) as unknown;
    const experiments = experimentsConfigSchema.parse(maybeExperiments);
    return experiments;
  } catch {
    // TODO [ONRAMP-1773]: log something here when fetching experiments fails.
    return DEFAULT_EXPERIMENTS;
  }
};

export const fetchDeviceExperiments = async () =>
  baseFetchExperiments({
    experimentSettings: DEVICE_EXPERIMENT_SETTINGS,
    subjectId: clientSessionIdStore.getDeviceId(),
    cookieName: 'deviceExperiments',
  });

export const fetchWalletUserExperiments = async () => {
  const walletUserId = clientSessionIdStore.getWalletUserId();

  const platformAttribution = clientSessionIdStore.getPlatformAttribution();
  const experimentSettings =
    platformAttributionToWalletUserExperimentSettingsMap[platformAttribution ?? 'web'];
  const doesClientHaveFeatureFlag =
    clientSessionIdStore.getFeatureFlags()?.walletUserExperimentsEnabled ?? false;
  const version =
    semver.parse(clientSessionIdStore.getSdkVersion()?.split('@')[1])?.toString() || undefined;

  if (!walletUserId || !experimentSettings || !doesClientHaveFeatureFlag) {
    return DEFAULT_EXPERIMENTS;
  }

  return baseFetchExperiments({
    experimentSettings,
    subjectId: walletUserId,
    version,
    cookieName: 'walletExperiments',
  });
};

export const fetchExperiments = async () => {
  const [deviceExperiments, walletExperiments] = await Promise.all([
    fetchDeviceExperiments().catch(() => undefined),
    fetchWalletUserExperiments().catch(() => undefined),
  ]);

  if (deviceExperiments) {
    setExperimentCookie('deviceExperiments', deviceExperiments);
  }

  if (walletExperiments) {
    setExperimentCookie('walletExperiments', walletExperiments);
  }

  const overriddenExperiments = getExperimentsFromCookieName('overridenExperiments');

  const allExperiments = {
    groups: deduplicateExperimentArrays([
      ...(deviceExperiments?.groups ?? []),
      ...(walletExperiments?.groups ?? []),
      ...(overriddenExperiments ?? []),
    ]),
  };

  return allExperiments;
};

export const setExperimentCookie = (
  cookieName: ExperimentCookieName,
  experiments: ExperimentsResponse,
) => {
  setCookie({ cookieName, value: JSON.stringify(experiments) });
};
