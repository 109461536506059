// eslint-disable-next-line no-restricted-imports
import type { Response, SecureInitParameters } from '@onramp/server/types';
import {
  addressAndAssetsToDestinationWallets,
  DestinationAddressAppParamsSchema,
} from '@onramp/shared/appParams.schema';
import type { AppState3dsType } from '@onramp/shared/appState3ds.schema';
import type { InitParamsType } from '@onramp/types/initParams';
import type { ClientAppDetails } from '@onramp/utils/clientApps/types';
import { ClientAppDetailsSchema } from '@onramp/utils/clientApps/types';
import type { AuthenticationMethod } from '@onramp/utils/clientSessionIdStore';
import { isLocalDevelopment } from '@onramp/utils/environment/sharedEnv';
import type { FeatureFlags } from '@onramp/utils/featureFlags/types';
import type { ClientGsscData } from '@onramp/utils/gssc';
import { clientGsscDataSchema } from '@onramp/utils/gssc';
import { getIsGuestCheckoutPath } from '@onramp/utils/guestCheckoutUtils';
import { isNotNullish } from '@onramp/utils/types';
import { getIsRoute } from '@onramp/v2/client/views/onramp/card-details/utils/getIsRoute';
import bigJs from 'big.js';
import type { IncomingMessage } from 'http';
import type { AppContext, AppInitialProps } from 'next/app';
import NextApp from 'next/app';
import { z } from 'zod';

import { checkInitialRouteServerRedirect } from './serverRedirects/checkInitialRouteServerRedirect';
import { QueryParamSchema } from './serverRedirects/handleNonIgnoredPaths';

export type InitialProps = AppInitialProps & {
  csrfToken: string | undefined;
  clientAppDetails: ClientAppDetails | undefined;
  gsscData: ClientGsscData | undefined;
  authenticationMethod: AuthenticationMethod | undefined;
  guestCheckoutCountryAllowlist: string[] | undefined;
  featureFlags: FeatureFlags | undefined;
  userUuid: string | undefined;
  isGuestCheckoutRoute: boolean;
  isV2Route: boolean;
  secureInitParameters: SecureInitParameters | undefined;
  appState3ds: AppState3dsType | undefined;
  initParams?: InitParamsType;
  requiredParamsError?: boolean;
};

/**
 * Redirect all non-404 routes to /select-asset, so users always start at beginning of the flow.
 * If a user refreshes their browser a few steps into the flow, this takes them back to the start.
 */
// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getInitialProps = async (appContext: AppContext): Promise<InitialProps | void> => {
  // If ctx.res is present, we're running on the server.
  const { isOnServer, req, res } =
    appContext.ctx.res !== undefined
      ? ({
          isOnServer: true,
          req: appContext.ctx.req as IncomingMessage,
          res: appContext.ctx.res as Response,
        } as const)
      : ({
          isOnServer: false,
          req: undefined,
          res: undefined,
        } as const);

  if (isOnServer && checkInitialRouteServerRedirect(req, appContext.router.query, res)) {
    // Need to return some value to satisfy typescript.
    return undefined;
  }

  const { locals = {} } = res ?? {};

  const isGuestCheckoutRoute = isOnServer
    ? appContext.router.route.startsWith('/buy/guest')
    : getIsGuestCheckoutPath();

  const isV2Route = isOnServer ? appContext.router.route.startsWith('/v2') : getIsRoute('/v2');
  const isLanding = isOnServer
    ? appContext.router.route.startsWith('/landing')
    : getIsRoute('/landing');

  const initialPropsCommonTags = {
    app_id: locals.clientAppDetails?.appId ?? '<missing app id>',
    page_path: appContext.ctx.pathname,
    logged_in: String(isNotNullish(locals.jwt)),
  };

  // Otherwise, we need to pass on through to Next.js
  const appInitialProps = await NextApp.getInitialProps(appContext);
  // Also get CSRF token from headers
  const csrfToken = validateInitialProp({
    prop: 'csrfToken',
    value: res?.getHeader('csrf-token'),
    schema: z.string(),
    res,
    tags: initialPropsCommonTags,
  });

  const clientAppDetails = validateInitialProp({
    prop: 'clientAppDetails',
    value: locals.clientAppDetails,
    schema: locals.clientAppDetails ? ClientAppDetailsSchema : ClientAppDetailsSchema.optional(),
    res,
    tags: initialPropsCommonTags,
  });

  const gsscData = validateInitialProp({
    prop: 'gsscData',
    value: locals.gsscData,
    schema: clientGsscDataSchema.optional(),
    res,
    tags: initialPropsCommonTags,
  });

  /** The GC country allowlist. If `undefined` during local development, defaults to `['US']` */
  const guestCheckoutCountryAllowlist = (() => {
    const configServiceAllowlist = res?.locals.configService?.getParameter(
      'payments/onramp-widget-critical',
      'guest-checkout-country-allowlist',
    )?.body.stringArray.stringArray;

    if (configServiceAllowlist || !isLocalDevelopment) return configServiceAllowlist;

    return ['US'];
  })();

  const secureInitParameters = res?.locals.secureInitParameters;
  const queryParams = appContext.ctx.query;
  let initParams = { ...queryParams } as InitParamsType;
  const nonceCookieData = res?.locals.nonceCookieData;

  if (nonceCookieData && Boolean(queryParams.nonce)) {
    initParams = {
      ...initParams,
      ...nonceCookieData,
    };

    if (nonceCookieData && nonceCookieData?.nonce !== queryParams.nonce) {
      throw new Error('Nonce mismatch');
    }
  }
  if (secureInitParameters) {
    initParams = {
      ...initParams,
      ...secureInitParameters,
    };
  }

  if (typeof initParams.handlingRequestedUrls === 'string') {
    initParams.handlingRequestedUrls = initParams.handlingRequestedUrls === 'true';
  }

  // A lot of developers find the destinationWallets param confusing, so we've added a simpler way to pass addresses
  // and supported assets. These new `addresses` and `assets` params are converted into a destinationWallets param.
  if (typeof initParams.addresses === 'string') {
    initParams.addresses = JSON.parse(decodeURIComponent(initParams.addresses)) as Record<
      string,
      string[]
    >;
    initParams.assets =
      typeof initParams.assets === 'string'
        ? (JSON.parse(initParams.assets) as string[])
        : undefined;
    initParams.destinationWallets = addressAndAssetsToDestinationWallets(
      initParams.addresses,
      initParams.assets,
    );
  }

  if (isV2Route || isLanding) {
    const validationParams = {
      ...initParams,
      destinationWallets:
        typeof initParams?.destinationWallets === 'string'
          ? (JSON.parse(decodeURIComponent(initParams?.destinationWallets)) as Record<
              string,
              unknown
            >)
          : initParams?.destinationWallets,
    };

    const requiredParams = RequiredParamSchema.safeParse(validationParams);
    if (!requiredParams.success) {
      res?.logger.error('Failed to parse required params', {
        error: requiredParams.error.format(),
      });
      initParams.requiredParamsError = true;
    } else {
      initParams.destinationWallets = requiredParams.data.destinationWallets;
    }

    if (initParams.presetCryptoAmount) {
      try {
        initParams.presetCryptoAmount = bigJs(initParams.presetCryptoAmount).toNumber();
      } catch {
        res?.logger.warn('Failed to parse presetCryptoAmount as number', {
          presetCryptoAmount: initParams.presetCryptoAmount,
        });
      }
    }

    if (initParams.presetFiatAmount) {
      try {
        initParams.presetFiatAmount = bigJs(initParams.presetFiatAmount).toNumber();
      } catch {
        res?.logger.warn('Failed to parse presetFiatAmount as number', {
          presetFiatAmount: initParams.presetFiatAmount,
        });
      }
    }
  }

  return {
    csrfToken,
    clientAppDetails,
    gsscData,
    authenticationMethod: res?.locals.jwt?.authenticationMethod as AuthenticationMethod | undefined,
    guestCheckoutCountryAllowlist,
    featureFlags: locals.featureFlags,
    userUuid: res?.locals.userUuid,
    isGuestCheckoutRoute,
    secureInitParameters,
    appState3ds: res?.locals.appState3ds,
    initParams,
    isV2Route,
    ...appInitialProps,
  };
};

function validateInitialProp<K extends keyof InitialProps>({
  prop,
  schema,
  res,
  value,
  tags,
}: {
  prop: K;
  value: unknown;
  schema: z.ZodSchema<InitialProps[K]>;
  res: Response | undefined;
  tags?: Record<string, string>;
}) {
  const parsed = schema.safeParse(value);

  if (!parsed.success) {
    res?.locals.statsd?.increment('invalid_initial_props', {
      ...tags,
      prop,
    });

    res?.logger.warn('Invalid initial prop', {
      ...tags,
      prop,
      error: parsed.error.format(),
    });

    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return parsed.data;
}

const RequiredParamSchema = QueryParamSchema.extend({
  destinationWallets: DestinationAddressAppParamsSchema,
});
