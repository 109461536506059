/**
 * @generated SignedSource<<f52960fe32154cbd75f9c373094f8814>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccountType = "COLLATERAL" | "DEFI_YIELD" | "FIAT" | "MULTISIG" | "MULTISIG_VAULT" | "STAKED_FUNDS" | "UNKNOWN" | "VAULT" | "WALLET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type readFiatWalletsAccountFragment$data = {
  readonly type: AccountType;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"readFiatWalletAccountFragment">;
  readonly " $fragmentType": "readFiatWalletsAccountFragment";
};
export type readFiatWalletsAccountFragment$key = {
  readonly " $data"?: readFiatWalletsAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readFiatWalletsAccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readFiatWalletsAccountFragment"
};

(node as any).hash = "eb37ccf1cb488c2aea12925f05dfea37";

export default node;
