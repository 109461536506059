/**
 * @generated SignedSource<<9c94016d968da1c1dc9d1105641af7e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CbPayCreateWalletTransactionInput = {
  appId?: string | null;
  destinationWalletDetails?: ReadonlyArray<CBPayDestinationWalletDetail | null> | null;
  sessionId?: string | null;
};
export type CBPayDestinationWalletDetail = {
  address?: string | null;
  assetIds?: ReadonlyArray<string | null> | null;
  networks?: ReadonlyArray<string | null> | null;
};
export type useCreateWalletTransactionMutation$variables = {
  input: CbPayCreateWalletTransactionInput;
};
export type useCreateWalletTransactionMutation$data = {
  readonly cbPayCreateWalletTransaction: {
    readonly __typename: "BadRequestError";
    readonly code: string;
    readonly message: string;
  } | {
    readonly __typename: "CbPayWalletTransactionSuccess";
    readonly cbPayWalletTransaction: {
      readonly transactionUuid: string | null;
      readonly walletNetworkMap: ReadonlyArray<{
        readonly address: string | null;
        readonly network: string | null;
      } | null> | null;
    } | null;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type useCreateWalletTransactionMutation = {
  response: useCreateWalletTransactionMutation$data;
  variables: useCreateWalletTransactionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "cbPayCreateWalletTransaction",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CbPayWalletTransaction",
            "kind": "LinkedField",
            "name": "cbPayWalletTransaction",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "transactionUuid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "CbPayWalletNetworkMapType",
                "kind": "LinkedField",
                "name": "walletNetworkMap",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "network",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "address",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "type": "CbPayWalletTransactionSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          }
        ],
        "type": "BadRequestError",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateWalletTransactionMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateWalletTransactionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "0539ff3712bd6b3985948ceefe025ee8",
    "id": null,
    "metadata": {},
    "name": "useCreateWalletTransactionMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateWalletTransactionMutation(\n  $input: CbPayCreateWalletTransactionInput!\n) {\n  cbPayCreateWalletTransaction(input: $input) {\n    __typename\n    ... on CbPayWalletTransactionSuccess {\n      cbPayWalletTransaction {\n        transactionUuid\n        walletNetworkMap {\n          network\n          address\n        }\n      }\n    }\n    ... on BadRequestError {\n      message\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "427079e0f186b7dd844c0ebff3f56882";

export default node;
