import { useCallback, useContext } from 'react';
import { useQuery } from 'react-query';
import { ApplePayStateContext } from '@onramp/components/ApplePayProvider';
import { checkIfApplePayIsSupported } from '@onramp/utils/applePay';
import {
  isProduction,
  targetEnvironment,
  targetEnvironmentMap,
} from '@onramp/utils/environment/sharedEnv';
import { reportError } from '@onramp/utils/errors';
import { coerceError } from '@onramp/utils/errors/coerceError';

export function useIsApplePaySupportedWithActiveCard() {
  const canMakePaymentsWithActiveCard = useCanMakePaymentsWithActiveCard();
  const {
    data: isApplePaySupportedWithActiveCard = false,
    isLoading,
    refetch,
  } = useQuery('canMakePaymentsWithActiveCard', canMakePaymentsWithActiveCard, {
    refetchOnWindowFocus: true,
  });

  return { isApplePaySupportedWithActiveCard, isLoading, refetch };
}

export function useCanMakePaymentsWithActiveCard() {
  const { braintreeClient, applePayClient } = useContext(ApplePayStateContext);

  return useCallback(async (): Promise<boolean> => {
    const isApplePaySupported = checkIfApplePayIsSupported();
    if (!isApplePaySupported) return false;

    // Don't run in production OR testing. For testing, we will mock the code below this condition.
    if (!isProduction && targetEnvironment !== targetEnvironmentMap.test) {
      /*
        The merchant id for our Braintree sandbox account doesn't work with
        AppleSession.canMakePaymentsWithActiveCard(). To allow sandbox user testing in dev we  want
        to display Apple Pay when the browser is compatible.
        https://developer.apple.com/apple-pay/sandbox-testing/
      */
      return isApplePaySupported;
    }

    try {
      if (!window.ApplePaySession || !applePayClient?.merchantIdentifier) return false;

      return await window.ApplePaySession.canMakePaymentsWithActiveCard(
        applePayClient.merchantIdentifier,
      );
    } catch (err) {
      const error = coerceError(err).addMetadata({
        context: 'useIsApplePaySupportedWithActiveCard.useCanMakePaymentsWithActiveCard',
        braintreeNetwork: 'apple_pay',
        hasBraintreeClient: braintreeClient !== undefined,
        hasApplePayClient: applePayClient !== undefined,
      });
      reportError(error);

      return false;
    }
  }, [applePayClient, braintreeClient]);
}
