import { Suspense, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { useBuyWidgetState } from '@onramp/state/BuyWidgetState';
import { coerceError, reportError } from '@onramp/utils/errors';
import { genericMessages } from '@onramp/utils/genericMessages';
import { assertPresence } from '@onramp/utils/relayUtils';

import { useCreateWalletTransaction } from '../hooks/useCreateWalletTransaction';

const CreateWalletTransactionBody = () => {
  const { formatMessage } = useIntl();
  const { createWalletTransaction, isCommitting } = useCreateWalletTransaction();
  const { walletTransactionUuid, walletTransactionNetworkAddressMap } = useBuyWidgetState();

  const handleCreateWalletTransaction = useCallback(async () => {
    try {
      const resp = await createWalletTransaction();
      const transactionUuid = assertPresence(resp.data?.transactionUuid, {
        debugMessage: 'Create wallet transaction uuid is not present',
        message: formatMessage(genericMessages.somethingWentWrong),
      });

      const walletNetworkMap = assertPresence(resp.data?.walletNetworkMap, {
        debugMessage: 'Create wallet transaction walletNetworkMap is not present',
        message: formatMessage(genericMessages.somethingWentWrong),
      });
      walletTransactionUuid.onChange(transactionUuid);
      walletTransactionNetworkAddressMap.onChange(
        walletNetworkMap.reduce(
          (prev, curr) => ({
            ...prev,
            ...(curr?.network && curr.address ? { [curr.network]: curr.address } : undefined),
          }),
          {} as Record<string, string>,
        ),
      );
    } catch (e) {
      // For now we're just reporting the error because the request is not required for the flow
      reportError(coerceError(e).addMetadata({ source: 'CreateWalletTransactionWrapper' }));
    }
  }, [
    createWalletTransaction,
    formatMessage,
    walletTransactionNetworkAddressMap,
    walletTransactionUuid,
  ]);

  useEffectOnMount(() => {
    if (!walletTransactionUuid.value && !isCommitting) {
      void handleCreateWalletTransaction();
    }
  });

  return null;
};

// To avoid suspending higher up the hierarchy we use this wrapper to catch the suspense lower in the tree.
export const CreateWalletTransactionWrapper: React.VFC = () => {
  return (
    <Suspense fallback={null}>
      <CreateWalletTransactionBody />
    </Suspense>
  );
};
