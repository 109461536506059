import React, { useCallback } from 'react';
import type { ComponentProps } from 'react';
import { useOpenUrl } from '@onramp/hooks/utils/useOpenUrl';

type EmbedSafeAnchorProps = Omit<ComponentProps<'a'>, 'onClick' | 'onKeyPress' | 'role'> & {
  onPress?: () => void;
};

const EmbedSafeAnchor = (props: EmbedSafeAnchorProps) => {
  const { children, href, onPress, ...otherProps } = props;

  const openUrl = useOpenUrl();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      if (href) {
        openUrl(href);
      }

      if (onPress) {
        onPress();
      }
    },
    [href, onPress, openUrl],
  );

  const handleAuxClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (event.button !== 1) {
        return;
      }

      handleClick(event);
    },
    [handleClick],
  );

  const handleKeyPress = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        if (href) {
          openUrl(href);
        }

        if (onPress) {
          onPress();
        }
      }
    },
    [href, onPress, openUrl],
  );

  return (
    <a
      onClick={handleClick}
      onAuxClick={handleAuxClick}
      onKeyPress={handleKeyPress}
      role="link"
      tabIndex={0}
      {...otherProps}
    >
      {children}
    </a>
  );
};

export default EmbedSafeAnchor;
