/**
 * @generated SignedSource<<d15da303ac1318d6a03068ec29a954ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useParseSourcesOfFundsAccountFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"getIsCountryAllowedAccountFragment" | "readCryptoAccountsFragment" | "readFiatWalletsAccountFragment">;
  readonly " $fragmentType": "useParseSourcesOfFundsAccountFragment";
};
export type useParseSourcesOfFundsAccountFragment$key = {
  readonly " $data"?: useParseSourcesOfFundsAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useParseSourcesOfFundsAccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useParseSourcesOfFundsAccountFragment"
};

(node as any).hash = "2b6a8b24ceadff873299a0e62fe4e8c9";

export default node;
