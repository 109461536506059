/**
 * @generated SignedSource<<220b075725e610747e8b50f774f4f555>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useParseSourcesOfFundsPaymentMethodFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"getIsCountryAllowedFiatFragment" | "readFiatWalletsPaymentMethodFragment" | "readPaymentMethodsFragment">;
  readonly " $fragmentType": "useParseSourcesOfFundsPaymentMethodFragment";
};
export type useParseSourcesOfFundsPaymentMethodFragment$key = {
  readonly " $data"?: useParseSourcesOfFundsPaymentMethodFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useParseSourcesOfFundsPaymentMethodFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useParseSourcesOfFundsPaymentMethodFragment"
};

(node as any).hash = "6137f733fed2a7b872d1aabcf3d59b8a";

export default node;
