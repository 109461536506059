/**
 * @generated SignedSource<<8f837a5b6dc184bda165d6ecc22b70d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useDefaultSourceOfFundsViewerFragment$data = {
  readonly accountByUuid?: {
    readonly " $fragmentSpreads": FragmentRefs<"readCryptoAccountFragment">;
  } | null;
  readonly accountsV2: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assetOrFiatCurrency: {
          readonly asset?: {
            readonly uuid: string;
          };
        } | null;
        readonly uuid: string;
        readonly " $fragmentSpreads": FragmentRefs<"readCryptoAccountFragment" | "readFiatWalletAccountFragment">;
      } | null;
    }>;
  } | null;
  readonly assetByUuid?: {
    readonly asset: {
      readonly latestPrice: {
        readonly price: string | null;
      } | null;
    };
  } | null;
  readonly cbPaySourcesOfFunds: ReadonlyArray<{
    readonly account?: {
      readonly " $fragmentSpreads": FragmentRefs<"readCryptoAccountFragment" | "readFiatWalletAccountFragment">;
    } | null;
    readonly isEnabled?: boolean | null;
    readonly paymentMethod?: {
      readonly uuid: string;
      readonly " $fragmentSpreads": FragmentRefs<"readFiatWalletPaymentMethodFragment" | "readPaymentMethodFragment">;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"readPrimaryCryptoAccountFragment" | "readPrimaryFiatWalletFragment" | "readPrimaryPaymentMethodSoFFragment">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"readPrimaryCryptoAccountFromAccountsV2">;
  readonly " $fragmentType": "useDefaultSourceOfFundsViewerFragment";
};
export type useDefaultSourceOfFundsViewerFragment$key = {
  readonly " $data"?: useDefaultSourceOfFundsViewerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useDefaultSourceOfFundsViewerFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useDefaultSourceOfFundsViewerFragment"
};

(node as any).hash = "49852b430238180202736b04ee5a7a5f";

export default node;
