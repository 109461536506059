/**
 * @generated SignedSource<<5066eb784a90bba28bc3923e831fe733>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readAccountViewerAssetUuidFragment$data = {
  readonly assetOrFiatCurrency: {
    readonly __typename: "ViewerAsset";
    readonly asset: {
      readonly uuid: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly " $fragmentType": "readAccountViewerAssetUuidFragment";
};
export type readAccountViewerAssetUuidFragment$key = {
  readonly " $data"?: readAccountViewerAssetUuidFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readAccountViewerAssetUuidFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readAccountViewerAssetUuidFragment"
};

(node as any).hash = "699f5e0f024550c11712c528603f6dec";

export default node;
