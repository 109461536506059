import { useCallback } from 'react';
import { fetchWithCommonHeaders } from '@onramp/utils/requests';

export const useValidateClientOrigin = () => {
  return useCallback(async (origin: string): Promise<boolean> => {
    return fetchWithCommonHeaders('/user/validate-client-origin', {
      method: 'POST',
      body: JSON.stringify({ origin }),
    })
      .then(async (r) => r.json())
      .then((isValid) => Boolean(isValid))
      .catch(() => false);
  }, []);
};
