// eslint-disable-next-line no-restricted-imports
import type { Request, Response } from '@onramp/server/types';
import { Routes } from '@onramp/utils/routes';
import type { IncomingMessage } from 'http';
import type { AppContext } from 'next/app';

import { handle3dsReturnRedirect } from './handle3dsReturnRedirect';
import { handleNonIgnoredPaths } from './handleNonIgnoredPaths';

/**
 * Returns true when a redirect response was sent.
 */
export function checkInitialRouteServerRedirect(
  req: IncomingMessage,
  query: AppContext['router']['query'],
  res: Response,
): boolean {
  const reqUrl = new URL(req?.url ?? '', process.env.NEXT_PUBLIC_ONRAMP_BASE_URL);
  // eslint-disable-next-line compat/compat
  const reqQuery = Object.fromEntries(reqUrl.searchParams.entries());

  if (skipRedirectForInputV2ToOcbNavigation(req, reqUrl)) {
    return false;
  }

  return (
    handle3dsReturnRedirect(reqUrl, query, res) ||
    handleNonIgnoredPaths(reqUrl, query, reqQuery, {}, res)
  );
}

/** This prevents a redirect loops when navigating from Input V2 to OCB, which we have to bypass the FE routing for. */
function skipRedirectForInputV2ToOcbNavigation(req: IncomingMessage | undefined, reqUrl: URL) {
  // will get type errors since this is IncomingMessage, but we need to check if it has originalUrl so just typecasting it to Request
  const referrerUrl = (req as Request)?.get ? (req as Request)?.get('Referer') : '';

  if (
    referrerUrl?.includes(Routes.V2.OnrampInput) &&
    reqUrl.pathname.includes(Routes.Buy.OneClick)
  ) {
    return true;
  }

  return false;
}
