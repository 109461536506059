/**
 * @generated SignedSource<<e5623396e9ede31026c045a35bcd92f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readAccountBalanceFragment$data = {
  readonly assetOrFiatCurrency: {
    readonly assetBalanceBreakdown?: {
      readonly availableToSendBalance: {
        readonly currency: string;
        readonly value: string;
      } | null;
    } | null;
    readonly type?: string;
  } | null;
  readonly " $fragmentType": "readAccountBalanceFragment";
};
export type readAccountBalanceFragment$key = {
  readonly " $data"?: readAccountBalanceFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readAccountBalanceFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readAccountBalanceFragment"
};

(node as any).hash = "39d829f8cd9c85d8b46405f6fe6c01f1";

export default node;
