import { useEffect, useRef } from 'react';
/*
  useInterval Hook sets up an interval and clears it after unmounting.
  It’s a combo of setInterval and clearInterval tied to the component lifecycle.
  Taken from https://overreacted.io/making-setinterval-declarative-with-react-hooks/
*/

function useInterval(callback: () => void, delay?: number | null) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    function tick() {
      if (savedCallback.current) savedCallback.current();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
