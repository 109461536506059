import { coerceError, HandledError, NetworkError, reportError } from '@onramp/utils/errors';
// eslint-disable-next-line import/no-cycle
import { fetchWithCommonHeaders } from '@onramp/utils/requests';

import type { KillSwitchCookieName } from '../cookies';
import { getCookie, setCookie } from '../cookies/clientUtils';

import type { KillSwitchesConfigType, KillSwitchesResponse } from './killSwitchConfiguration';
import {
  DEFAULT_KILL_SWITCHES,
  KILL_SWITCH_PARAMS,
  KILL_SWITCH_PATH_CLIENT,
  KillSwitchesConfigSchema,
  KillSwitchesCookieSchema,
} from './killSwitchConfiguration';

export const getKillSwitchesFromCookieName = (cookieName: KillSwitchCookieName) => {
  return KillSwitchesCookieSchema.parse(getCookie({ cookieName }))?.killswitches;
};

export const fetchKillSwitches = async (): Promise<KillSwitchesConfigType> => {
  const killSwitchesOverride = getKillSwitchesFromCookieName('overridenKillSwitches') ?? {};

  try {
    const killSwitchesCache = getKillSwitchesFromCookieName('fetchedKillSwitches');
    if (killSwitchesCache) {
      return {
        ...DEFAULT_KILL_SWITCHES,
        ...killSwitchesCache,
        ...killSwitchesOverride,
      };
    }
    // eslint-disable-next-line no-empty
  } catch {}

  const paramsJson = JSON.stringify(KILL_SWITCH_PARAMS);

  // The API expects a base64 encoded query param.
  const url = `${KILL_SWITCH_PATH_CLIENT}?q=${window.btoa(paramsJson)}`;

  try {
    const res = await fetchWithCommonHeaders(url);

    try {
      const killSwitches = KillSwitchesConfigSchema.parse(await res.json()).killswitches;
      setKillSwitchCookie('fetchedKillSwitches', { killswitches: killSwitches });

      return {
        ...DEFAULT_KILL_SWITCHES,
        ...killSwitches,
        ...killSwitchesOverride,
      };
    } catch (e) {
      throw new HandledError({
        handledError: e instanceof Error ? e : undefined,
        message: "Zod didn't parse the killswitches successfully",
      });
    }
  } catch (e) {
    reportError(coerceError(e, { fallback: NetworkError }));
    return DEFAULT_KILL_SWITCHES;
  }
};

export const setKillSwitchCookie = (
  cookieName: KillSwitchCookieName,
  killSwitches: KillSwitchesResponse,
) => {
  setCookie({ cookieName, value: JSON.stringify(killSwitches) });
};
