import type { IconName } from '@cbhq/cds-web';

/**
 * This list of supported currencies comes from CDS:
 * https://cds.coinbase-corp.com/components/icon#page=implementation
 *
 * This list is simply to drive the icon selection, not to enable / disable certain accounts.
 */

export const supportedCurrenciesToIconNameMap: ReadonlyMap<string, string> = new Map([
  ['AUD', 'cashAustralianDoller'],
  ['BRL', 'cashBrazilianReal'],
  ['CAD', 'cashCanadianDollar'],
  ['EUR', 'cashEUR'],
  ['GBP', 'cashGBP'],
  ['IDR', 'cashIndonesianRupiah'],
  ['JPY', 'cashJPY'],
  ['PHP', 'cashPhilippinePeso'],
  ['PLN', 'cashPolishZloty'],
  ['INR', 'cashIndianRupee'],
  ['SGD', 'cashSingaporeDollar'],
  ['CHF', 'cashSwissFranc'],
  ['THB', 'cashThaiBaht'],
  ['TRY', 'cashTurkishLira'],
  ['AED', 'cashUaeDirham'],
  ['USD', 'cashUSD'],
  ['VND', 'cashVietnameseDong'],
]);

export function getCashIconName(currency?: string): IconName {
  const iconName = supportedCurrenciesToIconNameMap.get(currency ?? '');
  return (iconName ?? 'cash') as IconName;
}
