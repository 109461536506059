import { atom } from 'jotai';

export type NonNullSecureInitParameters = {
  appId: string;
  destinationWallets: {
    address: string;
    assets?: string[];
    blockchains?: string[];
    supportedNetworks?: string[];
  }[];
};

export const secureInitParametersAtom = atom<NonNullSecureInitParameters | undefined>(undefined);
