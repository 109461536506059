import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import type {
  useCreateWalletTransactionMutation,
  useCreateWalletTransactionMutation$data,
} from '@onramp/data/__generated__/useCreateWalletTransactionMutation.graphql';
import { useDestinationWalletsV2 } from '@onramp/state/recoil/appParamsState';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import {
  addAlreadyReportedErrorsMetadata,
  coerceError,
  GqlError,
  TopLevelGqlError,
} from '@onramp/utils/errors';
import { genericMessages } from '@onramp/utils/genericMessages';
import { assertPresence } from '@onramp/utils/relayUtils';
import { graphql, useMutation } from '@cbhq/data-layer';

const createWalletTransactionMutation = graphql`
  mutation useCreateWalletTransactionMutation($input: CbPayCreateWalletTransactionInput!) {
    cbPayCreateWalletTransaction(input: $input) {
      __typename
      ... on CbPayWalletTransactionSuccess {
        cbPayWalletTransaction {
          transactionUuid
          walletNetworkMap {
            network
            address
          }
        }
      }
      ... on BadRequestError {
        message
        code
      }
    }
  }
`;

export const useCreateWalletTransaction = () => {
  const { formatMessage } = useIntl();
  const [commitMutation, isCommitting] = useMutation<useCreateWalletTransactionMutation>(
    createWalletTransactionMutation,
  );
  const { destinationWallets } = useDestinationWalletsV2();

  const createWalletTransaction = useCallback(
    async () =>
      new Promise<{
        data: CreateWalletTransactionSuccessResponse;
      }>((resolve, reject) => {
        commitMutation({
          variables: {
            input: {
              appId: clientSessionIdStore.getAppId(),
              sessionId: clientSessionIdStore.getClientSessionId(),
              /** This needs to pass the networks associated with the config. */
              destinationWalletDetails: destinationWallets.map(
                ({ address, networksForAPISubmission, assets }) => ({
                  address,
                  networks: networksForAPISubmission,
                  assetIds: assets,
                }),
              ),
            },
          },
          onError: ({ alreadyReportedError }) =>
            reject(new TopLevelGqlError('createWalletTransaction', alreadyReportedError)),
          onCompleted: ({ alreadyReportedErrors, response }) => {
            try {
              const data = assertPresence(response.cbPayCreateWalletTransaction, {
                debugMessage: 'createWalletTransaction onCompleted response',
                message: formatMessage(genericMessages.somethingWentWrong),
              });

              switch (data.__typename) {
                case 'CbPayWalletTransactionSuccess':
                  resolve({ data: data.cbPayWalletTransaction });
                  break;
                case 'BadRequestError':
                  throw new GqlError({
                    __typename: data.__typename,
                    message: data.message,
                    code: data.code,
                  });
                default:
                  throw new GqlError({
                    __typename: data.__typename,
                    debugMessage: `useCreateWalletTransactionMutation unrecognized typename: ${data.__typename}`,
                    message: formatMessage(genericMessages.somethingWentWrong),
                    variant: 'unrecognized_type',
                  });
              }
            } catch (err) {
              reject(addAlreadyReportedErrorsMetadata(coerceError(err), alreadyReportedErrors));
            }
          },
        });
      }).catch(async (err) =>
        Promise.reject(coerceError(err).addMetadata({ source: 'CreateWalletTransaction' })),
      ),
    [commitMutation, destinationWallets, formatMessage],
  );

  return {
    createWalletTransaction,
    isCommitting,
  };
};

type CreateWalletTransactionSuccessResponse = Extract<
  NonNullable<useCreateWalletTransactionMutation$data['cbPayCreateWalletTransaction']>,
  { __typename: 'CbPayWalletTransactionSuccess' }
>['cbPayWalletTransaction'];
