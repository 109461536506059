import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useOpenUrl } from '@onramp/hooks/utils/useOpenUrl';
import { logNavMenuAction } from '@onramp/utils/eventing';
import { Icon } from '@cbhq/cds-web/icons';
import { VStack } from '@cbhq/cds-web/layout';

import { ListCell } from './ListCellUserMenu';

const messages = defineMessages({
  website: {
    id: 'AboutMenu.website',
    defaultMessage: 'Website',
    description: 'The label for the Website menu item',
  },
  termsOfUse: {
    id: 'AboutMenu.termsOfUse',
    defaultMessage: 'Terms of Use',
    description: 'The label for the Terms of Use menu item',
  },
  privacy: {
    id: 'AboutMenu.privacy',
    defaultMessage: 'Privacy',
    description: 'The label for the Privacy menu item',
  },
});

export const AboutMenu = () => {
  const { formatMessage } = useIntl();

  const openUrl = useOpenUrl();

  const handlePressWebsite = useCallback(() => {
    logNavMenuAction('website');
    openUrl('https://www.coinbase.com/pay-sdk');
  }, [openUrl]);

  const handlePressTermsOfUse = useCallback(() => {
    logNavMenuAction('terms');
    openUrl('https://www.coinbase.com/legal/user_agreement');
  }, [openUrl]);

  const handlePressPrivacy = useCallback(() => {
    logNavMenuAction('privacy');
    openUrl('https://www.coinbase.com/legal/privacy');
  }, [openUrl]);

  return (
    <VStack>
      <ListCell
        title={formatMessage(messages.website)}
        media={<Icon name="globe" color="foreground" size="s" />}
        onPress={handlePressWebsite}
      />
      <ListCell
        title={formatMessage(messages.termsOfUse)}
        media={<Icon name="smartContract" color="foreground" size="s" />}
        onPress={handlePressTermsOfUse}
      />
      <ListCell
        title={formatMessage(messages.privacy)}
        media={<Icon name="lock" color="foreground" size="s" />}
        onPress={handlePressPrivacy}
      />
    </VStack>
  );
};
