import type { getIsCountryAllowedFiatFragment$key } from '@onramp/data/__generated__/getIsCountryAllowedFiatFragment.graphql';
import type {
  readPaymentMethodFragment$data,
  readPaymentMethodFragment$key,
} from '@onramp/data/__generated__/readPaymentMethodFragment.graphql';
import type { PaymentMethodTypeV2 } from '@onramp/data/graphql-types';
import applePayLogo from '@onramp/pages/buy/input/components/SourceOfFundsSelector/logos/apple-pay-logo.svg';
import type { SourceOfFundsData } from '@onramp/state/types/SourceOfFundsData';
import { host } from '@onramp/utils/environment/clientEnv';
import { isFiatAccountAllowed } from '@onramp/utils/getIsCountryAllowed';
import type { KillSwitchesConfigType } from '@onramp/utils/killswitches/killSwitchConfiguration';
import type { IconName } from '@cbhq/cds-web';
import { graphql, readInlineData } from '@cbhq/data-layer';

import type { SourceOfFundsToCommonFormatRequiredData } from './useSourceOfFundsToCommonFormatRequiredData';

const paymentMethodFragment = graphql`
  fragment readPaymentMethodFragment on PaymentMethodV2 @inline {
    ...getIsCountryAllowedFiatFragment
    uuid
    type
    paymentMethodName: name
    currency
    verified
    tradeCapabilities {
      allowBuy
    }

    pickerData {
      __typename

      # We'll need to add all remaining Debit Card types for now, all fragments later
      # Exhaustive switching on typename will then let us know when a new type is added upstream

      ... on BankPickerDataV2 {
        accountName
        accountNumber
      }
      ... on WorldpayCardPickerData {
        institutionName
        bin
        cardLast4
        use3ds
        requires3dsDdc
      }
      ... on CoinbaseFiatAccountPickerData {
        __typename
      }
    }
    limits {
      remainingAvailableLimit {
        currency
        value
      }
      description
    }
  }
`;

export function readPaymentMethod({
  paymentMethodRef,
  countryCode,
  isInstantACHEnabled,
  killSwitches,
  isEnabled,
}: {
  paymentMethodRef: readPaymentMethodFragment$key;
  isEnabled?: boolean;
} & SourceOfFundsToCommonFormatRequiredData): SourceOfFundsData | null {
  const paymentMethod = readInlineData(paymentMethodFragment, paymentMethodRef);

  if (!paymentMethod || paymentMethod.type === 'FIAT_ACCOUNT') {
    return null;
  }

  const meta = (() => {
    switch (paymentMethod.pickerData?.__typename) {
      case 'BankPickerDataV2':
        return paymentMethod.pickerData.accountNumber;
      case 'WorldpayCardPickerData':
        return paymentMethod.pickerData.cardLast4;
      default:
        return '';
    }
  })();

  // TODO [ONRAMP-1774]: clean this up when we have proper icons / urls.
  const iconName: IconName = (() => {
    switch (paymentMethod.pickerData?.__typename) {
      case 'BankPickerDataV2':
        return 'bank';
      case 'WorldpayCardPickerData':
        return 'card';
      default:
        return 'cash';
    }
  })();

  const applePayIconUrl = (() => {
    if (paymentMethod.type === 'APPLE_PAY') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return new URL(applePayLogo.src, host).toString();
    }
    return '';
  })();
  const name: string = (() => {
    if (paymentMethod.type === 'APPLE_PAY') {
      return paymentMethod.paymentMethodName;
    }
    switch (paymentMethod.pickerData?.__typename) {
      case 'BankPickerDataV2':
        // TODO [ONRAMP-1775]: verify this is actually what we want, lolz.
        return paymentMethod.pickerData.accountName;
      case 'WorldpayCardPickerData':
        return paymentMethod.pickerData.institutionName;
      default:
        return '';
    }
  })();

  const cardInfo = (() => {
    if (
      paymentMethod.pickerData?.__typename === 'WorldpayCardPickerData' &&
      paymentMethod.pickerData.use3ds
    ) {
      return {
        bin: paymentMethod.pickerData.bin,
        use3ds: paymentMethod.pickerData.use3ds,
        requires3dsDdc: paymentMethod.pickerData.requires3dsDdc,
      };
    }
    return undefined;
  })();

  return {
    type: 'paymentMethod',
    paymentMethodType: paymentMethod.type as PaymentMethodTypeV2,
    uuid: paymentMethod.uuid,
    title: name,
    meta,
    currency: paymentMethod.currency,
    description: paymentMethod.limits?.description,
    iconData: {
      iconName,
      iconUrl: applePayIconUrl,
    },
    cardInfo,
    limitData: {
      fiat: {
        value: paymentMethod.limits?.remainingAvailableLimit.value,
        currency: paymentMethod.limits?.remainingAvailableLimit.currency,
      },
    },
    verified: paymentMethod.verified,

    isDisabled:
      isKilledFromKillSwitch({
        pickerDataTypeName: paymentMethod.pickerData?.__typename,
        killSwitches,
      }) ||
      // isEnabled is from the new sources of funds API
      isEnabled !== undefined
        ? !isEnabled
        : !isPaymentMethodEnabled({
            countryCode,
            isPmVerified: paymentMethod.verified,
            pmAllowsBuys: paymentMethod.tradeCapabilities?.allowBuy ?? false,
            pmRef: paymentMethod,
            isInstantACHEnabled,
          }),
  };
}

export function isKilledFromKillSwitch({
  pickerDataTypeName,
  killSwitches,
}: {
  pickerDataTypeName:
    | NonNullable<readPaymentMethodFragment$data['pickerData']>['__typename']
    | null
    | undefined;
  killSwitches: KillSwitchesConfigType;
}): boolean {
  if (pickerDataTypeName && pickerDataTypeName !== '%other') {
    const killswitchKey = PMTypeToKillswitchMap[pickerDataTypeName];
    return (killswitchKey && killSwitches[killswitchKey]) || false;
  }
  return true;
}

export function isPaymentMethodEnabled({
  countryCode,
  pmAllowsBuys,
  isPmVerified,
  pmRef,
  isInstantACHEnabled,
}: {
  countryCode: string;
  pmAllowsBuys: boolean;
  isPmVerified: boolean;
  pmRef: getIsCountryAllowedFiatFragment$key | null | undefined;
  isInstantACHEnabled: boolean;
}): boolean {
  return (
    isPmVerified &&
    pmAllowsBuys &&
    (pmRef
      ? isFiatAccountAllowed({
          countryCode,
          pm: pmRef,
          isInstantACHEnabled,
        })
      : false)
  );
}

// Killswitch key for each PM type.
const PMTypeToKillswitchMap: Record<
  Exclude<NonNullable<readPaymentMethodFragment$data['pickerData']>['__typename'], '%other'>,
  keyof KillSwitchesConfigType | null
> = {
  BankPickerDataV2: 'kill_cbpay_instant_ach',
  WorldpayCardPickerData: 'kill_cbpay_non_3ds_cards',
  CoinbaseFiatAccountPickerData: 'kill_cbpay_cash_accounts',
};
