const copyToClipboard = (text: string | null) => {
  function selectContent(t: HTMLElement) {
    function rangeIsEmpty(range: Range) {
      if (range.startContainer !== range.endContainer || range.startOffset !== range.endOffset) {
        return false;
      }

      return true;
    }

    let currentRange;

    if (!('window' in global)) return;

    try {
      // TODO [ONRAMP-1770]: fix - copied from coinbase-www
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentRange = window.getSelection()!.getRangeAt(0);
    } catch (err) {
      currentRange = document.createRange();
    }

    const range = document.createRange();

    if (!rangeIsEmpty(currentRange) && t.getAttribute('data-force-autoselect') !== '1') {
      return;
    }

    // TODO [ONRAMP-1770]: fix - copied from coinbase-www
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.getSelection()!.removeAllRanges();
    range.selectNodeContents(t);
    // TODO [ONRAMP-1770]: fix - copied from coinbase-www
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.getSelection()!.addRange(range);
  }

  const node = document.createElement('DIV');
  node.textContent = text;
  document.body.appendChild(node);
  selectContent(node);
  document.execCommand('copy');
  node?.parentNode?.removeChild(node);
  return true;
};

export default copyToClipboard;
