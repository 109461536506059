// eslint-disable-next-line no-restricted-imports -- this file is the only place where we should use js-cookie
import Cookies from 'js-cookie';
import ms from 'ms';

import { isNotNullish } from '../types';

import type { CookieName, CookieNameAndArgs, CookieSettings } from '.';
import { getCookieKey, getCookieOptions } from '.';

export type ClientCookieName = {
  [Cookie in CookieName]: CookieSettings[Cookie] extends { httpOnly: true } ? never : Cookie;
}[CookieName];

const getClientCookieOptions = (cookieName: ClientCookieName) => {
  const { maxAge, ...cookieSettings } = getCookieOptions(cookieName);
  return {
    ...cookieSettings,
    expires: isNotNullish(maxAge) ? maxAge / ms('1 day') : undefined,
  };
};

export const setCookie = ({
  value,
  ...cookieNameAndArgs
}: CookieNameAndArgs<ClientCookieName> & { value: string }) => {
  const key = getCookieKey(cookieNameAndArgs);
  Cookies.set(key, value, getClientCookieOptions(cookieNameAndArgs.cookieName));
};

export const getCookie = (cookieNameAndArgs: CookieNameAndArgs<ClientCookieName>) => {
  return Cookies.get(getCookieKey(cookieNameAndArgs));
};

export const clearCookie = (cookieNameAndArgs: CookieNameAndArgs<ClientCookieName>) => {
  Cookies.remove(
    getCookieKey(cookieNameAndArgs),
    getClientCookieOptions(cookieNameAndArgs.cookieName),
  );
};
