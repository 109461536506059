/**
 * @generated SignedSource<<f8bdf643aa777fb1d6b37435280cf42b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type TwoFactorMethod = "sms" | "totp" | "u2f" | "%future added value";
export type twoFactorMethodSelectorQuery$variables = {};
export type twoFactorMethodSelectorQuery$data = {
  readonly viewer: {
    readonly userProperties: {
      readonly secondFactor: {
        readonly twoFactorMethod: TwoFactorMethod | null;
      } | null;
    };
  } | null;
};
export type twoFactorMethodSelectorQuery = {
  response: twoFactorMethodSelectorQuery$data;
  variables: twoFactorMethodSelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "SecondFactor",
  "kind": "LinkedField",
  "name": "secondFactor",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "twoFactorMethod",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "twoFactorMethodSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "kind": "RequiredField",
            "field": {
              "alias": null,
              "args": null,
              "concreteType": "UserProperties",
              "kind": "LinkedField",
              "name": "userProperties",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            "action": "LOG",
            "path": "viewer.userProperties"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "twoFactorMethodSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5830e1dac293d5a3b76c89750ce075ca",
    "id": null,
    "metadata": {},
    "name": "twoFactorMethodSelectorQuery",
    "operationKind": "query",
    "text": "query twoFactorMethodSelectorQuery {\n  viewer {\n    userProperties {\n      secondFactor {\n        twoFactorMethod\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "73db1e86abb10cb9e714935791f70e4c";

export default node;
