import { useIntl } from 'react-intl';
import { policyRestrictionsGuardMessages } from '@onramp/components/PolicyRestrictionsGuard/messages';
import type { twoFactorMethodSelectorQuery$data } from '@onramp/data/__generated__/twoFactorMethodSelectorQuery.graphql';
import { isMobileExperience } from '@onramp/utils/postMessage';
import { assertPresence } from '@onramp/utils/relayUtils';
import { atom, useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import { graphql } from '@cbhq/data-layer';

import { atomWithQueryAndMap } from '../utils';

const query = graphql`
  query twoFactorMethodSelectorQuery {
    viewer {
      userProperties @required(action: LOG) {
        secondFactor {
          twoFactorMethod
        }
      }
    }
  }
`;

const emptyTwoFactorMethodSelector = atom(undefined);

export const twoFactorMethodSelector = atomWithQueryAndMap({
  query,
  variables: () => ({}),
  mapResponse: (data: twoFactorMethodSelectorQuery$data) =>
    data.viewer?.userProperties.secondFactor?.twoFactorMethod,
});

export const useTwoFactorMethod = () => {
  const { formatMessage } = useIntl();
  // only call this API in webviews if there is an error will get the generic error screen
  const twoFactorMethod = useAtomValue(
    isMobileExperience() ? twoFactorMethodSelector : emptyTwoFactorMethodSelector,
  );
  // this check is only used in policyRestrictionsGuard so if this is not made will be ignored for non webviews
  if (twoFactorMethod === undefined) {
    return '';
  }
  return assertPresence(twoFactorMethod, {
    debugMessage: 'Failed to load 2fa method',
    message: formatMessage(policyRestrictionsGuardMessages.failedToLoadAccountInfo),
    mockEnvVar: 'TWO_FACTOR_METHOD',
  });
};

export const twoFactorMethodLoadable = loadable(twoFactorMethodSelector);
