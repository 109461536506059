import { useReffedFunction } from '../useReffedFunction';

import type { WidgetCommonEventOptionalParametersMapping } from './eventDeclarations';
import type { EventData, EventName, LogEventOptions } from './logEvent';
import { logEvent } from './logEvent';
import type { BaseLogWidgetEventFunctionType } from './useLogWidgetEvent';

type LogEventFunctionType<ParametersMapping extends WidgetCommonEventOptionalParametersMapping> =
  BaseLogWidgetEventFunctionType<ParametersMapping>;

export const useLogEvent = <
  ParametersMapping extends WidgetCommonEventOptionalParametersMapping = WidgetCommonEventOptionalParametersMapping,
>(): LogEventFunctionType<ParametersMapping> => {
  return useReffedFunction((eventName, ...args) =>
    logEvent(
      `onramp.${eventName}` as EventName,
      ...(args as [metadata: EventData, options?: LogEventOptions]),
    ),
  );
};
