import { useContext } from 'react';
import type { WidgetKey } from '@onramp/shared/appParams.schema';

import { AppManagerProviderContext } from './AppManagerProvider';
import type { DefinedWidgetParameters } from './types';

export const useSafeAppParams = () => useContext(AppManagerProviderContext);

export const useAppParams = () => {
  const params = useSafeAppParams();
  if (!params) {
    throw new Error('App Manager Provider not present');
  }
  return params;
};

// Use widget specific params inside app routes
export function useWidgetParams<T extends WidgetKey>(widget: T): DefinedWidgetParameters<T> {
  const params = useAppParams();
  if (!params[widget]) {
    throw new Error(`${widget} app params not initialized`);
  }
  return params[widget] as DefinedWidgetParameters<T>;
}
