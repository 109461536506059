/**
 * @generated SignedSource<<b2583649e1e905237920cb580804e3ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useParseSourcesOfFundsAccountDepositInformationFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"readDepositInformationFragment">;
  readonly " $fragmentType": "useParseSourcesOfFundsAccountDepositInformationFragment";
};
export type useParseSourcesOfFundsAccountDepositInformationFragment$key = {
  readonly " $data"?: useParseSourcesOfFundsAccountDepositInformationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useParseSourcesOfFundsAccountDepositInformationFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useParseSourcesOfFundsAccountDepositInformationFragment"
};

(node as any).hash = "15ca3c698837915f3c5be06aba4ae557";

export default node;
