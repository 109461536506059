import { useCallback } from 'react';
import type { GraphQLTaggedNode, OperationType } from '@cbhq/data-layer';
import { fetchQuery, useRelayEnvironment } from '@cbhq/data-layer';

export function useQueryFetcher() {
  const environment = useRelayEnvironment();

  return useCallback(
    async <T extends OperationType>(
      query: GraphQLTaggedNode,
      params: T['variables'],
      config?: Parameters<typeof fetchQuery>[3],
    ): Promise<T['response']> => {
      /*
       * It is very easy to forget calling toPromise(). This hooks
       * simplifies that a bit and takes care of this and using context.
       */
      return fetchQuery<T>(environment, query, params, config).toPromise();
    },
    [environment],
  );
}
