/* eslint-disable relay/unused-fields */
import type { readPrimaryPaymentMethodFragment$key } from '@onramp/data/__generated__/readPrimaryPaymentMethodFragment.graphql';
import type { readPrimaryPaymentMethodSoFFragment$key } from '@onramp/data/__generated__/readPrimaryPaymentMethodSoFFragment.graphql';
import type { useDefaultSourceOfFundsViewerFragment$data } from '@onramp/data/__generated__/useDefaultSourceOfFundsViewerFragment.graphql';
import type { BuyWidgetSchema } from '@onramp/shared/appParams.schema';
import {
  isKilledFromKillSwitch,
  isPaymentMethodEnabled,
} from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readPaymentMethod';
import type { SourceOfFundsToCommonFormatRequiredData } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/useSourceOfFundsToCommonFormatRequiredData';
import { isNotNullish } from '@onramp/utils/types';
import type { BigSource } from 'big.js';
import bigJs from 'big.js';
import type { z } from 'zod';
import { graphql, readInlineData } from '@cbhq/data-layer';

const readPrimaryPaymentMethodSoFFragment = graphql`
  fragment readPrimaryPaymentMethodSoFFragment on CBPaySourceOfFunds @inline {
    __typename
    ... on CBPaySourceOfFundsPaymentMethod {
      isEnabled
      paymentMethod {
        ...readPrimaryPaymentMethodFragment
        # eslint-disable-next-line relay/must-colocate-fragment-spreads
        ...getIsCountryAllowedFiatFragment
        tradeCapabilities {
          allowBuy
        }
        verified
        pickerData {
          __typename
        }
      }
    }
  }
`;

const readPrimaryPaymentMethodFragment = graphql`
  fragment readPrimaryPaymentMethodFragment on PaymentMethodV2 @inline {
    uuid
    type
    primaryBuy
    limits {
      remainingAvailableLimit {
        value
      }
    }
  }
`;

type ReadPrimaryPaymentMethodParams = SourceOfFundsToCommonFormatRequiredData & {
  viewer: useDefaultSourceOfFundsViewerFragment$data;
  canMakePaymentsWithActiveCard: boolean | undefined;
  fiatAmount?: BigSource;
  defaultPaymentMethod?: z.infer<typeof BuyWidgetSchema>['defaultPaymentMethod'];
  applePayEnabled: boolean;
};

export const readPrimaryPaymentMethod = ({
  canMakePaymentsWithActiveCard,
  viewer,
  fiatAmount,
  countryCode,
  isInstantACHEnabled,
  killSwitches,
  defaultPaymentMethod,
  applePayEnabled,
}: ReadPrimaryPaymentMethodParams) => {
  const filteredPms =
    viewer.cbPaySourcesOfFunds
      ?.filter(isNotNullish)
      .map((e) =>
        readInlineData<readPrimaryPaymentMethodSoFFragment$key>(
          readPrimaryPaymentMethodSoFFragment,
          e,
        ),
      )
      .filter((e): e is typeof e & { __typename: 'CBPaySourceOfFundsPaymentMethod' } => {
        return e.__typename === 'CBPaySourceOfFundsPaymentMethod';
      })
      .filter((e) => {
        const killed = isKilledFromKillSwitch({
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
          pickerDataTypeName: e.paymentMethod?.pickerData?.__typename as any,
          killSwitches,
        });

        // isEnabled is from the new sources of funds API
        const enabledSoF =
          e.isEnabled !== undefined
            ? Boolean(e.isEnabled)
            : isPaymentMethodEnabled({
                countryCode,
                isPmVerified: e.paymentMethod?.verified ?? false,
                pmAllowsBuys: e.paymentMethod?.tradeCapabilities?.allowBuy ?? false,
                pmRef: e.paymentMethod,
                isInstantACHEnabled,
              });

        const applePayDisabled =
          e.paymentMethod?.pickerData?.__typename === 'ApplePayPickerDataV2' && !applePayEnabled;

        const isDisabled = killed || !enabledSoF || applePayDisabled;

        return !isDisabled;
      })
      .map((pm) =>
        readInlineData<readPrimaryPaymentMethodFragment$key>(
          readPrimaryPaymentMethodFragment,
          pm.paymentMethod,
        ),
      )
      .filter(isNotNullish) ?? [];

  if (fiatAmount) {
    const amount = bigJs(fiatAmount);
    filteredPms.sort((pm1, pm2) => {
      const hasEnoughLimit1 = amount.lte(pm1.limits?.remainingAvailableLimit.value ?? 0);
      const hasEnoughLimit2 = amount.lte(pm2.limits?.remainingAvailableLimit.value ?? 0);

      if (hasEnoughLimit1 && !hasEnoughLimit2) {
        // pm1 should come first
        return -1;
      }

      if (!hasEnoughLimit1 && hasEnoughLimit2) {
        // pm2 should come first
        return 1;
      }

      return 0;
    });
  }

  const primaryPm = filteredPms?.find((pm) => {
    if (defaultPaymentMethod) {
      switch (defaultPaymentMethod) {
        case 'APPLE_PAY':
          return pm.type === 'APPLE_PAY' && canMakePaymentsWithActiveCard;
        case 'ACH_BANK_ACCOUNT':
          return pm.type === 'ACH_BANK_ACCOUNT';
        case 'CARD':
          return pm.type === 'WORLDPAY_CARD';
        default:
          return false;
      }
    }

    // TODO [ONRAMP-1759]: change logic when primaryBuy field works; return ach, card, or apple pay for now
    return (
      pm.type === 'ACH_BANK_ACCOUNT' ||
      pm.type === 'WORLDPAY_CARD' ||
      (pm.type === 'APPLE_PAY' && canMakePaymentsWithActiveCard)
    );
  });

  // Default to first payment method if no primary payment method is found above with defaultPaymentMethod param
  if (!primaryPm && filteredPms.length > 0 && defaultPaymentMethod) {
    return filteredPms[0];
  }

  return primaryPm;
};
