/**
 * @generated SignedSource<<84bc5ed4ff28d26171db09ff0fc225d6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type countryCodeSelectorQuery$variables = {};
export type countryCodeSelectorQuery$data = {
  readonly viewer: {
    readonly userProperties: {
      readonly country: {
        readonly code: string;
      } | null;
    };
  };
};
export type countryCodeSelectorQuery = {
  response: countryCodeSelectorQuery$data;
  variables: countryCodeSelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "countryCodeSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "countryCodeSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5dffd9a87c285e24dd4a0df6f5d8bed8",
    "id": null,
    "metadata": {},
    "name": "countryCodeSelectorQuery",
    "operationKind": "query",
    "text": "query countryCodeSelectorQuery {\n  viewer {\n    userProperties {\n      country {\n        code\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ac4cb146fd1de07c932c51f6e7dfcc5";

export default node;
