import fromPairs from 'lodash/fromPairs';
import type { z } from 'zod';

export const getQueryParams = () => new URL(window.location.href).searchParams;
export const getQueryParamsAsRecord = () => fromPairs([...getQueryParams().entries()]);

export const getQueryParamsObjectFromZodSchema = <T extends z.ZodObject<z.ZodRawShape>>(
  schema: T | z.ZodEffects<T>,
  /** query params parsed as a `Record`, like the result of {@link getQueryParamsAsRecord getQueryParamsAsRecord} — needed for server support */
  queryParams?: Record<string, unknown>,
): z.infer<T> => {
  const params = queryParams ?? getQueryParamsAsRecord();

  const shape = (() => {
    if ('shape' in schema) {
      return schema.shape;
    }

    return schema._def.schema.shape;
  })();

  return Object.keys(shape).reduce<Record<string, unknown>>((acc, curr) => {
    acc[curr] = params[curr] || undefined;
    if (acc[curr]) {
      // try to parse stringified objects
      try {
        acc[curr] = JSON.parse(decodeURIComponent(acc[curr] as string));
      } catch {
        // do nothing
      }
    }
    return acc;
  }, {});
};
