/**
 * @generated SignedSource<<74f4fcf1c02fd382a542a97024fc0b1d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useFilterCBPayAssetsByDestinationWalletsFragment$data = {
  readonly networks: ReadonlyArray<{
    readonly name: string | null;
  } | null> | null;
  readonly symbol: string | null;
  readonly " $fragmentType": "useFilterCBPayAssetsByDestinationWalletsFragment";
};
export type useFilterCBPayAssetsByDestinationWalletsFragment$key = {
  readonly " $data"?: useFilterCBPayAssetsByDestinationWalletsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"useFilterCBPayAssetsByDestinationWalletsFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useFilterCBPayAssetsByDestinationWalletsFragment"
};

(node as any).hash = "912f84cc0f762edace50254d1aeff11b";

export default node;
