/**
 * @generated SignedSource<<fdaee5471b159e9d95d4bcd3cbdb3c47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readCryptoAccountsFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"readCryptoAccountFragment">;
  readonly " $fragmentType": "readCryptoAccountsFragment";
};
export type readCryptoAccountsFragment$key = {
  readonly " $data"?: readCryptoAccountsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readCryptoAccountsFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readCryptoAccountsFragment"
};

(node as any).hash = "dc66e9bfce5b747647510cb564f456e2";

export default node;
