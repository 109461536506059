import { useMemo } from 'react';
import { useTransferType } from '@onramp/hooks/useTransferType';
import { useBuyWidgetState } from '@onramp/state/BuyWidgetState/hooks';
import { useSafeOnrampNetworks } from '@onramp/state/contexts';
// eslint-disable-next-line import/no-cycle
import { assetMetadataSelector } from '@onramp/state/recoil/appParamsState';
import { isGsscValidAtom } from '@onramp/state/recoil/authState';
// eslint-disable-next-line import/no-cycle
import { supportedGuestCheckoutAssetsLoadable } from '@onramp/state/recoil/guestCheckoutState';
// eslint-disable-next-line import/no-cycle
import { useKillSwitches } from '@onramp/utils/killswitches/useKillSwitches';
import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';

import { getUseCallHookFnOnMount } from '../getUseCallFnOnMount';
// eslint-disable-next-line import/no-cycle
import { getIsGuestCheckoutPath } from '../guestCheckoutUtils';
import { is3ds } from '../is3ds';
import { useReffedFunction } from '../useReffedFunction';
import { wrapHookWithCallOnce } from '../wrapWithCallOnce';

import type {
  OnrampEventName,
  OnrampEventOptionalParametersMapping,
  ReportedOnrampStateParameters,
} from './eventDeclarations';
import { WidgetOnrampEvents } from './eventDeclarations';
import { logEvent } from './logEvent';
import { logEventOnceCacheMap } from './logEventOnce';
import type { BaseLogWidgetEventFunctionType } from './useLogWidgetEvent';

type LogOnrampEventFunctionType =
  BaseLogWidgetEventFunctionType<OnrampEventOptionalParametersMapping>;

export const useLogOnrampEvent = () => {
  const { transferType } = useTransferType();
  const { selectedAsset: selectedAssetField, sourceOfFunds: sourceOfFundsField } =
    useBuyWidgetState();
  const { activeNetwork } = useSafeOnrampNetworks() ?? {};
  const isGsscValid = useAtomValue(isGsscValidAtom);
  const isGuestCheckout = getIsGuestCheckoutPath();
  const killSwitches = useKillSwitches();
  const assetMetadataLoadable = useAtomValue(
    useMemo(
      () =>
        isGuestCheckout ? supportedGuestCheckoutAssetsLoadable : loadable(assetMetadataSelector),
      [isGuestCheckout],
    ),
  );
  const assetMetadata =
    assetMetadataLoadable.state === 'hasData' ? assetMetadataLoadable.data : undefined;

  return useReffedFunction<LogOnrampEventFunctionType>(
    (eventName, ...[optionalEventParameters, options]) => {
      const eventData = WidgetOnrampEvents[eventName];

      const selectedAsset = selectedAssetField.value.ticker;
      const selectedPaymentMethod =
        sourceOfFundsField.value?.paymentMethodType || sourceOfFundsField.value?.type;

      const reportableState: ReportedOnrampStateParameters = {
        transferType: String(transferType),
        selectedAsset: selectedAsset || 'undefined',
        selectedPaymentMethod: selectedPaymentMethod || 'undefined',
        activeNetwork: activeNetwork?.id || 'undefined',
        is3ds: is3ds(sourceOfFundsField.value),
        // Computed below
        isLayer2Network: 'undefined',
        isGsscValid,
      };

      const eventMetadata = {
        ...eventData,
        ...reportableState,
        ...optionalEventParameters,
      };

      // Do any computations on parameters after merging new event data in case of overrides
      eventMetadata.isLayer2Network = String(
        assetMetadata?.[eventMetadata.selectedAsset]?.blockchain !== eventMetadata.activeNetwork,
      );

      logEvent(`onramp.${eventName}`, eventMetadata, {
        ...options,
        isBroadcastToSDKEnabled: !killSwitches.kill_cbpay_broadcast_analytics_events,
      });
    },
  );
};

export const useLogOnrampEventOnce = wrapHookWithCallOnce(useLogOnrampEvent, {
  cacheMap: logEventOnceCacheMap,
  argsToKeyFn: (eventName: OnrampEventName) => eventName,
});

export const useLogOnrampEventOnMount = getUseCallHookFnOnMount(useLogOnrampEvent);
export const useLogOnrampEventOnceOnMount = getUseCallHookFnOnMount(useLogOnrampEventOnce);
