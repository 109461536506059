import type { readAccountViewerAssetUuidFragment$key } from '@onramp/data/__generated__/readAccountViewerAssetUuidFragment.graphql';
import { graphql, readInlineData } from '@cbhq/data-layer';

const readAccountViewerAssetUuidFragment = graphql`
  fragment readAccountViewerAssetUuidFragment on Account @inline {
    assetOrFiatCurrency {
      __typename
      ... on ViewerAsset {
        asset {
          uuid
        }
      }
    }
  }
`;

/** Reads the corresponding asset uuid from the crypto account */
export const readAccountViewerAssetUuid = (
  accountRef: readAccountViewerAssetUuidFragment$key,
): string | undefined => {
  const account = readInlineData(readAccountViewerAssetUuidFragment, accountRef);

  return (
    (account.assetOrFiatCurrency?.__typename === 'ViewerAsset' &&
      account.assetOrFiatCurrency.asset.uuid) ||
    undefined
  );
};
