import { useMemo } from 'react';
import { useUserEmailLoadable } from '@onramp/state/recoil/selectors/userEmailSelector';
import { isLocalDevelopment, isProduction } from '@onramp/utils/environment/sharedEnv';

export function useCanSeeInternalInfo() {
  const loadable = useUserEmailLoadable();
  const userEmail = useMemo(
    () => (loadable.state === 'hasData' ? loadable.data : null),
    [loadable],
  );

  return useMemo(() => {
    const hasCoinbaseEmail = userEmail?.endsWith('@coinbase.com') ?? false;
    return !isProduction || isLocalDevelopment || hasCoinbaseEmail;
  }, [userEmail]);
}
