/**
 * @generated SignedSource<<abfbebb2c8ccd051cf018f55c2a8fd3f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type userEmailSelectorQuery$variables = {};
export type userEmailSelectorQuery$data = {
  readonly viewer: {
    readonly userProperties: {
      readonly email: string | null;
    };
  };
};
export type userEmailSelectorQuery = {
  response: userEmailSelectorQuery$data;
  variables: userEmailSelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "userEmailSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "userEmailSelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0347310dbe7369166f91f24c83a839a0",
    "id": null,
    "metadata": {},
    "name": "userEmailSelectorQuery",
    "operationKind": "query",
    "text": "query userEmailSelectorQuery {\n  viewer {\n    userProperties {\n      email\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "944f7ab5d00503ce1f8e15e3fa034956";

export default node;
