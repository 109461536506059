import type {
  readPaymentMethodsFragment$data,
  readPaymentMethodsFragment$key,
} from '@onramp/data/__generated__/readPaymentMethodsFragment.graphql';
import { readPaymentMethod } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readPaymentMethod';
import type { SourceOfFundsToCommonFormatRequiredData } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/useSourceOfFundsToCommonFormatRequiredData';
import { isNonEmpty } from '@onramp/utils/isNonEmpty';
import { graphql, readInlineData } from '@cbhq/data-layer';

const paymentMethodFragment = graphql`
  fragment readPaymentMethodsFragment on PaymentMethodV2 @inline {
    ...readPaymentMethodFragment
    uuid
    primaryBuy
  }
`;

type ReadPaymentMethodsArgs = {
  paymentMethodsRef: {
    paymentMethod: readPaymentMethodsFragment$key;
    isEnabled?: boolean;
  }[];
  readSourceOfFundsData: SourceOfFundsToCommonFormatRequiredData;
  applePayAvailable: boolean;
};

export function readPaymentMethods({
  paymentMethodsRef,
  readSourceOfFundsData,
  applePayAvailable,
}: ReadPaymentMethodsArgs) {
  const rawPaymentMethods = paymentMethodsRef
    .map((ref) => {
      const paymentMethod = readInlineData(paymentMethodFragment, ref.paymentMethod);

      return (
        paymentMethod && {
          paymentMethod,
          isEnabled: ref.isEnabled,
        }
      );
    })
    .filter(isNonEmpty);

  const rawPaymentMethodsMap = rawPaymentMethods.reduce((acc, el) => {
    acc.set(el.paymentMethod.uuid, el.paymentMethod);
    return acc;
  }, new Map<string, readPaymentMethodsFragment$data>());

  const paymentMethods = rawPaymentMethods
    .map((pm) =>
      readPaymentMethod({
        paymentMethodRef: pm.paymentMethod,
        isEnabled: pm.isEnabled,
        ...readSourceOfFundsData,
      }),
    )
    .filter(isNonEmpty)
    .filter(
      (pm) =>
        pm.paymentMethodType === 'WORLDPAY_CARD' ||
        pm.paymentMethodType === 'ACH_BANK_ACCOUNT' ||
        (applePayAvailable && pm.paymentMethodType === 'APPLE_PAY'),
    )
    .sort(({ isDisabled }) => (isDisabled ? 1 : -1));

  const enabledPaymentMethods = paymentMethods.filter((acct) => !acct.isDisabled);

  const primaryPaymentMethod =
    enabledPaymentMethods.find(
      (acct) => acct.uuid && rawPaymentMethodsMap.get(acct.uuid)?.primaryBuy,
    ) || enabledPaymentMethods[0];

  return {
    fiatAccounts: paymentMethods,
    primaryFiatAccount: primaryPaymentMethod,
  };
}
