/**
 * @generated SignedSource<<8ed2aca80a670410ecba4a0c0e686391>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readCryptoAccountFragment$data = {
  readonly assetOrFiatCurrency: {
    readonly __typename: "TiersCurrency";
    readonly name: string;
  } | {
    readonly __typename: "ViewerAsset";
    readonly asset: {
      readonly imageUrl: string;
      readonly name: string;
      readonly symbol: string;
      readonly uuid: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly name: string;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"getIsCountryAllowedAccountFragment" | "readAccountBalanceFiatFragment" | "readAccountBalanceFragment">;
  readonly " $fragmentType": "readCryptoAccountFragment";
};
export type readCryptoAccountFragment$key = {
  readonly " $data"?: readCryptoAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readCryptoAccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readCryptoAccountFragment"
};

(node as any).hash = "581cd72e80f696c4e876a3293948e518";

export default node;
