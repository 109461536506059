import { createContext, useContext, useEffect, useState } from 'react';
import type { ReactChild } from 'react';
import { useRouter } from 'next/router';

const InitialScreenContext = createContext<boolean>(false);

export const useIsInitialScreen = () => useContext(InitialScreenContext);

export const IsInitialScreenProvider = ({ children }: { children: ReactChild }) => {
  const [isInitialScreen, setIsInitialScreen] = useState(true);
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', () => setIsInitialScreen(false));
  }, [router, setIsInitialScreen]);

  return (
    <InitialScreenContext.Provider value={isInitialScreen}>
      {children}
    </InitialScreenContext.Provider>
  );
};
