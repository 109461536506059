import { CB_CLIENT } from '@onramp/config/cbClient';
// eslint-disable-next-line import/no-cycle
import { getRouteWithParams } from '@onramp/hooks/useOnRampRouter';
import {
  APP_ID_HEADER,
  CB_CLIENT_HEADER,
  CB_CLIENT_SESSION_ID_HEADER,
  CSRF_TOKEN_HEADER,
  PLATFORM_ATTRIBUTION_HEADER,
  USER_ID_HEADER,
} from '@onramp/shared/headerKeys';
import { getAnalyticsHeaders } from '@cbhq/client-analytics';
import { createFetch, defaultFetchConfig } from '@cbhq/data-layer';
import type { CbDataRequestInit } from '@cbhq/data-layer/dts/network/nonThrowingFetch';

import { leaveBreadcrumb } from './bugsnag';
import { clientSessionIdStore } from './clientSessionIdStore';
import { getCustomLogger } from './logger';

// Common headers for REST and GQL requests
export const getCommonHeaders = (): Record<string, string> => {
  const userUuid = clientSessionIdStore.getUserUuid();
  const sessionId = clientSessionIdStore.getClientSessionId();
  const csrfToken = clientSessionIdStore.getCsrfToken();
  const appId = clientSessionIdStore.getAppId();
  const appAttribution = clientSessionIdStore.getPlatformAttribution();

  return {
    ...getAnalyticsHeaders(),
    'Content-Type': 'application/json',
    [CB_CLIENT_HEADER]: CB_CLIENT,
    [CB_CLIENT_SESSION_ID_HEADER]: sessionId,
    ...(appId && { [APP_ID_HEADER]: appId }),
    ...(appAttribution && { [PLATFORM_ATTRIBUTION_HEADER]: appAttribution }),
    ...(userUuid && { [USER_ID_HEADER]: userUuid }),
    ...(csrfToken && { [CSRF_TOKEN_HEADER]: csrfToken }),
  };
};

/**
 * This is a `fetch` instrumented with the default `@cbhq/data-layer` logic, like automatic retries. It will also:
 * - Resolve to a `Response` (like the regular `fetch`) if `res.ok` is `true`
 * - Resolve to an `ApiError` if `res.ok` is `false`
 * - Resolve to a `NetworkError` if the `fetch` promise is rejected
 */
const instrumentedFetch = createFetch({
  ...defaultFetchConfig,
  setHeaders: {
    headers: () => getCommonHeaders(),
  },
  logger: getCustomLogger('instrumented fetch'),
  retryUnauthorized: {
    handleUnauthorized: async () => {
      // Don't try to refresh the token if we're already on the guest checkout flow
      if (window.location.pathname.startsWith('/buy/guest/')) return false;

      // We know that we have no refresh token when we've come through /token/import
      if (clientSessionIdStore.getAuthenticationMethod() === 'token-import') {
        leaveBreadcrumb('Skipping access token refresh via instrumented fetch for imported token');
      } else {
        leaveBreadcrumb('Attempting to refresh access token via instrumented fetch');
        // eslint-disable-next-line no-restricted-globals -- using the global fetch to avoid infinite recursion
        const res = await fetch('/token/refresh', {
          method: 'POST',
          headers: getCommonHeaders(),
        }).catch(() => undefined);

        if (res?.status === 200) {
          return true;
        }
      }

      // eslint-disable-next-line no-restricted-globals -- using the global fetch to avoid infinite recursion
      fetch(getRouteWithParams('Signout'), {
        method: 'POST',
        headers: getCommonHeaders(),
      }).finally(() => {
        window.location.assign(getRouteWithParams('Signin'));
      });

      return false;
    },
  },
});

export async function fetchWithCommonHeaders(url: string, init?: CbDataRequestInit) {
  const res = await instrumentedFetch(url, {
    ...init,
    metricTags: { ...init?.metricTags, fetch_url: url.split('?')[0] },
  });

  if (res instanceof Error) throw res;
  return res;
}
