// eslint-disable-next-line @cbhq/no-lib-import
import type { RequiredFieldLogger } from 'relay-runtime/lib/store/RelayStoreTypes';
import { ActionType, AnalyticsEventImportance, ComponentType } from '@cbhq/client-analytics';

import { checkIfApplePayIsSupported } from '../applePay';
import { clientSessionIdStore } from '../clientSessionIdStore';
import type { ErrorType } from '../errors';

import { logEvent } from './logEvent';
import { logEventOnce } from './logEventOnce';
import { useLogEventOnMount } from './useLogEventOnMount';

export type ReportableMetadata = Record<string, string | string[]>;

export type MenuOption = 'website' | 'terms' | 'privacy' | 'paymentMethod';
export type Screen = 'nav_menu' | 'debug_menu' | 'unknown';
export type Widget = 'buy';

/**
 * To allow a new event from being sent without relying on `useLogOnrampEvent`, add its name here.
 * We should strive to migrate events to those hooks unless it's not possible — e.g. reportError can't call a hook, so it
 * needs a regular function for error_handled, etc.
 * */
export type DeprecatedEventName =
  | 'onramp.asset_selected'
  | 'blocked_by_policy_restrictions'
  | 'onramp.unsupported_country'
  | 'onramp.applepay.is_supported'
  | 'checkout.checkoutflow_confirm_buy'
  | 'checkout.checkoutflow_begin'
  | 'onramp.ach_selected'
  | 'onramp.click_ach'
  | 'onramp.nav_click_menu_action'
  | 'onramp.signout'
  | 'onramp.nav_viewed_menu'
  | 'onramp.nav_click_close_menu'
  | 'onramp.nav_click_open_menu'
  | 'error'
  | 'error_handled'
  | 'error_network'
  | 'error_internal'
  | 'onramp.required_gql_field_missing'
  | 'onramp.click_new_transaction'
  | 'onramp.show_send_details'
  | 'onramp.click_back'
  | 'onramp.select_crypto'
  | 'onramp.click_add_payment_method_done'
  | 'onramp.click_verify_card'
  | 'onramp.click_add_card_info'
  | 'onramp.click_add_billing_info'
  | 'onramp.click_add_debit_card'
  | 'onramp.click_add_payment'
  | 'onramp.init_onramp_widget_success'
  | 'onramp.app_boot'
  | 'onramp.submit_crypto_amoount'
  | `onramp.is_instant_ach_enabled.${boolean}`
  | 'onramp.source_of_funds'
  | 'split_test_device_exposed'
  | 'onramp.experiment_exposed'
  | 'onramp.unsupported_browser_locale'
  | 'have_sources_of_funds';
/**
 * TODO [ONRAMP-1932]: Migrate these events to useLogOnrampEvent so we have more state infomation by default.
 */
export const logUnsupportedLocale = (locale: string) => {
  logEventOnce(`onramp.unsupported_browser_locale`, {
    loggingId: '58dab29f-803b-41b4-a965-2300827aac7a',
    componentType: ComponentType.page,
    action: ActionType.change,
    locale,
  });
};
export const logSelectAssetClick = (asset: string) =>
  logEvent('onramp.asset_selected', {
    loggingId: '426ba2fb-a939-4b05-a260-80d4b929287f',
    componentType: ComponentType.button,
    action: ActionType.click,
    asset,
  });

export const logSourceOfFunds = (metadata: {
  isInstantACHEnabled: boolean;
  numOfAchAccounts: number;
  numOfDisabledAchAccounts: number;
  numOfCards: number;
  numOfDisabledCards: number;
}) => {
  logEventOnce('onramp.source_of_funds', {
    loggingId: '06fe8be1-9cb4-41a2-8008-c357f7257afe',
    componentType: ComponentType.unknown,
    action: ActionType.measurement,
    ...metadata,
  });
};

// Used for datadog metric only
export const logInstantAch = (isInstantAchEnabled: boolean) => {
  logEventOnce(`onramp.is_instant_ach_enabled.${isInstantAchEnabled}`, {
    loggingId: isInstantAchEnabled
      ? '39634f61-e67d-4fc9-8b5b-7f0ce5943387'
      : '119c541a-2643-48aa-aa41-d477ca0ad449',
    componentType: ComponentType.unknown,
    action: ActionType.measurement,
  });
};

export const logSubmitCryptoAmount = (metadata: {
  isFiat: boolean;
  fiatCurrency?: string;
  inputAmount: string;
  selectedAsset: string;
  network?: string;
}) =>
  logEvent('onramp.submit_crypto_amoount', {
    loggingId: '21766254-da23-42e3-b1f8-25434fb98e3d',
    componentType: ComponentType.text_input,
    action: ActionType.select,
    ...metadata,
  });

export const useLogAppBoot = () => {
  return useLogEventOnMount('onramp.app_boot', {
    loggingId: '97302073-f410-4d2d-bbc5-8c698f185105',
    componentType: ComponentType.page,
  });
};

export const logInitOnrampWidget = () => {
  const { initMethod, widgetParams } = clientSessionIdStore.getRedactedAppParamsMetadata() ?? {};
  const buyWidgetConfig = widgetParams?.widget === 'buy' ? widgetParams : undefined;
  logEventOnce('onramp.init_onramp_widget_success', {
    loggingId: '5993b1af-5f88-4445-bfa3-271382ee5a40',
    componentType: ComponentType.page,
    action: ActionType.change,
    initMethod,
    ...buyWidgetConfig,
    destinationWallets: buyWidgetConfig?.destinationWallets
      ? JSON.stringify(buyWidgetConfig.destinationWallets)
      : null,
  });
};

export const logClickAddPayment = () =>
  logEvent('onramp.click_add_payment', {
    loggingId: '99e20d96-d066-4314-9a07-0061b26c4e96',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

// Add debit card flow
export const logClickAddDebitCard = () =>
  logEvent('onramp.click_add_debit_card', {
    loggingId: 'c4f00b1f-16d7-4548-bf43-d8f01956be63',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

export const logClickAddBillingInfo = () =>
  logEvent('onramp.click_add_billing_info', {
    loggingId: 'f3d4b712-851a-448a-b5fb-63d51774116a',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

export const logClickAddCardInfo = () =>
  logEvent('onramp.click_add_card_info', {
    loggingId: '5d4a2ef3-f62a-4652-802b-22f056f25ab1',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

export const logClickVerifyCard = () =>
  logEvent('onramp.click_verify_card', {
    loggingId: '5662462b-31ec-40e2-ae3c-cfb90591ff4d',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

export const logClickAddPaymentMethodDone = () =>
  logEvent('onramp.click_add_payment_method_done', {
    loggingId: '3dc6fd93-5356-4c11-a607-78d64d889047',
    componentType: ComponentType.button,
    action: ActionType.click,
  });
// End add debit card flow

export const logSelectCryptoAccount = (selectedCrypto = '') =>
  logEvent('onramp.select_crypto', {
    loggingId: '78c199d4-131f-41a6-8461-22fe9df12672',
    componentType: ComponentType.button,
    action: ActionType.click,
    selectedCrypto,
  });

export const logClickBack = () =>
  logEvent('onramp.click_back', {
    loggingId: '8c810216-c567-4b08-a066-41c5f8e65274',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

export const logClickShowDetails = () =>
  logEvent('onramp.show_send_details', {
    loggingId: '28bf6cde-0035-4412-bb7f-a57505edb0ed',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

export const logClickNewTransaction = () =>
  logEvent(
    'onramp.click_new_transaction',
    {
      loggingId: '3d209d20-2d99-40bf-b107-80f9174ac6f1',
      componentType: ComponentType.button,
      action: ActionType.click,
    },
    { importance: AnalyticsEventImportance.high },
  );

export const logError = (metadata: ReportableMetadata & { errorType: ErrorType }) => {
  logEvent(
    'error',
    {
      componentType: ComponentType.unknown,
      action: ActionType.process,
      ...metadata,
    },
    { importance: AnalyticsEventImportance.high },
  );

  // we still log the old events for compatibility
  logEvent(
    `error_${metadata.errorType}`,
    {
      componentType: ComponentType.unknown,
      action: ActionType.process,
      ...metadata,
    },
    { importance: AnalyticsEventImportance.high },
  );
};

export const logNavOpenMenu = () =>
  logEvent('onramp.nav_click_open_menu', {
    loggingId: '3d15db88-fa71-484b-b5bc-0509e569becf',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

export const logNavCloseMenu = () =>
  logEvent('onramp.nav_click_close_menu', {
    loggingId: 'd89daab7-6d68-40ba-961c-e8713bcaf62b',
    componentType: ComponentType.button,
    action: ActionType.click,
  });

export const logNavViewedMenu = (prevPageName: string) =>
  logEvent('onramp.nav_viewed_menu', {
    loggingId: 'a73f2e11-12b0-4ba1-a860-8f91b4877a80',
    componentType: ComponentType.modal,
    action: ActionType.render,
    prevPageName,
  });

export const logNavSignout = (signoutSource: Screen) =>
  logEvent('onramp.signout', {
    loggingId: 'c15045f9-34f0-488c-bbcb-6e0e5ac38804',
    componentType: ComponentType.button,
    action: ActionType.click,
    signoutSource,
  });

export const logNavMenuAction = (menuOption: MenuOption) =>
  logEvent('onramp.nav_click_menu_action', {
    loggingId: 'b4189026-9eb1-4605-9754-daa3401c1eb5',
    componentType: ComponentType.button,
    action: ActionType.click,
    menuOption,
  });

export const logClickAch = (widget: Widget) => {
  logEvent('onramp.click_ach', {
    loggingId: '5288694f-b5ef-4880-888d-07f7682ee022',
    componentType: ComponentType.button,
    action: ActionType.click,
    widget,
  });
};

export const logAchSelected = (widget: Widget) => {
  logEvent('onramp.ach_selected', {
    loggingId: '1ba6ebb1-e3ff-4458-b0ad-c06ad0afd4e2',
    componentType: ComponentType.button,
    action: ActionType.render,
    widget,
  });
};

export const logCheckoutFlowBeginOnce = () => {
  logEventOnce('checkout.checkoutflow_begin', {
    loggingId: '9d956446-5741-44c4-afc7-dd0a9a273331',
    componentType: ComponentType.page,
    action: ActionType.view,
  });
};

export const logCheckoutFlowConfirmBuy = () => {
  logEvent('checkout.checkoutflow_confirm_buy', {
    loggingId: 'a7c6a26b-e697-497c-9a6b-dfff2fb9dff6',
    componentType: ComponentType.button,
    action: ActionType.click,
  });
};

export const PERF_INITIATING_PURCHASE_METRIC = 'cbpay.perf_initiating_purchase';
export const PERF_BUY_POLL_STATUS_METRIC = 'cbpay.perf_polling_buy';
export const PERF_SEND_POLL_STATUS_METRIC = 'cbpay.perf_polling_send';
export const PERF_OCB_ESTIMATE_LATENCY_METRIC = 'cbpay.perf_ocb_estimate_latency';

export type RequiredFieldLoggerArg = Parameters<RequiredFieldLogger>[0];
export const logRequiredGqlFieldMissing = (
  message: Partial<RequiredFieldLoggerArg> & { message: string; args?: string[] },
) => {
  logEvent('onramp.required_gql_field_missing', {
    componentType: ComponentType.unknown,
    action: ActionType.process,
    ...message,
    // error can have type Error, so we need to convert it to a string to avoid type errors
    error: 'error' in message ? String(message.error) : undefined,
  });
};

// APPLY PAY LOGGERS
export const logIsApplePaySupported = () =>
  logEvent('onramp.applepay.is_supported', {
    loggingId: '5815b5be-c8c6-40db-9579-77fa23bd96a8',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    isApplePaySupported: checkIfApplePayIsSupported(),
  });

export const logApplePayCorrelationIdFailure = () => {
  logEvent('onramp.applepay.get_correlation_id_failure', {
    loggingId: '3da53da3-b209-46ff-bf99-1721686c7b69',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
};

export const logApplePayCreateBuySuccess = (partialErrorCode?: string) => {
  logEvent('onramp.applepay.create_buy_success', {
    loggingId: 'f1259511-92ce-4021-817d-324b2997bc97',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    partialErrorCode,
  });
};

export const logApplePayCreateBuyFailure = () => {
  logEvent('onramp.applepay.create_buy_failure', {
    loggingId: 'fd1b68d0-da7b-426f-a947-bd8a01b83e90',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
};

export const logApplePayAddCard = () => {
  logEvent('onramp.applepay.add_card', {
    loggingId: '8893bbcc-e1bb-4335-b572-4175cac0ecaa',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
};

export const logApplePayAddCardAttemptFailure = ({
  hasApplePaySession,
  hasMerchantIdentifier,
}: {
  hasApplePaySession: boolean;
  hasMerchantIdentifier: boolean;
}) => {
  logEvent('onramp.applepay.add_card_attempt_failure', {
    loggingId: 'd451e650-7e0a-4c61-b812-e61867bbd8f1',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    hasApplePaySession,
    hasMerchantIdentifier,
  });
};

export const logApplePayAddCardResult = (result: boolean) => {
  logEvent('onramp.applepay.add_card_result', {
    loggingId: 'e721c96a-9367-4614-a48a-b94f53d5d94e',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    applePayAddedCard: result,
  });
};

export const logApplePayMinSendAmountFailure = ({
  inputCryptoAmount,
  minCryptoSendAmount,
  type,
}: {
  inputCryptoAmount: string;
  minCryptoSendAmount: string;
  type: 'buy' | 'send';
}) => {
  logEvent('onramp.applepay.min_send_amount_failure', {
    loggingId: 'ce709072-4743-4ed4-ac41-e07dcc53b6c8',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    inputCryptoAmount,
    minCryptoSendAmount,
    type,
  });
};

export const logApplePayClientMissing = () => {
  logEvent('onramp.applepay.client_missing', {
    loggingId: '56b03af6-f936-4706-b90d-8a810720ade1',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
};

export const logApplePaySubmitBuyDoubleResolve = (type: 'resolve' | 'reject') => {
  logEvent('onramp.applepay.submit_buy_promise_double_resolve', {
    loggingId: 'a2c84418-4f95-4b5d-8fa2-21742ebcbb3e',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    type,
  });
};

export const logApplePayCommitBuyFailure = (
  reason: 'killswitch' | 'topLevelGQL' | 'partialError' | 'genericError' | 'unrecognized',
) => {
  logEvent('onramp.applepay.commit_buy_failure', {
    loggingId: 'c2a085e7-6bf4-420e-8a2c-958dc3b88d55',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    reason,
  });
};

export const logApplePayCommitBuySuccess = () => {
  logEvent('onramp.applepay.commit_buy_success', {
    loggingId: 'a8e5f416-20c2-45bb-872c-e116c18aae34',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
};

export const logApplePayPreviewSendAmountSuccess = () => {
  logEvent('onramp.applepay.preview_send_amount_success', {
    loggingId: '0cb15ade-2796-45bb-921a-7efa1ebc7a7e',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
};

export const logApplePayPreviewSendAmountFailure = (
  reason: 'error' | 'unrecognized' | 'topLevelGQL',
) => {
  logEvent('onramp.applepay.preview_send_amount_failure', {
    loggingId: 'b6d9b006-a792-4c46-90fb-07d457137054',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    reason,
  });
};

export const logApplePayCreateSendFailure = ({
  isMaxSend,
  reason,
}: {
  isMaxSend: boolean;
  reason: 'error' | 'unrecognized' | 'topLevelGQL';
}) => {
  logEvent('onramp.applepay.create_send_failure', {
    loggingId: '474cbd41-ee86-4672-afc3-69a2b24fca61',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    isMaxSend,
    reason,
  });
};

export const logApplePayCreateSendSuccess = (type: 'send' | 'maxSend') => {
  logEvent('onramp.applepay.create_send_success', {
    loggingId: '963ee256-38b3-4a66-bc48-9e11ab722d5b',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    type,
  });
};

export const logApplePay2FARequired = () => {
  logEvent('onramp.applepay.2fa_required', {
    loggingId: 'f80b12a4-42f6-4673-a92c-29148079f866',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
};

export const logApplePayCommitSendFailure = (reason: 'error' | 'unrecognized' | 'topLevelGQL') => {
  logEvent('onramp.applepay.commit_send_failure', {
    loggingId: '2096d18c-bd43-48da-a6db-39a41e737d6b',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    reason,
  });
};

export const logApplePayCommitSendSuccess = () => {
  logEvent('onramp.applepay.commit_send_success', {
    loggingId: '4f8d2100-e270-49d0-bb54-4993b6340eb1',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
  });
};

export const logApplePay2FA = ({
  step,
  metadata,
}: {
  step: 'start' | 'cancel' | 'error' | 'success';
  metadata?: ReportableMetadata;
}) => {
  logEvent('onramp.applepay.2fa', {
    loggingId: '92d4b0ee-260a-4d0c-9499-ee4f4b3e42f6',
    componentType: ComponentType.unknown,
    action: ActionType.unknown,
    step,
    metadata,
  });
};
