import type { filterCryptoAccountsByBalanceFragment$key } from '@onramp/data/__generated__/filterCryptoAccountsByBalanceFragment.graphql';
import { graphql, readInlineData } from '@cbhq/data-layer';

const filterCryptoAccountsByBalanceFragment = graphql`
  fragment filterCryptoAccountsByBalanceFragment on Account @inline {
    type
    availableBalanceInNativeCurrency {
      value
    }
    assetOrFiatCurrency {
      __typename
    }
  }
`;

/**
 * The required fiat balance for the account to be considered in the default display logic.
 * Default is > 0 - anything with a balance in there.
 */
const MINIMUM_REQUIRED_BALANCE = 0;

/** Filter by crypto accounts with a minimum balance */
export const filterCryptoAccountsByBalance = (
  accountRef: filterCryptoAccountsByBalanceFragment$key,
  minimumBalance: number = MINIMUM_REQUIRED_BALANCE,
): boolean => {
  const account = readInlineData(filterCryptoAccountsByBalanceFragment, accountRef);

  return (
    account.type === 'WALLET' &&
    account.assetOrFiatCurrency?.__typename === 'ViewerAsset' &&
    (account.availableBalanceInNativeCurrency?.value || 0) > minimumBalance
  );
};
