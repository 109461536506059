import type { Complete } from '@onramp/utils/types';
import { useFields } from '@cb/forms';

import type { BuyWidgetStateProps } from './common';
import { BuyWidgetState } from './common';

export const useBuyWidgetState = () =>
  useFields<Complete<BuyWidgetStateProps>, Record<keyof BuyWidgetStateProps, Error>>(
    BuyWidgetState,
  );

type Entries<T> = { [K in keyof T]: readonly [K, T[K]] }[keyof T];

export const useBuyWidgetStateValues = () => {
  const buyWidgetState = useBuyWidgetState();
  return (Object.entries(buyWidgetState) as Entries<typeof buyWidgetState>[]).reduce<
    Partial<BuyWidgetStateProps>
  >((acc, [key, value]) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment -- TS doesn't realize that `key` and `value` will always have compatible types
    acc[key] = value.value as any;
    return acc;
  }, {});
};
