import { useMemo } from 'react';
import { useBuyWidgetState } from '@onramp/state/BuyWidgetState/hooks';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';

export type TransferType = 'buy-and-send' | 'send-only' | 'convert-and-send';

type UseTransferTypeResponse = {
  transferType?: TransferType;
  isBuyAndSend: boolean;
  isSendOnly: boolean;
  isConvertAndSend: boolean;
};

export const useTransferType = (): UseTransferTypeResponse => {
  const fields = useBuyWidgetState();
  const selectedAsset = fields.selectedAsset?.value;
  const sourceOfFunds = fields.sourceOfFunds?.value;
  const currentType = sourceOfFunds?.type;

  return useMemo(() => {
    // Default to send (impacts title shown on screen)
    let transferType: TransferType | undefined;

    /**
     * 'crypto' - any crypto account, i.e. eth, btc, dai, etc.
     * 'fiat' - cash accounts held in Coinbase, i.e. USD, GBP, etc.
     * 'paymentMethod' - ach, credit, debit.
     */
    if (currentType === 'crypto' && selectedAsset?.assetUuid !== sourceOfFunds?.assetUuid) {
      transferType = 'convert-and-send';
    } else if (currentType === 'crypto') {
      transferType = 'send-only';
    } else if (currentType === 'paymentMethod' || currentType === 'fiat') {
      transferType = 'buy-and-send';
    }

    clientSessionIdStore.setTransferType(transferType);

    return {
      transferType,
      isBuyAndSend: transferType === 'buy-and-send',
      isSendOnly: transferType === 'send-only',
      isConvertAndSend: transferType === 'convert-and-send',
    };
  }, [currentType, selectedAsset?.assetUuid, sourceOfFunds?.assetUuid]);
};
