import type { readAccountBalanceFragment$key } from '@onramp/data/__generated__/readAccountBalanceFragment.graphql';
import bigJs from 'big.js';
import { graphql, readInlineData } from '@cbhq/data-layer';

const fragment = graphql`
  fragment readAccountBalanceFragment on Account @inline {
    assetOrFiatCurrency {
      ... on TiersCurrency {
        type
        assetBalanceBreakdown {
          availableToSendBalance {
            currency
            value
          }
        }
      }
      ... on ViewerAsset {
        assetBalanceBreakdown {
          availableToSendBalance {
            currency
            value
          }
        }
      }
    }
  }
`;

export const readAccountBalance = (accountRef: readAccountBalanceFragment$key | null) => {
  const data = readInlineData(fragment, accountRef);
  const { currency, value } =
    data?.assetOrFiatCurrency?.assetBalanceBreakdown?.availableToSendBalance ?? {};
  const isFiat = data?.assetOrFiatCurrency?.type === 'fiat';

  const roundToNearestCent = (val: string | undefined) =>
    bigJs(val ?? '0')
      .round(2, bigJs.roundDown)
      .toString();

  return { currency, value: isFiat ? roundToNearestCent(value) : value ?? '0' };
};
