import { coerceError, NetworkError, reportError } from '@onramp/utils/errors';
import type { Logger } from '@cbhq/data-layer';

import type { RequiredFieldLoggerArg } from './eventing';
import { logRequiredGqlFieldMissing } from './eventing';
import { logWidgetMetric } from './metrics';

const BUGSNAG_CONTEXT = 'graphql_logger';

/**
 * Types have been defined by observed responses from the GQL API.
 */

type GqlQueryErrorMetadata = {
  operation: string;
  graphqlError?: {
    name: string;
    graphqlErrors: {
      message: string;
      path: string[];
      extensions?: {
        correlationID: string;
      };
    }[];
    meta?: {
      requestId: string;
      cfRay: string;
    };
  };
};

type GqlMutationErrorMetadata = {
  operationName: string;
  operationId: string;
  errors: {
    message: string;
    path: string[];
    extensions?: {
      correlationID: string;
    };
  }[];
};

// This sometimes returns true for mutations, but we still
const isGqlQueryMetadata = (obj: unknown): obj is GqlQueryErrorMetadata =>
  !!obj && typeof obj === 'object' && 'operation' in obj;

const isGqlMutationMetadata = (obj: unknown): obj is GqlMutationErrorMetadata =>
  !!obj && typeof obj === 'object' && 'operationName' in obj;

export const getCustomErrorLogger =
  (source: string): Logger['error'] =>
  (arg: unknown, metadata: unknown) => {
    const error = getError(arg, metadata);

    addBugsnagDataAndUpdateMessage(error, metadata);
    error.addMetadata({ source });
    error.addMetadata(formatMetadata(metadata));

    reportError(error);
  };

function getOperationName(metadata: unknown): string | undefined {
  if (isGqlQueryMetadata(metadata)) {
    return metadata.operation;
  }
  if (isGqlMutationMetadata(metadata)) {
    return metadata.operationName;
  }
  return undefined;
}

function getErrorMessage(arg: unknown, metadata: unknown) {
  const operationName = getOperationName(metadata);
  if (typeof arg === 'string' && operationName) {
    return `${operationName}: ${arg}`;
  }
  if (typeof arg === 'string') {
    return arg;
  }
  return undefined;
}

function getError(arg: unknown, metadata: unknown): NetworkError {
  const errorMessage = getErrorMessage(arg, metadata);
  if (errorMessage) {
    return new NetworkError(errorMessage);
  }
  return coerceError(arg, { fallback: NetworkError });
}

/**
 * Groups errors by path for operations with a single field error.
 * Falls back to error message in other cases.
 */
function addBugsnagDataAndUpdateMessage(error: NetworkError, metadata: unknown) {
  const arrayPathRegex =
    /^(?<pathStart>[a-z]+(?:\.[a-z]+)*\.)(?<index>[0-9]+)(?<pathEnd>\.[a-z]+(?:\.[a-z]+)*)$/i;

  const groupingHash = (() => {
    // try/catch is here just in case we're off on typing
    try {
      if (
        isGqlQueryMetadata(metadata) &&
        metadata.graphqlError &&
        metadata.graphqlError.graphqlErrors.length
      ) {
        const errors = metadata.graphqlError.graphqlErrors;

        const match = errors[0].path.join('.').match(arrayPathRegex);
        if (match) {
          const { pathStart, pathEnd } = match.groups as { pathStart: string; pathEnd: string };
          const pathTestRegex = new RegExp(`${pathStart}[0-9]+${pathEnd}`, 'i');
          if (errors.every((e) => pathTestRegex.test(e.path.join('.')))) {
            return `${metadata.operation}/${pathStart}<index>${pathEnd}`;
          }
        }

        if (errors.length === 1) {
          const stringPath = errors[0].path.join('.');
          return `${metadata.operation}/${stringPath}`;
        }
      }

      if (isGqlMutationMetadata(metadata) && metadata.errors.length) {
        const match = metadata.errors[0].path.join('.').match(arrayPathRegex);
        if (match) {
          const { pathStart, pathEnd } = match.groups as { pathStart: string; pathEnd: string };
          const pathTestRegex = new RegExp(`${pathStart}[0-9]+${pathEnd}`, 'i');
          if (metadata.errors.every((e) => pathTestRegex.test(e.path.join('.')))) {
            return `${metadata.operationName}/${pathStart}<index>${pathEnd}`;
          }
        }

        if (metadata.errors.length === 1) {
          const stringPath = metadata.errors[0].path.join('.');
          return `${metadata.operationName}/${stringPath}`;
        }
      }

      return null;
    } catch {
      return null;
    }
  })();

  if (groupingHash) {
    // eslint-disable-next-line no-param-reassign
    error.message = `${error.message} - ${groupingHash}`;
  }

  error.addBugsnagData({
    context: BUGSNAG_CONTEXT,
    groupingHash: groupingHash ?? error.message,
  });
}

function formatMetadata(metadata: unknown): {
  detected_error_type: 'query' | 'mutation' | 'unknown';
  correlation_id?: string;
  cf_ray?: string;
  operation_id?: string;
  errors?: string[];
} {
  if (isGqlQueryMetadata(metadata)) {
    return {
      detected_error_type: 'query',
      correlation_id: metadata.graphqlError?.graphqlErrors[0]?.extensions?.correlationID,
      cf_ray: metadata.graphqlError?.meta?.cfRay,
      errors: metadata.graphqlError?.graphqlErrors.map(errorToReadableString),
    };
  }

  if (isGqlMutationMetadata(metadata)) {
    return {
      detected_error_type: 'mutation',
      correlation_id: metadata.errors?.[0]?.extensions?.correlationID,
      operation_id: `${getOperationId(metadata)}`,
      errors: metadata.errors?.map(errorToReadableString),
    };
  }

  return {
    detected_error_type: 'unknown',
  };
}

function errorToReadableString(error: { message: string; path: string[] }) {
  return `${error.message} [${error.path.join('.')}]`;
}

function getOperationId({ operationId }: GqlMutationErrorMetadata): string | undefined {
  if (operationId === undefined) {
    return undefined;
  }

  const openingBraceIndex = operationId.indexOf('{');
  return `${operationId.slice(0, openingBraceIndex)}<REDACTED_OBJECT>`;
}

const getCustomLogFnWithRelayRequiredDetection =
  (level: keyof Logger, source: string): typeof console.log =>
  (...args) => {
    // reference: https://github.cbhq.net/frontend/data-layer/blob/698a3a5f122b34b32c13a63b7f6bad1275479497/packages/data-layer/src/relay/requiredFieldLogger.ts
    if (typeof args[0] === 'string' && args[0].includes('@required(action:')) {
      const requiredFieldLoggerArg =
        typeof args[1] === 'object' ? (args[1] as RequiredFieldLoggerArg) : undefined;
      logRequiredGqlFieldMissing({
        message: args[0],
        args: args.slice(1).map(String),
        ...requiredFieldLoggerArg,
      });
      logWidgetMetric({
        metricName: 'required_gql_field_missing',
        value: 1,
        tags: {
          kind: requiredFieldLoggerArg?.kind,
          owner: requiredFieldLoggerArg?.owner,
          fieldPath: requiredFieldLoggerArg?.fieldPath,
        },
      });
    }
    // eslint-disable-next-line no-console
    console[level](`[${source}]`, ...args);
  };

export const getCustomLogger = (source: string): Logger => {
  const bugsnagErrorLogger = getCustomErrorLogger(source);
  const consoleErrorLogger = getCustomLogFnWithRelayRequiredDetection('error', source);

  return {
    log: getCustomLogFnWithRelayRequiredDetection('log', source),
    debug: getCustomLogFnWithRelayRequiredDetection('debug', source),
    warn: getCustomLogFnWithRelayRequiredDetection('warn', source),
    error: (...args) => {
      consoleErrorLogger(...args);
      bugsnagErrorLogger(...args);
    },
    /* eslint-enable no-console */
  };
};
