import { createContext, useContext } from 'react';
import { noop } from '@cbhq/cds-utils';

type SplashScreenContextType = {
  isVisible: boolean;
  dismissSplashScreen: () => void;
};

export const SplashScreenContext = createContext<SplashScreenContextType>({
  isVisible: false,
  dismissSplashScreen: noop,
});

export function useSplashScreenContext() {
  return useContext(SplashScreenContext);
}
