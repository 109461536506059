import escapeRegExp from 'lodash/escapeRegExp';
import fromPairs from 'lodash/fromPairs';
import get from 'lodash/get';

import { isNotNullish } from './types';

const baseRoutes = {
  embed: 'embed',
  buy: 'buy',
  flowAgnostic: 'flowAgnostic',
  landing: 'landing',
  v2: 'v2',
} as const;
export type BaseRouteKey = keyof typeof baseRoutes;

type BaseRoutesObject = { [key: string]: '' | `/${string}` | BaseRoutesObject };

/**
 * When adding a new route here, make sure to:
 * - Generate a new pageKeyMappingRegex.json file by running `yarn config:generatePageKeyJson`
 * - Copy that json to coinbase_pay_web_page_key_mapping_regex in the devevelopment config service (https://config.cbhq.net/development/data/analytics-service-development?q=coinbase_pay_web_page_key_mapping_regex)
 * - Ask for someone to copy the change to the production config service in #ask-client-analytics
 *
 * This can be done before or after merge, but keep in mind that the new `page_key` value won't appear in analytics
 * until after the config is updated.
 */
export const Routes = {
  Error: '/error',
  Home: '/',
  Signin: '/signin',
  Login: '/login',
  Landing: '/landing',
  Signout: '/signout',
  Return3DS: '/return-3ds',
  ReturnGuestCheckout: '/return-guest-checkout',
  V2: subpaths(baseRoutes.v2, {
    GuestOnrampEdit: '/guest/onramp/edit',
    GuestOnrampSelectAsset: '/guest/onramp/select-asset',
    GuestOnrampOrderDetails: '/guest/onramp/order-details',
    GuestOnrampOrderSubmitted: '/guest/onramp/order-submitted',
    GuestOnrampOrderPreview: '/guest/onramp/order-preview',
    GuestOnrampPriceDetails: '/guest/onramp/price-details',
    GuestOnrampPhoneVerification: '/guest/onramp/phone-verification',
    GuestOnrampChangeNetwork: '/guest/onramp/change-network',
    GuestOnrampTransactionLimit: '/guest/onramp/transaction-limit',
    GuestOnrampSuccess: '/guest/onramp/success',

    OnrampCardDetails: '/onramp/card-details',
    OnrampInput: '/onramp/input',
    OnrampChangeNetwork: '/onramp/change-network',
    OnrampChangeAsset: '/onramp/change-asset',
  }),
  Buy: subpaths(baseRoutes.buy, {
    SelectAsset: '/select-asset',
    /** This will cause the widget to redirect to the appropriate page */
    Index: '',
    Input: '/input',
    SelectCoinbaseBalance: '/select-coinbase-balance',
    SelectPaymentMethod: '/select-payment-method',
    ApplePayAddCard: '/select-payment-method/apple-pay-add-card',
    OrderPreview: '/order-preview',
    OrderPreviewWalletDetails: '/order-preview/wallet-details',
    Send2FA: '/send/verify-identity',
    SendSuccess: '/send/success',
    SendSuccessDetails: '/send/success/details',
    SendAbort: '/send/abort',
    SendAbortDetails: '/send/abort/details',
    BuyInitiatingPurchase: '/initiating-purchase',
    SelectNetwork: '/select-network',
    SelectNetworkWarning: '/select-network-warning',
    Return3DS: '/return-3ds',
    DepositHowItWorks: '/deposit/how-it-works',
    DepositDetails: '/deposit/details',

    // GUEST CHECKOUT ROUTES
    GuestCheckout: '/guest/checkout',
    GuestLogin: '/guest/login',
    GuestConfirmationPending: '/guest/checkout/pending',
    GuestConfirmationSuccess: '/guest/checkout/success',
    GuestInput: '/guest/input',
    GuestSelectAsset: '/guest/select-asset',
    GuestRestrictedCountry: '/guest/restricted-country',
    GuestPhoneVerification: '/guest/phone-verification',
    GuestBillingAddress: '/guest/billing-address',
    GuestOrderPreview: '/guest/order-preview',
    GuestOrderDetails: '/guest/order-details',
    GuestInterstitial: '/guest/interstitial',
    GuestSuccess: '/guest/success',
    GuestChangeNetwork: '/guest/change-network',
    GuestCardDetails: '/guest/card-details',
    GuestOrderSubmitted: '/guest/order-submitted',
    GuestUnsupportedAsset: '/guest/unsupported-asset',

    // ONE-CLICK BUY
    OneClick: '/one-click',

    // ACCOUNT FLOW SOON TO BE V2
    AccountOrderPreview: '/account/order-preview',
  } as const),
  Embed: subpaths(baseRoutes.embed, {
    Index: '',
    Button: '/button',
    OAuthDirect: '/oauth-direct',
  } as const),
  // TODO [ONRAMP-2368]: add satisfies BaseRoutesObject: `as const satisfies BaseRoutesObject`
} as const;

export const getPathType = (pathname: string): BaseRouteKey => {
  if (/(^\/embed)/.test(pathname)) {
    return baseRoutes.embed;
  }

  if (/(^\/buy)/.test(pathname)) {
    return baseRoutes.buy;
  }

  if (/(^\/landing)/.test(pathname)) {
    return baseRoutes.landing;
  }

  if (/(^\/v2)/.test(pathname)) {
    return baseRoutes.v2;
  }

  // TODO [ONRAMP-1780]: Remove this & make it the  default path type?
  if (/(^\/error)/.test(pathname) || /(^\/return-guest-checkout)/.test(pathname)) {
    return baseRoutes.flowAgnostic;
  }

  return baseRoutes.buy;
};

// Utility type to help consumers push to namespaced routes i.e. "Buy.SelectAsset"
type NestedKeyOf<ObjectType> = {
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends Record<string, unknown>
    ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
    : `${Key}`;
}[keyof ObjectType & (string | number)];

// Exclude base routes
export type RouteKey = Exclude<NestedKeyOf<typeof Routes>, BaseRouteKey>;

const getAllRouteKeys = <R extends BaseRoutesObject>(
  routes: R,
  prefix?: string,
): NestedKeyOf<R>[] =>
  (Object.keys(routes) as (keyof R)[])
    .flatMap((key) => {
      if (typeof key === 'string') {
        const field = routes[key];
        if (typeof field === 'string') return [key];
        if (typeof field === 'object') return getAllRouteKeys(field, key);
      }

      return [];
    })
    .map((unprefixedKey) => [prefix, unprefixedKey].filter(Boolean).join('.')) as NestedKeyOf<R>[];

export const allRouteKeys = getAllRouteKeys(Routes);
type SubRoutes<Prefix extends string, Routes extends Record<string, string>> = {
  [K in keyof Routes]: `/${Prefix}${Routes[K]}`;
};

function subpaths<Prefix extends BaseRouteKey, Routes extends Record<string, string>>(
  base: Prefix,
  routes: Routes,
): SubRoutes<Prefix, Routes> {
  return Object.keys(routes).reduce((prev, curr) => {
    return { ...prev, [curr]: `/${base}${routes[curr]}` };
  }, {}) as SubRoutes<Prefix, Routes>;
}

/**
 * This object is helpful for any routes that represent the user being directed back to CBPay from
 * an external URL, such as the 3DS verification flow. These routes conceptually represent
 * "picking back up where you left off", so no redirect will happen and each route will have to
 * handle that accordingly.
 */
const redirectExemptRoutes: Record<BaseRouteKey, string[]> = {
  buy: [Routes.Return3DS, Routes.Buy.Return3DS, Routes.Buy.GuestPhoneVerification],
  embed: [],
  flowAgnostic: [Routes.ReturnGuestCheckout],
  landing: [Routes.Login],
  v2: [],
};

export function getIsRedirectExemptRoute({
  pathType,
  route,
}: {
  pathType: BaseRouteKey;
  route: string;
}): boolean {
  const exemptRoutes = redirectExemptRoutes[pathType];
  if (!exemptRoutes) return false;

  return exemptRoutes.some((exemptRoute) => route.includes(exemptRoute));
}

export const routeKeyRegexMap: Record<RouteKey, RegExp> = fromPairs(
  allRouteKeys
    .map((routeKey) => {
      const routePath = get(Routes, routeKey);
      if (typeof routePath !== 'string') return undefined;
      return [routeKey, new RegExp(`^${escapeRegExp(routePath)}/?(\\?.*)?$`)];
    })
    .filter(isNotNullish),
) as Record<RouteKey, RegExp>;

// eslint-disable-next-line compat/compat, @typescript-eslint/no-unused-expressions -- replace `fromPairs` above 👆🏼 with `Object.fromEntries` once this comment warns because there's no `compat/compat` lint error (AKA when all of our browser targets support `Object.fromEntries`)
Object.fromEntries;

const routeKeyRegexes = Object.entries(routeKeyRegexMap).map(([routeKey, regex]) => ({
  routeKey: routeKey as RouteKey,
  regex,
}));

export function getRouteKeyFromPath(pathStr: string) {
  try {
    const { pathname } = new URL(pathStr, window.location.origin);
    return getRouteKeyFromPathname(pathname);
  } catch {
    return undefined;
  }
}

export function getRouteKeyFromPathname(pathname: string) {
  return routeKeyRegexes.find(({ regex }) => pathname.match(regex))?.routeKey ?? undefined;
}
