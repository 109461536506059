import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import type { ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useWidgetParams } from '@onramp/components/AppManagerProvider';
import { useIsInitialScreen } from '@onramp/components/InitialScreenProvider';
import { CB_WALLET_APP_ID } from '@onramp/constants/cbWallets';
import type { OnrampNetworksProviderQuery } from '@onramp/data/__generated__/OnrampNetworksProviderQuery.graphql';
import { useReportAndViewError } from '@onramp/hooks/useReportAndViewError';
import { useIsNetworkKillSwitched } from '@onramp/pages/buy/select-asset/hooks/useIsNetworkKillSwitched';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { dedupe } from '@onramp/utils/dedupe';
import { HandledError } from '@onramp/utils/errors/HandledError';
import { getIsGuestCheckoutPath } from '@onramp/utils/guestCheckoutUtils';
import { useKillSwitches } from '@onramp/utils/killswitches/useKillSwitches';
import { isNotNullish } from '@onramp/utils/types';
import { useAtomValue } from 'jotai';
import { graphql, useLazyLoadQuery } from '@cbhq/data-layer';

import type { CBPayAssetNetworks, ParsedNetworkFormat } from '../BuyWidgetState';
import { defaultValues, useBuyWidgetState } from '../BuyWidgetState';
import type { AssetMetadata } from '../recoil/appParamsState';
import { assetMetadataSelector, useDestinationWalletsV2 } from '../recoil/appParamsState';
import { guestCheckoutAssetMetadataAtom } from '../recoil/guestCheckoutState';

import { readCbPayNetwork } from './readCbPayNetwork';

type NetworkToAddressMap = Record<string, string>;

type OnrampNetworkContextType = {
  availableNetworksFromAppParameters: string[];
  availableNetworksFromBackendForAsset: ParsedNetworkFormat[];
  networkToWalletMap: NetworkToAddressMap;
  activeNetwork?: ParsedNetworkFormat;
  selectedAssetSupportedNetworks: ParsedNetworkFormat[];
  getWalletAddressByNetwork: (id: string) => string | null;
  computeActiveWalletAddress: (
    ticker: string,
    cbpayNetworks?: CBPayAssetNetworks,
  ) => string | undefined;
  getSelectedAssetDefaultNetwork: (appId: string) => ParsedNetworkFormat;
};

/** Provides upfront computation for onramp networks and wallets */
export const OnrampNetworkContext = createContext<OnrampNetworkContextType | undefined>(undefined);

export const onrampNetworksProviderQuery = graphql`
  query OnrampNetworksProviderQuery($assetUuid: Uuid!, $include: Boolean!) {
    viewer {
      assetByUuid(uuid: $assetUuid) @include(if: $include) {
        supportedNetworks(action: RECEIVE, applicationContext: CB_PAY) {
          supportedNetworks {
            networkName
            networkSlug
            isDefault
            # eslint-disable-next-line relay/unused-fields
            assetImageUrl
          }
        }
      }
    }
  }
`;

const messages = defineMessages({
  defaultNetworkUnavailablePageTitle: {
    id: 'OnrampNetworksProvider.defaultNetworkUnavailableTitle',
    defaultMessage: 'An error occurred',
    description: 'Page title for default network unavailable error',
  },
  defaultNetworkUnavailableErrorTitle: {
    id: 'OnrampNetworksProvider.defaultNetworkUnavailableErrorTitle',
    defaultMessage: 'This network is not supported',
    description: 'Error title for default network unavailable error',
  },
  defaultNetworkUnavailableErrorMessage: {
    id: 'OnrampNetworksProvider.defaultNetworkUnavailableErrorMessage',
    defaultMessage: 'The network you selected is currently not supported by Coinbase Onramp.',
    description: 'Error message for default network unavailable error',
  },
  defaultNetworkUnavailableErrorButtonText: {
    id: 'OnrampNetworksProvider.defaultNetworkUnavailableErrorButtonText',
    defaultMessage: 'Go back',
    description: 'A button with text allowing the user to go back',
  },
});

export const OnrampNetworksProvider = ({ children }: { children: ReactNode }) => {
  const {
    destinationWallets,
    defaultNetwork: defaultNetworkAppParam,
    defaultAsset: defaultAssetAppParam,
  } = useWidgetParams('buy');
  const { selectedNetwork, selectedAsset, walletTransactionNetworkAddressMap } =
    useBuyWidgetState();
  const reportAndViewError = useReportAndViewError();
  const { formatMessage } = useIntl();
  const killSwitches = useKillSwitches();
  const hasSelectedAsset = Boolean(selectedAsset.value.assetUuid);
  const isBaseKilled = useKillSwitches().kill_cbpay_base_support;
  const isInitialScreen = useIsInitialScreen();

  const { networksWithAllAssetsRequested, destinationWallets: destinationWalletsFromAppParamsV2 } =
    useDestinationWalletsV2();

  const isGuestCheckout = getIsGuestCheckoutPath();
  const assetMetadata = useAtomValue(
    isGuestCheckout ? guestCheckoutAssetMetadataAtom : assetMetadataSelector,
  );

  const unfilteredData = useLazyLoadQuery<OnrampNetworksProviderQuery>(
    onrampNetworksProviderQuery,
    {
      assetUuid: selectedAsset.value.assetUuid,
      include: Boolean(selectedAsset.value.assetUuid),
    },
  );
  const data = useMemo(() => {
    if (!isBaseKilled || !unfilteredData.viewer.assetByUuid?.supportedNetworks?.supportedNetworks) {
      return unfilteredData;
    }

    const filteredNetworks =
      unfilteredData.viewer.assetByUuid.supportedNetworks.supportedNetworks.filter(
        (network) => network?.networkSlug !== 'base',
      );

    return {
      ...unfilteredData,
      viewer: {
        ...unfilteredData.viewer,
        assetByUuid: {
          ...unfilteredData.viewer.assetByUuid,
          supportedNetworks: {
            ...unfilteredData.viewer.assetByUuid.supportedNetworks,
            supportedNetworks: filteredNetworks,
          },
        },
      },
    };
  }, [isBaseKilled, unfilteredData]);

  /** A list of all networks supported by the selected asset from the backend.
   * Since we fetch the assets from different sources in experiments, we need to convert them to a common format. */
  const availableNetworksFromBackendForAsset = useMemo<ParsedNetworkFormat[]>(() => {
    return parseCbPayAssetNetworks(selectedAsset.value.networks).reduce((accumulator, network) => {
      // Because our new endpoint doesn't have all the data we need, we attempt to map it from the other network data source.
      const matchingNetwork = data.viewer.assetByUuid?.supportedNetworks?.supportedNetworks?.find(
        (n) => n?.networkSlug === network.id,
      );
      // If assetByUuid.supportedNetworks doesn't contain the network, it means it's not supported or geofenced. So we
      // filter those networks out. UNLESS it's the default network, because assetByUuid.supportedNetworks will return
      // an empty supportedNetworks array if only the default network is supported.
      if (matchingNetwork || network.isDefault) {
        accumulator.push({
          ...network,
          name: matchingNetwork?.networkName || network.name,
          assetImageUrl: network?.assetImageUrl || matchingNetwork?.assetImageUrl || '',
        });
      }
      return accumulator;
    }, [] as ParsedNetworkFormat[]);
  }, [selectedAsset.value, data.viewer.assetByUuid?.supportedNetworks?.supportedNetworks]);

  /** The network <=> wallet address mapping based off the developer parameters we've been provided.
   *  i.e. {bitcoin: '12345', ethereum: 0x12345, ...} */
  const networkToWalletMap = useMemo(() => {
    if (walletTransactionNetworkAddressMap.value) {
      return walletTransactionNetworkAddressMap.value;
    }

    return destinationWallets.reduce((map, { address, assets, blockchains, supportedNetworks }) => {
      // We want the supportedNetworks parameter to take precendence for available networks.
      const networkIds =
        supportedNetworks && supportedNetworks.length > 0
          ? [...parseNetworkIdsFromStrings(supportedNetworks)]
          : [
              ...parseNetworkIdsFromStrings(blockchains),
              ...getNetworkIdsFromTickers(assets ?? [], assetMetadata),
            ];

      const newAddressMappings = networkIds.reduce(
        (p, id) => ({
          [id]: address,
          ...p,
        }),
        {} as NetworkToAddressMap,
      );

      return {
        ...newAddressMappings,
        ...map, // Override any new network mappings - first on the list takes priority
      };
    }, {} as NetworkToAddressMap);
  }, [assetMetadata, destinationWallets, walletTransactionNetworkAddressMap.value]);

  const computeAvailableNetworksFromAppParameters = useCallback(
    (ticker: string) => {
      if (!ticker) return [];

      const configsWithSelectedAssetRequested = destinationWalletsFromAppParamsV2.filter((config) =>
        config.assets.includes(ticker),
      );

      const associatedNetworks = dedupe([
        // We know these networks want all assets supported and we can't know whether the asset was presented due to a network.
        // Even if we add a network here that doesn't make sense for the asset, it's ok because it will be filtered down be backend network support.
        ...networksWithAllAssetsRequested,
        // For configs where the asset is referenced, append any networks from that config since the developer has indicated we support the network.
        ...configsWithSelectedAssetRequested.flatMap(({ networks }) => {
          if (networks.length === 0) {
            // For configs that don't have any networks specified we need
            // we need to add the default network as an option.
            return [assetMetadata[ticker]?.blockchain].filter(isNotNullish);
          }
          return networks;
        }),
      ]);

      return associatedNetworks;
    },
    [assetMetadata, destinationWalletsFromAppParamsV2, networksWithAllAssetsRequested],
  );

  /** The available networks computed from developer app parameters via the network <=> wallet address mapping above */
  const availableNetworksFromAppParameters = useMemo(() => {
    return computeAvailableNetworksFromAppParameters(selectedAsset.value.ticker);
  }, [computeAvailableNetworksFromAppParameters, selectedAsset.value.ticker]);

  const { isNetworkKilledById } = useIsNetworkKillSwitched();

  /* The cross section between FE available networks <=> BE asset available networks
   * BE - availableNetworksFromBackendForAsset
   * FE - availableNetworksFromAppParameters */
  const availableNetworksCrossSection = useMemo<ParsedNetworkFormat[]>(() => {
    return computeNetworksCrossSection(
      availableNetworksFromAppParameters, // finds the valid IDs from destinationWallets app parameters
      availableNetworksFromBackendForAsset, // gets all the supported networks from the backend amd filters them out based on valid IDS from the FE
      isNetworkKilledById,
    );
  }, [
    availableNetworksFromAppParameters,
    availableNetworksFromBackendForAsset,
    isNetworkKilledById,
  ]);

  /**
   * Compute what network should be active. This will be the network crypto is sent on. Network priority:
   * 1. Use selected network from user selection
   * 2. Use default network set by developer
   * 3. Use default network from asset supported networks list
   * 4. Use first available from asset supported networks list
   * 5. Use default network from asset hardcoded frontend list
   * 6. Undefined (no asset selected yet)
   */
  const computeActiveNetworkFromParameters = useCallback(
    (networksCrossSection: ParsedNetworkFormat[]): string | undefined => {
      // Selected network from user selection
      // TODO [ONRAMP-1768]: this has to change
      if (selectedNetwork.value?.id) {
        return selectedNetwork.value.id;
      }

      // Use the default network app param if it's in available network for the asset
      if (
        defaultNetworkAppParam &&
        networksCrossSection.some((n) => n.id === defaultNetworkAppParam)
      ) {
        return defaultNetworkAppParam;
      }

      // Default network deterimined by the backend
      const defaultNetworkFromBackend = networksCrossSection.find((n) => n.isDefault)?.id;
      if (defaultNetworkFromBackend) {
        return defaultNetworkFromBackend;
      }

      // First network available from the backend
      const firstNetworkFromBackend = networksCrossSection?.[0]?.id;
      if (firstNetworkFromBackend) {
        return firstNetworkFromBackend;
      }

      return undefined;
    },
    [defaultNetworkAppParam, selectedNetwork.value?.id],
  );

  const activeNetworkId = useMemo<string | undefined>(() => {
    return computeActiveNetworkFromParameters(availableNetworksCrossSection);
  }, [computeActiveNetworkFromParameters, availableNetworksCrossSection]);

  const activeNetworkMetadata = useMemo<ParsedNetworkFormat | undefined>(
    () => availableNetworksCrossSection.find((n) => n.id === activeNetworkId),
    [availableNetworksCrossSection, activeNetworkId],
  );

  clientSessionIdStore.setActiveNetworkMetadata(activeNetworkMetadata);

  const getWalletAddressByNetwork = useCallback(
    (id: string): string | null => {
      return networkToWalletMap[id] ?? null;
    },
    [networkToWalletMap],
  );

  /** Used to lookup the active wallet address for an asset without relying on the selected asset state */
  const computeActiveWalletAddress = useCallback<
    OnrampNetworkContextType['computeActiveWalletAddress']
  >(
    (ticker, cbpayNetworks) => {
      const assetsSupportedNetworks = parseCbPayAssetNetworks(cbpayNetworks);
      const crossSectionOfSupportedNetworks = computeNetworksCrossSection(
        computeAvailableNetworksFromAppParameters(ticker),
        assetsSupportedNetworks,
        isNetworkKilledById,
      );
      const network = computeActiveNetworkFromParameters(crossSectionOfSupportedNetworks);
      if (!network) {
        return undefined;
      }
      return networkToWalletMap[network];
    },
    [
      computeActiveNetworkFromParameters,
      networkToWalletMap,
      computeAvailableNetworksFromAppParameters,
      isNetworkKilledById,
    ],
  );

  const getSelectedAssetDefaultNetwork = useCallback(
    (appId: string) => {
      const defaultFromParam = availableNetworksCrossSection.find(
        ({ id }) => id === defaultNetworkAppParam,
      );

      if (defaultFromParam) {
        return defaultFromParam;
      }

      const appNetworkOverrides = defaultNetworkOverrideMap[appId];
      if (appNetworkOverrides) {
        const networkOverrideId = appNetworkOverrides[selectedAsset.value.ticker];
        if (networkOverrideId) {
          const networkOverride = availableNetworksCrossSection.find(
            ({ id }) => id === networkOverrideId,
          );
          if (networkOverride) return networkOverride;
        }
      }

      const baseNetwork = availableNetworksCrossSection.find(({ id }) => id === 'base');
      if (baseNetwork) {
        return baseNetwork;
      }

      return (
        availableNetworksCrossSection.find(({ isDefault }) => isDefault) ??
        availableNetworksCrossSection[0]
      );
    },
    [availableNetworksCrossSection, defaultNetworkAppParam, selectedAsset.value.ticker],
  );

  const value = useMemo(
    () => ({
      availableNetworksFromBackendForAsset,
      availableNetworksFromAppParameters,
      selectedAssetSupportedNetworks: availableNetworksCrossSection,
      networkToWalletMap,
      activeNetwork: activeNetworkMetadata,
      activeNetworkMetadata,
      getWalletAddressByNetwork,
      computeActiveWalletAddress,
      getSelectedAssetDefaultNetwork,
    }),
    [
      availableNetworksFromBackendForAsset,
      availableNetworksFromAppParameters,
      availableNetworksCrossSection,
      networkToWalletMap,
      activeNetworkMetadata,
      getWalletAddressByNetwork,
      computeActiveWalletAddress,
      getSelectedAssetDefaultNetwork,
    ],
  );

  useEffect(() => {
    if (killSwitches.kill_cbpay_default_network_check || !hasSelectedAsset) return;

    function reportNetworkUnsupportedError() {
      // Reset the selected asset so that going back to the select-asset screen doesn't immediately re-trigger an error
      selectedAsset.onChange(defaultValues.selectedAsset);
      reportAndViewError(
        new HandledError({
          message: formatMessage(messages.defaultNetworkUnavailableErrorMessage),
          debugMessage: 'The network you are trying to add funds to is currently not supported.',
        })
          .addHandlingParams({
            title: formatMessage(messages.defaultNetworkUnavailableErrorTitle),
            pageTitle: formatMessage(messages.defaultNetworkUnavailablePageTitle),
            backType: isInitialScreen ? 'none' : 'back',
            showExitButton: isInitialScreen,
          })
          .addMetadata({
            /*
              Bugsnag will omit metadata keys if the value is undefined. Using the String
              constructor to provide 'undefined' as a value so these keys come up in searches.
            */
            defaultNetwork: String(defaultNetworkAppParam),
            ticker: selectedAsset.value.ticker,

            // This shows us the pool of networks possible.
            backendAvailableNetworks: String(
              availableNetworksFromBackendForAsset?.map((network) => network?.id).join(','),
            ),

            // This shows us what networks were available on the FE given the selected asset.
            frontendAvailableNetworks: String(
              availableNetworksCrossSection.map((network) => network?.id).join(','),
            ),
          }),
      );
    }

    const isL2Asset = (availableNetworksFromBackendForAsset?.length ?? 0) > 0;
    const defaultNetworkId =
      availableNetworksFromBackendForAsset.find((n) => n.isDefault)?.id ??
      assetMetadata[selectedAsset.value.ticker]?.blockchain;

    const isDefaultAsset =
      selectedAsset.value.ticker === defaultAssetAppParam ||
      selectedAsset.value.assetUuid === defaultAssetAppParam ||
      (destinationWallets.length === 1 &&
        destinationWallets[0]?.assets?.length === 1 &&
        destinationWallets[0].assets[0] === selectedAsset.value.ticker);

    const defaultNetworkAppParamNotSupported =
      isDefaultAsset && // only check during initialization
      defaultNetworkAppParam &&
      !availableNetworksFromAppParameters.includes(defaultNetworkAppParam);
    const defaultBlockchainNotSupported =
      defaultNetworkId === undefined ||
      !availableNetworksFromAppParameters.includes(defaultNetworkId);

    if (!isL2Asset) {
      if (defaultNetworkAppParamNotSupported || defaultBlockchainNotSupported) {
        reportNetworkUnsupportedError();
      }

      return;
    }

    const hasMatchingSupportedNetwork = availableNetworksCrossSection.some(
      (network) => network?.id === defaultNetworkAppParam,
    );
    const defaultNetworkNotSupported =
      isDefaultAsset && // only check during initialization
      defaultNetworkAppParam &&
      !hasMatchingSupportedNetwork;

    if (defaultNetworkNotSupported || availableNetworksCrossSection.length === 0) {
      reportNetworkUnsupportedError();
    }
  }, [
    availableNetworksFromAppParameters,
    defaultNetworkAppParam,
    formatMessage,
    hasSelectedAsset,
    killSwitches.kill_cbpay_default_network_check,
    availableNetworksFromBackendForAsset,
    reportAndViewError,
    selectedAsset.value.ticker,
    availableNetworksCrossSection,
    assetMetadata,
    selectedAsset,
    defaultAssetAppParam,
    destinationWallets,
    isInitialScreen,
  ]);

  return <OnrampNetworkContext.Provider value={value}>{children}</OnrampNetworkContext.Provider>;
};

function getNetworkIdsFromTickers(tickers: string[], assetMetadata: AssetMetadata): string[] {
  if (!tickers) return [];
  return dedupe(tickers.map((ticker) => assetMetadata[ticker]?.blockchain).filter(isNotNullish));
}

function parseNetworkIdsFromStrings(blockchains?: string[]): string[] {
  if (!blockchains) return [];
  return dedupe(blockchains.filter(isNotNullish));
}

export const useSafeOnrampNetworks = () => useContext(OnrampNetworkContext);

export const useOnrampNetworks = () => {
  const context = useSafeOnrampNetworks();
  if (!context) throw new Error('No provider for OnrampNetworkContext');
  return context;
};

function parseCbPayAssetNetworks(networks?: CBPayAssetNetworks): ParsedNetworkFormat[] {
  return (
    networks?.map((network) => ({
      id: readCbPayNetwork(network.name).id || '',
      isDefault: Boolean(network.isDefault),
      name: network.displayName || '',
      minimumSendCryptoAmount: Number(network.minSend) || 0,
    })) || []
  );
}

function computeNetworksCrossSection(
  validIds: string[],
  networks: ParsedNetworkFormat[],
  isNetworkKillSwitched: (networkId: string) => boolean,
) {
  return networks.filter((n) => validIds.includes(n.id) && !isNetworkKillSwitched(n.id));
}

type NetworkOverrideMap = Record<string, Record<string, string>>;

const defaultNetworkOverrideMap: NetworkOverrideMap = {
  [CB_WALLET_APP_ID]: {
    USDC: 'base',
  },
};
