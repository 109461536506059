/* eslint-disable react-perf/jsx-no-new-function-as-prop */
import React from 'react';
import { ListCellFallback } from '@cbhq/cds-web/cells';
import { SearchInput } from '@cbhq/cds-web/controls';
import { Box, VStack } from '@cbhq/cds-web/layout';

export function LoadingAssets() {
  return (
    <VStack>
      <Box spacing={2}>
        <SearchInput value="" onChangeText={() => {}} disabled autoFocus={false} />
      </Box>
      <VStack flexGrow={1}>
        <ListCellFallback title description media="asset" />
        <ListCellFallback title description media="asset" />
        <ListCellFallback title description media="asset" />
        <ListCellFallback title description media="asset" />
        <ListCellFallback title description media="asset" />
      </VStack>
    </VStack>
  );
}
