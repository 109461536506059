import { defineMessages } from 'react-intl';

export const policyRestrictionsGuardMessages = defineMessages({
  failedToLoadAccountInfo: {
    id: `PolicyRestrictionsGuard.failedToLoadAccountInfo`,
    defaultMessage: 'Failed to load account information.',
    description: 'An error occurred when attempting to load account information for a user.',
  },
  loading: {
    id: `PolicyRestrictionsGuard.loading`,
    defaultMessage: 'Wait for a moment...',
    description: 'Loading message instructing the user to wait.',
  },
  notYetAvailable: {
    id: `PolicyRestrictionsGuard.notYetAvailable`,
    defaultMessage: 'Not yet available in your location',
    description: "Title explaining the app is not available in the user's country",
  },
  yubiKeyNotSupportedTitle: {
    id: `PolicyRestrictionsGuard.yubiKeyNotSupportedTitle`,
    defaultMessage: 'YubiKey is not yet supported',
    description: 'Title explaining Yubikey two factor authentication is not available',
  },
  yubiKeyNotSupportedMessage: {
    id: `PolicyRestrictionsGuard.yubiKeyNotSupportedMessage`,
    defaultMessage:
      'Coinbase Onramp is currently not supporting YubiKey. You can send crypto to your wallet from your Coinbase account.',
    description:
      'Description explain the app does not support YubiKey 2FA and to use their Coinbase account to send instead.',
  },
  countryNotAllowedMessage: {
    id: `PolicyRestrictionsGuard.countryNotAllowedMessage`,
    defaultMessage: `Coinbase Onramp is currently not available in {country}. You can still send crypto from {link} to your wallet.`,
    description:
      'The app Coinbase Onramp is not available in a specific country. The user can still use their Coinbase account to send funds to their wallet.',
  },
  title: {
    id: `PolicyRestrictionsGuard.title`,
    defaultMessage: 'Coinbase Onramp',
    description: "Header title shown while we're checking if the user is allowed to use CBOnramp",
  },
  copiedToClipboard: {
    id: 'PolicyRestrictionsGuard.copiedToClipboard',
    defaultMessage: 'Copied to clipboard',
    description: 'Toast text for copying to clipboard',
  },
  copyToContinue: {
    id: 'PolicyRestrictionsGuard.copyToContinue',
    defaultMessage: 'Please copy the link below and open it in your browser to continue.',
    description: 'Instruction for the user to follow a link manually',
  },
});
