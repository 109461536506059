import { createContext, useContext } from 'react';

export const AuthedGraphqlProviderContext = createContext({
  /**
   * Don't use this, unless you really know what you're doing. It'll nuke the entire Relay environment.
   *
   *  - In a graph-based cache, it doesn't make sense to invalidate a single query, since
   *      data is stored in a graph of data records (and not in key-value pairs)
   *  - Due to some odd behavior with suspense/Relay, triggering a query retry using fetch keys
   *      results in a new network call, but useLazyLoadQuery returning stale data out of the cache.
   *  - To ensure fresh data is being returned from a data-refetch, we end up taking a heavy-handed
   *      approach of resetting the entire cache/data store (to make retries useful).
   */
  resetRelayEnvironment: () => {},
});

export const useResetRelayEnvironment = () =>
  useContext(AuthedGraphqlProviderContext).resetRelayEnvironment;
