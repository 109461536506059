import fromPairs from 'lodash/fromPairs';

export function parseUrl(...params: ConstructorParameters<typeof URL>) {
  try {
    return new URL(...params);
  } catch {
    return undefined;
  }
}

export function getQueryParamsAsObject(
  ...params: ConstructorParameters<typeof URL>
): Record<string, unknown> | undefined {
  const url = parseUrl(...params);

  if (url) {
    return fromPairs([...url.searchParams.entries()]);
  }

  return undefined;
}
