/*
 * Similar to retail mobile's implementation: https://github.cbhq.net/consumer/react-native/blob/712c54901db91015e728b8623a25b9d6ce167fbd/src/packages/app/src/hooks/payments/useRunDigitalWalletPaymentMethodVerification.ts
 */
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import type { useRunDigitalWalletVerificationMutation } from '@onramp/data/__generated__/useRunDigitalWalletVerificationMutation.graphql';
import {
  addAlreadyReportedErrorsMetadata,
  coerceError,
  GqlError,
  TopLevelGqlError,
} from '@onramp/utils/errors';
import { genericMessages } from '@onramp/utils/genericMessages';
import { assertPresence } from '@onramp/utils/relayUtils';
import { graphql, useMutation } from '@cbhq/data-layer';

export type RunDigitalWalletVerificationParams = { paymentMethodId: string; pmsvcId?: string };

const runDigitalWalletVerificationMutation = graphql`
  mutation useRunDigitalWalletVerificationMutation(
    $input: CompleteWalletVerificationMonorailInput!
  ) {
    completeWalletVerificationMonorail(input: $input) {
      ... on CompleteWalletVerificationMonorailSuccess {
        __typename
      }
      ... on BadRequestError {
        __typename
        message
        code
      }
    }
  }
`;

export function useRunDigitalWalletVerification() {
  const { formatMessage } = useIntl();
  const [commitMutation] = useMutation<useRunDigitalWalletVerificationMutation>(
    runDigitalWalletVerificationMutation,
  );

  return useCallback(
    async ({ paymentMethodId, pmsvcId }: RunDigitalWalletVerificationParams) => {
      return new Promise<RunDigitalWalletVerificationSuccessResponse>((resolve, reject) => {
        commitMutation({
          variables: {
            input: {
              paymentMethodUuid: paymentMethodId,
              pmsvcId,
            },
          },
          onError: ({ alreadyReportedError }) => {
            reject(new TopLevelGqlError('runDigitalWalletVerification', alreadyReportedError));
          },
          onCompleted: ({ alreadyReportedErrors, response }) => {
            try {
              const data = assertPresence(response.completeWalletVerificationMonorail, {
                debugMessage: 'useRunDigitialWalletVerification onCompleted response',
                message: formatMessage(genericMessages.somethingWentWrong),
              });
              switch (data.__typename) {
                case 'CompleteWalletVerificationMonorailSuccess':
                  resolve(data);
                  break;
                case 'BadRequestError':
                  throw new GqlError({
                    code: data.code,
                    message: data.message,
                    __typename: data.__typename,
                  });
                default:
                  throw new GqlError({
                    __typename: data.__typename,
                    debugMessage: `useRunDigitalWalletVerification unrecognized typename: ${data.__typename}`,
                    message: formatMessage(genericMessages.somethingWentWrong),
                    variant: 'unrecognized_type',
                  });
              }
            } catch (err) {
              reject(addAlreadyReportedErrorsMetadata(coerceError(err), alreadyReportedErrors));
            }
          },
        });
      }).catch(async (err) =>
        Promise.reject(coerceError(err).addMetadata({ source: 'RunDigitalWalletVerification' })),
      );
    },
    [commitMutation, formatMessage],
  );
}

type RunDigitalWalletVerificationSuccessResponse = Extract<
  useRunDigitalWalletVerificationMutation['response']['completeWalletVerificationMonorail'],
  {
    __typename: 'CompleteWalletVerificationMonorailSuccess';
  }
>;
