/**
 * @generated SignedSource<<c64da0e070dbf4a3d5630d3f10903df4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type nativeCurrencySelectorQuery$variables = {};
export type nativeCurrencySelectorQuery$data = {
  readonly viewer: {
    readonly userProperties: {
      readonly nativeCurrency: string;
    } | null;
  };
};
export type nativeCurrencySelectorQuery = {
  response: nativeCurrencySelectorQuery$data;
  variables: nativeCurrencySelectorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nativeCurrency",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "nativeCurrencySelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              {
                "kind": "RequiredField",
                "field": (v0/*: any*/),
                "action": "LOG",
                "path": "viewer.userProperties.nativeCurrency"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "nativeCurrencySelectorQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a030941bc839da2c47935db5435e0006",
    "id": null,
    "metadata": {},
    "name": "nativeCurrencySelectorQuery",
    "operationKind": "query",
    "text": "query nativeCurrencySelectorQuery {\n  viewer {\n    userProperties {\n      nativeCurrency\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd3848d2409b96a0dcc8da3181e7edb3";

export default node;
