import { useCallback } from 'react';
import type { useFilterCBPayAssetsByDestinationWalletsFragment$key } from '@onramp/data/__generated__/useFilterCBPayAssetsByDestinationWalletsFragment.graphql';
import { useDestinationWalletsV2 } from '@onramp/state/recoil/appParamsState';
import { parseStoneIdToNetworkId } from '@onramp/utils/blockchains/stoneIdFormat';
import { graphql, readInlineData } from '@cbhq/data-layer';

const useFilterCBPayAssetsByDestinationWalletsFragment = graphql`
  fragment useFilterCBPayAssetsByDestinationWalletsFragment on CBPayAsset @inline {
    symbol
    networks {
      name
    }
  }
`;

export const useFilterCBPayAssetsByDestinationWallets = () => {
  const { singleAssetsRequested, networksWithAllAssetsRequested } = useDestinationWalletsV2();

  return useCallback<(assetRef: useFilterCBPayAssetsByDestinationWalletsFragment$key) => boolean>(
    (assetRef) => {
      const asset = readInlineData(useFilterCBPayAssetsByDestinationWalletsFragment, assetRef);
      /**
       * For the asset to be displayed it needs to have either:
       * 1. Top level network config displayed i.e. networks: "ethereum"
       * 2. Config with the asset directly specified i.e. assets: "ETH"
       */
      const isRequestedByAssetTicker = asset.symbol && singleAssetsRequested.includes(asset.symbol);
      if (isRequestedByAssetTicker) {
        return isRequestedByAssetTicker;
      }

      const isRequestedByNetwork = asset.networks
        ?.map((network) => parseStoneIdToNetworkId(network?.name))
        .some((stoneId) => stoneId && networksWithAllAssetsRequested.includes(stoneId));
      if (isRequestedByNetwork) {
        return isRequestedByNetwork;
      }

      return false;
    },
    [singleAssetsRequested, networksWithAllAssetsRequested],
  );
};
