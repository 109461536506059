import { initializeUsingQueryParams } from '@onramp/components/AppManagerProvider/utils';
import type { Response } from '@onramp/server/types';
import {
  addressAndAssetsToDestinationWallets,
  BaseBuyWidgetSchema,
  DefaultPaymentMethodSchema,
  FiatCurrencySchema,
  NetworkIdSchema,
} from '@onramp/shared/appParams.schema';
import { z } from 'zod';

export function isEligibleForOneClickBuy(
  reqQuery: Record<string, unknown>,
  reqBody: unknown,
  res: Response,
  overrideRequiredParams = false, // used by importTokenHandler for backwards compatibility
): boolean {
  const isOneClickBuyKilled = res.locals.killSwitches?.kill_one_click_buy;
  if (isOneClickBuyKilled) {
    return false;
  }

  if (overrideRequiredParams) {
    return true;
  }

  const haveAllAggregatorParams =
    AggregatorParamsSchema.safeParse(reqQuery).success ||
    AggregatorParamsBodySchema.safeParse(reqBody).success;

  return haveAllAggregatorParams || haveMinimumOneClickBuyParams(reqQuery, res);
}

function haveMinimumOneClickBuyParams(reqQuery: Record<string, unknown>, res: Response): boolean {
  const params = initializeUsingQueryParams(reqQuery);
  const parseResult = BaseBuyWidgetSchema.safeParse(params.data);
  if (!parseResult.success) {
    return false;
  }
  const parsedParams = parseResult.data;

  // Only the buy widget is eligible for OCB
  if (parsedParams?.widget !== 'buy') {
    return false;
  }

  // Convert address & assets to destinationWallets if avaialble
  if (parsedParams.destinationWallets === undefined && parsedParams.addresses) {
    parsedParams.destinationWallets = addressAndAssetsToDestinationWallets(
      parsedParams.addresses,
      parsedParams.assets,
    );
  }

  // There should only be one destination wallet with one supported asset
  const { destinationWallets } = res.locals.secureInitParameters ?? parsedParams;
  const canInferDefaultAsset =
    destinationWallets &&
    destinationWallets.length === 1 &&
    destinationWallets[0].assets?.length === 1;
  if (!parsedParams.defaultAsset && !canInferDefaultAsset) {
    return false;
  }

  // We need to have either a presetFiatAmount or a presetCryptoAmount parameter
  if (
    parsedParams.presetFiatAmount === undefined &&
    parsedParams.presetCryptoAmount === undefined
  ) {
    return false;
  }

  return true;
}

const AggregatorParamsSchema = z
  .object({
    quoteId: z.string().min(1),
    defaultAsset: z.string().min(1),
    defaultNetwork: NetworkIdSchema.optional(),
    defaultPaymentMethod: DefaultPaymentMethodSchema,
    presetFiatAmount: z.preprocess((x) => Number(x), z.number()).optional(),
    presetCryptoAmount: z.preprocess((x) => Number(x), z.number()).optional(),
    fiatCurrency: FiatCurrencySchema,
  })
  .refine((schema) => {
    return (
      (schema.presetFiatAmount === undefined && schema.presetCryptoAmount !== undefined) ||
      (schema.presetFiatAmount !== undefined && schema.presetCryptoAmount === undefined)
    );
  });

export const AggregatorParamsBodySchema = z.object({
  params: AggregatorParamsSchema,
});
