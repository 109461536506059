// Use this file to mock specific GraphQL fields for local testing
// See https://docs.cbhq.net/frontend/data-layer/guides/field-level-mocker
export const fieldOverrides = [
  {
    fieldOverride: [
      // This simulates the error case where a user has a negative available to send balance and we need to prevent them from buying
      {
        path: 'DISABLED.viewer.balanceData',
        value: {
          balanceInWbl: {
            value: '1001.000000',
          },
          portfolioBalanceV5: {
            balance: {
              totalPortfolioBalance: {
                value: '1000.000000',
              },
            },
          },
        },
      },
      // This simulates NY users who cannot send USDC on any network other than Ethereum because multichain is geofenced in NY
      // If only the default network is supported, viewer.assetByUuid.supportedNetworks returns an empty array (very intuitive /s)
      {
        path: 'DISABLED.viewer.assetByUuid.supportedNetworks',
        value: {
          supportedNetworks: [],
        },
        variables: {
          assetByUuid: {
            uuid: '2b92315d-eab7-5bef-84fa-089a131333f5',
          },
          supportedNetworks: {
            action: 'RECEIVE',
            applicationContext: 'CB_PAY',
          },
        },
      },
    ],
  },
];
