import { useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { AuthedGraphqlProviderContext } from '@onramp/components/AuthedGraphqlProvider/AuthedGraphqlProviderContext';
import { isLocalDevelopment } from '@onramp/utils/environment/sharedEnv';
import { coerceError, reportError } from '@onramp/utils/errors';
import { getCustomLogger } from '@onramp/utils/logger';
import { fetchWithCommonHeaders } from '@onramp/utils/requests';
import type { MockData } from '@cbhq/data-layer';
import { createGraphqlEnvironment, GraphqlProvider } from '@cbhq/data-layer';

type AuthedGraphqlProviderProps = {
  children: ReactNode;
};

export const AuthedGraphqlProvider = ({ children }: AuthedGraphqlProviderProps) => {
  const [environment, setEnvironment] = useState(getGraphqlEnvironment);

  const value = useMemo(
    () => ({
      resetRelayEnvironment: () => {
        try {
          setEnvironment(getGraphqlEnvironment());
        } catch (e) {
          reportError(
            coerceError(e).addMetadata({ source: 'AuthedGraphqlProvider.resetRelayEnvironment' }),
          );
        }
      },
    }),
    [],
  );

  return (
    <AuthedGraphqlProviderContext.Provider value={value}>
      <GraphqlProvider environment={environment}>{children}</GraphqlProvider>
    </AuthedGraphqlProviderContext.Provider>
  );
};

let fieldLevelMockData: MockData[] = [];

if (isLocalDevelopment) {
  try {
    // eslint-disable-next-line global-require
    fieldLevelMockData = require('./fieldOverrides').fieldOverrides as MockData[];
  } catch (e) {
    if (!Array.isArray(fieldLevelMockData)) {
      fieldLevelMockData = [];
    }
  }
}

const getGraphqlEnvironment = () =>
  createGraphqlEnvironment({
    graphqlUrl: '/graphql/query',
    networkFetch: fetchWithCommonHeaders,
    logger: getCustomLogger('AuthedGraphqlProvider'),
    // Add support for field level mocking in local dev mode
    ...(isLocalDevelopment && graphqlEnvFieldLevelMockConfig),
    // Other options to TBD - see https://github.cbhq.net/mono/repo/blob/master/eng/shared/react/data-layer/docs/api.md#graphql-environment--provider
  });

const graphqlEnvFieldLevelMockConfig = {
  featureFlags: {
    enableFieldLevelMocker: true,
  },
  interceptors: {
    fieldLevelMockerConfig: {
      mockData: fieldLevelMockData,
    },
  },
};
