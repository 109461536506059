export type SearchScore = {
  /** asc */
  relevance: number | null;
  /** percent match of query and account */
  coverage: number;
};

/**
 * Generate a search 'relevance' score for an Account asset.
 *
 * from consumer/react-native/src/packages/app/src/utils/SearchUtil.ts
 * @returns index of first occurrence
 */
export const calculateSearchScore = (
  searchQuery: string,
  primaryPhrase: string,
  secondaryPhrase?: string,
): SearchScore => {
  const lowercasedQuery = searchQuery.toLowerCase();
  const primaryPhaseOccurrence = primaryPhrase.toLowerCase().indexOf(lowercasedQuery);
  const secondaryPhraseOccurrence = secondaryPhrase
    ? secondaryPhrase.toLowerCase().indexOf(lowercasedQuery)
    : -1;

  // Calculate relevance and coverage based on the first occurrence of query in primary phrase
  if (
    primaryPhaseOccurrence >= 0 &&
    (primaryPhaseOccurrence <= secondaryPhraseOccurrence || secondaryPhraseOccurrence <= -1)
  ) {
    return {
      relevance: primaryPhaseOccurrence,
      coverage: lowercasedQuery.length / primaryPhrase.length,
    };
  }

  // otherwise, if the first occurrence of the query exists in the secondary phrase, calculate relevance
  // and coverage based on the secondary phrase
  if (secondaryPhrase && secondaryPhraseOccurrence >= 0) {
    return {
      relevance: secondaryPhraseOccurrence,
      coverage: lowercasedQuery.length / secondaryPhrase.length,
    };
  }

  return {
    relevance: null,
    coverage: 0,
  };
};
