import { ALLOWED_QUERY_PARAM_KEYS } from '@onramp/config/allowedQueryParamKeys';
import { isServer } from '@onramp/utils/environment/sharedEnv';

export function persistQueryParams(path: string): string {
  const currentParams = new URLSearchParams(isServer() ? '' : window.location.search || '');
  const newParams = new URLSearchParams();

  currentParams.forEach((value, key) => {
    if (ALLOWED_QUERY_PARAM_KEYS.includes(key)) {
      newParams.append(key, value);
    }
  });

  const qs = persistQueryParamsFromSearchParams(currentParams);
  return qs ? `${path}?${qs}` : path;
}

export function persistQueryParamsFromSearchParams(currentParams: URLSearchParams) {
  const newParams = new URLSearchParams();
  currentParams.forEach((value, key) => {
    if (ALLOWED_QUERY_PARAM_KEYS.includes(key)) {
      newParams.append(key, value);
    }
  });

  return newParams.toString();
}
