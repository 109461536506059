import { useLayoutEffect } from 'react';
import { broadcastEvent } from '@onramp/utils/postMessage';
import { routeKeyRegexMap } from '@onramp/utils/routes';
import { useRouter } from 'next/router';
import { initNextJsTrackPageview } from '@cbhq/client-analytics';

export default function useInitApis() {
  const router = useRouter();
  useLayoutEffect(() => {
    // enable page view tracking
    initNextJsTrackPageview({ nextJsRouter: router, pageKeyRegex: routeKeyRegexMap });

    // Public events for routing
    broadcastTransitionView(router.asPath);
    router.events.on('routeChangeStart', (asPath: string) => {
      broadcastTransitionView(asPath);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function broadcastTransitionView(asPath: string) {
  broadcastEvent({ eventName: 'transition_view', pageRoute: asPath.split('?')[0] });
}
