import { useMemo } from 'react';
import type { PartialPaletteConfig, SpectrumHue } from '@cbhq/cds-common';

import { getQueryParams } from './queryParams';
import { useThemeSpectrum } from './useThemeSpectrum';

type ThemeColorPalette = Partial<Record<SpectrumHue, PartialPaletteConfig>>;

// Colors mostly copied from Wallet RN
// Ref: https://github.cbhq.net/wallet/wallet-mobile/blob/master/workspaces/apps/rn/src/app/ThemeColorPreferenceProvider.tsx

export const themeColorConfigs: ThemeColorPalette = {
  blue: {
    primary: 'blue60',
    primaryForeground: 'gray0',
    primaryWash: 'blue0',
  },
  teal: {
    primary: 'teal60',
    primaryWash: 'teal0',
    primaryForeground: 'gray0',
  },
  purple: {
    primary: 'purple70',
    primaryForeground: 'gray0',
    primaryWash: 'purple0',
  },
  pink: {
    primary: 'pink60',
    primaryForeground: 'gray0',
    primaryWash: 'pink0',
    negative: 'red50',
  },
  green: {
    primary: 'green60',
    primaryForeground: 'gray0',
    primaryWash: 'green0',
    positive: 'green40',
  },
} as const;

export const darkThemeColorConfigs: ThemeColorPalette = {
  ...themeColorConfigs,
  green: {
    ...themeColorConfigs.green,
    positive: 'green70',
  },
} as const;

export const useThemeColorPalette = () => {
  const spectrum = useThemeSpectrum();

  const themeQueryParam = useMemo(() => {
    const themeParam = getQueryParams().get('themeColor') as SpectrumHue;
    const palette = spectrum === 'dark' ? darkThemeColorConfigs : themeColorConfigs;

    return palette[themeParam] ?? palette.blue;
  }, [spectrum]);

  return themeQueryParam;
};
