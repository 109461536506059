import { useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import exitWidget from '@onramp/utils/exitWidget';
import { genericMessages } from '@onramp/utils/genericMessages';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { Pictogram } from '@cbhq/cds-web/illustrations';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';
import { ActionType, ComponentType, logEvent } from '@cbhq/client-analytics';

export function WebView2FaRestrictionOverlay() {
  const { formatMessage } = useIntl();

  const handleAddAuthenticatorPress = useCallback(() => {
    logEvent('onramp.web_view_2fa_restriction_add_authenticator_pressed', {
      loggingId: 'd1679be0-8651-4a56-b71c-422271b327ef',
      componentType: ComponentType.unknown,
      action: ActionType.click,
    });
    const url = new URL(
      'security/settings?register-authenticator',
      process.env.NEXT_PUBLIC_ACCOUNTS_HOST ?? '',
    );

    /**
     * If in a WebView, open URL in the current browsing context; otherwise, in a new tab or window.
     * The special behavior when in a WebView aims to allow Android WebViews to intercept the navigation and provide an alternative experience.
     *
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#attr-target}
     * @see {@link https://github.com/react-native-webview/react-native-webview/blob/master/docs/Reference.md#setSupportMultipleWindows}
     * */
    window.open(url, '_self');
  }, []);

  const handleCancelPress = useCallback(() => {
    logEvent('onramp.web_view_2fa_restriction_cancel_pressed', {
      loggingId: '5c88e3b7-338f-43e6-9efb-f10ec31233f8',
      componentType: ComponentType.unknown,
      action: ActionType.click,
    });
    exitWidget();
  }, []);

  return (
    <VStack
      flexGrow={1}
      gap={1}
      justifyContent="space-between"
      alignItems="center"
      height="100%"
      spacing={3}
      testID="webview-restriction-overlay"
    >
      <VStack alignItems="center">
        <Box spacingTop={6} spacingBottom={3}>
          <Pictogram name="error" />
        </Box>
        <TextTitle3 as="h3" align="center" spacingBottom={2}>
          {formatMessage(messages.webview2faErrorTitle)}
        </TextTitle3>
        <TextBody as="p" align="center" color="foregroundMuted">
          {formatMessage(messages.webview2faErrorMessage)}
        </TextBody>
      </VStack>

      <VStack gap={1} width="100%">
        <Box width="100%">
          <Button variant="primary" block onPress={handleAddAuthenticatorPress}>
            {formatMessage(messages.webview2faSetupButtonLabel)}
          </Button>
        </Box>
        <Box width="100%">
          <Button variant="secondary" block onPress={handleCancelPress}>
            {formatMessage(genericMessages.cancel)}
          </Button>
        </Box>
      </VStack>
    </VStack>
  );
}

const messages = defineMessages({
  webview2faErrorTitle: {
    id: 'WebView2faRestrictionGuard.webview2faErrorTitle',
    defaultMessage: 'Authenticator 2FA required',
    description:
      'Error title for when the user does not have a supported 2FA method for the current context.',
  },
  webview2faErrorMessage: {
    id: 'WebView2faRestrictionGuard.webview2faErrorMessage',
    defaultMessage:
      'Your 2FA method is not currently supported at this time. Please set up an authenticator app in your Coinbase account in order to continue.',
    description:
      'Error message for when the user does not have a supported 2FA method for the current context.',
  },
  webview2faSetupButtonLabel: {
    id: 'WebView2faRestrictionGuard.webview2faSetupButtonLabel',
    defaultMessage: 'Set up Authenticator 2FA',
    description:
      'Label for button shown when the user cannot proceed with their currently enabled 2fa methods. Button leads directly to a page where the user can add the recommended + supported 2fa method.',
  },
});
