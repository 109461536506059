/**
 * @generated SignedSource<<9ac552351a00d6afb1e6159281ad8f31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readCBPayAssetAsOnrampStateFragment$data = {
  readonly exponent: number | null;
  readonly imageUrl: string | null;
  readonly name: string | null;
  readonly networks: ReadonlyArray<{
    readonly displayName: string | null;
    readonly isDefault: boolean | null;
    readonly minSend: string | null;
    readonly name: string | null;
  } | null> | null;
  readonly symbol: string | null;
  readonly uuid: string | null;
  readonly " $fragmentType": "readCBPayAssetAsOnrampStateFragment";
};
export type readCBPayAssetAsOnrampStateFragment$key = {
  readonly " $data"?: readCBPayAssetAsOnrampStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readCBPayAssetAsOnrampStateFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readCBPayAssetAsOnrampStateFragment"
};

(node as any).hash = "d9d91dff4ef580c6e8bc84c1790e6864";

export default node;
