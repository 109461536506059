import type { Getter } from 'jotai';
import { atom, getDefaultStore } from 'jotai';
import { atomWithQuery } from 'jotai-relay';
import type { Environment, fetchQuery, Variables } from 'relay-runtime';
import type { GraphQLTaggedNode } from '@cbhq/data-layer';

type FetchQueryConfig = Parameters<typeof fetchQuery>[3];

export function atomWithQueryAndMap<TVariables extends Variables, T = unknown>({
  query,
  variables,
  getConfig,
  getEnvironment,
  mapResponse = (v) => v as T,
}: {
  query: GraphQLTaggedNode;
  variables: (get: Getter) => TVariables;
  getConfig?: (get: Getter) => FetchQueryConfig;
  getEnvironment?: (get: Getter) => Environment;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapResponse?: (response: any) => T;
}) {
  const queryAtom = atomWithQuery<{ variables: TVariables; response: T }>(
    query,
    variables,
    getConfig,
    getEnvironment,
  );
  const mappedAtom: typeof queryAtom = atom(
    async (get) => mapResponse(await get(queryAtom)),
    (_get, set, ...args) => set(queryAtom, ...args),
  );

  return Object.assign(mappedAtom, { query });
}

export const jotaiStore = getDefaultStore();

// Expose on the global object for access in tests.
if (process.env.NEXT_PUBLIC_NODE_ENV !== 'production') {
  Object.assign(globalThis, { jotaiStore });
}
