// TODO [ONRAMP-1783]: Move this to the hook folder and update references.

import { useEffect, useMemo, useRef, useState } from 'react';
import type { Spectrum } from '@cbhq/cds-common';

import { getQueryParams } from './queryParams';

function getThemeQueryParam(): Spectrum | undefined {
  const themeParam = getQueryParams().get('theme');
  return themeParam === 'dark' || themeParam === 'light' ? themeParam : undefined;
}

export const useThemeSpectrum = (): Spectrum => {
  const mediaQuery = useRef<MediaQueryList>(window.matchMedia('(prefers-color-scheme: dark)'));
  const [prefersDarkMode, setPrefersDarkMode] = useState(() => mediaQuery.current.matches);
  const themeQueryParam = useMemo(getThemeQueryParam, []);

  useEffect(() => {
    const queryList = mediaQuery.current;
    const changeHandler = ({ matches }: { matches: boolean }) => {
      setPrefersDarkMode(matches);
    };

    /*
      https://developer.mozilla.org/en-US/docs/Web/API/MediaQueryList/addListener
      `addEventListener` and `removeEventListener` may not be available on the MediaQueryList object
      in certain older browsers which affects our embedded site in the Wallet iOS app. In these
      cases, we need to use the deprecated `addListener` and `removeListener` methods instead.
    */
    const queryListHasEventListener = typeof queryList.addEventListener === 'function';

    if (queryListHasEventListener) {
      queryList.addEventListener('change', changeHandler);
    } else {
      queryList.addListener(changeHandler);
    }

    return () => {
      if (queryListHasEventListener) {
        queryList.removeEventListener('change', changeHandler);
      } else {
        queryList.removeListener(changeHandler);
      }
    };
  }, []);

  if (themeQueryParam) {
    return themeQueryParam;
  }

  return prefersDarkMode ? 'dark' : 'light';
};
