import React, { useMemo } from 'react';
import type { RouteKey } from '@onramp/utils/routes';
import { getRouteKeyFromPath } from '@onramp/utils/routes';

import { BuyOneClickPageDataProvider } from './BuyOneClickPageDataProvider';
import { BuySelectAssetPageDataProvider } from './BuySelectAssetPageDataProvider';

const PassthroughProvider: React.FC = ({ children }) => <>{children}</>;

// This component provides a way to fetch all of the data required by a certain page before the page content starts to
// render. This can prevent waterfalling requests that create long loading times.
export const PageDataProvider: React.FC = ({ children }) => {
  const Provider = useMemo(() => {
    const pageKey = getRouteKeyFromPath(window.location.href);
    return (pageKey && pageDataProvidersMap.get(pageKey)) || PassthroughProvider;
  }, []);

  return <Provider>{children}</Provider>;
};

const pageDataProvidersMap = new Map<RouteKey, React.FC>([
  ['Buy.SelectAsset', BuySelectAssetPageDataProvider],
  ['Buy.OneClick', BuyOneClickPageDataProvider],
]);
