import { jsonStringSchema } from '@onramp/shared/utils.schema';
import type { Merge } from 'type-fest';
import { z } from 'zod';

const KILL_SWITCH_PATH = 'v3/coinbase.killswitch.KillSwitchService/KillSwitches';

/**
  According to https://github.cbhq.net/frontend/coinbase-www/tree/master/shared/src/utils/clientKillSwitch,
  `platform` is used to further limit the killswitch to specific platforms, such as certain
  mobile OS versions, the web, etc.
*/
export const KILL_SWITCH_PARAMS = Object.freeze({
  scope: 'coinbase_pay',
  platform: 'web',
  version: '',
});

export const KILL_SWITCH_PATH_CLIENT = `/cb-api-proxy/${KILL_SWITCH_PATH}`;
export const KILL_SWITCH_PATH_SERVER = `/api/${KILL_SWITCH_PATH}`;
export const KillSwitchesConfigSchema = z.object({
  killswitches: z.record(z.boolean().optional()),
});
export const KillSwitchesCookieSchema = jsonStringSchema
  .pipe(KillSwitchesConfigSchema)
  .optional()
  .catch(undefined);
export type KillSwitchesResponse = z.infer<typeof KillSwitchesConfigSchema>;
export type BaseKillSwitchesConfigType = KillSwitchesResponse['killswitches'];
export type KillSwitchesConfigType = Merge<
  BaseKillSwitchesConfigType,
  typeof DEFAULT_KILL_SWITCHES
>;

/**
 * A best practice for defining a kill switch is to use the verb `kill_` as a prefix and then the
 * name of the component or logic to kill switch.
 *
 * **Warning**: _all_ kill switches _must_ default to `false`, as the KS service won't return
 * disabled killswitches at all — e.g. if `kill_cb_pay` is disabled, the API will respond with `{}`,
 * not `{ kill_cb_pay: false }`
 */
// TODO [ONRAMP-2368]: add `satisfies Record<string, false>` to make sure all killswitches default to false
export const DEFAULT_KILL_SWITCHES = {
  kill_cbpay_non_3ds_cards: false,
  kill_cbpay_instant_ach: false,
  kill_cb_pay: false,
  kill_add_debit_card: false,
  kill_cbpay_cash_accounts: false,
  kill_cbpay_default_network_check: false,
  kill_one_click_buy: false,
  kill_one_click_buy_change_asset: false,
  kill_cbpay_buy_flow: false,
  kill_cbpay_guest_checkout: false,
  kill_cbpay_import_token_nonce_conversion: false,
  kill_cbpay_new_transaction_button: false,
  kill_cbpay_guest_base_support: false,
  kill_cbpay_base_support: false,
  kill_cbpay_wallet_cko_listener: true,
  kill_cbpay_broadcast_analytics_events: false,
  kill_cbpay_clear_app_params_cookie: false,
  kill_cbpay_buy_flow_delay: false,
  kill_cbpay_guest_checkout_3p: false,
  kill_cbpay_guest_checkout_recaptcha: false,
  kill_cbpay_applepay: false,
};
