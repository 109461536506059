import type { readCBPayAssetUuidFragment$key } from '@onramp/data/__generated__/readCBPayAssetUuidFragment.graphql';
import { graphql, readInlineData } from '@cbhq/data-layer';

const readCBPayAssetUuidFragment = graphql`
  fragment readCBPayAssetUuidFragment on CBPayAsset @inline {
    uuid
  }
`;

/** Read the CBPayAsset uuid for a comparison check */
export const readCBPayAssetUuid = (assetRef: readCBPayAssetUuidFragment$key) =>
  readInlineData(readCBPayAssetUuidFragment, assetRef).uuid;
