import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { useReportAndViewError } from '@onramp/hooks/useReportAndViewError';
import { useValidateClientOrigin } from '@onramp/hooks/useValidateClientOrigin';
import type { AnyWidgetParameters, WidgetKey } from '@onramp/shared/appParams.schema';
import { parseWidgetParams } from '@onramp/shared/appParams.schema';
import { activeWidgetAtom, buyWidgetParamsAtom } from '@onramp/state/recoil/appParamsState';
import { initMethodAtom } from '@onramp/state/recoil/atoms/initProcessAtoms';
import { secureInitParametersAtom } from '@onramp/state/recoil/atoms/secureInitAtoms';
import { setGuestCheckoutInitialValuesAtom } from '@onramp/state/recoil/guestCheckoutState';
import { jotaiStore } from '@onramp/state/recoil/utils';
import { checkDestinationWalletsUniformShape } from '@onramp/utils/checkDestinationWalletsUniformShape';
import { clientSessionIdStore } from '@onramp/utils/clientSessionIdStore';
import { HandledError } from '@onramp/utils/errors';
import { logInitOnrampWidget, logIsApplePaySupported, useLogAppBoot } from '@onramp/utils/eventing';
import { useLogCDPMetric } from '@onramp/utils/eventing/useLogCDPMetric';
import { getCbpayQueryValues } from '@onramp/utils/getCbpayQueryValues';
import { getIsEmbedded } from '@onramp/utils/getIsEmbedded';
import { logWidgetMetricOnce } from '@onramp/utils/metrics';
import type { MessageData } from '@onramp/utils/postMessage';
import {
  broadcastEvent,
  onBroadcastedPostMessage,
  postMessageToSdk,
} from '@onramp/utils/postMessage';
import { getQueryParamsAsRecord } from '@onramp/utils/queryParams';
import { fetchWithCommonHeaders } from '@onramp/utils/requests';
import type { ObjectUnion } from '@onramp/utils/types';
import { useReffedFunction } from '@onramp/utils/useReffedFunction';
import { logCriticalStepMetric } from '@onramp/v2/client/views/guest/onramp/analytics';
import { useAtomValue, useSetAtom } from 'jotai';
import { ZodError } from 'zod';
import { VStack } from '@cbhq/cds-web/layout';
import { ApiError } from '@cbhq/data-layer';

import { Loader } from '../Loader';

import type { InitMethodType, WidgetParameters } from './types';
import type { ParsedQueryParams } from './utils';
import { getErrorMessageFromSchema, initializeUsingQueryParams, parseQueryParams } from './utils';

type AppParameterProviderProps = {
  children: ReactNode;
  /** Only used for minimal content provider & tests to allow rendering hooks & children without initialization safety */
  shouldUseContentGuard?: boolean;
};

export type AppParamsMetadata = {
  /** How the widget was initialized e.g. `nonce`, `post_message`, `query_params` */
  initMethod: InitMethodType | undefined;
  /** General params and metadata e.g. app id, platform attribution, sdk version, platform version, RN version, integration type (`standalone`, `secure_standalone`, or `direct`), target (`popup` or `tab`), theme, theme color, etc. */
  queryExclusiveParams: ParsedQueryParams;
  /** Widget-specific params e.g. for the buy flow, it would have `destinationWallets`, `presetFiatAmount`, etc. */
  widgetParams: ObjectUnion<AnyWidgetParameters> | undefined;
};

type AttemptInitParamMethod = 'nonce' | 'post_message' | 'query_params';

export const AppManagerProvider = ({
  children,
  shouldUseContentGuard = true,
}: AppParameterProviderProps) => {
  const [activeWidget, setActiveWidget] = useState<WidgetKey | undefined>(undefined);
  const [buyParams, setBuyParams] = useState<WidgetParameters['buy']>(undefined);
  const reportAndViewError = useReportAndViewError();
  const logCDPMetric = useLogCDPMetric();
  const validateOrigin = useValidateClientOrigin();
  const querySchema = useMemo(() => parseQueryParams(), []);
  const initMethodRef = useRef<InitMethodType>();
  const clientAppDetails = clientSessionIdStore.getClientAppDetails();

  // TODO [ONRAMP-2473]: Dedupe app params being stored in global state
  // GLOBAL STATE
  const setInitMethod = useSetAtom(initMethodAtom);
  const setGlobalActiveWidget = useSetAtom(activeWidgetAtom);
  const setGlobalBuyParams = useSetAtom(buyWidgetParamsAtom);
  const setInitialGuestCheckoutData = useSetAtom(setGuestCheckoutInitialValuesAtom);
  const secureInitParams = useAtomValue(secureInitParametersAtom);

  useLogAppBoot();

  const value = useMemo<WidgetParameters>(() => {
    const isWidgetInitialized = Boolean(buyParams) && Boolean(activeWidget);
    return {
      ...querySchema.data,
      appId:
        secureInitParams?.appId || clientSessionIdStore.getAppId() || querySchema.data.appId || '',
      isEmbedded: getIsEmbedded(),
      activeWidget,
      buy: buyParams,
      isInitialized: querySchema.success && isWidgetInitialized,
    };
  }, [activeWidget, buyParams, querySchema.data, querySchema.success, secureInitParams]);

  const attemptInitMethod = useMemo<AttemptInitParamMethod>(() => {
    const cbpayQueryValues = getCbpayQueryValues();
    const nonce = cbpayQueryValues?.nonce ?? value.nonce;

    if (nonce) return 'nonce';

    if (value.type === 'secure_standalone') return 'post_message';

    return 'query_params';
  }, [value.nonce, value.type]);

  useEffect(
    () =>
      // setting this asynchronously so that partial results are available (e.g. queryParams may be available before widgetParams)
      clientSessionIdStore.setAppParamsMetadata({
        widgetParams: value.buy,
        queryExclusiveParams: querySchema.data,
        initMethod: initMethodRef.current,
      }),
    [querySchema.data, value.buy],
  );

  useEffect(() => {
    if (clientSessionIdStore.getPlatformAttribution() === 'ios') {
      logIsApplePaySupported();
    }
  }, []);

  const handleOnAppParams = useReffedFunction(
    (data: MessageData | undefined, initMethod: InitMethodType | undefined) => {
      setInitMethod(initMethod);
      // trying this out to see if it helps with inits when GCO is out
      logCriticalStepMetric('init_session_onramp');
      logWidgetMetricOnce({
        metricName: 'critical_step',
        value: 1,
        tags: {
          step: 'init',
          initMethod,
          isSecureInit: Boolean(secureInitParams),
        },
      });

      if (!querySchema.success && !getCbpayQueryValues()) {
        reportAndViewError(
          new HandledError({
            code: 'invalid_query_params',
            message:
              'There was a problem starting the app. Please verify the developer settings and try again.',
            debugMessage: getErrorMessageFromSchema(querySchema.error),
          }),
        );
      }

      if (clientAppDetails?.isSecureInitRequired && !jotaiStore.get(secureInitParametersAtom)) {
        reportAndViewError(
          new HandledError({
            code: 'invalid_init_method',
            message:
              'There was a problem starting the app. Please verify the developer settings and try again.',
            debugMessage: `Secure initialization is required for app ${clientAppDetails?.appId}`,
          }),
        );
      }

      const { destinationWallets } = {
        ...data,
        ...secureInitParams,
      };

      logWidgetMetricOnce({
        value: 1,
        metricName: 'destination_wallets_uniform_shape',
        tags: {
          uniform: checkDestinationWalletsUniformShape(destinationWallets),
        },
      });

      const { data: parsedData, error: parsedError } = parseWidgetParams({
        ...data,
        // Secure params always take priority if we're directly passed duplicate params
        ...secureInitParams,
      });
      initMethodRef.current = initMethod;
      // setting this synchronously so that it's available for events, metrics, and errors triggered in this callback
      clientSessionIdStore.setAppParamsMetadata({
        widgetParams: parsedData,
        queryExclusiveParams: querySchema.data,
        initMethod,
      });

      if (parsedData) {
        broadcastEvent({ eventName: 'open', widgetName: parsedData.widget });

        // eslint-disable-next-line default-case
        switch (parsedData.widget) {
          case 'buy':
            setGlobalBuyParams(parsedData);
            setBuyParams(parsedData);
            setInitialGuestCheckoutData(parsedData);
            logInitOnrampWidget();
            logCDPMetric('CDP_METRIC_TYPE_INIT_SUCCESS');
            break;
        }

        setGlobalActiveWidget(parsedData.widget);
        setActiveWidget(parsedData.widget);
      } else {
        reportAndViewError(
          new HandledError({
            code: 'invalid_widget_params',
            debugMessage: getErrorMessageFromSchema(parsedError),
            message:
              'There was a problem initializing the widget. Please verify the app parameters and try again.',
          }).addMetadata({
            source: 'AppManagerProvider.handleOnAppParams',
            widgetParamsKeys: data
              ? Object.keys(data)
                  .filter((key) => Boolean(data[key]))
                  .join(',')
              : 'undefined',
          }),
        );
      }
    },
  );

  // Handle nonce & secure standalone execution sequence
  useEffectOnMount(() => {
    if (attemptInitMethod === 'nonce') {
      const cbpayQueryValues = getCbpayQueryValues();
      const nonce = cbpayQueryValues?.nonce ?? value.nonce;
      // Nonce & pixel initialization
      const fetchAppParameters = () => {
        fetchWithCommonHeaders('/get-widget-params', {
          method: 'POST',
          body: JSON.stringify({ nonce }),
        })
          .then(async (r) => r.json())
          .then(({ appParams }: { appParams: MessageData }) => {
            handleOnAppParams(appParams, 'nonce');
          })
          .catch((error) => {
            const handledError = new HandledError({
              code: 'invalid_widget_params',
              debugMessage:
                error instanceof ZodError
                  ? getErrorMessageFromSchema(error)
                  : `Not a zod error: ${String(error)}`,
              message:
                'There was a problem initializing the widget. Please verify the app parameters and try again.',
            }).addMetadata({ source: 'AppManagerProvider.useEffectOnMount' });

            if (error instanceof ApiError) {
              const { reauthenticationRequired, responseJson, status } = error;

              handledError.addMetadata({
                responseJson: JSON.stringify(responseJson),
                reauthenticationRequired,
                status,
              });
            }

            reportAndViewError(handledError);
          });
      };

      if (clientSessionIdStore.getCsrfToken()) {
        fetchAppParameters();
      } else {
        const interval = setInterval(() => {
          if (clientSessionIdStore.getCsrfToken()) {
            fetchAppParameters();
            clearInterval(interval);
          }
        }, 200);
      }
    } else if (attemptInitMethod === 'post_message') {
      // Post message initialization
      postMessageToSdk('app_ready');

      onBroadcastedPostMessage('app_params', {
        onMessage: (data) => handleOnAppParams(data, 'post_message'),
        onValidateOrigin: async (origin) => validateOrigin(origin),
      });
    }
  });

  // Handle query param init
  useEffect(() => {
    if (attemptInitMethod === 'query_params' && !value.isInitialized) {
      // Default: Query string initialization
      const { data, initMethod } = initializeUsingQueryParams(getQueryParamsAsRecord());
      handleOnAppParams(data, initMethod);
    }
  }, [handleOnAppParams, attemptInitMethod, value.isInitialized]);

  return (
    <AppManagerProviderContext.Provider value={value}>
      <AppManagerContentGuard shouldUseContentGuard={shouldUseContentGuard} value={value}>
        {children}
      </AppManagerContentGuard>
    </AppManagerProviderContext.Provider>
  );
};

const AppManagerContentGuard: React.FC<
  { value: WidgetParameters } & Pick<AppParameterProviderProps, 'shouldUseContentGuard'>
> = ({ children, value, shouldUseContentGuard }) => {
  const { isInitialized } = value;
  const initMethod = useAtomValue(initMethodAtom);
  const secureInitParams = useAtomValue(secureInitParametersAtom);

  useEffect(() => {
    if (isInitialized) {
      logWidgetMetricOnce({
        metricName: 'critical_step',
        value: 1,
        tags: { step: 'init-success', initMethod, isSecureInit: Boolean(secureInitParams) },
      });
    }
  }, [initMethod, isInitialized, secureInitParams]);

  // Block rendering until initialization has occured.
  // This way our widgets can use non-nullable values without optional chaining.
  if (shouldUseContentGuard && !isInitialized) {
    return (
      <VStack width="100vw" height="100vh">
        <Loader />
      </VStack>
    );
  }

  return <>{children}</>;
};

export const AppManagerProviderContext = createContext<WidgetParameters | undefined>(undefined);
