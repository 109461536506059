import type { Simplify } from 'type-fest';

export type AtLeastOne<T> = [T, ...T[]];

/** Requires all optoinal properties to be provided while allowing optional properties to be undefined. */
export type Complete<T> = {
  // [P in keyof Required<T>] (index signature) ->
  //     Create propetty in new type for each property in non-optional version of T
  // Pick<T, P> (ORIGINAL_TYPE herein) ->
  //     The original type for the property being evaluated
  // Required<Pick<T, P>> (REQUIRED_TYPE herein) ->
  //     The original type for the property being evaluated made non-optional
  // Pick<T, P> extends Required<Pick<T, P>> ->
  //    true/false if ORIGINAL_TYPE matches REQUIRED_TYPE
  // ? T[P] : T[P] | undefined
  //    ORIGINAL_TYPE when already required
  //    REQUIRED_TYPE | undefined when originally optional
  [P in keyof Required<T>]: Pick<T, P> extends Required<Pick<T, P>> ? T[P] : T[P] | undefined;
};

export type RequiredFields<T> = {
  // [K in keyof T] as T[K]
  //    Iterates over values of T
  // extends Required<T>[K] ?
  //    Checks if the iterated value of T is the same as the non-optional value (meaning)
  // K : never
  //    K includes the key in the index signature, where never excludes the key from the index signature
  // T[K]
  //    Use the original value of the type for the key
  [K in keyof T as T[K] extends Required<T>[K] ? K : never]: T[K];
};

export type ValueOf<T> = T[keyof T];

export type PickPartial<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type DeepPartial<T> = T extends (infer El)[]
  ? DeepPartial<El>[]
  : // eslint-disable-next-line @typescript-eslint/ban-types
  T extends Function
  ? T
  : T extends Record<keyof unknown, unknown>
  ? { [key in keyof T]?: DeepPartial<T[key]> }
  : T;

export type DeepWritable<T> = T extends (infer El)[]
  ? DeepWritable<El>[]
  : // eslint-disable-next-line @typescript-eslint/ban-types
  T extends Function
  ? T
  : T extends Record<keyof unknown, unknown>
  ? { -readonly [key in keyof T]: DeepWritable<T[key]> }
  : T;

export type DeepReadonly<T> = T extends (infer El)[]
  ? readonly DeepReadonly<El>[]
  : // eslint-disable-next-line @typescript-eslint/ban-types
  T extends Function
  ? T
  : T extends Record<keyof unknown, unknown>
  ? { readonly [key in keyof T]: DeepReadonly<T[key]> }
  : T;

// eslint-disable-next-line @typescript-eslint/ban-types
export type AwaitedFn<T extends Function> = T extends (...args: infer Args) => infer Ret
  ? (...args: Args) => Awaited<Ret>
  : T;

export type ResolveType<T> = T extends (...args: infer Args) => infer Ret
  ? (...args: Args) => ResolveType<Ret>
  : T extends Promise<infer TAwaited>
  ? Promise<ResolveType<TAwaited>>
  : { [K in keyof T]: ResolveType<T[K]> };

export const isNotNullish = <T>(value: T | null | undefined): value is T =>
  value !== null && value !== undefined;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyFunction = (...args: any[]) => any;

/**
 * Helper to make it easier to work with object unions. Will make all keys available in some type in the union available in all
 * of them — if missing it will be added as `[key]?: undefined`.
 *
 * @example
 * // { str: string; num?: undefined } | { num: number; str?: undefined }
 * type Example = ObjectUnion<{ str: string } | { num: number }>;
 *
 * // example usage
 * type SubmitEvent = { type: 'submit'; formData: Record<string, any> };
 * type ChangeEvent = { type: 'change'; value: string };
 * type RawEventUnion = SubmitEvent | ChangeEvent;
 * type WrappedEventUnion = ObjectUnion<SubmitEvent | ChangeEvent>;
 *
 * function raw(event: RawEventUnion) {
 *    event.value; // type error
 *    if (event.value) {
 *      // nothing happens — you'd need to check specifically for event.type
 *    }
 * }
 *
 * function wrapped(event: WrappedEventUnion) {
 *    event.value; // string | undefined
 *    if (event.value) {
 *      // event has type ChangeEvent inside of the if
 *    }
 * }
 */
export type ObjectUnion<T> = ObjectUnionHelper<T>;

/**
 * Same as the `keyof` operator, but works with unions. E.g. `keyof ({ str: string } | { num: number })` => `never`, but
 * `KeyOfUnion<{ str: string } | { num: number }>` => `'str' | 'num'`
 */
type KeyOfUnion<T> = T extends unknown ? keyof T : never;

/**
 * We need to have two copies of the input type for {@link ObjectUnion} so that one is distributed inside the conditional
 * expression {@link T} and the other isn't {@link U}. The helper is used so that the second argument (that should always
 * equal the first one) isn't available in the public type, making it harder to misuse it.
 */
type ObjectUnionHelper<T, U extends T = T> = T extends unknown
  ? Simplify<T & { [K in Exclude<KeyOfUnion<U>, keyof T>]?: undefined }>
  : never;

/**
 * A version of {@link Omit} that works with unions:
 * @example
 * type ObjA = { prop1: 'a', prop2: string };
 * type ObjB = { prop1: 'b', prop2: string };
 *
 * // { prop1: 'a', prop2: string } | { prop1: 'b', prop2: string }
 * type Incorrect = Omit<ObjA | ObjB, 'prop1'>;
 *
 * // { prop2: string } | { prop2: string }
 * type Correct = DistributiveOmit<ObjA | ObjB, 'prop1'>;
 */
export type DistributiveOmit<T, K extends keyof T> = T extends unknown ? Omit<T, K> : undefined;

/**
 * A way to verify that a variable's type satisfies a given type without changing it. This should be
 * replaced with `satisfies T` once our tooling supports it
 */
export const satisfies =
  <T>() =>
  <U extends T>(value: U) =>
    value;
