import { coerceError, HandledError, NetworkError } from '@onramp/utils/errors';
import {
  getAuthStatus,
  logSessionCheckMetric,
} from '@onramp/v2/client/utils/auth/isCbSessionActive';

import { isLocalDevelopment } from '../environment/sharedEnv';

/**
 * This utility makes a call to an API on the Coinbase login service to check if the user has a valid session. We can't
 * proxy this request through our node server because it needs to have the login.coinabse.com cookies in order to
 * actually check the session. We need to check this session because the U2FA component requires an active login session
 * to work.
 *
 * @returns true/false if there is a valid Coinbase login session
 */
export const isCBSessionActive = async (): Promise<boolean> => {
  if (isLocalDevelopment) {
    // We can't call the login service directly in local due to CORS, so we just assume the login session is active.
    return true;
  }

  try {
    // Access control for this API is managed here in Entry Gateway, it has a strict list of allowed headers, so we
    // cannot use our normal fetchWithCommonHeaders method.
    // https://github.cbhq.net/infra/entry-gateway/blob/0dbe3c1a834919a70d39ead344208d62a968817f/config/config.primary.production.aws.use1.yml#L7478
    // eslint-disable-next-line no-restricted-globals
    const getStatusRes = await fetch(
      `${process.env.NEXT_PUBLIC_LOGIN_HOST}/api/v1/get-session-status`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
      },
    );

    if (!getStatusRes.ok && getStatusRes.status !== 401) {
      logSessionCheckMetric('error');
      throw new HandledError({
        message: 'Error validating session',
        debugMessage: `Received a non-200, non-401 response when calling get-session-status: ${getStatusRes.status}`,
      }).addMetadata({
        source: 'isCBSessionActive',
      });
    }

    logSessionCheckMetric(await getAuthStatus(getStatusRes));

    if (getStatusRes.ok) {
      return true;
    }

    // This clears any expired session state, preventing issues with two-factor-register
    // eslint-disable-next-line no-restricted-globals
    const deleteRes = await fetch(`${process.env.NEXT_PUBLIC_LOGIN_HOST}/api/v1/delete`, {
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    });

    if (!deleteRes.ok) {
      throw new Error('Failed to clear expired session state');
    }

    return false;
  } catch (e) {
    logSessionCheckMetric('error');
    return Promise.reject(
      coerceError(e, { fallback: NetworkError }).addMetadata({
        source: 'isCBSessionActive',
      }),
    );
  }
};
