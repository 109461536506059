import { reportBugsnag } from '../bugsnag';
import { isProduction } from '../environment/sharedEnv';
import type { ReportableMetadata } from '../eventing';
import { logError } from '../eventing';
import { logWidgetMetric } from '../metrics';
import { broadcastErrorEvent } from '../postMessage';

import type { BaseError, ErrorState } from './errors';

export type ReportErrorOptions = {
  /** Should only be used internally by reportAndViewError. Used to help determine error impact at a glace. */
  userFacing?: true;
  severity?: 'info' | 'warning' | 'error';
  broadcast?: boolean;
};

export function reportError(error: BaseError, options: ReportErrorOptions = {}) {
  const errorState = error.asErrorState();
  const { type, skipReportToBugsnag, source } = errorState;

  const sanitizedMetadata = getReportableMetadata(errorState, options);

  const shouldBroadcastError = options.broadcast ?? options.userFacing;

  logError({ errorType: type, ...sanitizedMetadata });

  logWidgetMetric({
    metricName: 'error',
    value: 1,
    tags: {
      error_type: type,
      user_facing: `${Boolean(options.userFacing)}`,
      severity: options.severity,
      source: source ?? 'unknown',
    },
  });

  // TODO [ONRAMP-1772]: log errorState.debugMessage in dev if we have one
  // probably also add debugMessage as an optional arg to most `BaseError` variants
  if (type === 'internal') {
    if (!skipReportToBugsnag) {
      reportBugsnag(error, {
        severity: options.severity ?? 'error',
        metadata: sanitizedMetadata,
      });
    }

    if (!isProduction) {
      // eslint-disable-next-line no-console
      console.error(errorState.error);
    }

    if (shouldBroadcastError) {
      broadcastErrorEvent({ errorType: 'internal_error', debugMessage: error.message });
    }
  } else if (type === 'handled') {
    if (!skipReportToBugsnag) {
      reportBugsnag(error, {
        severity: options.severity ?? 'info',
        metadata: sanitizedMetadata,
      });
    }

    if (shouldBroadcastError) {
      broadcastErrorEvent({
        errorType: 'handled_error',
        code: errorState.code,
        debugMessage: errorState.debugMessage || errorState.message,
      });
    }
  } else if (type === 'network') {
    if (!skipReportToBugsnag) {
      reportBugsnag(error, {
        severity: options.severity ?? 'warning',
        metadata: sanitizedMetadata,
      });
    }

    if (shouldBroadcastError) {
      broadcastErrorEvent({ errorType: 'network_error', debugMessage: error.message });
    }
  }

  return errorState;
}

/**
 * Sanitizes metadata by removing undefined values and prepending keys with `error_`.
 *
 * This should only be called internally by reportError, just before reporting the metadata out.
 * @param obj - Unsanitized metadata
 * @returns Sanitized metadata
 */
function sanitizeMetadata(obj: Record<string, string | string[] | undefined>): ReportableMetadata {
  const whitelistedKeys = new Set(['__typename', 'gql_code', 'gql_message', 'gql_variant']);
  return Object.keys(obj).reduce((prev: ReportableMetadata, key) => {
    const value = obj[key];

    if (value !== undefined) {
      const valueArray = Array.isArray(value) ? value : [value];
      const newKey = whitelistedKeys.has(key) ? key : `error_${key}`;
      // eslint-disable-next-line no-param-reassign
      prev[newKey] = valueArray.length === 1 ? valueArray[0] : valueArray;
    }

    return prev;
  }, {});
}

function getReportableMetadata(errorState: ErrorState, options: ReportErrorOptions) {
  const sanitizedMetadata = sanitizeMetadata(errorState.metadata ?? {});
  const addToMetadata = (key: string, value?: string) => {
    if (value) {
      sanitizedMetadata[key] = value;
    }
  };

  addToMetadata('error_user_facing', `${Boolean(options.userFacing)}`);

  // Common
  addToMetadata('errorstate_message', errorState.message);
  addToMetadata('referrer', document.referrer);

  if (errorState.type === 'internal') {
    if (!errorState.message) {
      addToMetadata('errorstate_message', errorState.error.message);
    }
    // This is a constant message we can use to query in DD
    // i.e. the error message may be i18n friendly for display, while this message will be english and consistent.
    addToMetadata('errorstate_internal_message', errorState.error.message);
    addToMetadata('errorstate_internal_name', errorState.error.name);
  }

  if (errorState.debugMessage) {
    addToMetadata('errorstate_debug_message', errorState.debugMessage);
  }

  if (errorState.type === 'handled') {
    addToMetadata('errorstate_code', errorState.code);
  }

  return sanitizedMetadata;
}
