export type DigestAlgorithm = 'SHA-1' | 'SHA-256' | 'SHA-384' | 'SHA-512';

/** Hashes a string with a given algorithm & returns a hex string representing the result */
export async function digestString(input: string, algorithm: DigestAlgorithm): Promise<ArrayBuffer>;
export async function digestString(
  input: undefined,
  algorithm: DigestAlgorithm,
): Promise<undefined>;
export async function digestString(input: string | undefined, algorithm: DigestAlgorithm) {
  if (!input) return undefined;
  return crypto.subtle.digest(algorithm, new TextEncoder().encode(input));
}

export function bytesToString(buffer: ArrayBuffer) {
  const hashArray = Array.from(new Uint8Array(buffer)); // convert buffer to byte array
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
  return hashHex;
}
