import { defineMessages } from 'react-intl';

export const genericMessages = defineMessages({
  cancel: {
    id: 'genericMessages.cancel',
    defaultMessage: 'Cancel',
    description: 'Cancel button',
  },
  close: {
    id: 'genericMessages.close',
    defaultMessage: 'Close',
    description: 'Close button',
  },
  checkStatus: {
    id: 'genericMessages.checkStatus',
    defaultMessage: 'Check status',
    description: 'Generic check status message',
  },
  checkStatusBody: {
    id: 'genericMessages.checkStatusBody',
    defaultMessage:
      "We're unable to load Coinbase Onramp at this time. Please check our status page for more information or try again later.",
    description: 'Generic error message body',
  },
  coinbasePay: {
    id: 'genericMessages.coinbasePay',
    defaultMessage: 'Coinbase Pay',
    description: 'Coinbase Pay application name',
  },
  goBack: {
    id: 'genericMessages.goBack',
    defaultMessage: 'Go back',
    description: 'Go back button',
  },
  pleaseTryAgainLater: {
    id: 'genericMessages.pleaseTryAgainLater',
    defaultMessage: 'Please try again later.',
    description: 'Generic try again later message',
  },
  purchaseFailedTitle: {
    id: 'genericMessages.purchaseFailedTitle',
    defaultMessage: 'Purchase failed',
    description: 'Title for purchase failed message',
  },
  purchaseFailedMessage: {
    id: 'genericMessages.purchaseFailedMessage',
    defaultMessage:
      'There was a problem with your payment. You may create a Coinbase account to contnue buying crypto. An account gives you higher limits, instant access to funds and saved payment methods.',
    description: 'Message for generic purchase failed',
  },
  purchaseFailedAction: {
    id: 'genericMessages.purchaseFailedAction',
    defaultMessage: 'Create an account',
    description: 'Action for purchase failed message',
  },
  somethingWentWrong: {
    id: 'genericMessages.somethingWentWrong',
    defaultMessage: 'Something went wrong',
    description: 'Generic error message',
  },
  secondaryActionTitle: {
    id: 'genericMessages.secondaryActionTitle',
    defaultMessage: 'Cancel',
    description: 'Cancel button for mobile experience',
  },
  tryAgain: {
    id: 'genericMessages.tryAgain',
    defaultMessage: 'Try again',
    description: 'Try again button',
  },
  coinbaseOnramp: {
    id: 'genericMessages.coinbaseOnramp',
    defaultMessage: 'Coinbase Onramp',
    description: 'Coinbase Onramp application name',
  },
  continue: {
    id: 'genericMessages.continue',
    defaultMessage: 'Continue',
    description: 'Continue',
  },
  loadingPaymentMethod: {
    id: 'genericMessages.loadingPaymentMethod',
    defaultMessage: 'Loading your payment method...',
    description: 'Loading payment method',
  },
  fetchingQuote: {
    id: 'genericMessages.fetchingQuote',
    defaultMessage: 'Fetching the quote...',
    description: 'Fetching quote',
  },
  threeDSUpdateWalletMessage: {
    id: 'genericMessages.threeDSUpdateWalletMessage',
    defaultMessage:
      'Your card is not supported with this version of Coinbase Wallet. Please update to the latest version and try again.',
    description: 'Error message to update wallet app',
  },
});
