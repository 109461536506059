import { useMemo } from 'react';
import {
  assetMetadataSelector,
  networkMetadataSelector,
} from '@onramp/state/recoil/appParamsState';
import { countryPermissionMap } from '@onramp/utils/getIsCountryAllowed';
import { useKillSwitches } from '@onramp/utils/killswitches/useKillSwitches';
import { useAtomValue } from 'jotai';

export const useDynamicKillSwitchPrefixes = () => {
  const networkMetadata = useAtomValue(networkMetadataSelector);
  const assetMetadata = useAtomValue(assetMetadataSelector);

  return useMemo(
    () =>
      [
        {
          prefix: 'kill_cbpay_network_',
          label: 'Network kill switch',
          helperText: 'Network name (e.g. optimism, polygon)',
          sampleSuffix: 'polygon',
          allowedSuffixes: Object.keys(networkMetadata),
        },
        {
          prefix: 'kill_cbpay_asset_',
          label: 'Asset kill switch',
          helperText: 'Asset ticker (e.g. ETH, BTC)',
          sampleSuffix: 'ETH',
          allowedSuffixes: Object.keys(assetMetadata),
        },
        {
          prefix: 'kill_cbpay_buy_flow_country_',
          label: 'Buy flow kill switch per country',
          helperText: 'Country code (e.g. US, BR)',
          sampleSuffix: 'BR',
          allowedSuffixes: Object.keys(countryPermissionMap),
        },
      ] as const,
    [assetMetadata, networkMetadata],
  );
};

export type DynamicKillSwitchPrefix = ReturnType<
  typeof useDynamicKillSwitchPrefixes
>[number]['prefix'];

export function usePrefixedKillSwitches(prefix: DynamicKillSwitchPrefix) {
  const killSwitches = useKillSwitches();

  return useMemo(
    () =>
      Object.keys(killSwitches)
        .filter((item) => item.startsWith(prefix) && killSwitches[item])
        .map((item) => item.slice(prefix.length)),
    [killSwitches, prefix],
  );
}
