/**
 * @generated SignedSource<<1efab6e0fdcdd6f06b380814d8c314cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AccountType = "COLLATERAL" | "DEFI_YIELD" | "FIAT" | "MULTISIG" | "MULTISIG_VAULT" | "STAKED_FUNDS" | "UNKNOWN" | "VAULT" | "WALLET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type readFiatWalletAccountFragment$data = {
  readonly assetOrFiatCurrency: {
    readonly __typename: "TiersCurrency";
    readonly code: string;
    readonly color: string;
    readonly name: string;
    readonly type: string;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly name: string;
  readonly type: AccountType;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"getIsCountryAllowedAccountFragment" | "readAccountBalanceFragment">;
  readonly " $fragmentType": "readFiatWalletAccountFragment";
};
export type readFiatWalletAccountFragment$key = {
  readonly " $data"?: readFiatWalletAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readFiatWalletAccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readFiatWalletAccountFragment"
};

(node as any).hash = "a9f8d4e65fb8989f543c3c5e73f80678";

export default node;
