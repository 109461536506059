import type { nativeCurrencySelectorQuery$data } from '@onramp/data/__generated__/nativeCurrencySelectorQuery.graphql';
import { useIsGuestCheckoutPath } from '@onramp/utils/guestCheckoutUtils';
import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import { graphql } from '@cbhq/data-layer';

import { fiatCurrencySelector } from '../guestCheckoutState';
import { atomWithQueryAndMap } from '../utils';

const query = graphql`
  query nativeCurrencySelectorQuery {
    viewer {
      userProperties {
        nativeCurrency @required(action: LOG)
      }
    }
  }
`;

export const nativeCurrencySelector = atomWithQueryAndMap({
  query,
  variables: () => ({}),
  mapResponse: (data: nativeCurrencySelectorQuery$data) =>
    data.viewer.userProperties?.nativeCurrency,
});

export const useNativeCurrency = () => {
  const isGuestCheckout = useIsGuestCheckoutPath();
  return useAtomValue(isGuestCheckout ? fiatCurrencySelector : nativeCurrencySelector);
};

export const nativeCurrencyLoadable = loadable(nativeCurrencySelector);
