import { useEffect } from 'react';
import type { FC } from 'react';
import { useSetAtom } from 'jotai';
import { useHydrateAtoms } from 'jotai/utils';
import { environmentAtom } from 'jotai-relay';
import { useRelayEnvironment } from '@cbhq/data-layer';

export const JotaiRelayProvider: FC = ({ children }) => {
  const relayEnvironment = useRelayEnvironment();
  const setJotaiRelayEnvironment = useSetAtom(environmentAtom);

  useHydrateAtoms([[environmentAtom, relayEnvironment]]);

  useEffect(
    () => setJotaiRelayEnvironment(relayEnvironment),
    [relayEnvironment, setJotaiRelayEnvironment],
  );

  return <>{children}</>;
};
