import type { PollForBuyStatusErrorMetadata } from '@onramp/hooks/buy/usePollForBuyStatus';

import type { ErrorState } from './errors';
import { BaseError, GqlError, mergeErrorMetadata } from './errors';

type CommitBuyAndVerifyErrorArgs = {
  commitBuyUuid?: string;
  gqlErrorOrMessage: GqlError | string;
  debugMessage: string;
  hasBuySucceeded: boolean;
  hasPollSucceeded: boolean;
  pollForBuyStatusErrorMetadata?: PollForBuyStatusErrorMetadata;
};
export class CommitBuyAndVerifyError extends BaseError {
  public commitBuyUuid: string | undefined;

  public gqlError: GqlError | undefined;

  /** Buy can fail after commit succeeds if 3ds poll fails. */
  public hasBuySucceeded: boolean;

  /** Commit succeeds before we start polling for 3ds. */
  public get hasCommitSucceeded() {
    return this.gqlError === undefined;
  }

  public hasPollSucceeded: boolean;

  public pollForBuyStatusErrorMetadata: PollForBuyStatusErrorMetadata | undefined;

  constructor({
    commitBuyUuid,
    gqlErrorOrMessage,
    debugMessage,
    hasBuySucceeded,
    hasPollSucceeded,
    pollForBuyStatusErrorMetadata,
  }: CommitBuyAndVerifyErrorArgs) {
    super(gqlErrorOrMessage);
    this.commitBuyUuid = commitBuyUuid;
    this.debugMessage = debugMessage;
    if (gqlErrorOrMessage instanceof GqlError) {
      this.gqlError = gqlErrorOrMessage;
    }

    this.hasBuySucceeded = hasBuySucceeded;
    this.hasPollSucceeded = hasPollSucceeded;
    this.pollForBuyStatusErrorMetadata = pollForBuyStatusErrorMetadata;
  }

  public getMetadata(): Record<string, string[]> {
    return mergeErrorMetadata(
      super.getMetadata(),
      this.successMetadata,
      this.gqlError?.getMetadata() ?? {},
      this.pollForBuyStatusErrorMetadata ?? {},
    );
  }

  public get successMetadata() {
    return {
      hasBuySucceeded: `${this.hasBuySucceeded}`,
      hasCommitSucceeded: `${this.hasCommitSucceeded}`,
      hasPollSucceeded: `${this.hasPollSucceeded}`,
    };
  }

  public asErrorState(): ErrorState {
    if (this.gqlError !== undefined) {
      return this.gqlError.asErrorState();
    }

    return super.asErrorState();
  }
}
