import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

type NoSSRProps = {
  children: ReactNode;
};

export const NoSSR = ({ children }: NoSSRProps) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  return !isMounted ? null : <>{children}</>;
};
