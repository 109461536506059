/**
 * @generated SignedSource<<0170171f10aad983dac448417bc77e4e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PaymentMethodTypeV2 = "ACH_BANK_ACCOUNT" | "APPLE_PAY" | "ARGENTINE_BANK_ACCOUNT" | "BANCOMAT_PAY" | "BLIK" | "DENEB_IMPS" | "DENEB_UPI" | "DIRECT_DEPOSIT_ACCOUNT" | "EFT" | "FEDWIRE" | "FIAT_ACCOUNT" | "GIFTCARD" | "GOOGLE_PAY" | "IDEAL_BANK_ACCOUNT" | "INTERAC" | "OPEN_BANKING" | "PAYMENT_METHOD_TYPE_UNKNOWN" | "PAYPAL_ACCOUNT" | "PIX_DEPOSIT" | "PIX_WITHDRAWAL" | "QD" | "REMITLY" | "RTP" | "SEPA_BANK_ACCOUNT" | "SEPA_V2" | "SG_FAST" | "SG_PAYNOW" | "SOFORT" | "SWIFT_BANK_ACCOUNT" | "TRUSTLY" | "UK" | "UK_BANK_ACCOUNT" | "WORLDPAY_CARD" | "ZENGIN_DEPOSIT" | "ZENGIN_DEPOSIT_V2" | "ZENGIN_WITHDRAWAL" | "ZENGIN_WITHDRAWAL_V2" | "ZEPTO" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type readFiatWalletsPaymentMethodFragment$data = {
  readonly pickerData: {
    readonly __typename: "CoinbaseFiatAccountPickerData";
    readonly fiatAccount: {
      readonly uuid: string;
    };
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
  readonly type: PaymentMethodTypeV2;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"readFiatWalletPaymentMethodFragment">;
  readonly " $fragmentType": "readFiatWalletsPaymentMethodFragment";
};
export type readFiatWalletsPaymentMethodFragment$key = {
  readonly " $data"?: readFiatWalletsPaymentMethodFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readFiatWalletsPaymentMethodFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readFiatWalletsPaymentMethodFragment"
};

(node as any).hash = "0082301cd489a6a1e7ab9a5aecb08a99";

export default node;
