/**
 * @generated SignedSource<<bb91169f1761b3e7d9c36687f1d77cf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useDefaultSourceOfFundsViewerQuery$variables = {};
export type useDefaultSourceOfFundsViewerQuery$data = {
  readonly viewer: {
    readonly userProperties: {
      readonly country: {
        readonly code: string;
      } | null;
    };
    readonly " $fragmentSpreads": FragmentRefs<"useSourceOfFundsToCommonFormatRequiredData">;
  };
};
export type useDefaultSourceOfFundsViewerQuery = {
  response: useDefaultSourceOfFundsViewerQuery$data;
  variables: useDefaultSourceOfFundsViewerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "concreteType": "Country",
  "kind": "LinkedField",
  "name": "country",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "code",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useDefaultSourceOfFundsViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useSourceOfFundsToCommonFormatRequiredData"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useDefaultSourceOfFundsViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProperties",
            "kind": "LinkedField",
            "name": "userProperties",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "showInstantAchUx",
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca6e288801c6102dcf12544c0074dc12",
    "id": null,
    "metadata": {},
    "name": "useDefaultSourceOfFundsViewerQuery",
    "operationKind": "query",
    "text": "query useDefaultSourceOfFundsViewerQuery {\n  viewer {\n    ...useSourceOfFundsToCommonFormatRequiredData\n    userProperties {\n      country {\n        code\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment useSourceOfFundsToCommonFormatRequiredData on Viewer {\n  userProperties {\n    country {\n      code\n    }\n    showInstantAchUx\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4aa59caf5399d4e09c2adf221c72c214";

export default node;
