import type { countryCodeSelectorQuery$data } from '@onramp/data/__generated__/countryCodeSelectorQuery.graphql';
import { useAtomValue } from 'jotai';
import { loadable } from 'jotai/utils';
import { graphql } from '@cbhq/data-layer';

import { atomWithQueryAndMap } from '../utils';

const query = graphql`
  query countryCodeSelectorQuery {
    viewer {
      userProperties {
        country {
          code
        }
      }
    }
  }
`;

export const countryCodeSelector = atomWithQueryAndMap({
  query,
  variables: () => ({}),
  mapResponse: (data: countryCodeSelectorQuery$data) => data.viewer.userProperties.country?.code,
});

export const useCountryCode = () => useAtomValue(countryCodeSelector);

export const countryCodeLoadable = loadable(countryCodeSelector);
export const useCountryCodeLoadable = () => useAtomValue(countryCodeLoadable);
