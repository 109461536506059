import type { readCryptoAccountFragment$key } from '@onramp/data/__generated__/readCryptoAccountFragment.graphql';
import type { SourceOfFundsData } from '@onramp/state/types/SourceOfFundsData';
import { isCryptoAccountAllowed } from '@onramp/utils/getIsCountryAllowed';
import { graphql, readInlineData } from '@cbhq/data-layer';

import { readAccountBalance } from './readAccountBalance';
import { readAccountBalanceFiat } from './readAccountBalanceFiat';
import type { SourceOfFundsToCommonFormatRequiredData } from './useSourceOfFundsToCommonFormatRequiredData';

const accountFragment = graphql`
  fragment readCryptoAccountFragment on Account @inline {
    ...getIsCountryAllowedAccountFragment
    ...readAccountBalanceFragment
    ...readAccountBalanceFiatFragment
    uuid
    name
    assetOrFiatCurrency {
      __typename
      ... on ViewerAsset {
        asset {
          uuid
          symbol
          name
          imageUrl
        }
      }
      ... on TiersCurrency {
        name
      }
    }
  }
`;

export function readCryptoAccount({
  countryCode,
  inputAccountRef,
  nativeAssetUuid,
}: {
  inputAccountRef: readCryptoAccountFragment$key;
  nativeAssetUuid: string;
} & SourceOfFundsToCommonFormatRequiredData): SourceOfFundsData | null {
  const inputAccount = readInlineData(accountFragment, inputAccountRef);
  const cryptoBalance = readAccountBalance(inputAccount);
  const fiatBalance = readAccountBalanceFiat(inputAccount);

  if (
    !isCryptoAccountAllowed(countryCode, inputAccount) ||
    inputAccount.assetOrFiatCurrency?.__typename !== 'ViewerAsset'
  ) {
    return null;
  }

  const asset = inputAccount.assetOrFiatCurrency?.asset;
  if (!asset || (Number(cryptoBalance?.value) <= 0 && asset.uuid !== nativeAssetUuid)) {
    return null;
  }

  return {
    type: 'crypto',
    uuid: inputAccount.uuid,
    assetUuid: asset.uuid,
    title: asset.name,
    meta: asset.symbol,
    description: '',
    iconData: {
      iconUrl: asset.imageUrl,
    },
    limitData: {
      fiat: {
        value: fiatBalance.value,
        currency: fiatBalance.currency,
      },
      crypto: {
        value: cryptoBalance.value,
        currency: cryptoBalance.currency,
      },
    },
  };
}
