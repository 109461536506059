/* eslint-disable no-console */
// @ts-check

/**
 * Example: parseCommitTag('commit-e7645e8eb96d1f2109b62e85b63acd28a427e285-201288') => 'e7645e8eb96d1f2109b62e85b63acd28a427e285'
 *
 * @param {string | undefined} tag
 * @returns {string | undefined}
 */
const parseCommitTag = (tag) => {
  if (tag && tag.split('-').length > 2) {
    return tag.split('-')[1];
  }
  console.log(`Unable to parse CODEFLOW_COMMIT_TAG '${tag}'`);
  return undefined;
};

/**
 * Let's default to the one passed in from the Dockerfile build, or buildkite
 *
 * @returns {string}
 */
const getAppVersion = () => {
  if (!process.env.NEXT_PUBLIC_CODEFLOW_COMMIT_TAG && process.env.BUILDKITE_COMMIT) {
    console.log(
      `Outside of Codeflow, using Buildkite version instead: ${process.env.BUILDKITE_COMMIT}`,
    );
    return process.env.BUILDKITE_COMMIT;
  }

  if (process.env.NEXT_PUBLIC_CODEFLOW_COMMIT_TAG) {
    const commitTag = process.env.NEXT_PUBLIC_CODEFLOW_COMMIT_TAG;
    const parsed = parseCommitTag(commitTag);
    if (parsed) {
      return parsed;
    }
  }

  return 'local';
};

module.exports = getAppVersion;
