import { useIntl } from 'react-intl';
import type { CryptoAccountListCellFragment$key } from '@onramp/data/__generated__/CryptoAccountListCellFragment.graphql';
import formatCryptoCurrency from '@onramp/utils/formatCryptoCurrency';
import formatFiatCurrency from '@onramp/utils/formatFiatCurrency';
import { readAccountBalance } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readAccountBalance';
import { readAccountBalanceFiat } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readAccountBalanceFiat';
import { useReffedFunction } from '@onramp/utils/useReffedFunction';
import type { CellSpacing } from '@cbhq/cds-web';
import { CellMedia, ListCell } from '@cbhq/cds-web/cells';
import { graphql, useFragment } from '@cbhq/data-layer';

const cryptoAccountListCellFragment = graphql`
  fragment CryptoAccountListCellFragment on Account {
    ...readAccountBalanceFragment
    ...readAccountBalanceFiatFragment
    name
    assetOrFiatCurrency {
      __typename
      ... on ViewerAsset {
        asset {
          uuid
          symbol
          name
          imageUrl
        }
      }
    }
  }
`;

type CryptoAccountListCellProps = {
  fragmentRef: CryptoAccountListCellFragment$key;
  isSelected: boolean;
  onPress: (uuid: string) => Promise<void>;
  isEnabled?: boolean;
};
const innerHoritzontalSpacing = { spacingHorizontal: 1 } as CellSpacing;
export const CryptoAccountListCell = ({
  onPress,
  fragmentRef,
  isSelected,
  isEnabled = true,
}: CryptoAccountListCellProps) => {
  const intl = useIntl();
  const account = useFragment(cryptoAccountListCellFragment, fragmentRef);
  const { assetOrFiatCurrency } = account;
  const isViewerAsset = assetOrFiatCurrency?.__typename === 'ViewerAsset';
  const accountBalance = readAccountBalance(account);
  const accountBalanceFiat = readAccountBalanceFiat(account);
  const symbol = isViewerAsset && assetOrFiatCurrency.asset.symbol;
  const imageUrl = isViewerAsset && assetOrFiatCurrency.asset.imageUrl;
  const fullAssetName = isViewerAsset && assetOrFiatCurrency.asset.name;

  const handleOnPress = useReffedFunction(async () => {
    const assetUuid = isViewerAsset && assetOrFiatCurrency.asset.uuid;
    return onPress(assetUuid || '');
  });

  return (
    <ListCell
      testID={`select-asset-${symbol}`}
      title={fullAssetName}
      description={symbol}
      detail={formatFiatCurrency({
        amount: accountBalanceFiat.value,
        currency: accountBalanceFiat.currency,
        intl,
      })}
      subdetail={formatCryptoCurrency({
        amount: accountBalance.value,
        currency: accountBalance.currency,
        intl,
      })}
      innerSpacing={innerHoritzontalSpacing}
      onPress={handleOnPress}
      selected={isSelected}
      media={<CellMedia type="asset" title={symbol || ''} source={imageUrl || ''} />}
      disabled={!isEnabled}
    />
  );
};
