/**
 * @generated SignedSource<<fb1090f43adad65ce7896d80eebb4ffb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readPaymentMethodsFragment$data = {
  readonly primaryBuy: boolean;
  readonly uuid: string;
  readonly " $fragmentSpreads": FragmentRefs<"readPaymentMethodFragment">;
  readonly " $fragmentType": "readPaymentMethodsFragment";
};
export type readPaymentMethodsFragment$key = {
  readonly " $data"?: readPaymentMethodsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readPaymentMethodsFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readPaymentMethodsFragment"
};

(node as any).hash = "b91a5ef989945d834843992ad46ebfcf";

export default node;
