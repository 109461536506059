import { atomWithStorage } from 'jotai/utils';

export const isMockBuyAndSendEnabledMessage = `When this is true, the commit buy and send APIs (and polling for buy & 
send statuses) will be mocked out to enable easy testing of the post-purchase pages. Guest Checkout not supported yet.`;
export const isMockBuyAndSendEnabledAtom = atomWithStorage<boolean>(
  'is-mock-buy-and-send-enabled',
  false,
);

export const isDebug3dsEnabledMessage = `When this is true, mocked responses for commitBuy will trigger the 3DS flow, 
the poll for 3DS status call will be mocked, and the buy flow will redirect to the 3DS return page. Not implemented yet.`;
export const isDebug3dsEnabledAtom = atomWithStorage<boolean>('is-debug-3ds-enabled', false);
