/* eslint-disable max-classes-per-file */
import React from 'react';
import type { PayloadError } from '@cbhq/data-layer';

import type { AtLeastOne, RequiredFields } from '../types';
import { isNotNullish } from '../types';

import type {
  ErrorHandlingParameters,
  GqlErrorArgs,
  GqlErrorTypename,
  GqlErrorVariant,
} from './types';

export type ErrorLevel = 'application' | 'root' | 'screen';

export const defaultErrorHandlingParams: RequiredFields<ErrorHandlingParameters> = {
  backType: 'dismiss',
};

export type AddMetadataArgument = Record<
  string,
  string | number | boolean | undefined | null | string[]
>;

export type BugsnagData = {
  /**
   * Group errors under a single context.
   */
  context?: string;
  /**
   * How individual errors are grouped (default grouping uses surrounding source code). Best used for similar error messages.
   * https://docs.bugsnag.com/platforms/javascript/react/customizing-error-reports/#groupinghash
   */
  groupingHash?: string;
};

export const mergeErrorMetadata = (
  ...metadatas: AtLeastOne<Record<string, string | string[] | undefined | null>>
) => {
  const mergedMetadata: Record<string, string[]> = {};

  for (const metadata of metadatas) {
    for (const key of Object.keys(metadata)) {
      const value = metadata[key];
      if (isNotNullish(value)) {
        mergedMetadata[key] ??= [];
        const valueArray = Array.isArray(value) ? value : [value];
        mergedMetadata[key].push(...valueArray);
      }
    }
  }

  return mergedMetadata;
};

type BaseErrorOptions = ErrorOptions & {
  debugMessage?: string;
};

export abstract class BaseError extends Error {
  public debugMessage?: string = undefined;

  protected _bugsnagData: BugsnagData = {};

  protected _metadata: Record<string, string[]> = {};

  protected _handlingParams: Partial<ErrorHandlingParameters> = {};

  constructor(error: string | Error, options?: BaseErrorOptions) {
    super(error instanceof Error ? error.message : error, options);

    // fallback for browsers that don't support `error.cause`
    if (options?.cause && !this.cause) this.cause = options.cause;

    this.debugMessage = options?.debugMessage;

    if (error instanceof Error) {
      this.stack = error.stack;
      this.name = error.name;
    }
  }

  public get handlingParams() {
    return this._handlingParams;
  }

  public get handlingMetadata() {
    const { handlingParams } = this;
    return Object.keys(handlingParams).reduce((acc: Record<string, string[]>, nextKey) => {
      let nextValue = handlingParams[nextKey as keyof ErrorHandlingParameters];

      if (nextValue === undefined) {
        return acc;
      }

      if (React.isValidElement(nextValue)) {
        return acc;
      }

      nextValue = nextValue as Exclude<typeof nextValue, JSX.Element>;
      if (typeof nextValue === 'string') {
        acc[`handling_${nextKey}`] = [nextValue];
      }

      return acc;
    }, {});
  }

  public getMetadata(): Record<string, string[]> {
    return mergeErrorMetadata(
      this.cause instanceof BaseError ? this.cause.getMetadata() : {},
      this._metadata,
      this.handlingMetadata,
    );
  }

  public get type() {
    return this.asErrorState().type;
  }

  public get bugsnagData(): BugsnagData {
    const bugsnagData = { ...this._bugsnagData };
    if (this.cause instanceof GqlError && !bugsnagData.groupingHash) {
      bugsnagData.groupingHash = this.cause.getGroupingHash();
    }

    return bugsnagData;
  }

  public addDefaultHandlingParams() {
    return this.addHandlingParams();
  }

  public addHandlingParams(handlingParams: Partial<ErrorHandlingParameters> = {}) {
    this._handlingParams = {
      ...defaultErrorHandlingParams,
      ...this.handlingParams,
      ...handlingParams,
    };

    return this;
  }

  public addMetadata(...metadatas: AtLeastOne<AddMetadataArgument>) {
    for (const metadata of metadatas) {
      for (const key of Object.keys(metadata)) {
        this._metadata[key] ??= [];
        const value = metadata[key];
        const valueArray = Array.isArray(value) ? value : [String(value)];
        this._metadata[key].push(...valueArray);
      }
    }

    return this;
  }

  public asErrorState(): ErrorState {
    return {
      type: 'internal',
      error: this,
      source: this.getSource(),
      metadata: mergeErrorMetadata(this.getMetadata(), {
        type: 'internal',
      }),
      ...(this.debugMessage ? { debugMessage: this.debugMessage } : {}),
      ...this.handlingParams,
    };
  }

  public addBugsnagData(data: Partial<BugsnagData>) {
    this._bugsnagData = {
      ...this._bugsnagData,
      ...data,
    };
    return this;
  }

  protected getSource() {
    // Reverse the source array so that the most specific source is first
    return this.getMetadata().source?.reverse().join('.');
  }
}

export class NetworkError extends BaseError {
  public asErrorState(): ErrorState {
    return {
      type: 'network',
      error: this,
      source: this.getSource(),
      metadata: mergeErrorMetadata(this.getMetadata(), {
        type: 'network',
      }),
      ...(this.debugMessage ? { debugMessage: this.debugMessage } : {}),
      ...this.handlingParams,
    };
  }
}

// Top level failure for GQL used for onError callbacks in mutations.
// Always returns an error for reference but can usually be unhelpful (i.e. mutation responded with null)
export class TopLevelGqlError extends NetworkError {
  constructor(name: string, alreadyReportedError?: Error | null) {
    // We want the to be reported consistently so we can easily identify these top level errors.
    // i.e. `createSendMutation: Top level GQL failure`
    super(`Top level GQL failure: ${name}`, { cause: alreadyReportedError });

    if (alreadyReportedError) {
      this.addMetadata({ alreadyReportedErrorMsg: alreadyReportedError.message });
    }
  }
}

// Please keep the following comment in sync with the constructor
/** This error should be used whenever we get a `__typename` back in the `onCompleted` callback of a `commitMutation`. */
export class GqlError extends NetworkError {
  /** The __typename from the GQL response */
  __typename: GqlErrorTypename | string;

  code?: string;

  message: string;

  variant: GqlErrorVariant;

  // Please keep the following comment in sync with the class
  /** This error should be used whenever we get a `__typename` back in the `onCompleted` callback of a `commitMutation`. */
  constructor({ __typename, code, debugMessage, message, variant = 'default' }: GqlErrorArgs) {
    super(message, { debugMessage });
    this.__typename = __typename;
    this.code = code;
    this.message = message;
    this.variant = variant;

    // This should be a user friendly message from the backend so it should be safe to use
    if (message && variant === 'default') {
      this.addHandlingParams({ message });
    }

    const groupingHash = this.getGroupingHash();
    if (groupingHash) {
      this.addBugsnagData({ groupingHash });
    }
  }

  getGroupingHash() {
    if (this.debugMessage) return this.debugMessage;

    if (this.code) {
      return `${this.__typename}/${this.code}`;
    }

    return undefined;
  }

  public getMetadata() {
    const gqlMetadata: Record<string, string[]> = {
      __typename: [this.__typename],
      gql_message: [this.message],
      gql_variant: [this.variant],
    };

    if (this.code !== undefined) {
      gqlMetadata.gql_code = [this.code];
    }

    return mergeErrorMetadata(super.getMetadata(), gqlMetadata);
  }
}

/**
 * Adds GraphQL mutation alreadyReportedErrors from commitMutation onCompleted to metadata.
 * Will not add metadata to `error` if `alreadyReportedErrors` is `null`.
 *
 * @param error - The {@link BaseError} to add metadata to.
 * @param alreadyReportedErrors - Pass this straight from the `onCompleted` callback to `commitMutation`.
 * @returns `error` with metadata from `alreadyReportedErrors`, if any.
 */
export function addAlreadyReportedErrorsMetadata(
  error: BaseError,
  alreadyReportedErrors: PayloadError[] | null,
) {
  if (alreadyReportedErrors === null) {
    return error;
  }

  const stringifiedErrors = alreadyReportedErrors.map((payloadError) =>
    JSON.stringify(payloadError),
  );

  error.addMetadata({ alreadyReportedErrors: stringifiedErrors });

  return error;
}

export type InternalErrorState = {
  type: 'internal';
  error: Error;
};

export enum HandleErrorCodes {
  AssertDataPresence = 'assert_data_presence',
  InvalidQueryParams = 'invalid_query_params',
  InvalidWidgetParams = 'invalid_widget_params',
  InvalidInitMethod = 'invalid_init_method',
}

export type HandledErrorCode = `${HandleErrorCodes}`;

export type HandledErrorState = {
  type: 'handled';
  /** Public user facing error message. */
  message: string;
  /** Error code to be surfaced to third parties. */
  code?: HandledErrorCode;
  /** Helpful developer message for debugging. Will be surfaced to third parties. */
  debugMessage?: string;
  /** The error which has been handled. Some metadata may be extracted from this, depending on its type. */
  handledError?: Error;
};

export type NetworkErrorState = {
  type: 'network';
  error: NetworkError;
};

export type OneOfDefinedError = InternalErrorState | HandledErrorState | NetworkErrorState;
export type ErrorType = OneOfDefinedError['type'];

export type ErrorState = OneOfDefinedError &
  Partial<ErrorHandlingParameters> & {
    debugMessage?: string;
    metadata?: Record<string, string[]>;
    source?: string;
  };

export type ReportAndViewError = {
  reportAndViewError: ReportAndViewErrorFunc;
  resetError: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export type ReportAndViewErrorFunc<T extends ErrorState | void = void> = (error: BaseError) => T;
