import type {
  readPrimaryFiatWalletFragment$data as readPrimaryFiatWalletFragmentType,
  readPrimaryFiatWalletFragment$key,
} from '@onramp/data/__generated__/readPrimaryFiatWalletFragment.graphql';
import type { useDefaultSourceOfFundsViewerFragment$data } from '@onramp/data/__generated__/useDefaultSourceOfFundsViewerFragment.graphql';
import { readAccountBalance } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readAccountBalance';
import { isPaymentMethodSupportedByCountry } from '@onramp/utils/getIsCountryAllowed';
import { isNotNullish } from '@onramp/utils/types';
import { graphql, readInlineData } from '@cbhq/data-layer';

const readPrimaryFiatWalletFragment = graphql`
  fragment readPrimaryFiatWalletFragment on CBPaySourceOfFunds @inline {
    __typename
    ... on CBPaySourceOfFundsFiatWallet {
      isEnabled
      # eslint-disable-next-line relay/unused-fields
      paymentMethod {
        # Needed to reference later on
        # eslint-disable-next-line relay/unused-fields
        uuid
      }
      account {
        ...readAccountBalanceFragment
      }
    }
  }
`;

export const readPrimaryFiatWallet = (
  viewer: useDefaultSourceOfFundsViewerFragment$data,
  countryCode: string | null | undefined,
) => {
  if (!isPaymentMethodSupportedByCountry(countryCode, 'fiat_wallet')) {
    return null;
  }

  const filteredFiatWallets = viewer.cbPaySourcesOfFunds
    ?.filter(isNotNullish)
    .map((e) => readInlineData<readPrimaryFiatWalletFragment$key>(readPrimaryFiatWalletFragment, e))
    .filter(
      (
        w,
      ): w is readPrimaryFiatWalletFragmentType & {
        __typename: 'CBPaySourceOfFundsFiatWallet';
      } => {
        return w.__typename === 'CBPaySourceOfFundsFiatWallet' && Boolean(w.isEnabled);
      },
    );

  const sortedFiatWallets = filteredFiatWallets?.sort((a, b) => {
    return (
      Number(readAccountBalance(b.account)?.value) ||
      0 - Number(readAccountBalance(a.account)?.value) ||
      0
    );
  });

  return sortedFiatWallets?.[0];
};
