import type { readCBPayAssetAsOnrampStateFragment$key } from '@onramp/data/__generated__/readCBPayAssetAsOnrampStateFragment.graphql';
import type { CBPayAssetNetworks, SelectedAsset } from '@onramp/state/BuyWidgetState';
import { graphql, readInlineData } from '@cbhq/data-layer';

const readCBPayAssetAsOnrampStateFragment = graphql`
  fragment readCBPayAssetAsOnrampStateFragment on CBPayAsset @inline {
    uuid
    symbol
    uuid
    imageUrl
    name
    exponent
    # eslint-disable-next-line relay/unused-fields
    networks {
      name
      displayName
      minSend
      isDefault
    }
  }
`;

/** Parse the CBPayAsset entity to our common format */
export const readCBPayAssetAsOnrampState = (
  assetRef: readCBPayAssetAsOnrampStateFragment$key | undefined,
): SelectedAsset => {
  return readCBPayAssetAsOnrampStateWithAccountUuid(assetRef, undefined);
};

export const readCBPayAssetAsOnrampStateWithAccountUuid = (
  assetRef: readCBPayAssetAsOnrampStateFragment$key | undefined,
  accountUuid: string | undefined,
): SelectedAsset => {
  const cbPayAsset = assetRef && readInlineData(readCBPayAssetAsOnrampStateFragment, assetRef);

  const networks = cbPayAsset ? (cbPayAsset.networks as CBPayAssetNetworks) : [];

  return {
    accountUuid: accountUuid || cbPayAsset?.symbol || '',
    assetUuid: cbPayAsset?.uuid || '',
    imageUrl: cbPayAsset?.imageUrl || '',
    name: cbPayAsset?.name || '',
    ticker: cbPayAsset?.symbol || '',
    exponent: cbPayAsset?.exponent || 0,
    networks,
  };
};
