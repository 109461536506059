import type { z } from 'zod';

type ZodValidator = {
  test: (arg: string) => unknown;
  message: string | Partial<Omit<z.ZodCustomIssue, 'code'>>;
};

export const validUrl: ZodValidator = {
  test: (s: string) => {
    try {
      return new URL(s).protocol === 'https:';
    } catch {
      return false;
    }
  },
  message: 'Invalid url',
};

export const RegexChecks: Record<string, RegExp> = {
  // Default validation we need to use for any third party input for security sanitization
  defaultSafeString: /^[a-zA-Z0-9#$@!^*. ]*$/,
  // Wallet address + ENS
  walletAddress: /[a-zA-Z0-9.:]*$/,
  // Wallet address + ENS
  numeric: /^[0-9]*$/,
  numericWithDecimal: /^\d*(?:\.\d+)?$/,
};
