import type { AnalyticsEventDataOnMount } from '@cbhq/client-analytics';
import { ActionType } from '@cbhq/client-analytics';

import { getUseCallFnOnMount } from '../getUseCallFnOnMount';

import type { EventName, LogEventOptions } from './logEvent';
import { logEvent } from './logEvent';

// Overriding useLogEventOnMount to add our own parameters to the events
export const useLogEventOnMount = getUseCallFnOnMount(
  (name: EventName, metadata: AnalyticsEventDataOnMount, options?: LogEventOptions) => {
    // `@cbhq/client-analytics`'s `useLogEventOnMount` hardcodes `action` to `ActionType.render`, doing the same here
    logEvent(name, { action: ActionType.render, ...metadata }, options);
  },
);
