import type { Simplify } from 'type-fest';

type CallOnceHelperOptions<Args extends unknown[]> = {
  /** Used to generate the key to the cache */
  argsToKeyFn: (...args: Args) => string;
  /** Used to generate the cached value that, if equal, will cause the fn not to be called again */
  argsToCacheFn?: (...args: Args) => string;
  /** The Map used to store key/value pairs */
  cacheMap: Map<string, string>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WrappableFunction = (...args: any[]) => void;

function wrapWithCallOnceHelper<Fn extends WrappableFunction>(
  fn: Fn,
  {
    argsToKeyFn,
    argsToCacheFn = (...args) => JSON.stringify(args),
    cacheMap,
  }: CallOnceHelperOptions<Parameters<Fn>>,
): Fn {
  return function wrappedWithCallOnce(...args: Parameters<Fn>): void {
    const key = argsToKeyFn(...args);
    const cache = argsToCacheFn(...args);
    const cachedArgs = cacheMap.get(key);

    if (!cachedArgs || cachedArgs !== cache) {
      cacheMap.set(key, cache);
      fn(...args);
    }
  } as Fn;
}

export type CallOnceOptions<Args extends unknown[]> = Simplify<
  Omit<CallOnceHelperOptions<Args>, 'cacheMap'> &
    Partial<Pick<CallOnceHelperOptions<Args>, 'cacheMap'>>
>;

export function wrapWithCallOnce<Fn extends WrappableFunction>(
  fn: Fn,
  options: CallOnceOptions<Parameters<Fn>>,
): Fn {
  const cacheMap = options.cacheMap ?? new Map<string, string>();
  return wrapWithCallOnceHelper(fn, { ...options, cacheMap });
}

export function wrapHookWithCallOnce<
  Fn extends WrappableFunction,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  HookFn extends (...hookArgs: any[]) => Fn,
>(useHook: HookFn, options: CallOnceOptions<Parameters<Fn>>): HookFn {
  const cacheMap = options.cacheMap ?? new Map<string, string>();
  return function useCallOnceHook(...hookArgs: Parameters<HookFn>): Fn {
    return wrapWithCallOnceHelper(useHook(...hookArgs), { ...options, cacheMap });
  } as HookFn;
}
