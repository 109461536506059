import React, { forwardRef, memo } from 'react';
import type { ForwardedRef } from 'react';
import { useScaleConditional } from '@cbhq/cds-common/scale/useScaleConditional';
import { compactListHeight, listHeight } from '@cbhq/cds-common/tokens/cell';
import type { ListCellBaseProps } from '@cbhq/cds-common/types';
import type { CellSharedProps } from '@cbhq/cds-web/cells/Cell';
import { Cell } from '@cbhq/cds-web/cells/Cell';
import { CellDetail } from '@cbhq/cds-web/cells/CellDetail';
import { Icon } from '@cbhq/cds-web/icons';
import { Box, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextHeadline } from '@cbhq/cds-web/typography';

export type ListCellProps = ListCellBaseProps & CellSharedProps;

export const ListCell = memo(
  forwardRef(function ListCell(
    {
      accessory,
      action,
      compact,
      title,
      description,
      detail,
      disabled,
      media,
      multiline,
      selected,
      subdetail,
      variant,
      ...props
    }: ListCellProps,
    ref: ForwardedRef<HTMLElement>,
  ) {
    const minHeight = useScaleConditional(compact ? compactListHeight : listHeight);

    const accessoryType = accessory === 'arrow' ? 'caretRight' : 'externalLink';
    let end;

    if (action) {
      end = <Box justifyContent="flex-end">{action}</Box>;
    } else if (detail || subdetail) {
      end = <CellDetail detail={detail} subdetail={subdetail} variant={variant} />;
    }

    return (
      <Cell
        {...props}
        accessory={<Icon name={accessoryType} color="foregroundMuted" size="s" />}
        detail={end}
        media={media}
        disabled={disabled}
        minHeight={minHeight}
        selected={selected}
        ref={ref}
      >
        <VStack>
          {!!title && (
            <TextHeadline as="div" overflow="truncate">
              {title}
            </TextHeadline>
          )}

          {!!description && (
            <TextBody
              as="div"
              color="foregroundMuted"
              overflow={multiline ? undefined : 'truncate'}
            >
              {description}
            </TextBody>
          )}
        </VStack>
      </Cell>
    );
  }),
);
