import type { readFiatWalletAccountFragment$key } from '@onramp/data/__generated__/readFiatWalletAccountFragment.graphql';
import type { readFiatWalletPaymentMethodFragment$key } from '@onramp/data/__generated__/readFiatWalletPaymentMethodFragment.graphql';
import type { PaymentMethodTypeV2 } from '@onramp/data/graphql-types';
import type { SourceOfFundsData } from '@onramp/state/types/SourceOfFundsData';
import formatFiatCurrency from '@onramp/utils/formatFiatCurrency';
import { getCashIconName } from '@onramp/utils/getCashIconName';
import { isFiatWalletAllowed } from '@onramp/utils/getIsCountryAllowed';
import { graphql, readInlineData } from '@cbhq/data-layer';

import { messages } from './messages';
import { readAccountBalance } from './readAccountBalance';
import type { SourceOfFundsToCommonFormatRequiredData } from './useSourceOfFundsToCommonFormatRequiredData';

const accountFragment = graphql`
  fragment readFiatWalletAccountFragment on Account @inline {
    ...getIsCountryAllowedAccountFragment
    ...readAccountBalanceFragment
    uuid
    name
    type
    assetOrFiatCurrency {
      __typename
      ... on TiersCurrency {
        code
        name
        color
        type
      }
    }
  }
`;
const paymentMethodFragment = graphql`
  fragment readFiatWalletPaymentMethodFragment on PaymentMethodV2 @inline {
    uuid
    name
    type
    currency

    pickerData {
      __typename
      ... on CoinbaseFiatAccountPickerData {
        fiatAccount {
          uuid
        }
      }
    }
  }
`;

export function readFiatWallet({
  killSwitches,
  inputAccountRef,
  paymentMethodRef,
  intl,
  countryCode,
}: {
  inputAccountRef: readFiatWalletAccountFragment$key;
  paymentMethodRef: readFiatWalletPaymentMethodFragment$key;
} & SourceOfFundsToCommonFormatRequiredData): SourceOfFundsData | null {
  const inputAccount = readInlineData(accountFragment, inputAccountRef);
  const paymentMethod = readInlineData(paymentMethodFragment, paymentMethodRef);
  const accountBalance = readAccountBalance(inputAccount);

  if (
    !isFiatWalletAllowed(countryCode, inputAccount) ||
    !inputAccount ||
    !paymentMethod ||
    paymentMethod.type !== 'FIAT_ACCOUNT' ||
    paymentMethod.pickerData?.__typename !== 'CoinbaseFiatAccountPickerData' ||
    paymentMethod?.pickerData.fiatAccount.uuid !== inputAccount.uuid
  ) {
    return null;
  }

  if (
    !Number(accountBalance.value) ||
    inputAccount?.assetOrFiatCurrency?.__typename !== 'TiersCurrency' ||
    killSwitches.kill_cbpay_cash_accounts
  ) {
    return null;
  }

  const { code } = inputAccount.assetOrFiatCurrency;
  const cashAmount = formatFiatCurrency({
    amount: accountBalance?.value,
    currency: accountBalance?.currency,
    intl,
  });
  const description = intl.formatMessage(messages.cashDescription, { cashAmount });

  return {
    type: 'fiat',
    paymentMethodType: paymentMethod.type as PaymentMethodTypeV2,
    uuid: paymentMethod.uuid,
    title:
      paymentMethod.currency === 'USD'
        ? intl.formatMessage(messages.usDollar)
        : inputAccount.assetOrFiatCurrency.name,
    meta: code,
    currency: paymentMethod.currency,
    description,
    iconData: {
      iconName: getCashIconName(code),
      iconBackgroundColor: inputAccount.assetOrFiatCurrency.color,
    },
    cardInfo: undefined,
    limitData: {
      fiat: {
        value: accountBalance.value,
        currency: accountBalance.currency,
      },
    },
    isDisabled: (Number(accountBalance?.value) ?? 0) <= 0,
  };
}
