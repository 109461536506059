/* eslint-disable @typescript-eslint/naming-convention */
import type { PaymentMethodTypeV2 } from '@onramp/data/graphql-types';
import type { PollForBuyStatusErrorMetadata } from '@onramp/hooks/buy/usePollForBuyStatus';
import type { CreateSendSuccessResponse } from '@onramp/hooks/send/useCreateSend';
import type { TransferType } from '@onramp/hooks/useTransferType';
import type { Metadata as OCBMetadata } from '@onramp/pages/buy/one-click/hooks/useIsOneClickBuyEligible';
import type { OnrampInputSource, SelectedAsset } from '@onramp/state/BuyWidgetState';
import type { SourceOfFundsType } from '@onramp/state/types/SourceOfFundsData';
import type { ValidEventData } from '@cbhq/client-analytics';
import { ActionType, ComponentType } from '@cbhq/client-analytics';

import type { LogWidgetMetricParams } from '../metrics';

import type { ExtraEventData } from './logEvent';

/**
 * ADDING A NEW EVENT
 * 1. Add an entry to the optional parameter mapping which will add a new event name.
 * 2. Create a new metadata object with the logging id, component type and action.
 * 3. Add the new event to the Events dictionary.
 *
 * USEFUL NOTES
 * - Generate a new logging id using: http://go/uuid
 * - logEvent does not report if both componentType and action are unknown
 */

/**
 * The state that is sent automatically with each event.
 * When sending optional parameters, try to use the same naming convention here to override them.
 * i.e. when a user selects a new network we will want to override "activeNetwork" since the state won't be updated.
 */
type WidgetCommonStateParameters = {
  is3ds: boolean;
  selectedPaymentMethod: string;
  isGsscValid: boolean | undefined;
};

export type ReportedCheckoutStateParameters = WidgetCommonStateParameters;

export type ReportedOnrampStateParameters = WidgetCommonStateParameters & {
  transferType: string;
  selectedAsset: string;
  activeNetwork: string;
  isLayer2Network: string;
};

type DdcFinishedState = 'success' | 'fail';

type OCBCreateTransferTimeMetadata =
  | {
      result: 'success';
    }
  | {
      result: 'fail';
      errorMessage: string;
    };

export type WidgetCommonEventOptionalParametersMapping = {
  change_payment_method: {
    sourceOfFunds: string; // legacy
    selectedPaymentMethod: string;
  };
  debug_menu_viewed: {
    tab: string;
    canSeeInternalUseOnlyTabs: boolean;
  };
  redirected_to_login: undefined;
  has_active_session: undefined;
  apple_pay_availability_metadata: ExtraEventData;
  onramp_router_navigation:
    | { type: 'push' | 'replace' | 'replace_with_query_params'; path: string }
    | { type: 'goBack' | 'reload' };
  learn_more_about_ach_opened: undefined;
  learn_more_about_ach_closed: undefined;
  ach_modal_add_payment_method: undefined;
};

export type CommonEventName = keyof WidgetCommonEventOptionalParametersMapping;

type ApplePayEventPrefix = 'applepay.';
export type ApplePayEventName = `${ApplePayEventPrefix}${
  | ApplePayFlowEventName
  | ApplePayFailureEventName}`;
type ApplePayFlowEventName =
  | 'add_card'
  | 'add_card_result'
  | 'create_buy_success'
  | 'commit_buy_success'
  | 'preview_send_amount_success'
  | 'create_send_success'
  | 'commit_send_success'
  | '2fa_required'
  | '2fa';
type ApplePayFailureEventName =
  | 'get_correlation_id_failure'
  | 'create_buy_failure'
  | 'add_card_attempt_failure'
  | 'min_send_amount_failure'
  | 'client_missing'
  | 'submit_buy_promise_double_resolve'
  | 'commit_buy_failure'
  | 'preview_send_amount_failure'
  | 'create_send_failure'
  | 'commit_send_failure';

export type OnrampEventOptionalParametersMapping = WidgetCommonEventOptionalParametersMapping & {
  // Select asset page
  viewed_default_asset_tab: {
    tab_id: string;
  };
  viewed_select_asset_tab: {
    tab_id: string;
  };
  clicked_select_asset_tab_button: {
    tab_id: string;
  };
  clicked_select_asset_buy_new_asset_button: undefined;
  send_txn_submit: {
    asset: string;
    network: string;
    cryptoAmount: number | undefined;
    assetSubsidy: boolean;
  };
  buy_send_txn_submit: {
    create_buy_amount: string;
    create_buy_crypto_amount: number | undefined;
    create_buy_subtotal: string;
    create_buy_total: string;
    create_buy_fee: string;
    source_of_funds_type: string | undefined;
    source_of_funds_uuid: string | undefined;
    source_of_funds_limit: string;
    send_fee_estimate_crypto: string;
    send_fee_estimate_fiat: string;
    fee_treatment: string;
    input_amount: string;
    asset: string;
    network: string;
    asset_subsidy: 'buy' | 'buy_and_send' | undefined;
  };
  txn_submit: {
    flow: 'buy' | 'send';
    transferUuid: string | undefined;
    blueprintToken?: string | undefined | null;
  };
  attribute_buy_success: { input: string };
  attribute_send_success: { input: string };
  commit_send_after_buy: {
    transferUuid: string;
    blueprintToken: string | null;
    chained_send_crypto_amount: string;
    create_send_amount_crytpo: string;
    create_send_amount_fiat: string;
    create_send_amount_total: string;
  };
  change_active_network: {
    activeNetwork?: string;
  };
  // L2 events
  viewed_order_preview_network_row: {
    numOfSupportedNetworks: number;
  };
  click_order_preview_network_row: undefined;
  click_select_network: {
    activeNetwork?: string;
  };
  submit_2FA: undefined;
  attempt_2FA: undefined;
  submit_success: {
    asset?: string;
    fiat_currency?: string;
    fiat_value?: number;
    send_status?: string;
  };
  // 3DS
  '3ds2_ddc_attempt': undefined;
  '3ds2_ddc_finished': {
    state: DdcFinishedState;
  };
  '3ds2_saved_state': {
    nonce3ds: string;
    cbpayQuery: string;
    transactionId: string | undefined;
  };
  '3ds2_start_polling': undefined;
  '3ds2_end_polling': {
    resultType: string;
  };
  '3ds2_start_redirect': {
    redirectUrl: string;
  };
  '3ds2_card_rejected': undefined;
  '3ds2_redirect_received': {
    nonce: string;
    nonce3ds: string;
  };
  '3ds2_redirect_start_polling': {
    transferStatus: string;
  };
  '3ds2_redirect_success': undefined;
  onramp_input_source_submitted: {
    source: OnrampInputSource;
  };
  // Network fee
  network_fee_free: {
    asset: string;
    hasCoinbaseOneSubscription: boolean;
  };
  invalid_wallet_address: {
    isInCreateWalletTransactionTreatment: boolean;
    assetTicker: string;
  };
  // events requested by the wallet team
  source_of_funds_report: {
    enabledPaymentMethodTypesWithBalance: `${PaymentMethodTypeV2}`[];
    cryptoAssetsWithBalance: string[];
    fiatCurrenciesWithBalance: string[];
    // eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
    highestPaymentMethodLimitPerCurrency: { [currencyCode: string]: number };
  };
  send_failure: {
    __typename: string;
    gql_code: string | undefined;
    gql_message: string | undefined;
  };
  buy_failure: {
    paymentMethodType: `${PaymentMethodTypeV2}` | undefined;
    hasPollSucceeded: boolean;
    required3dsVerification: boolean;
    has3dsVerificationSucceeded: boolean;
  } & Partial<PollForBuyStatusErrorMetadata>;
  buy_insufficient_limit: {
    paymentMethodType: `${PaymentMethodTypeV2}` | undefined;
  };
  max_send_pressed: {
    hasInsufficientFunds: boolean;
    availableBalanceInFiat: string | undefined;
    availableBalanceInCrypto: string | undefined;
    networkFeeInFiat: number;
    networkFeeInCrypto: number;
    maxSendInputAmountInFiat: string;
    maxSendInputAmountInCrypto: string;
  };
  create_max_send_result: {
    success: boolean;
    createSendResponse?: CreateSendSuccessResponse;
    errorMessage?: string;
  };
  max_send_insufficient_funds: {
    userMessage: string | undefined;
    isMaxSend: boolean;
    hasInsufficientFunds: boolean;
    transferSubtotal?: { fiatAmount: string; cryptoAmount: string };
    networkFee?: number;
    transferTotal?: { fiatAmount: string; cryptoAmount: string };
  };
  // One click buy
  ocb_page_view: {
    defaultAsset?: string;
    singleAssetsRequested?: string[];
    networksWithAllAssetsRequested?: string[];
    selectedAsset?: SelectedAsset;
    assetToSelect?: SelectedAsset;
    isInOCBTreatment?: boolean;
  };
  ocb_page_not_eligible_redirect: {
    defaultAsset?: string;
    singleAssetsRequested?: string[];
    networksWithAllAssetsRequested?: string[];
    failedToSelectAsset?: boolean;
    isInOCBTreatment?: boolean;
  } & Partial<OCBMetadata>;
  ocb_page_eligibility_check: OCBMetadata;
  ocb_page_submit: {
    inputAmount?: string;
    inputType?: 'fiat' | 'crypto';
    paymentMethodType?: SourceOfFundsType;
    assetTicker?: string;
    transferType?: TransferType;
  };
  ocb_page_cancel: undefined;
  ocb_page_change_asset: undefined;
  ocb_page_change_source_of_funds: undefined;
  ocb_page_change_network: undefined;
  ocb_page_create_transfer_requested: {
    inputAmount?: string;
    inputType?: 'fiat' | 'crypto';
    assetTicker?: string;
  };
  ocb_page_create_transfer_succeeded: {
    inputAmount?: string;
  };
  ocb_page_create_transfer_failure: {
    errorMessage: string;
    inputAmount?: string;
  };
  ocb_page_create_transfer_time: {
    timeInMs: number;
  } & OCBCreateTransferTimeMetadata;
  ocb_page_input_amount_debounced_change: {
    inputAmount: string;
  };
  ocb_page_swap_input_type: {
    newInputType: 'fiat' | 'crypto';
  };
  submit_apple_pay_step: LogWidgetMetricParams['submit_apple_pay_step']['tags'];
  guest_checkout_step: LogWidgetMetricParams['guest_checkout_step']['tags'];
  preset_fiat_amount_param_ignored: LogWidgetMetricParams['preset_fiat_amount_param_ignored']['tags'];
  submit_amount: {
    isFiat: boolean;
    fiatCurrency?: string;
    inputAmount: string;
    selectedAsset: string;
    network?: string;
  };
  // Try again errors
  initiating_purchase_try_again_buy_success_send_failure: undefined;
  initiating_purchase_try_again_buy_canceled: undefined;
  initiating_purchase_try_again_generic: undefined;
  error_handler_try_again_handling_params_function: undefined;
  error_handler_try_again_show_try_again_to_dismiss: undefined;
  error_handler_try_again_can_try_again: undefined;
  // ACH improvements
  sof_auto_switch_blocked_by_limit: {
    amount: string;
    limit: string;
  };
  sof_auto_switch_blocked_by_type: {
    currentSoFType: string | undefined;
    toSoFType: string | undefined;
  };
  sof_auto_switch: {
    sofType: SourceOfFundsType;
    paymentMethodType: PaymentMethodTypeV2 | undefined;
  };
  // Min send o11y
  min_send_amount_failure: {
    inputCryptoAmount: string;
    minSendCryptoAmount: string;
    type: 'buy' | 'send';
    sofType: SourceOfFundsType | undefined;
    paymentMethodType: PaymentMethodTypeV2 | undefined;
    amount: string | undefined;
    pricePerCoin: number | undefined;
    ticker: string | undefined;
  };
  create_send_after_buy: {
    accountUuid: string;
    inputType: string;
    inputAmount: string;
  };
  have_sources_of_funds: {
    haveCryptoSourceOfFunds: boolean;
    haveFiatSourceOfFunds: boolean;
  };
  blocked_by_webview_2fa_guard: undefined;
};

export type OnrampEventName = keyof OnrampEventOptionalParametersMapping;

export type EventMetadata = ValidEventData;

export const viewed_default_asset_tab: EventMetadata = {
  loggingId: '8db5e927-f04d-4b0e-997d-487f07eb453d',
  componentType: ComponentType.page,
  action: ActionType.view,
};

export const viewed_select_asset_tab: EventMetadata = {
  loggingId: '81887df5-0bba-49a7-80f2-eca9706c9196',
  componentType: ComponentType.page,
  action: ActionType.view,
};

export const clicked_select_asset_tab_button: EventMetadata = {
  loggingId: '472a575e-ee42-4461-b7a8-181193e789da',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const clicked_select_asset_buy_new_asset_button: EventMetadata = {
  loggingId: 'b6f3e053-2315-418f-b4a7-45352c746036',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const change_payment_method: EventMetadata = {
  loggingId: 'ecee0500-c7b2-43a9-b0e1-d1ce02ca5c7c',
  componentType: ComponentType.dropdown,
  action: ActionType.change,
};

export const redirected_to_login: EventMetadata = {
  loggingId: '8da26701-6c57-400e-8db2-520e0cf88b09',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const has_active_session: EventMetadata = {
  loggingId: 'bcac9f2d-58d9-4329-94c8-80a22a21a88d',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const send_txn_submit: EventMetadata = {
  loggingId: '59c3ce4c-4b9c-4347-8e17-e08a3784eea4',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const buy_send_txn_submit: EventMetadata = {
  loggingId: 'a10346a4-8b1f-43ad-8654-b2f74532b680',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const txn_submit: EventMetadata = {
  loggingId: 'f840deaa-3c65-4147-83bb-ef7848b9f2a9',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const commit_send_after_buy: EventMetadata = {
  loggingId: '9f84f306-62ac-4f5a-b380-c4c2e52a6403',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const submit_2FA: EventMetadata = {
  loggingId: '649ca704-56d7-415a-b440-697baf39d7c4',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const attempt_2FA: EventMetadata = {
  loggingId: '6a15b3d6-fbb4-4f3e-997f-db8ade7e4256',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const submit_success: EventMetadata = {
  loggingId: 'cae56d94-69e7-4af4-b18f-8b7db3a924a7',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const click_select_network: EventMetadata = {
  loggingId: 'd0905edb-85c3-4030-96c5-5eca88b78ad7',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const viewed_order_preview_network_row: EventMetadata = {
  loggingId: '51f77681-5054-4698-8da3-b5835b26ada6',
  componentType: ComponentType.button,
  action: ActionType.render,
};

export const click_order_preview_network_row: EventMetadata = {
  loggingId: '7f026e54-7772-40aa-9189-0ca29dabebc8',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const change_active_network: EventMetadata = {
  loggingId: '8c65f649-4595-4f1e-9e27-ef63f303aa06',
  componentType: ComponentType.button,
  action: ActionType.change,
};

export const _3ds_ddc_attempt: EventMetadata = {
  loggingId: '4e7b85be-f6f6-4723-a2c4-ae8448c1657a',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const _3ds_ddc_finished: EventMetadata = {
  loggingId: '1085223e-cf10-4732-a001-9052875342ca',
  componentType: ComponentType.unknown,
  action: ActionType.measurement,
};

export const _3ds2_saved_state: EventMetadata = {
  loggingId: '5fae2073-53bb-483f-99b8-652b9bbc0c0b',
  componentType: ComponentType.unknown,
  action: ActionType.change,
};

export const _3ds_start_polling: EventMetadata = {
  loggingId: '7bd70d20-2a2f-4377-8d35-f419c8ac9290',
  componentType: ComponentType.unknown,
  action: ActionType.measurement,
};

export const _3ds_end_polling: EventMetadata = {
  loggingId: '7e125e50-ed7b-4d1f-b6a4-5a1c2f516479',
  componentType: ComponentType.unknown,
  action: ActionType.measurement,
};

export const _3ds_start_redirect: EventMetadata = {
  loggingId: '5f86f753-bfaa-4fa1-aa18-51aed266f824',
  componentType: ComponentType.page,
  action: ActionType.change,
};

export const _3ds2_card_rejected: EventMetadata = {
  loggingId: '867994c7-be8e-45a9-91ae-03c37a20c215',
  componentType: ComponentType.page,
  action: ActionType.change,
};

export const _3ds2_redirect_received: EventMetadata = {
  loggingId: 'ecd0b6a2-7914-4455-8e20-de9c74501e35',
  componentType: ComponentType.unknown,
  action: ActionType.change,
};

export const attribute_buy_success: EventMetadata = {
  loggingId: 'e80de502-2b94-4d5c-a85b-9241928fb0c3',
  componentType: ComponentType.unknown,
  action: ActionType.change,
};

export const attribute_send_success: EventMetadata = {
  loggingId: '94de8797-5e3d-43e6-9c00-5f3153fb9782',
  componentType: ComponentType.unknown,
  action: ActionType.change,
};

export const _3ds2_redirect_start_polling: EventMetadata = {
  loggingId: '930a0334-6a52-44e7-92be-37c091cb7f96',
  componentType: ComponentType.page,
  action: ActionType.measurement,
};

export const _3ds_redirect_success: EventMetadata = {
  loggingId: '9165ef16-e68f-415f-bf0e-c95710940a7d',
  componentType: ComponentType.page,
  action: ActionType.change,
};

export const onramp_input_source_submitted: EventMetadata = {
  loggingId: '33bb23b4-a75b-4a41-a750-5977b6189899',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const debug_menu_viewed: EventMetadata = {
  loggingId: 'f696a283-2786-4685-9655-4d5d68b35fbd',
  componentType: ComponentType.unknown,
  action: ActionType.render,
};

export const network_fee_free: EventMetadata = {
  loggingId: '765e5501-b4c8-4af7-b401-1d55af8d8172',
  componentType: ComponentType.unknown,
  action: ActionType.render,
};

export const invalid_wallet_address: EventMetadata = {
  loggingId: 'fd9d8a86-dd20-46c8-9f25-cbb96f6805e6',
  componentType: ComponentType.unknown,
  action: ActionType.change,
};

export const source_of_funds_report: EventMetadata = {
  loggingId: '97c7d095-e74a-434e-ad6f-066ec2e7c27e',
  componentType: ComponentType.unknown,
  action: ActionType.measurement,
};

export const send_failure: EventMetadata = {
  loggingId: '61d58b2e-c644-4f5c-97e9-0df9abffb0d1',
  componentType: ComponentType.unknown,
  action: ActionType.measurement,
};

export const buy_failure: EventMetadata = {
  loggingId: '6aba7703-7042-443a-9d39-bceacfd40df3',
  componentType: ComponentType.unknown,
  action: ActionType.measurement,
};

export const buy_insufficient_limit: EventMetadata = {
  loggingId: 'a18e3874-72d5-4114-b644-b5780ff1ac44',
  componentType: ComponentType.unknown,
  action: ActionType.measurement,
};

export const ocb_page_view: EventMetadata = {
  loggingId: '9249931c-a651-4e5e-84a5-59340d2b0e1d',
  componentType: ComponentType.page,
  action: ActionType.view,
};

export const ocb_page_not_eligible_redirect: EventMetadata = {
  loggingId: '33a8139b-4d92-4199-b05f-f8dc830f71ca',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const ocb_page_eligibility_check: EventMetadata = {
  loggingId: 'e55651e6-1a65-4577-b601-b5f83f966f23',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const ocb_page_submit: EventMetadata = {
  loggingId: '4aa313f4-ba01-4bd3-8322-52f18c937038',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const ocb_page_cancel: EventMetadata = {
  loggingId: '03d46dcc-d542-4ef6-acd7-9619e237eb2d',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const ocb_page_change_asset: EventMetadata = {
  loggingId: '6e0aa66b-fbc2-4b00-8761-dfb9a6f74363',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const ocb_page_change_source_of_funds: EventMetadata = {
  loggingId: '45371bf5-5818-43e4-bab2-ff6efbc40b91',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const ocb_page_change_network: EventMetadata = {
  loggingId: '0d36df0d-f128-4b2d-ae71-006ba9b87c75',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const ocb_page_create_transfer_requested: EventMetadata = {
  loggingId: 'a6cf3f02-656c-43af-b08c-20de82fb65f8',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const ocb_page_create_transfer_succeeded: EventMetadata = {
  loggingId: '71453444-32bd-481f-9772-aafe47fa7767',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const ocb_page_create_transfer_failure: EventMetadata = {
  loggingId: '2bcdbe20-07bc-472f-976f-6f11c0c4cb6b',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const ocb_page_create_transfer_time: EventMetadata = {
  loggingId: '02468c4f-0f2c-469d-8196-b5a7360fc2ee',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const ocb_page_input_amount_debounced_change: EventMetadata = {
  loggingId: '4dc2394a-7a6a-409f-80fc-9abaeefbc4fa',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const ocb_page_swap_input_type: EventMetadata = {
  loggingId: '9f1c3f83-e6be-4061-9421-4d0493637df0',
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const landing_page_viewed: EventMetadata = {
  loggingId: 'd32b9e35-2916-423d-b3b7-5d1b55c0d6c4',
  componentType: ComponentType.page,
  action: ActionType.view,
};

export const landing_page_continue_to_guest: EventMetadata = {
  loggingId: 'd32b9e35-2916-423d-b3b7-5d1b55c0d6c4',
  componentType: ComponentType.page,
  action: ActionType.click,
};

export const landing_page_continue_to_signin: EventMetadata = {
  loggingId: '616df3f8-715b-4c04-9239-b2ac6f653c52',
  componentType: ComponentType.page,
  action: ActionType.click,
};

export const redirected_after_load_on_land: EventMetadata = {
  loggingId: '1968cd28-67bd-4215-ad4f-8668a8a1dab5',
  componentType: ComponentType.page,
  action: ActionType.process,
};

export const apple_pay_availability_metadata: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.measurement,
};

export const max_send_pressed: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

export const create_max_send_result: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const max_send_insufficient_funds: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

export const have_sources_of_funds: EventMetadata = {
  loggingId: '08da5fca-f792-49cb-a981-6c7d0d28c85f',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const submit_apple_pay_step: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const onramp_router_navigation: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const guest_checkout_step: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const preset_fiat_amount_param_ignored: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const submit_amount: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const initiating_purchase_try_again_buy_success_send_failure: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const initiating_purchase_try_again_buy_canceled: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const initiating_purchase_try_again_generic: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const error_handler_try_again_handling_params_function: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const error_handler_try_again_show_try_again_to_dismiss: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const error_handler_try_again_can_try_again: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const learn_more_about_ach_opened: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const learn_more_about_ach_closed: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const ach_modal_add_payment_method: EventMetadata = {
  componentType: ComponentType.button,
  action: ActionType.click,
};

const sof_auto_switch_blocked_by_limit: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const sof_auto_switch_blocked_by_type: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const sof_auto_switch: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const min_send_amount_failure: EventMetadata = {
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const blocked_by_webview_2fa_guard: EventMetadata = {
  action: ActionType.process,
  componentType: ComponentType.unknown,
  loggingId: '0045eabb-37b8-416a-8321-2e382272bf38',
};

export const create_send_after_buy: EventMetadata = {
  loggingId: '75c81345-a5cc-41bf-9a55-98aaddfc4b2a',
  componentType: ComponentType.unknown,
  action: ActionType.process,
};

const WidgetCommonEvents: Record<CommonEventName, EventMetadata> = {
  change_payment_method,
  debug_menu_viewed,
  redirected_to_login,
  has_active_session,
  apple_pay_availability_metadata,
  onramp_router_navigation,
  learn_more_about_ach_opened,
  learn_more_about_ach_closed,
  ach_modal_add_payment_method,
} as const;

export const WidgetOnrampEvents: Record<OnrampEventName, EventMetadata> = {
  ...WidgetCommonEvents,
  viewed_default_asset_tab,
  viewed_select_asset_tab,
  clicked_select_asset_tab_button,
  clicked_select_asset_buy_new_asset_button,
  send_txn_submit,
  buy_send_txn_submit,
  txn_submit,
  commit_send_after_buy,
  submit_2FA,
  attempt_2FA,
  submit_success,
  click_select_network,
  viewed_order_preview_network_row,
  click_order_preview_network_row,
  change_active_network,
  '3ds2_ddc_attempt': _3ds_ddc_attempt,
  '3ds2_ddc_finished': _3ds_ddc_finished,
  '3ds2_saved_state': _3ds2_saved_state,
  '3ds2_start_polling': _3ds_start_polling,
  '3ds2_end_polling': _3ds_end_polling,
  '3ds2_start_redirect': _3ds_start_redirect,
  '3ds2_card_rejected': _3ds2_card_rejected,
  '3ds2_redirect_received': _3ds2_redirect_received,
  '3ds2_redirect_start_polling': _3ds2_redirect_start_polling,
  '3ds2_redirect_success': _3ds_redirect_success,
  onramp_input_source_submitted,
  network_fee_free,
  invalid_wallet_address,
  source_of_funds_report,
  send_failure,
  buy_failure,
  buy_insufficient_limit,
  max_send_pressed,
  create_max_send_result,
  max_send_insufficient_funds,
  ocb_page_view,
  ocb_page_not_eligible_redirect,
  ocb_page_eligibility_check,
  ocb_page_submit,
  ocb_page_cancel,
  ocb_page_change_asset,
  ocb_page_change_source_of_funds,
  ocb_page_change_network,
  ocb_page_create_transfer_requested,
  ocb_page_create_transfer_succeeded,
  ocb_page_create_transfer_failure,
  ocb_page_create_transfer_time,
  ocb_page_input_amount_debounced_change,
  ocb_page_swap_input_type,
  submit_apple_pay_step,
  guest_checkout_step,
  preset_fiat_amount_param_ignored,
  submit_amount,
  initiating_purchase_try_again_buy_success_send_failure,
  initiating_purchase_try_again_buy_canceled,
  initiating_purchase_try_again_generic,
  error_handler_try_again_handling_params_function,
  error_handler_try_again_show_try_again_to_dismiss,
  error_handler_try_again_can_try_again,
  attribute_buy_success,
  attribute_send_success,
  sof_auto_switch_blocked_by_limit,
  sof_auto_switch_blocked_by_type,
  sof_auto_switch,
  min_send_amount_failure,
  create_send_after_buy,
  have_sources_of_funds,
  blocked_by_webview_2fa_guard,
};
