import { useIntl } from 'react-intl';
import type { useSourceOfFundsToCommonFormatRequiredData$key } from '@onramp/data/__generated__/useSourceOfFundsToCommonFormatRequiredData.graphql';
import { useKillSwitches } from '@onramp/utils/killswitches/useKillSwitches';
import { assertPresence } from '@onramp/utils/relayUtils';
import { graphql, useFragment } from '@cbhq/data-layer';

import { messages } from './messages';

const viewerFragment = graphql`
  fragment useSourceOfFundsToCommonFormatRequiredData on Viewer {
    userProperties {
      country {
        code
      }
      showInstantAchUx
    }
  }
`;

export function useSourceOfFundsToCommonFormatRequiredData(
  viewerFragmentRef: useSourceOfFundsToCommonFormatRequiredData$key,
) {
  const intl = useIntl();
  const killSwitches = useKillSwitches();

  const fragmentData = useFragment(viewerFragment, viewerFragmentRef);

  return {
    intl,
    killSwitches,
    countryCode: assertPresence(fragmentData.userProperties.country?.code, {
      debugMessage: 'useSourcesOfFunds fragmentData.userProperties.country?.code',
      message: intl.formatMessage(messages.missingFieldErrorMessage),
    }),
    isInstantACHEnabled: fragmentData.userProperties.showInstantAchUx ?? false,
  };
}

export type SourceOfFundsToCommonFormatRequiredData = ReturnType<
  typeof useSourceOfFundsToCommonFormatRequiredData
>;
