import { Suspense } from 'react';
import type { ReactNode } from 'react';
import { Loader } from '@onramp/components/Loader';
import { PageTitle } from '@onramp/components/PageTitle';

import type { ModalLayoutProps } from './Modal';
import { ModalLayout } from './Modal';

type PageWrapperProps = {
  headerText?: string;
  children: ReactNode;
  loadingElement?: ReactNode;
  pageTitle?: string;
  borderedHeader?: boolean;
  hideHamburgerMenu?: boolean;
  hasPageSuspense?: boolean;
} & Pick<ModalLayoutProps, 'onBack'>;

/**
 * PageWrapper component to be consumed by various screens/guards. Technically only contains header/body (not footer).
 */
export const PageWrapper = ({
  headerText = '',
  onBack,
  children,
  loadingElement = <Loader />,
  pageTitle = headerText,
  borderedHeader,
  hideHamburgerMenu,
  hasPageSuspense = true,
}: PageWrapperProps) => {
  return (
    <>
      <PageTitle title={pageTitle} />
      <ModalLayout
        title={pageTitle}
        onBack={onBack}
        borderedHeader={borderedHeader}
        hideHamburgerMenu={hideHamburgerMenu}
      >
        {hasPageSuspense ? (
          <Suspense fallback={loadingElement}>{children}</Suspense>
        ) : (
          <>{children}</>
        )}
      </ModalLayout>
    </>
  );
};
