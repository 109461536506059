import type {
  readFiatWalletsAccountFragment$data,
  readFiatWalletsAccountFragment$key,
} from '@onramp/data/__generated__/readFiatWalletsAccountFragment.graphql';
import type { readFiatWalletsPaymentMethodFragment$key } from '@onramp/data/__generated__/readFiatWalletsPaymentMethodFragment.graphql';
import { readFiatWallet } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/readFiatWallet';
import type { SourceOfFundsToCommonFormatRequiredData } from '@onramp/utils/fragments/readSourceOfFundsToCommonFormat/useSourceOfFundsToCommonFormatRequiredData';
import { isNonEmpty } from '@onramp/utils/isNonEmpty';
import { graphql, readInlineData } from '@cbhq/data-layer';

const accountFragment = graphql`
  fragment readFiatWalletsAccountFragment on Account @inline {
    ...readFiatWalletAccountFragment
    uuid
    type
  }
`;
const paymentMethodFragment = graphql`
  fragment readFiatWalletsPaymentMethodFragment on PaymentMethodV2 @inline {
    ...readFiatWalletPaymentMethodFragment
    uuid
    type

    pickerData {
      __typename
      ... on CoinbaseFiatAccountPickerData {
        fiatAccount {
          uuid
        }
      }
    }
  }
`;

export function readFiatWallets({
  inputAccountsRef,
  paymentMethodsRef,
  readSourceOfFundsData,
}: {
  inputAccountsRef: readonly readFiatWalletsAccountFragment$key[];
  paymentMethodsRef: readonly readFiatWalletsPaymentMethodFragment$key[];
  readSourceOfFundsData: SourceOfFundsToCommonFormatRequiredData;
}) {
  const inputAccounts = inputAccountsRef.map((ref) => readInlineData(accountFragment, ref));
  const paymentMethods = paymentMethodsRef.map((ref) => readInlineData(paymentMethodFragment, ref));

  // Iterate once so we can directly access data in an object below.
  const cashAccountsObj = inputAccounts.reduce((acc, account) => {
    const uuid = account.uuid ?? '';
    if (account.type === 'FIAT' && uuid) acc[uuid] = account;
    return acc;
  }, {} as Record<string, readFiatWalletsAccountFragment$data>);

  const pairs = paymentMethods
    .map((pm) => {
      if (
        pm?.type === 'FIAT_ACCOUNT' &&
        pm?.pickerData?.__typename === 'CoinbaseFiatAccountPickerData'
      ) {
        const accountUuid = pm?.pickerData.fiatAccount.uuid ?? '';
        const account = cashAccountsObj[accountUuid];

        if (account) return { paymentMethod: pm, account };
      }

      return null;
    })
    .filter(isNonEmpty);

  return pairs
    .map(({ account, paymentMethod }) =>
      readFiatWallet({
        inputAccountRef: account,
        paymentMethodRef: paymentMethod,
        ...readSourceOfFundsData,
      }),
    )
    .filter(isNonEmpty);
}
