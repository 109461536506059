import { HandledError, reportError } from '../errors';

/** Reads the network name format and returns readable properties.
 *  Format: networks/<id>-<net> i.e. networks/mina-mainnet */
export function parseStoneIdToNetworkId(name: string | null = ''): string | undefined {
  const split = name?.split(/[/-]+/);
  const stoneId = split?.[1];

  if (!stoneId) {
    reportError(
      new HandledError({
        message: 'parseStoneIdToNetworkId was called with an empty stoneId',
      }).addMetadata({ networkName: name, stoneId }),
    );

    return undefined;
  }

  return stoneId;
}
