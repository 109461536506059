import { BaseError } from './errors';
import { InternalError } from './InternalError';

type CoerceErrorOptions = {
  /** The debug message to set if one does not already exist */
  defaultDebugMessage?: string;
  /** The class to fall back to if this the coerced error is not already a `BaseError` */
  fallback?: new (error: Error | string) => BaseError;
};

/**
 * Coerces a non-`InternalError` value into an `InternalError`, or returns its input if already a `InternalError`.
 * @param unknownError - The potentially non-`InternalError` value to coerce into an `InternalError` or return.
 */
export function coerceError(unknownError: unknown, options?: CoerceErrorOptions): InternalError;
export function coerceError<T extends BaseError>(unknownError: T, options?: CoerceErrorOptions): T;
export function coerceError(unknownError: unknown, options?: CoerceErrorOptions): InternalError {
  try {
    // eslint-disable-next-line no-inner-declarations
    function fallbackWithDebugMessage(error: Error | string) {
      const fallback = new (options?.fallback ?? InternalError)(error);
      fallback.debugMessage = options?.defaultDebugMessage;
      return fallback;
    }

    if (unknownError instanceof BaseError) {
      // eslint-disable-next-line no-param-reassign
      unknownError.debugMessage ??= options?.defaultDebugMessage;
      return unknownError;
    }

    if (unknownError instanceof Error) {
      return fallbackWithDebugMessage(unknownError);
    }

    if (typeof unknownError === 'string') {
      return fallbackWithDebugMessage(new Error(unknownError));
    }

    const type = typeof unknownError;

    if (
      unknownError === null ||
      ['bigint', 'boolean', 'number', 'symbol', 'undefined'].includes(type)
    ) {
      return fallbackWithDebugMessage(
        `Attempted to parse a primitive value as an error - ${unknownError}`,
      );
    }

    const objectError = unknownError as Record<string, unknown>;

    // This comes from relay sometimes
    if ('alreadyReportedError' in objectError) {
      const { alreadyReportedError } = objectError;

      if (typeof alreadyReportedError === 'string') {
        return fallbackWithDebugMessage(alreadyReportedError).addMetadata({
          objectError: JSON.stringify(objectError),
        });
      }

      if (typeof alreadyReportedError === 'object') {
        return fallbackWithDebugMessage(
          `Attempted to parse alreadyReportedError object as an error: ${stringifyError(
            alreadyReportedError,
          )}`,
        ).addMetadata({
          objectError: JSON.stringify(objectError),
        });
      }
    }

    return fallbackWithDebugMessage(
      `When expecting an error, encountered a non-error in an unknown format: ${stringifyError(
        unknownError,
      )}`,
    );
  } catch (parsingError) {
    // This ensures that if this function has a bug, it's still handled downstream.
    if (parsingError instanceof BaseError) {
      parsingError.debugMessage ??= options?.defaultDebugMessage;
      return parsingError;
    }

    // This should be impossible to hit, but is here for complete safety & to make typescript happy
    return new InternalError(
      `Internal error enforcing the following as an error: ${stringifyError(unknownError)}`,
      {
        debugMessage: options?.defaultDebugMessage,
      },
    ).addMetadata({ parsingError: JSON.stringify(parsingError) });
  }
}

function stringifyError(unknownError: unknown) {
  return JSON.stringify(unknownError, Object.getOwnPropertyNames(unknownError));
}
