import Head from 'next/head';

type PageTitleProps = {
  title?: string;
};

export const PAGE_TITLE_BASE = 'Coinbase Onramp';

export const PageTitle = ({ title }: PageTitleProps) => {
  return (
    <Head>
      <title>{title ? `${title} | ${PAGE_TITLE_BASE}` : PAGE_TITLE_BASE}</title>
    </Head>
  );
};
