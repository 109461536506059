/**
 * @generated SignedSource<<88c2a58a57f936b8628ae5ada21d9c8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CompleteWalletVerificationMonorailInput = {
  paymentMethodUuid: string;
  pmsvcId?: string | null;
};
export type useRunDigitalWalletVerificationMutation$variables = {
  input: CompleteWalletVerificationMonorailInput;
};
export type useRunDigitalWalletVerificationMutation$data = {
  readonly completeWalletVerificationMonorail: {
    readonly __typename: "BadRequestError";
    readonly code: string;
    readonly message: string;
  } | {
    readonly __typename: "CompleteWalletVerificationMonorailSuccess";
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type useRunDigitalWalletVerificationMutation = {
  response: useRunDigitalWalletVerificationMutation$data;
  variables: useRunDigitalWalletVerificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "message",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useRunDigitalWalletVerificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "completeWalletVerificationMonorail",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/)
            ],
            "type": "CompleteWalletVerificationMonorailSuccess",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "BadRequestError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useRunDigitalWalletVerificationMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "completeWalletVerificationMonorail",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "type": "BadRequestError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "33e8f6a67d05227c2f602129f287832c",
    "id": null,
    "metadata": {},
    "name": "useRunDigitalWalletVerificationMutation",
    "operationKind": "mutation",
    "text": "mutation useRunDigitalWalletVerificationMutation(\n  $input: CompleteWalletVerificationMonorailInput!\n) {\n  completeWalletVerificationMonorail(input: $input) {\n    __typename\n    ... on CompleteWalletVerificationMonorailSuccess {\n      __typename\n    }\n    ... on BadRequestError {\n      __typename\n      message\n      code\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dc5ed87bc01222da6f16f8393e7060bc";

export default node;
