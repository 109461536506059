/**
 * @generated SignedSource<<638d85a9f1f922fd0b17a568ff93af16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readCBPayAssetUuidFragment$data = {
  readonly uuid: string | null;
  readonly " $fragmentType": "readCBPayAssetUuidFragment";
};
export type readCBPayAssetUuidFragment$key = {
  readonly " $data"?: readCBPayAssetUuidFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readCBPayAssetUuidFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readCBPayAssetUuidFragment"
};

(node as any).hash = "5f63ca00cef619d95d862b5b87a7d55c";

export default node;
