/**
 * @generated SignedSource<<e121748bd95b0bbacbf00f501847e074>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type readAccountBalanceFiatFragment$data = {
  readonly assetOrFiatCurrency: {
    readonly assetBalanceBreakdown?: {
      readonly availableToSendBalanceFiat: {
        readonly currency: string;
        readonly value: string;
      } | null;
    } | null;
  } | null;
  readonly " $fragmentType": "readAccountBalanceFiatFragment";
};
export type readAccountBalanceFiatFragment$key = {
  readonly " $data"?: readAccountBalanceFiatFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"readAccountBalanceFiatFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "readAccountBalanceFiatFragment"
};

(node as any).hash = "884d4cc01f3104ea12eb17f473f8f740";

export default node;
