// TODO [ONRAMP-1782]: Move this to the hook folder and update references.

import { useCallback, useRef } from 'react';

import type { AnyFunction } from './types';

/**
 * Creates a stable reference to a function which maintains an up to date reference of closures. It will never change *and*
 * will never reference stale data. The returned function can be used in a dependency array without triggering re-runs.
 *
 * @example
 *  function Component() {
 *    const [hasSynced, setHasSynced] = useState(false);
 *    const logSyncStatus = useReffedFunction(() => console.log({ hasSynced }));
 *
 *    useEffect(() => {
 *      sync().then(() => setHasSynced(true));
 *    }, []);
 *
 *    useEffect(() => {
 *      // will always log up to date data
 *      logSyncStatus();
 *    }, [hasSynced]);
 *
 *    // equivalent to `useEffect(() => {}, [])` because `logSyncStatus` never changes
 *    useEffect(() => {}, [logSyncStatus]);
 *
 *    return null;
 *  }
 */
export const useReffedFunction = <Fn extends AnyFunction>(fn: Fn) => {
  const fnRef = useRef(fn);
  fnRef.current = fn;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback<Fn>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    ((...args: Parameters<Fn>): ReturnType<Fn> => fnRef.current(...args)) as Fn,
    [fnRef],
  );
};
