import type { readAccountBalanceFiatFragment$key } from '@onramp/data/__generated__/readAccountBalanceFiatFragment.graphql';
import { graphql, readInlineData } from '@cbhq/data-layer';

const fragment = graphql`
  fragment readAccountBalanceFiatFragment on Account @inline {
    assetOrFiatCurrency {
      ... on TiersCurrency {
        assetBalanceBreakdown {
          availableToSendBalanceFiat {
            currency
            value
          }
        }
      }
      ... on ViewerAsset {
        assetBalanceBreakdown {
          availableToSendBalanceFiat {
            currency
            value
          }
        }
      }
    }
  }
`;

export const readAccountBalanceFiat = (accountRef: readAccountBalanceFiatFragment$key | null) => {
  const data = readInlineData(fragment, accountRef);
  const { currency, value } =
    data?.assetOrFiatCurrency?.assetBalanceBreakdown?.availableToSendBalanceFiat ?? {};

  return { currency, value: value ?? '0' };
};
