import { defineMessages, useIntl } from 'react-intl';
import { Banner } from '@cbhq/cds-web/banner/Banner';
import { Divider, VStack } from '@cbhq/cds-web/layout';

const messages = defineMessages({
  title: {
    id: 'BuyFlowKillSwitchBanner.title',
    description: 'Title of the disclaimer letting users know of the upcoming exchange outage',
    defaultMessage: 'Buying crypto is temporarily unavailable',
  },
  content: {
    id: 'BuyFlowKillSwitchBanner.content',
    description: 'Content of the disclaimer letting users know of the upcoming exchange outage',
    defaultMessage:
      'We’re upgrading our payment system. Try again in a few hours or transfer crypto instead.',
  },
});

export const BuyFlowKillSwitchBanner = () => {
  const { formatMessage } = useIntl();
  return (
    <VStack>
      <Banner
        title={formatMessage(messages.title)}
        startIcon="info"
        variant="informational"
        bordered={false}
      >
        {formatMessage(messages.content)}
      </Banner>
      <Divider />
    </VStack>
  );
};
