import { useCallback } from 'react';
import { usePrefixedKillSwitches } from '@onramp/hooks/usePrefixedKillSwitches';
import type { SelectedAsset } from '@onramp/state/BuyWidgetState';

import { useIsNetworkKillSwitched } from './useIsNetworkKillSwitched';

export const useIsAssetKillSwitched = () => {
  const assetKillSwitches = usePrefixedKillSwitches('kill_cbpay_asset_');
  const { isNetworkKilledByDisplayName } = useIsNetworkKillSwitched();

  const isAssetKilledByTicker = useCallback(
    (asset: string) => {
      // check for killed assets
      return assetKillSwitches.includes(asset.toLowerCase());
    },
    [assetKillSwitches],
  );

  const isAssetKilled = useCallback(
    (asset: SelectedAsset) => {
      // check for killed assets
      if (isAssetKilledByTicker(asset.ticker)) {
        return true;
      }

      const defaultNetwork = asset.networks?.find((network) => network.isDefault);
      if (defaultNetwork?.displayName) {
        return isNetworkKilledByDisplayName(defaultNetwork.displayName);
      }

      return false;
    },
    [isAssetKilledByTicker, isNetworkKilledByDisplayName],
  );

  return {
    isAssetKilled,
    isAssetKilledByTicker,
  };
};
