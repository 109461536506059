import { useIntl } from 'react-intl';
import type { ErrorHandlingParameters } from '@onramp/utils/errors/types';
import exitWidget from '@onramp/utils/exitWidget';
import { genericMessages } from '@onramp/utils/genericMessages';
import { useLogWidgetMetricOnceOnMount, useLogWidgetMetricOnMount } from '@onramp/utils/metrics';
import { isMobileExperience } from '@onramp/utils/postMessage';
import type {
  IllustrationHeroSquareNames,
  IllustrationPictogramNames,
  IllustrationSpotSquareNames,
} from '@cbhq/cds-web';
import { Button } from '@cbhq/cds-web/buttons/Button';
import { HeroSquare, Pictogram, SpotSquare } from '@cbhq/cds-web/illustrations';
import { Box } from '@cbhq/cds-web/layout';
import { VStack } from '@cbhq/cds-web/layout/VStack';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { useIsInitialScreen } from '../InitialScreenProvider';
import type { ModalLayoutProps } from '../Modal';
import { ModalLayout } from '../Modal';

type GenericErrorScreenProps = {
  variant?: 'modal' | 'fill';
  modalTitle?: string;
} & Pick<ModalLayoutProps, 'onBack'> &
  GenericErrorViewProps;

type GenericErrorViewProps = {
  title?: string;
  subtitle?: string | JSX.Element;
  onTryAgain?: () => void;
  showExitButton?: boolean;
  retryButtonTitle?: string;
  exitButtonTitle?: string;
  pictogramName?: ErrorHandlingParameters['pictogramName'];
  secondaryButtonTitle?: string;
  onSecondaryAction?: () => void;
};

export const GenericErrorView = ({
  title,
  subtitle,
  onTryAgain,
  showExitButton = false,
  retryButtonTitle,
  exitButtonTitle,
  pictogramName = 'warning',
  secondaryButtonTitle,
  onSecondaryAction,
}: GenericErrorViewProps) => {
  // 'error-screen' is the only critical step that's allowed to be logged multiple times because it's not used for funnel-like analysis
  useLogWidgetMetricOnMount({
    metricName: 'critical_step',
    tags: { step: 'error-screen' },
    value: 1,
  });
  useLogWidgetMetricOnceOnMount({
    metricName: 'critical_step',
    tags: { step: 'session-with-error-screen' },
    value: 1,
  });

  const { formatMessage } = useIntl();

  return (
    <VStack testID="generic-error-screen" as="main" spacing={3} height="100%">
      <VStack flexGrow={1} alignItems="center">
        <Box
          spacingVertical={isHero(pictogramName) || isSpot(pictogramName) ? 3 : 8}
          justifyContent="center"
        >
          {isSpot(pictogramName) && (
            <SpotSquare name={getSpotName(pictogramName)} dimension="96x96" />
          )}
          {isHero(pictogramName) && (
            <HeroSquare name={getHeroName(pictogramName)} dimension="200x200" />
          )}
          {isPictogram(pictogramName) && (
            // Don't use anything larger than 64x64! Larger sizes are deprecated.
            <Pictogram name={pictogramName} dimension="64x64" />
          )}
        </Box>
        <TextTitle3 as="h3" color="foreground" spacingTop={2} spacingBottom={1} align="center">
          {title ?? formatMessage(genericMessages.somethingWentWrong)}
        </TextTitle3>
        <TextBody as="p" color="foregroundMuted" spacingBottom={3} align="center">
          {subtitle ?? formatMessage(genericMessages.pleaseTryAgainLater)}
        </TextBody>
        {showExitButton && (
          <Box alignSelf="stretch">
            <Button variant="secondary" block onPress={exitWidget}>
              {exitButtonTitle ?? formatMessage(genericMessages.goBack)}
            </Button>
          </Box>
        )}
      </VStack>
      <VStack gap={1}>
        {onTryAgain !== undefined && (
          <Box>
            <Button block onPress={onTryAgain}>
              {retryButtonTitle ?? formatMessage(genericMessages.tryAgain)}
            </Button>
          </Box>
        )}
        {onSecondaryAction !== undefined && (
          <Box>
            <Button variant="secondary" block onPress={onSecondaryAction}>
              {secondaryButtonTitle ?? formatMessage(genericMessages.cancel)}
            </Button>
          </Box>
        )}
      </VStack>
    </VStack>
  );
};

export const GenericErrorScreen = ({
  modalTitle = 'An error occurred',
  onBack: incomingOnBack,
  variant = 'modal',
  showExitButton = false,
  ...viewProps
}: GenericErrorScreenProps) => {
  const isInitialScreen = useIsInitialScreen();
  const shouldShowFallbackExitButton = isMobileExperience() && isInitialScreen;
  const shouldShowExitButton = showExitButton || (isMobileExperience() && !incomingOnBack);

  const onBack = incomingOnBack ?? (shouldShowFallbackExitButton ? exitWidget : undefined);

  // Variant is not currently set by any of the consumers of this component, but let's leave it here in case we want it later.
  if (variant === 'modal') {
    return (
      <ModalLayout title={modalTitle} onBack={onBack}>
        <GenericErrorView {...viewProps} showExitButton={shouldShowExitButton} />
      </ModalLayout>
    );
  }

  return <GenericErrorView {...viewProps} showExitButton={shouldShowExitButton} />;
};

const HeroRegex = /^hero:(.*)/;
const SpotRegex = /^spot:(.*)/;
const isHero = (
  name: GenericErrorScreenProps['pictogramName'],
): name is `hero:${IllustrationHeroSquareNames}` =>
  typeof name === 'string' && HeroRegex.test(name);

const isSpot = (
  name: GenericErrorScreenProps['pictogramName'],
): name is `spot:${IllustrationSpotSquareNames}` =>
  typeof name === 'string' && SpotRegex.test(name);

const isPictogram = (
  name: GenericErrorScreenProps['pictogramName'],
): name is IllustrationPictogramNames => typeof name === 'string' && !HeroRegex.test(name);

const getHeroName = (name: `hero:${IllustrationHeroSquareNames}`) =>
  name.match(HeroRegex)?.[1] as IllustrationHeroSquareNames;

const getSpotName = (name: `spot:${IllustrationSpotSquareNames}`) =>
  name.match(SpotRegex)?.[1] as IllustrationSpotSquareNames;
