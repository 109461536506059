import type {
  readDepositInformationFragment$data,
  readDepositInformationFragment$key,
} from '@onramp/data/__generated__/readDepositInformationFragment.graphql';
import {
  FasterPaymentsDepositInformation,
  SepaDepositInformation,
} from '@onramp/state/types/DepositInformation';
import { coerceError, reportError } from '@onramp/utils/errors';
import { isNonEmpty } from '@onramp/utils/isNonEmpty';
import type { z } from 'zod';
import { graphql, readInlineData } from '@cbhq/data-layer';

const readDepositInformationFragment = graphql`
  fragment readDepositInformationFragment on Account @inline {
    depositInformation {
      isDepositAllowed
      depositCurrency
      availableDepositMethods {
        isActive
        type
        depositInformation {
          key
          title
          value
        }
      }
    }
  }
`;

type ReadDepositInformationArgs = {
  inputAccountsRef: readonly readDepositInformationFragment$key[];
};

export function readDepositInformation({ inputAccountsRef }: ReadDepositInformationArgs) {
  const depositInformationEntries = inputAccountsRef.map((ref) =>
    readInlineData(readDepositInformationFragment, ref),
  );

  const gbpDepositInformation = depositInformationEntries.find(
    (entry) => entry.depositInformation?.depositCurrency === 'GBP',
  )?.depositInformation;

  const eurDepositInformation = depositInformationEntries.find(
    (entry) => entry.depositInformation?.depositCurrency === 'EUR',
  )?.depositInformation;

  const fasterPaymentsDepositInformation = getDepositMethodByType(
    gbpDepositInformation,
    'uk_bank_account',
  );
  const sepaDepositInformation = getDepositMethodByType(eurDepositInformation, 'sepa');

  return {
    fasterPaymentsDepositInformation,
    sepaDepositInformation,
  };
}

type AvailableDepositMethod = NonNullable<
  NonNullable<
    NonNullable<
      readDepositInformationFragment$data['depositInformation']
    >['availableDepositMethods']
  >[0]
>;

function getDepositMethodByType(
  depositInformation: readDepositInformationFragment$data['depositInformation'] | undefined,
  type: 'uk_bank_account',
): z.infer<typeof FasterPaymentsDepositInformation> | null;
function getDepositMethodByType(
  depositInformation: readDepositInformationFragment$data['depositInformation'] | undefined,
  type: 'sepa',
): z.infer<typeof SepaDepositInformation> | null;
function getDepositMethodByType(
  depositInformation: readDepositInformationFragment$data['depositInformation'] | undefined,
  type: string,
) {
  if (!depositInformation?.isDepositAllowed) {
    return null;
  }

  const depositMethods =
    depositInformation?.availableDepositMethods
      ?.filter(isNonEmpty)
      .filter((method) => method.isActive) ?? [];

  for (const method of depositMethods) {
    if (method.type === type) {
      const innerDepositInformation = parseDepositInformation(method);
      if (innerDepositInformation !== null) {
        return innerDepositInformation;
      }
    }
  }

  return null;
}

function parseDepositInformation({ depositInformation, type }: AvailableDepositMethod) {
  try {
    const maybeDepositInformation = formatDepositInformationObject(type, depositInformation);

    if (type === 'uk_bank_account') {
      return FasterPaymentsDepositInformation.parse(maybeDepositInformation);
    }
    if (type === 'sepa') {
      return SepaDepositInformation.parse(maybeDepositInformation);
    }

    return null;
  } catch (error) {
    reportError(
      coerceError(error).addMetadata({
        source: 'parseDepositInformation',
      }),
    );
    return null;
  }
}

function formatDepositInformationObject(
  type: string | null,
  fields: AvailableDepositMethod['depositInformation'],
) {
  const reducer = <T>(
    agg: T,
    info: NonNullable<AvailableDepositMethod['depositInformation']>[number] | null,
  ) =>
    !info?.key || !info.title || !info?.value
      ? agg
      : {
          ...agg,
          [info.key]: info,
        };

  if (type === 'sepa') {
    return fields?.reduce<Partial<z.infer<typeof SepaDepositInformation>>>(reducer, {});
  }

  if (type === 'uk_bank_account') {
    return fields?.reduce<Partial<z.infer<typeof FasterPaymentsDepositInformation>>>(reducer, {});
  }

  return null;
}
