import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import type { useSelectAssetTabsFragment$key } from '@onramp/data/__generated__/useSelectAssetTabsFragment.graphql';
import { useEffectOnMount } from '@onramp/hooks/useEffectOnMount';
import { useBuyWidgetState } from '@onramp/state/BuyWidgetState';
import { useLogOnrampEvent } from '@onramp/utils/eventing/useLogOnrampEvent';
import { isNotNullish } from '@onramp/utils/types';
import { graphql, useFragment } from '@cbhq/data-layer';

import { filterCryptoAccountsByBalance } from '../fragments/filterCryptoAccountsByBalance';
import { readAccountViewerAssetUuid } from '../fragments/readAccountViewerAssetUuid';
import { readCBPayAssetUuid } from '../fragments/readCBPayAssetUuid';

/** We are setting the minimimum required balance to default to the buy tab to be 1
 *  so it removes any accounts with some dust in there from previous transactions */
const MINIMUM_REQUIRED_BALANCE = 1;

const useSelectAssetTabsFragment = graphql`
  fragment useSelectAssetTabsFragment on Viewer
  @argumentDefinitions(filter: { type: CBPayAssetFilters }) {
    cbPayTradableAssets(filter: $filter) {
      edges {
        node {
          ...readCBPayAssetUuidFragment
        }
      }
    }
    accountsV2 {
      edges {
        node {
          ...filterCryptoAccountsByBalanceFragment
          ...readAccountViewerAssetUuidFragment
        }
      }
    }
  }
`;

const messages = defineMessages({
  sendTabTitle: {
    id: 'useSelectAssetTabs.sendTabTitle',
    description: 'The title for the send tab in the asset selection screen',
    defaultMessage: 'Transfer from Coinbase',
  },
  buyTabTitle: {
    id: 'useSelectAssetTabs.buyTabTitle',
    description: 'The title for the buy tab in the asset selection screen',
    defaultMessage: 'Buy new asset',
  },
});

export type TabId = 'buy' | 'send';

const selectAssetTabs = [
  { id: 'send', label: messages.sendTabTitle },
  { id: 'buy', label: messages.buyTabTitle },
] as const;

/** Handles selecting the default tab */
export const useSelectAssetTabs = (
  viewerRef: useSelectAssetTabsFragment$key,
  defaultTab?: TabId,
  firstTabId?: TabId,
) => {
  const logOnrampEvent = useLogOnrampEvent();
  const { selectAssetPageActiveTab } = useBuyWidgetState();
  const [activeTab, setActiveTab] = useState<TabId | undefined>(
    selectAssetPageActiveTab.value ?? defaultTab,
  );
  const { accountsV2, cbPayTradableAssets } = useFragment(useSelectAssetTabsFragment, viewerRef);

  useEffectOnMount(function initSelectAssetPageActiveTab() {
    if (!selectAssetPageActiveTab.value) {
      selectAssetPageActiveTab.onChange(defaultTab);
    }
  });

  useEffect(() => {
    if (!activeTab && accountsV2 && cbPayTradableAssets) {
      const cryptoAccountsWithBalance = accountsV2.edges
        .map((n) => n.node)
        .filter(isNotNullish)
        .filter((account) => {
          return (
            filterCryptoAccountsByBalance(account, MINIMUM_REQUIRED_BALANCE) &&
            // Needs to be in the available tradable assets as well, otherwise we might show
            cbPayTradableAssets?.edges
              ?.map((n) => n?.node)
              .filter(isNotNullish)
              .some((asset) => readCBPayAssetUuid(asset) === readAccountViewerAssetUuid(account))
          );
        });

      let newActiveTab: TabId;
      if (cryptoAccountsWithBalance.length > 0) {
        newActiveTab = 'send';
      } else {
        newActiveTab = 'buy';
      }

      logOnrampEvent('viewed_default_asset_tab', { tab_id: newActiveTab });
      setActiveTab(newActiveTab);
      selectAssetPageActiveTab.onChange(newActiveTab);
    }
  }, [
    activeTab,
    accountsV2,
    cbPayTradableAssets,
    defaultTab,
    logOnrampEvent,
    selectAssetPageActiveTab,
  ]);

  const handleSelectTab = useCallback(
    (id: TabId) => {
      logOnrampEvent('clicked_select_asset_tab_button', { tab_id: id });
      setActiveTab(id);
      selectAssetPageActiveTab.onChange(id);
    },
    [logOnrampEvent, selectAssetPageActiveTab],
  );

  const { formatMessage } = useIntl();
  const tabs = useMemo(() => {
    const localizedTabs = selectAssetTabs.map((tab) => ({
      id: tab.id,
      label: formatMessage(tab.label),
    }));

    if (firstTabId) {
      const firstTabIndex = localizedTabs.findIndex((t) => t.id === firstTabId);

      // if not found or already the first tab, do nothing
      if (firstTabIndex !== -1 && firstTabIndex !== 0) {
        // removes the the tab from its original position
        const [firstTab] = localizedTabs.splice(firstTabIndex, 1);
        // and adds it back to the beginning of the array
        localizedTabs.unshift(firstTab);
      }
    }

    return localizedTabs;
  }, [formatMessage, firstTabId]);

  return { tabs, activeTab, handleSelectTab };
};
