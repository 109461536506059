import { useMemo, useRef } from 'react';
import type { useRunChecksAgainstAssetListFragment$key } from '@onramp/data/__generated__/useRunChecksAgainstAssetListFragment.graphql';
import type { SelectedAsset } from '@onramp/state/BuyWidgetState';
import { useDestinationWalletsV2 } from '@onramp/state/recoil/appParamsState';
import { isNotNullish } from '@onramp/utils/types';
import { graphql, useFragment } from '@cbhq/data-layer';

import { readCBPayAssetAsOnrampState } from '../fragments/readCBPayAssetAsOnrampState';
import type { SelectAssetCallbackProps } from '../index.page';

import { useFilterCBPayAssetsByDestinationWallets } from './useFilterCBPayAssetsByDestinationWallets';
import { useValidateAssets } from './useValidateAssets';

const useRunChecksAgainstAssetListFragment = graphql`
  fragment useRunChecksAgainstAssetListFragment on Viewer
  @argumentDefinitions(filter: { type: CBPayAssetFilters }) {
    cbPayTradableAssets(filter: $filter) {
      edges {
        node {
          ...useFilterCBPayAssetsByDestinationWalletsFragment
          ...readCBPayAssetAsOnrampStateFragment
        }
      }
    }
  }
`;

/** Checks whether we should auto select an asset and continue to the next screen */
export const useRunChecksAgainstAssetList = (
  viewerRef: useRunChecksAgainstAssetListFragment$key,
  onSelect?: (data: SelectAssetCallbackProps) => void,
) => {
  const hasAlreadyAutoSelected = useRef(false);
  const selectedAsset = useSelectAssetFromList(viewerRef);
  if (selectedAsset !== undefined && !hasAlreadyAutoSelected.current) {
    onSelect?.({ asset: selectedAsset });
    hasAlreadyAutoSelected.current = true;
  }
};

/**
 * Checks the desinationWallets init param to see if only one asset should be available to the user. If so we return
 * that asset, otherwise return undefined.
 */
export function useSelectAssetFromList(
  viewerRef: useRunChecksAgainstAssetListFragment$key,
): SelectedAsset | undefined {
  const { shouldAutoSelectAsset } = useDestinationWalletsV2();
  const filterAssetsByDestinationWallets = useFilterCBPayAssetsByDestinationWallets();
  const data = useFragment(useRunChecksAgainstAssetListFragment, viewerRef);

  const filteredAssets = useMemo(
    () =>
      data.cbPayTradableAssets?.edges
        ?.map((e) => e?.node)
        .filter(isNotNullish)
        .filter(filterAssetsByDestinationWallets) || [],
    [data, filterAssetsByDestinationWallets],
  );

  // Validate asset or network not available
  useValidateAssets(filteredAssets, '');

  // Autoselect asset
  if (shouldAutoSelectAsset && filteredAssets.length === 1) {
    const selectedAsset = readCBPayAssetAsOnrampState(filteredAssets[0]);
    return selectedAsset;
  }

  return undefined;
}
